import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactElement, useReducer, Reducer, useState, useEffect } from "react";
import ClientContactHttpService from "../../Http/ClientContact/ClientContact.http.service";
import { CreateClientContactDTO } from "../../Types/ClientContact";
import { Gender, SelectOption } from "../../Types/Common";
import { BusinessUnitHttpService } from "../../Http/BusinessUnit/BusinessUnit.http.service";
import { SelectInput } from "../UI/InputFields/SelectInput";
interface CreateContactModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleSave: () => void;
}

const genders = ["male", "female", "non-binary"];

export default function CreateClientContactModal(
  props: CreateContactModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [contact, setContact] = useReducer<
    Reducer<CreateClientContactDTO, Partial<CreateClientContactDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    {} as CreateClientContactDTO
  );

  const [businessUnitsSelectOptions, setBusinessUnitsSelectOptions] = useState<
    SelectOption[]
  >([]);

  const handleCreateContact = async () => {
    if (contact.name != null && contact.businessUnitId != null) {
      await ClientContactHttpService.createContact(contact);
      props.handleSave();
      props.setModalOpen(false);
    }
  };

  useEffect(() => {
    BusinessUnitHttpService.getBusinessUnits().then((results) => {
      const businessUnitSelectOptions = results.map((businessUnit) => ({
        id: businessUnit.id as number,
        name: businessUnit.name,
      }));
      setBusinessUnitsSelectOptions(businessUnitSelectOptions);
    });
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      id="client-create-view"
    >
      <DialogTitle>Create Client Contact</DialogTitle>
      <DialogContent>
        <Box
          noValidate
          autoComplete="off"
          component="form"
          display="flex"
          flexDirection="column"
          gap={1}
          padding={1}
        >
          <Typography variant="h6">Personal Information</Typography>
          <Box display="flex" flexDirection="row" gap={3}>
            <TextField
              label="Name"
              id="name"
              variant="outlined"
              required
              fullWidth
              value={contact.name}
              onChange={(event) => setContact({ name: event.target.value })}
            />
            <TextField
              label="Gender"
              id="gender"
              variant="outlined"
              fullWidth
              select
              defaultValue={null}
              onChange={(event) =>
                setContact({ gender: event.target.value as Gender })
              }
            >
              {genders.map((gender) => (
                <MenuItem key={gender} value={gender}>
                  {gender}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Typography variant="h6" mt={4}>
            Company
          </Typography>
          <Box display="flex" flexDirection="row" gap={3}>
            <TextField
              label="Title"
              id="title"
              variant="outlined"
              fullWidth
              required
              value={contact.title}
              onChange={(event) => setContact({ title: event.target.value })}
            />
            <TextField
              label="Department Code"
              id="departmentCode"
              variant="outlined"
              fullWidth
              value={contact.departmentCode}
              onChange={(event) =>
                setContact({ departmentCode: event.target.value })
              }
            />
          </Box>
          <Box display="flex" flexDirection="row" gap={3} mt={2}>
            <SelectInput
              labelText="Organizational Unit"
              selectValues={businessUnitsSelectOptions}
              value={contact.businessUnitId}
              onChange={(event) =>
                setContact({ businessUnitId: parseInt(event.target.value) })
              }
              editMode={true}
              required
              fullWidth
              ignoreValidation
            />
          </Box>
          <Typography variant="h6" mt={4}>
            Contact
          </Typography>
          <Box display="flex" flexDirection="row" gap={3}>
            <TextField
              label="Phone Number"
              id="phone"
              variant="outlined"
              fullWidth
              value={contact.phone}
              onChange={(event) => setContact({ phone: event.target.value })}
            />
            <TextField
              label="Email"
              id="email"
              variant="outlined"
              required
              fullWidth
              value={contact.email}
              onChange={(event) => setContact({ email: event.target.value })}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => props.setModalOpen(false)}
          color="primary"
          id="close-client-create-view"
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreateContact}
          variant="contained"
          color="secondary"
          id="save-client-create-view"
        >
          Create Contact
        </Button>
      </DialogActions>
    </Dialog>
  );
}
