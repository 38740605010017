import {
  ProjectStatusAndCount,
  ProjectCount,
  ProjectsCountDto,
  ProjectEvent,
  ProjectEventDTO,
} from "../../Types/Analytics";

export default class AnalyticsMapper {
  public static mapProjectCount(
    projectCountByStage: ProjectsCountDto,
    status?: "active"
  ): ProjectCount[] {
    const getProjectCount = (projects: ProjectStatusAndCount[]) => {
      if (status === "active") {
        projects = projects.filter((project) => project.status === "active");
      }
      return projects.reduce(
        (sum, project) => sum + parseInt(project.projectCount),
        0
      );
    };

    return [
      {
        id: "discover",
        label: "Discovery",
        value: getProjectCount(projectCountByStage.discover),
      },
      {
        id: "assess",
        label: "Assessment",
        value: getProjectCount(projectCountByStage.assess),
      },
      {
        id: "buy",
        label: "Buy",
        value: getProjectCount(projectCountByStage.buy),
      },
      {
        id: "pilot",
        label: "Pilot",
        value: getProjectCount(projectCountByStage.pilot),
      },
      {
        id: "adopt",
        label: "Adoption",
        value: getProjectCount(projectCountByStage.adopt),
      },
    ];
  }

  public static mapEvents(events: ProjectEventDTO[]): ProjectEvent[] {
    const mappedUpcomingEvents = events.map((event) => {
      return {
        ...event,
        date: new Date(event.date),
      };
    });
    return mappedUpcomingEvents;
  }
}
