import { useContext, useEffect, useState } from "react";
import { ImpactValueHttpService } from "../../../../Http/ImpactValue/ImpactValue.http.service";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../utils";
import { ImpactValue, VariableType } from "../../../../Types/ImpactValue";
import { Project } from "../../../../Types/Project";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";

function sortVariablesInCalculations(impactValues: ImpactValue[]) {
  impactValues.forEach((impactValue) => {
    if (impactValue.calculations) {
      impactValue.calculations.sort((a, b) => a.id - b.id);
      impactValue.calculations.forEach((calculation) => {
        if (calculation.variables) {
          calculation.variables.sort(
            (a: VariableType, b: VariableType) => a.id - b.id
          );
        }
      });
    }
  });
  return impactValues;
}

interface UseFetchImpactValueReturns {
  impactValues: ImpactValue[];
  setImpactValues: React.Dispatch<React.SetStateAction<ImpactValue[]>>;
  fetchImpactValues: () => void;
}

function useFetchImpactValue(project: Project): UseFetchImpactValueReturns {
  const { enqueueSnackbar } = useSnackbar();
  const { activeStep } = useContext(GlobalProjectEditContext);
  const [impactValues, setImpactValues] = useState<ImpactValue[]>([]);

  const isDiscover = activeStep === 0;
  const isBuy = activeStep === 2;
  const isPilot = activeStep === 3;
  const shouldFetch = isDiscover || isBuy || isPilot;
  const { id, funnelStage } = project;

  const stageToFetch = () => {
    if (isDiscover) {
      return "discover";
    } else if (isBuy) {
      return "buy";
    } else if (isPilot) {
      return "pilot";
    } else {
      return funnelStage;
    }
  };

  const fetchImpactValues = () => {
    if (shouldFetch) {
      ImpactValueHttpService.getImpactValues(id, stageToFetch())
        .then((impactValues) => {
          const sortedImpactValues = sortVariablesInCalculations(impactValues);
          setImpactValues(sortedImpactValues);
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error);
          enqueueSnackbar(`Could not fetch impact values: ${errorMessage}`, {
            variant: "error",
          });
        });
    }
  };

  useEffect(() => {
    fetchImpactValues();
  }, []);

  return { impactValues, setImpactValues, fetchImpactValues };
}

export default useFetchImpactValue;
