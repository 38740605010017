import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, Reducer, useEffect, useReducer } from "react";
import { CreateStartupDTO, Startup } from "../../../Types/Startup";
import { StartupHttpService } from "../../../Http/Startup/Startup.http.service";
import { getErrorMessage } from "../../../utils";
import { AxiosError } from "axios";
import CustomTextField from "../InputFields/CustomTextField";

interface AddNewStartupModal {
  startupName: string;
  handleModalClose: () => void;
  modalOpen: boolean;
  handleSelectStartup: (selectedStartup: Startup | null) => void;
  handleImport?: (id: number) => void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(1)}`,
  },
  "& .MuiAutocomplete-root": {
    marginTop: theme.spacing(1),
  },
}));

const initialModalState = {
  name: "",
  website: "",
} as CreateStartupDTO;

const AddNewStartupModal = ({ ...props }: AddNewStartupModal): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [newStartup, setNewStartup] = useReducer<
    Reducer<CreateStartupDTO, Partial<CreateStartupDTO>>
  >((state, newState) => ({ ...state, ...newState }), initialModalState);

  useEffect(() => {
    setNewStartup({ name: props.startupName });
  }, [props.startupName]);

  const showError = (value: string) => {
    enqueueSnackbar(`Please Enter ${value}`, {
      variant: "error",
    });
  };

  const hasName = () => !!newStartup.name;
  const hasWebsite = () => !!newStartup.website;

  const handleAddStartup = async () => {
    if (!hasName() && !hasWebsite())
      return showError("Company Name and Website");
    if (!hasName()) return showError("Company Name");
    if (!hasWebsite()) return showError("Website");

    try {
      const newStartupId = await StartupHttpService.createStartup(newStartup);
      const createdStartup = await StartupHttpService.getStartupById(
        newStartupId,
        true
      );
      props.handleSelectStartup(createdStartup);
    } catch (error) {
      const errorMessage = getErrorMessage(error as AxiosError);
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  return (
    <>
      <StyledDialog
        data-testid="add-new-startup-modal"
        open={props.modalOpen}
        onClose={props.handleModalClose}
        maxWidth="xs"
      >
        <DialogTitle>Add New Startup</DialogTitle>
        <DialogContent>
          <Grid spacing={2} container columns={1}>
            <Grid item xs={12}>
              <CustomTextField
                label="Company Name"
                id="startup-name"
                fullWidth
                required
                value={newStartup.name}
                onChange={(event) =>
                  setNewStartup({ name: event.target.value })
                }
                editMode={true}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Website"
                id="startup-website"
                fullWidth
                required
                value={newStartup.website}
                onChange={(event) =>
                  setNewStartup({ website: event.target.value })
                }
                editMode={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box display="flex" alignItems="flex-end" gap={1}>
            <Button onClick={props.handleModalClose} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddStartup}
            >
              Add Startup
            </Button>
          </Box>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default AddNewStartupModal;
