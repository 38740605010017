import { TableCell, TableRow, styled } from "@mui/material";
import { ReactElement, useState } from "react";
import EditDeleteMenu from "../../UI/EditDeleteMenu";
import { LeadProject, LeadProjectLeader } from "../../../Types/LeadProject";
import DeleteModal from "../../UI/Modals/DeleteModal/DeleteModal";
import ManageProjectLeadLeaderModal from "./ManageProjectLeadLeaderModal";

const HoverableTableRow = styled(TableRow)({
  "&:hover ": {
    ".deleteIcon": {
      opacity: 1,
    },
  },
});

interface ProjectProjectLeadLeadersProps {
  projectLeadLeader: LeadProjectLeader;
  onRefresh: () => void;
  leadProject: LeadProject;
}

const ProjectLeadLeadersTableRow = (
  props: ProjectProjectLeadLeadersProps
): ReactElement => {
  const [ProjectLeadLeaderModalOpen, setProjectLeadLeaderModalOpen] =
    useState(false);
  const [
    isEditProjectLeadLeaderModalOpen,
    setisEditProjectLeadLeaderModalOpen,
  ] = useState(false);

  const { projectLeadLeader } = props;

  const deleteLeadProjectLeader = async () => {
    props.onRefresh();
  };

  return (
    <>
      <HoverableTableRow
        data-testid={`lead-project-leader-${projectLeadLeader.id}`}
        key={projectLeadLeader.id}
      >
        <TableCell>{projectLeadLeader.businessUnit?.name}</TableCell>
        <TableCell align="left">
          {projectLeadLeader.projectLeader?.name}
        </TableCell>
        <TableCell align="left">{projectLeadLeader.useCase?.name}</TableCell>
        <TableCell align="left">
          <EditDeleteMenu
            entity="lead-project-leader"
            setEditModalOpen={setisEditProjectLeadLeaderModalOpen}
            setDeleteModalOpen={setProjectLeadLeaderModalOpen}
          />
        </TableCell>
      </HoverableTableRow>
      {ProjectLeadLeaderModalOpen && (
        <DeleteModal
          id={props.projectLeadLeader.id}
          parentId={props.leadProject.id}
          modalOpen={ProjectLeadLeaderModalOpen}
          setModalOpen={setProjectLeadLeaderModalOpen}
          entity="Project Lead Leader"
          handleSave={deleteLeadProjectLeader}
        />
      )}
      {isEditProjectLeadLeaderModalOpen && (
        <ManageProjectLeadLeaderModal
          projectLeadLeader={projectLeadLeader}
          setModalOpen={setisEditProjectLeadLeaderModalOpen}
          modalOpen={isEditProjectLeadLeaderModalOpen}
          leadProject={props.leadProject}
          onRefresh={props.onRefresh}
        />
      )}
    </>
  );
};

export default ProjectLeadLeadersTableRow;
