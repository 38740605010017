import { ReactElement } from "react";
import { StartupOpportunity } from "../../../../Types/Opportunity";
import { Box, Typography, styled } from "@mui/material";
import { getStartupStatus } from "../../../../utils";
import theme from "../../../../theme";

const StatCardsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1.5),
}));

const StatCardWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1),
  flex: 1,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.customBorderRadius.sm,
}));

const StatCardTextLarge = styled(Typography, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $color?: string }>(({ theme, $color }) => ({
  fontWeight: 300,
  color: $color ?? theme.palette.primary.main,
  lineHeight: theme.spacing(4),
  fontSize: theme.typography.h5.fontSize,
}));

interface StatCardProps {
  label: string;
  value: number;
}

const StatCard = (props: StatCardProps): ReactElement => {
  return (
    <StatCardWrapper>
      <Typography
        variant="caption"
        sx={{
          lineHeight: theme.spacing(2),
          fontWeight: 500,
          letterSpacing: "0.5px",
        }}
      >
        {props.label}
      </Typography>
      <StatCardTextLarge data-testid={`stat-card-value-${props.label}`}>
        {props.value}
      </StatCardTextLarge>
    </StatCardWrapper>
  );
};

interface ProjectStatsProps {
  opportunities: StartupOpportunity[];
}

export default function ProjectStats(props: ProjectStatsProps): ReactElement {
  const projectsInEvaluation = props.opportunities.filter(
    (opp) => getStartupStatus(opp) === "Evaluation"
  ).length;
  const projectsSelected = props.opportunities.filter(
    (opp) => getStartupStatus(opp) === "Selected"
  ).length;
  const projectsAdopted = props.opportunities.filter(
    (opp) => getStartupStatus(opp) === "Adopted"
  ).length;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      data-testid="relatedProjectsStatsSection"
    >
      <Box display="flex" gap={1}>
        <StatCardTextLarge $color={theme.palette.common.black}>
          Total
        </StatCardTextLarge>
        <StatCardTextLarge data-testid="stat-card-value-Total">
          {props.opportunities.length}
        </StatCardTextLarge>
      </Box>
      <StatCardsContainer>
        <StatCard label="Evaluation" value={projectsInEvaluation} />
        <StatCard label="Selected" value={projectsSelected} />
        <StatCard label="Adopted" value={projectsAdopted} />
      </StatCardsContainer>
    </Box>
  );
}
