import React, { ReactElement, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: "flex",
      width: "500px",
      flexDirection: "column",
      justifyContent: "center",
      padding: "20px",
      zIndex: 1,
    },
    passwordRequirements: {
      margin: 0,
      columns: 2,
    },
  })
);

interface Props {
  setFormType: (x: string) => void;
  setSignedIn: (x: boolean) => void;
  // eslint-disable-next-line
  user: any;
}

export default function ActiveAccount(props: Props): ReactElement {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const textFieldForPasswordRef = React.useRef<HTMLInputElement>(null);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleNewPassword = async () => {
    if (validatePassword(password, confirmPassword)) {
      try {
        await Auth.completeNewPassword(
          props.user,
          password,
          {},
          { tenantId: "vclms" }
        );
        props.setSignedIn(true);
        // eslint-disable-next-line
      } catch (error: any) {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      }
    }
  };

  const validatePassword = (password: string, password2: string): boolean => {
    if (password !== password2) {
      enqueueSnackbar("Password doesn't match.", {
        variant: "error",
      });
      return false;
    } else return true;
  };

  return (
    <Box className={classes.form}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="h4" align="center" sx={{ fontWeight: 500 }}>
          You are required to update your password
        </Typography>
        <Typography variant="caption" align="center">
          A new password is required when you are logging in for the first time.
        </Typography>
        <TextField
          id="password"
          label="New Password"
          autoComplete="new-password"
          type={showNewPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value as string)}
          inputProps={{
            onKeyPress: (event) => {
              if (event.key === "Enter" && textFieldForPasswordRef.current) {
                textFieldForPasswordRef.current.focus();
              }
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="activate-new-password-visibility-toggle"
                  aria-label="toggle new password visibility"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  sx={{
                    "& .MuiSvgIcon-root": { color: "white" },
                  }}
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="password-confirm"
          label="Confirm Password"
          autoComplete="new-password"
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          inputRef={textFieldForPasswordRef}
          onChange={(e) => setConfirmPassword(e.target.value as string)}
          inputProps={{
            onKeyPress: (event) => {
              if (event.key === "Enter") {
                handleNewPassword;
              }
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="confirm-password-visibility-toggle"
                  aria-label="toggle confirm password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  sx={{
                    "& .MuiSvgIcon-root": { color: "white" },
                  }}
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText sx={{ color: "white" }}>
          <ul className={classes.passwordRequirements}>
            <li>At least 12 characters</li>
            <li>Contains number</li>
            <li>Contains uppercase letter</li>
            <li>Contains lowercase letter</li>
          </ul>
        </FormHelperText>
        <Box gap={1} justifyContent="center" display="flex">
          <Button
            type="submit"
            onClick={() => props.setFormType("signIn")}
            variant="outlined"
            color="secondary"
            id="redirect-signIn-button"
            sx={{
              width: "200px",
              margin: "auto",
            }}
          >
            Back to Sign In
          </Button>
          <Button
            type="submit"
            onClick={handleNewPassword}
            variant="contained"
            color="secondary"
            id="verify-email-button"
            sx={{
              width: "200px",
              margin: "auto",
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
