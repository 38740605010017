import { createContext, ReactElement, useState } from "react";
import { ProjectFilterableProperties } from "../Types/Project";
const defaultFilters = {
  status: ["active"],
  businessUnitId: -1,
};

type KanbanFilterContextType = {
  activeFilters: Partial<ProjectFilterableProperties>;
  setFilterToLocalStorage: (
    filter?: Partial<ProjectFilterableProperties>
  ) => void;
};
type KanbanFilterContextProps = {
  children: React.ReactNode;
};

export const KanbanFilterContext = createContext<KanbanFilterContextType>({
  activeFilters: defaultFilters,
  setFilterToLocalStorage: () => null,
});

export const KanbanFilterContextProvider = (
  props: KanbanFilterContextProps
): ReactElement => {
  const [activeFilters, setActiveFilters] = useState(
    JSON.parse(localStorage.getItem("projectFilters") as string) ||
      defaultFilters
  );

  const setFilterToLocalStorage = (
    newFilter: Partial<ProjectFilterableProperties> = defaultFilters
  ) => {
    localStorage.setItem("projectFilters", JSON.stringify(newFilter));
    setActiveFilters(
      JSON.parse(localStorage.getItem("projectFilters") as string)
    );
  };

  return (
    <KanbanFilterContext.Provider
      value={{ activeFilters, setFilterToLocalStorage }}
    >
      {props.children}
    </KanbanFilterContext.Provider>
  );
};
