import { ReactElement, useContext, useState } from "react";
import { Chip, Paper, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Project } from "../../../Types/Project";
import Button from "@mui/material/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import EditProjectModal from "./EditProjectModal";
import ProjectInfoCard from "./ProjectInfoCard";
import theme from "../../../theme";
import { GlobalProjectEditContext } from "../../../Context/ProjectDetailsContext";
import { findLogo } from "../../../utils";
import { ProjectLeadMatch } from "../../../Types/ClientContact";
import { StartupMatch } from "../../../Types/Startup";

interface Props {
  projectDetails: Project;
  handleSave: () => void;
}

const Heading = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
}));

const OverviewWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  overflowWrap: "anywhere",
}));

const OverviewItemWrapper = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  gap: theme.spacing(3),
}));

const FocusAreaWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const StatusSectionWrapper = styled(Paper, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $status }: { $status: string }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  backgroundColor: $status === "active" ? "#f8f8ff" : theme.palette.grey[50],
  border: `1px solid  ${
    $status === "active" ? theme.palette.primary.main : theme.palette.divider
  }`,
}));

const StatusCommentWrapper = styled(Box)(() => ({
  "& .MuiTextField-root": {
    marginBottom: 0,
  },
}));

const StatusSectionHeading = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const dateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
} as const;

export default function ProjectSummary(props: Props): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);
  const { setGlobalEditMode, globalEditMode } = useContext(
    GlobalProjectEditContext
  );

  const dateCreated = props.projectDetails.dateCreated?.toLocaleDateString(
    "de-DE",
    dateOptions
  );

  const lastModifiedDate =
    props.projectDetails.lastModifiedDate?.toLocaleDateString(
      "de-DE",
      dateOptions
    );

  const statusCommentLastModifiedDate =
    props.projectDetails.projectHistoryStatusChange?.[0]?.dateTriggered.toLocaleDateString(
      "de-DE",
      dateOptions
    );

  const handleModalOpen = () => {
    setModalOpen(true);
    setGlobalEditMode(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setGlobalEditMode(false);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const projectStatusDescription = (status: string) => {
    switch (status) {
      case "active":
        return "Status Update";
      case "archived":
        return "Project is archived!";
      case "on hold":
        return `Project is on hold${
          props.projectDetails.onHoldDeadline
            ? ` until ${new Date(
                props.projectDetails.onHoldDeadline
              ).toLocaleDateString("de-DE", dateOptions)}`
            : ""
        }`;
      default:
        return `Project is ${props.projectDetails.status}!`;
    }
  };

  const projectLeadData = {
    id: props.projectDetails.projectLeader?.id ?? "",
    name: props.projectDetails?.projectLeader?.name ?? "",
    title: props.projectDetails.projectLeader?.departmentCode ?? "",
    logo: props.projectDetails?.businessUnit?.ventureClient.files
      ? findLogo(
          props.projectDetails.businessUnit.ventureClient.files,
          "companyLogo"
        )
      : "",
  } as ProjectLeadMatch;

  const selectedOpportunity = props.projectDetails.opportunities.find(
    (opportunity) => opportunity.isSelectedForPilot === true
  );
  const startupPartnerData: StartupMatch = selectedOpportunity
    ? {
        id: selectedOpportunity.startup.id ?? "",
        name: selectedOpportunity.startup.name ?? "",
        logo: selectedOpportunity.startup.files
          ? findLogo(selectedOpportunity?.startup.files)
          : "",
      }
    : null;

  return (
    <Paper
      variant="outlined"
      sx={{ padding: theme.spacing(4) }}
      data-testid="project-summary"
    >
      <Heading>
        <Typography variant="h6">Project Summary</Typography>
        {!globalEditMode && (
          <Button onClick={handleModalOpen} id="edit-project-button">
            <EditOutlinedIcon />
          </Button>
        )}
      </Heading>
      {props.projectDetails.status === "adopted" && (
        <Chip
          data-testid="adopted-chip"
          label="Adopted"
          variant="outlined"
          size="small"
          sx={{
            color: theme.palette.success.light,
            borderColor: theme.palette.success.light,
            marginBottom: theme.spacing(2),
          }}
        />
      )}
      <OverviewWrapper data-testid="overview">
        <OverviewItemWrapper>
          <OverviewItem
            value={props.projectDetails.businessUnit?.name}
            label="Organizational Unit"
          />
          <OverviewItem
            value={props.projectDetails.projectOwner.name}
            label="Project Owner"
          />
        </OverviewItemWrapper>
      </OverviewWrapper>
      <p>{props.projectDetails.shortDescription}</p>
      {props.projectDetails.focusAreas.length > 0 && (
        <FocusAreaWrapper>
          {props.projectDetails.focusAreas.map((focusArea) => (
            <Chip
              key={focusArea.id}
              label={focusArea.name}
              color="primary"
              variant="outlined"
              size="small"
            />
          ))}
        </FocusAreaWrapper>
      )}
      {statusCommentLastModifiedDate && props.projectDetails.statusComment && (
        <StatusSectionWrapper
          data-testid="project-status-update"
          variant="outlined"
          $status={props.projectDetails.status}
        >
          <StatusSectionHeading>
            <Typography variant="subtitle2">
              {projectStatusDescription(props.projectDetails.status)}
            </Typography>
            <Typography color="text.secondary" variant="caption">
              {`${statusCommentLastModifiedDate} by ${props.projectDetails.projectHistoryStatusChange[0].user.name}`}
            </Typography>
          </StatusSectionHeading>
          {props.projectDetails.projectHistoryStatusChange[0]
            .projectStatusChangeReason && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 0.5,
                marginTop: 1,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Reason:</Typography>
              <Typography sx={{ fontStyle: "italic" }}>
                {
                  props.projectDetails.projectHistoryStatusChange[0]
                    .projectStatusChangeReason.description
                }
              </Typography>
            </Box>
          )}
          <StatusCommentWrapper>
            <Typography whiteSpace="pre-wrap">
              {props.projectDetails.statusComment}
            </Typography>
          </StatusCommentWrapper>
        </StatusSectionWrapper>
      )}
      <ProjectInfoCard
        startupPartner={startupPartnerData}
        leadProject={projectLeadData}
      />
      <Box>
        <Typography color="text.secondary" variant="caption" component="p">
          Created: {dateCreated}
        </Typography>
        <Typography color="text.secondary" variant="caption" component="p">
          {lastModifiedDate &&
            `Last Updated: ${lastModifiedDate} by ${props.projectDetails.lastModifiedBy?.name}`}
        </Typography>
      </Box>
      {modalOpen && (
        <EditProjectModal
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
          handleSave={props.handleSave}
          projectData={props.projectDetails}
        />
      )}
    </Paper>
  );
}

const TruncatedText = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
}));

interface OverviewItemProps {
  label: string;
  value: string;
}

const OverviewItem = (props: OverviewItemProps) => {
  return (
    <Box overflow="hidden" flex={1}>
      <TruncatedText variant="body1">{props.value}</TruncatedText>
      <TruncatedText color="text.secondary" variant="caption">
        {props.label}
      </TruncatedText>
    </Box>
  );
};
