import { Fragment, ReactElement, useState } from "react";
import { VariableType } from "../../../../Types/ImpactValue";
import styled from "@emotion/styled";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import theme from "../../../../theme";
import EditDeleteMenu from "../../../UI/EditDeleteMenu";
import { getErrorMessage, thousandSeparator } from "../../../../utils";
import ManageVariableModal from "./ManageVariableModal";
import DeleteModal from "../../../UI/Modals/DeleteModal/DeleteModal";
import { VariableHttpService } from "../../../../Http/Variable/Variable.http.service";
import { useSnackbar } from "notistack";

const VariableWrapper = styled(Box)(() => ({
  width: 114,
  minWidth: 114,
  height: 77,
  outline: `1px solid ${theme.palette.other.surfaceSoft}`,
  borderRadius: theme.customBorderRadius.md,
  padding: theme.spacing(1, 1.5),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const VariableHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  "& > button": { marginRight: "-8px", padding: "4px" },
  "& svg": {
    fontSize: "16px",
  },
}));

const VariableName = styled(Typography)(() => ({
  color: theme.palette.text.disabled,
  fontSize: theme.customTypography.fontSize10,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
}));

const VariableValue = styled(Typography)(() => ({
  fontSize: "18px",
  color: theme.palette.primary.main,
  position: "relative",
  fontWeight: 300,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const Operator = styled(Button)(() => ({
  fontSize: theme.customTypography.fontSize10,
  padding: 0,
  minWidth: "24px",
  minHeight: "24px",
  background: theme.palette.other.surfaceSoft,
  display: "grid",
  placeItems: "center",
  borderRadius: "50%",
  color: theme.palette.primary.main,
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  display: "flex",
  justifyContent: "center",
}));

interface Props {
  variable: VariableType;
  handleImpactValue: () => void;
}

const Variable = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [editVariableModalOpen, setEditVariableModalOpen] = useState(false);
  const [deleteVariableModalOpen, setDeleteVariableModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { variable } = props;

  const updateVarible = async (newOperator: string) => {
    await VariableHttpService.updateVariable({
      ...variable,
      operator: newOperator,
    })
      .then(() => {
        props.handleImpactValue();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update operator: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOperator = async (event: React.MouseEvent<HTMLElement>) => {
    const newOperator = event.currentTarget.innerText;
    await updateVarible(newOperator);
    setAnchorEl(null);
  };

  return (
    <Fragment key={variable.id}>
      {variable.operator && (
        <>
          <Operator onClick={handleClick}>{variable.operator}</Operator>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              sx: {
                ml: -1,
                mt: 0.5,
              },
            }}
          >
            <StyledMenuItem onClick={handleOperator}>+</StyledMenuItem>
            <StyledMenuItem onClick={handleOperator}>-</StyledMenuItem>
            <StyledMenuItem onClick={handleOperator}>x</StyledMenuItem>
            <StyledMenuItem onClick={handleOperator}>:</StyledMenuItem>
          </Menu>
        </>
      )}
      <VariableWrapper data-testid="variable">
        <VariableHeader>
          <VariableName>{variable.name}</VariableName>
          <EditDeleteMenu
            entity="variable"
            setEditModalOpen={setEditVariableModalOpen}
            setDeleteModalOpen={setDeleteVariableModalOpen}
          />
        </VariableHeader>
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <VariableValue>{thousandSeparator(variable.value)}</VariableValue>
          {variable.unit && (
            <Typography
              color="primary"
              fontSize={theme.customTypography.fontSize10}
            >
              €
            </Typography>
          )}
        </Box>
      </VariableWrapper>
      {editVariableModalOpen && (
        <ManageVariableModal
          variable={variable}
          modalOpen={editVariableModalOpen}
          calculationId={variable.calculationId}
          setModalOpen={setEditVariableModalOpen}
          handleImpactValue={props.handleImpactValue}
        />
      )}
      {deleteVariableModalOpen && (
        <DeleteModal
          id={variable.id}
          entity="Variable"
          modalOpen={deleteVariableModalOpen}
          setModalOpen={setDeleteVariableModalOpen}
          handleSave={props.handleImpactValue}
        />
      )}
    </Fragment>
  );
};

export default Variable;
