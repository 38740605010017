import axios from "axios";
import {
  CreateOpportunityDTO,
  UpdateOpportunityDto,
} from "../../Types/Opportunity";

export default class OpportunityHttpService {
  public static async createOpportunity(
    opportunity: CreateOpportunityDTO
  ): Promise<void> {
    await axios.post<void>(`/api/opportunity`, opportunity);
  }

  public static async deleteOpportunity(opportunityId: number): Promise<void> {
    await axios.delete<void>(`/api/opportunity/${opportunityId}`);
  }

  public static async updateOpportunity(
    opportunity: UpdateOpportunityDto,
    id: number
  ): Promise<void> {
    await axios.put<void>(`/api/opportunity/${id}`, opportunity);
  }
}
