import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  ReactElement,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { ProjectHttpService } from "../../../../../Http/Project/Project.http.service";
import ObjectivesSection from "../../../SharedComponents/ObjectiveSection/ObjectivesSection";
import { Project } from "../../../../../Types/Project";
import StrategicBenefitsSection from "../../../SharedComponents/StrategicBenefitsSection/StrategicBenefitsSection";
import PilotResourcesSection from "../../Buy/DataView/Form/PilotResourcesSection";
import PilotTeamSection from "../../Buy/DataView/Form/PilotTeamSection";
import PilotConclusionSection from "./Form/PilotConclusionSection";
import PilotBackgroundSection from "../../../SharedComponents/PilotBackgroundSection/PilotBackgroundSection";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import TimeplanAndMilestones from "../../Buy/DataView/Form/TimeplanAndMilestones/TimeplanAndMilestones";

interface Props {
  project: Project;
  handleSave: (withScroll?: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Typography: {
      marginTop: theme.spacing(6),
    },
  })
);
export default function Form(props: Props): ReactElement {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.project
  );
  const {
    setGlobalEditMode,
    globalEditMode,
    setShouldSaveProject,
    shouldSaveProject,
  } = useContext(GlobalProjectEditContext);

  useEffect(() => {
    setProject(props.project);
  }, [props.project]);

  useEffect(() => {
    setGlobalEditMode(editMode);
  }, [editMode]);

  useEffect(() => {
    const saveChanges = async () => {
      if (shouldSaveProject) {
        await saveProject();
        setShouldSaveProject(false);
      }
    };

    saveChanges();
  }, [shouldSaveProject]);

  const cancelEdit = () => {
    setProject(props.project);
    setEditMode(false);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const saveProject = async () => {
    await ProjectHttpService.updateProject(project);
    props.handleSave();
    setEditMode(false);
  };

  return (
    <div id="pilot-data-view-form">
      <form noValidate autoComplete="off">
        <Box
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Typography variant="h6">Pilot Project Insights</Typography>
          {!editMode && !globalEditMode && (
            <Button
              onClick={() => setEditMode(true)}
              id="pilot-edit-form-button"
            >
              <EditOutlinedIcon />
            </Button>
          )}
        </Box>
        <Typography variant="h6" className={classes.Typography}>
          Pilot Team
        </Typography>
        <PilotTeamSection
          project={project}
          editMode={editMode}
          onChange={setProject}
        />

        <Typography variant="h6" className={classes.Typography}>
          Pilot Resources
        </Typography>
        {project && (
          <PilotResourcesSection
            projectId={project.id}
            project={project}
            editMode={editMode}
            onChange={setProject}
          />
        )}
        <Typography variant="h6" className={classes.Typography}>
          Pilot Background
        </Typography>

        {project && (
          <PilotBackgroundSection
            project={project}
            editMode={editMode}
            onChange={setProject}
          />
        )}

        <ObjectivesSection
          projectId={props.project.id}
          objectives={props.project.objectives || []}
          handleSave={() => {
            editMode ? null : props.handleSave(false);
          }}
        />
        <TimeplanAndMilestones
          project={{
            ...props.project,
            meetings: props.project.meetings.filter(
              (meeting) => meeting.isInitial === false
            ),
            phases: props.project.phases.filter(
              (phase) => phase.isInitial === false
            ),
          }}
          handleSave={() => {
            editMode ? null : props.handleSave(false);
          }}
        />
        <Typography variant="h6" className={classes.Typography}>
          Strategic Benefits
        </Typography>
        <StrategicBenefitsSection
          project={project}
          editMode={editMode}
          onChange={setProject}
        />

        <Typography variant="h6" className={classes.Typography}>
          Pilot Conclusion
        </Typography>
        <PilotConclusionSection
          project={project}
          editMode={editMode}
          setProject={setProject}
        />
      </form>
      {editMode && (
        <Box
          sx={{
            justifyContent: "flex-end",
            flexDirection: "row",
            display: "flex",
            m: 2,
          }}
        >
          <Button onClick={cancelEdit} color="primary">
            Cancel
          </Button>
          <Button
            onClick={saveProject}
            variant="contained"
            color="secondary"
            sx={{ marginLeft: "15px" }}
          >
            Save Project
          </Button>
        </Box>
      )}
    </div>
  );
}
