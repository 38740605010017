import axios from "axios";
import { ProjectStatusChangeReason } from "../../Types/Project";

export class StatusChangeReasonsHttpService {
  public static async getReasons(
    status: "on hold" | "archived"
  ): Promise<ProjectStatusChangeReason[]> {
    return (
      await axios.get<ProjectStatusChangeReason[]>(
        `/api/status-change-reasons`,
        {
          params: {
            status,
          },
        }
      )
    ).data;
  }
}
