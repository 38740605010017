import { FilterList } from "@mui/icons-material";
import {
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  styled,
  Switch,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Fragment, ReactElement, useContext, useEffect, useState } from "react";
import { ProjectHttpService } from "../../Http/Project/Project.http.service";
import { Project, ProjectStages } from "../../Types/Project";
import ProjectKanbanCard from "./ProjectKanbanCard";
import { ProjectKanbanFilter } from "./ProjectKanbanFilter";
import { Close, Search } from "@mui/icons-material";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import StartupKanbanCard from "./StartupKanbanCard";
import { Opportunity } from "../../Types/Opportunity";
import { KanbanFilterContext } from "../../Context/KanbanFilterContext";
import Fuse from "fuse.js";
import ProjectLeadsKanban from "./ProjectLeadsKanban";
import CreateProjectLeadModal from "./CreateProjectLeadModal";
import { LeadProject } from "../../Types/LeadProject";
import { LeadProjectsHttpService } from "../../Http/LeadProjects/LeadProjects.http.service";

type Stages = "discover" | "assess" | "buy" | "pilot" | "adopt";

function getProjectCardsByStage(
  projects: Project[],
  refreshProjectData: () => void
): ReactElement[] {
  if (projects) {
    return projects
      .sort((a, b) => {
        if (a.funnelStageAge === b.funnelStageAge) return 0;
        else if (a.funnelStageAge === null) return 1;
        else if (b.funnelStageAge === null) return -1;
        return a.funnelStageAge - b.funnelStageAge;
      })
      .map((project) => (
        <Grid
          key={project.id}
          style={{ flexGrow: 1, width: "100%" }}
          item
          data-testid="projectCard"
        >
          <ProjectKanbanCard
            project={project}
            refreshProjectData={refreshProjectData}
          />
        </Grid>
      ));
  }
  return [<span key="null" />];
}

const filterOpportunitiesByStage = (
  opportunities: Opportunity[],
  funnelStage: string
) => {
  return (
    opportunities.filter((opportunity) => {
      if (funnelStage === "discover") {
        return true;
      } else if (funnelStage === "assess") {
        return !!opportunity.isQualified;
      } else {
        return !!opportunity.isSelectedForPilot;
      }
    }) || []
  );
};

const getOpportunityCardsByStage = (
  projects: Project[],
  refreshProjectData: () => void
): ReactElement[] => {
  return projects?.flatMap((project, index) => {
    if (project.opportunities?.length) {
      const filteredOpportunitiesByStage = filterOpportunitiesByStage(
        project.opportunities,
        project.funnelStage
      );

      const opportunityCards = filteredOpportunitiesByStage.map(
        (opportunity) => (
          <Grid
            key={opportunity.id}
            style={{ flexGrow: 1, width: "100%" }}
            item
            data-testid="opportunityCard"
          >
            <StartupKanbanCard
              project={project}
              opportunity={opportunity}
              refreshProjectData={refreshProjectData}
            />
          </Grid>
        )
      );
      return opportunityCards;
    } else {
      return [<span key={index} />];
    }
  });
};

const StartupSwitch = styled(Switch)(() => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 11,
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: "#fff",
  },
  ".MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    opacity: "1",
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiGrid-container": {
        padding: "16px",
      },
      "& .MuiGrid-container>.MuiGrid-item": {
        padding: "4px",
      },
    },
    column_container__request_inbox: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      border: "1px solid rgba(0, 0, 0, 0.12)",
      position: "relative",
      minHeight: "75vh",
    },
    column_divider: {
      flexGrow: 1,
      alignSelf: "center",
      margin: "0 5% 0 5%",
      border: "1px solid",
      borderColor: theme.palette.grey[400],
      zIndex: -1,
    },
  })
);

function ProjectKanban(): ReactElement {
  const classes = useStyles();
  const [projects, setProjects] = useState<Project[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const [projectsByStage, setProjectsByStage] = useState<ProjectStages>(
    {} as ProjectStages
  );
  const [leadProjects, setLeadProjects] = useState<LeadProject[]>([]);
  const [displayedLeadProjects, setDisplayedLeadProjects] =
    useState<LeadProject[]>(leadProjects);

  const [isStartupView, setIsStartupView] = useState(false);
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const { activeFilters } = useContext(KanbanFilterContext);

  const fuse = new Fuse(projects, {
    includeScore: true,
    ignoreLocation: true,
    threshold: 0.25,
    keys: ["name", "opportunities.startup.name"],
  });

  const fuseLeadProject = new Fuse(leadProjects, {
    includeScore: true,
    ignoreLocation: true,
    threshold: 0.25,
    keys: ["name", "opportunities.startup.name"],
  });

  useEffect(() => {
    const projectsToShow =
      searchValue !== ""
        ? (fuse.search(searchValue).map((result) => result.item) as Project[])
        : projects;

    const leadProjectsToShow =
      searchValue !== ""
        ? (fuseLeadProject
            .search(searchValue)
            .map((result) => result.item) as LeadProject[])
        : leadProjects;

    setProjectsByStage(
      // eslint-disable-next-line
      projectsToShow?.reduce((mapper: any, project: Project) => {
        (mapper[project["funnelStage"]] =
          (mapper[project["funnelStage"]] as Project[]) || []).push(project);
        return mapper;
      }, {}) as ProjectStages
    );

    setDisplayedLeadProjects(leadProjectsToShow);
  }, [projects, leadProjects, searchValue]);

  useEffect(() => {
    setGlobalLoader(true);

    const filterQuery = { ...activeFilters };
    if (filterQuery.businessUnitId === -1) {
      delete filterQuery.businessUnitId;
    }

    ProjectHttpService.getProjects(filterQuery).then((projects) => {
      setProjects(projects);
      setGlobalLoader(false);
    });

    LeadProjectsHttpService.getLeadProjects(filterQuery).then((leads) =>
      setLeadProjects(leads)
    );
  }, [refreshData]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const refreshProjectData = () => {
    setRefreshData(!refreshData);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsStartupView(event.target.checked);
  };

  const getNumberOfCardsByStage = (stage: Stages) => {
    if (isStartupView && projectsByStage[stage]) {
      return projectsByStage[stage].reduce(function (cardLength, nextProject) {
        if (nextProject.opportunities) {
          const activeOpportunitiesByStage = filterOpportunitiesByStage(
            nextProject.opportunities,
            nextProject.funnelStage
          );
          return cardLength + activeOpportunitiesByStage.length;
        }
        return 0;
      }, 0);
    } else {
      return projectsByStage[stage]?.length || 0;
    }
  };

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        margin={0}
        className={classes.root}
      >
        <Grid item xs={12} mb={2} display="flex" pr={3} gap={3}>
          <FormControlLabel
            data-testid="switch"
            control={<StartupSwitch onChange={handleSwitch} />}
            label={<Typography variant="caption">Startup View</Typography>}
          />
          <TextField
            sx={{ ml: "auto", width: "240px" }}
            id="project-name"
            data-testid="search-projects"
            value={searchValue}
            placeholder="A project or startup name"
            onChange={(event) => setSearchValue(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchValue.length > 0 ? (
                    <IconButton
                      size="small"
                      onClick={() => setSearchValue("")}
                      id="clear-search"
                    >
                      <Close />
                    </IconButton>
                  ) : (
                    <Search />
                  )}
                </InputAdornment>
              ),
            }}
            label="Search"
            variant="standard"
          />
          <Button
            data-testid="project-kanban-filter-button"
            onClick={() => setFilterDrawerOpen(true)}
            color="primary"
            sx={{ alignSelf: "end" }}
          >
            <FilterList sx={{ mr: 1 }} /> Filter
          </Button>
        </Grid>
        <ProjectLeadsKanban
          handleModalOpen={handleModalOpen}
          refreshData={refreshData}
          leadProjects={displayedLeadProjects}
        />
        <Grid
          item
          container
          justifyContent="center"
          spacing={1}
          xs={12}
          sm={2}
          data-testid="discoverColumn"
        >
          <Grid
            item
            container
            xs={12}
            display="inline-flex"
            justifyContent="space-between"
          >
            <Typography variant="h5">Discover</Typography>
            <div className={classes.column_divider} />
            <Typography
              variant="caption"
              component="p"
              m={"auto 0 auto auto"}
              data-testid="cardNumberDisplay"
            >
              ({getNumberOfCardsByStage("discover")})
            </Typography>
          </Grid>
          {isStartupView
            ? getOpportunityCardsByStage(
                projectsByStage.discover,
                refreshProjectData
              )
            : getProjectCardsByStage(
                projectsByStage.discover,
                refreshProjectData
              )}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          spacing={1}
          xs={12}
          sm={2}
          data-testid="assessColumn"
        >
          <Grid
            item
            container
            xs={12}
            display="inline-flex"
            justifyContent="space-between"
          >
            <Typography variant="h5">Assess</Typography>
            <div className={classes.column_divider} />
            <Typography
              variant="caption"
              component="p"
              m={"auto 0 auto auto"}
              data-testid="cardNumberDisplay"
            >
              ({getNumberOfCardsByStage("assess")})
            </Typography>
          </Grid>
          {isStartupView
            ? getOpportunityCardsByStage(
                projectsByStage.assess,
                refreshProjectData
              )
            : getProjectCardsByStage(
                projectsByStage.assess,
                refreshProjectData
              )}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          spacing={1}
          xs={12}
          sm={2}
          data-testid="buyColumn"
        >
          <Grid
            item
            container
            xs={12}
            display="inline-flex"
            justifyContent="space-between"
          >
            <Typography variant="h5">Buy</Typography>
            <div className={classes.column_divider} />
            <Typography
              variant="caption"
              component="p"
              m={"auto 0 auto auto"}
              data-testid="cardNumberDisplay"
            >
              ({getNumberOfCardsByStage("buy")})
            </Typography>
          </Grid>
          {isStartupView
            ? getOpportunityCardsByStage(
                projectsByStage.buy,
                refreshProjectData
              )
            : getProjectCardsByStage(projectsByStage.buy, refreshProjectData)}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          spacing={1}
          xs={12}
          sm={2}
          data-testid="pilotColumn"
        >
          <Grid
            item
            container
            xs={12}
            display="inline-flex"
            justifyContent="space-between"
          >
            <Typography variant="h5">Pilot</Typography>
            <div className={classes.column_divider} />
            <Typography
              variant="caption"
              component="p"
              m={"auto 0 auto auto"}
              data-testid="cardNumberDisplay"
            >
              ({getNumberOfCardsByStage("pilot")})
            </Typography>
          </Grid>
          {isStartupView
            ? getOpportunityCardsByStage(
                projectsByStage.pilot,
                refreshProjectData
              )
            : getProjectCardsByStage(projectsByStage.pilot, refreshProjectData)}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          spacing={1}
          xs={12}
          sm={2}
          data-testid="adoptColumn"
        >
          <Grid
            item
            xs={12}
            display="inline-flex"
            justifyContent="space-between"
          >
            <Typography variant="h5">Adopt</Typography>
            <div className={classes.column_divider} />
            <Typography
              variant="caption"
              component="p"
              m={"auto"}
              data-testid="cardNumberDisplay"
            >
              ({getNumberOfCardsByStage("adopt")})
            </Typography>
          </Grid>
          {isStartupView
            ? getOpportunityCardsByStage(
                projectsByStage.adopt,
                refreshProjectData
              )
            : getProjectCardsByStage(projectsByStage.adopt, refreshProjectData)}
        </Grid>
      </Grid>

      {modalOpen && (
        <CreateProjectLeadModal
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
        />
      )}

      {filterDrawerOpen && (
        <ProjectKanbanFilter
          open={filterDrawerOpen}
          setOpen={setFilterDrawerOpen}
          refresh={refreshProjectData}
        />
      )}
    </Fragment>
  );
}

export default ProjectKanban;
