import { Fragment, ReactElement, useEffect, useState } from "react";
import {
  Autocomplete,
  CircularProgress,
  debounce,
  TextField,
  Theme,
} from "@mui/material";
import { StartupContactDTO } from "../../../Types/StartupContact";
import StartupContactHttpService from "../../../Http/StartupContact/StartupContact.http.service";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  editMode: boolean;
  labelText: string;
  startupId: number | undefined;
  // eslint-disable-next-line
  onChange: any;
  contactData: StartupContactDTO | StartupContactDTO[] | undefined;
  fieldId: string;
  multiSelect?: boolean;
  required?: boolean;
  toolTipText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      minWidth: "35ch",
      "& .MuiInputLabel-root.Mui-error, .MuiInputLabel-asterisk.Mui-error": {
        color: theme.palette.warning.main,
      },
      "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.warning.main,
      },
      "& .MuiInput-underline:before, .MuiInput-underline:hover:before": {
        borderBottom: "none",
      },
    },
  })
);

export default function StartupContactSelect(props: Props): ReactElement {
  const classes = useStyles();
  const [contacts, setContacts] = useState<StartupContactDTO[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<StartupContactDTO[]>(
    []
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const searchForContacts = async (searchValue: string): Promise<void> => {
    setIsLoading(true);

    if (searchValue.trim().length > 0 && props.startupId)
      setContacts(
        await StartupContactHttpService.getContactsByName(
          props.startupId,
          searchValue
        )
      );
    setIsLoading(false);
  };

  useEffect(() => {
    if (props.multiSelect) {
      setSelectedContacts(props.contactData as StartupContactDTO[]);
    } else if (!props.multiSelect) {
      setSelectedContacts([{ ...(props.contactData as StartupContactDTO) }]);
    }
  }, []);

  //used to keep state and parent props in sync
  useEffect(() => {
    if (props.multiSelect) {
      props.onChange(selectedContacts as StartupContactDTO[]);
    } else if (!props.multiSelect) {
      props.onChange({
        [props.fieldId]: selectedContacts[0] ? selectedContacts[0].id : null,
      });
    }
  }, [selectedContacts]);

  const debouncedSearchForContacts = debounce(searchForContacts, 500);
  return (
    <Fragment>
      {props.editMode ? (
        props.multiSelect ? (
          <Autocomplete
            id={props.fieldId}
            multiple
            forcePopupIcon={false}
            options={contacts as StartupContactDTO[]}
            isOptionEqualToValue={(
              option: StartupContactDTO,
              value: StartupContactDTO
            ) => option.id === value.id}
            getOptionLabel={(option: StartupContactDTO) => option.name}
            filterOptions={(options) => options}
            value={selectedContacts as StartupContactDTO[]}
            filterSelectedOptions
            onInputChange={(_, newValue: string) => {
              if (event === null) return;
              debouncedSearchForContacts(newValue);
            }}
            onChange={(_, selectedContacts: StartupContactDTO[]) =>
              setSelectedContacts(selectedContacts as StartupContactDTO[])
            }
            className={classes.root}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.labelText}
                id={props.fieldId}
                placeholder="search by name"
                required={!!props.required}
                focused={false}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    marginTop: props.toolTipText ? "-7px" : "-2px",
                  },
                }}
                error={
                  props.required &&
                  !!!Object.keys(selectedContacts[0] || {}).length
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {params.InputProps.endAdornment}
                      {isLoading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <Autocomplete
            id={props.fieldId}
            options={contacts}
            forcePopupIcon={false}
            getOptionLabel={(option) => option.name}
            filterOptions={(options) => options}
            value={
              !!Object.keys(selectedContacts[0] || {}).length
                ? (selectedContacts[0] as StartupContactDTO)
                : null
            }
            filterSelectedOptions
            isOptionEqualToValue={(
              option: StartupContactDTO,
              value: StartupContactDTO
            ) => option.id === value.id}
            onInputChange={(_, newValue: string) => {
              if (event === null) return;
              debouncedSearchForContacts(newValue);
            }}
            onChange={(_, selectedContact) => {
              setSelectedContacts([
                (selectedContact as StartupContactDTO) || "",
              ]);
            }}
            className={classes.root}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.labelText}
                id={props.fieldId}
                placeholder="search by name"
                focused={false}
                required={!!props.required}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    marginTop: props.toolTipText ? "-7px" : "-2px",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {params.InputProps.endAdornment}
                      {isLoading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Fragment>
                  ),
                }}
                error={
                  props.required &&
                  !!!Object.keys(selectedContacts[0] || {}).length
                }
              />
            )}
          />
        )
      ) : (
        <TextField
          id={props.fieldId}
          label={props.labelText}
          InputLabelProps={{ shrink: true }}
          data-testid="startup-contact"
          value={
            selectedContacts.length
              ? selectedContacts.flatMap((contact) => contact.name).join(", ")
              : "-"
          }
          variant="standard"
          focused={false}
          fullWidth
          multiline
          sx={{ flexGrow: 1 }}
          inputProps={{ readOnly: true }}
          className={classes.root}
        />
      )}
    </Fragment>
  );
}
