import { ReactElement, useState } from "react";
import { TableCell, TableRow, styled, Typography } from "@mui/material";
import { Opportunity } from "../../../../../../Types/Opportunity";
import StartupSelect from "../../../../../UI/InputFields/StartupSelect";
import { Startup } from "../../../../../../Types/Startup";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../../../../../theme";
import { Flipped } from "react-flip-toolkit";

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  cursor: "pointer",
}));

interface AddStartupProps {
  isAddingStartup: boolean;
  setIsAddingStartup: (toggleIsAddingStartup: boolean) => void;
  assignedStartupsIds?: number[];
  projectId: number;
  projectFunnelStage: string;
  createOpportunity: (createdOpportunity: Opportunity) => void;
}

export function AddStartup(props: AddStartupProps): ReactElement {
  const [selectedStartup, setSelectedStartup] = useState<Startup | null>(null);

  const handleAssignStartup = (startup: Startup | null) => {
    if (startup) {
      const createdOpportunity = {
        id: -1,
        projectId: props.projectId,
        startupId: startup.id,
        isQualified: props.projectFunnelStage === "discover" ? false : true,
        isSelectedForPilot: false,
        rating: null,
        startup: { name: startup.name },
      } as Opportunity;
      props.createOpportunity(createdOpportunity);
    }
    setSelectedStartup(null);
    props.setIsAddingStartup(false);
  };

  const isAssessStage = props.projectFunnelStage === "assess";

  return props.isAddingStartup ? (
    <TableRow className="add-startup-column" data-testid="add-startup-column">
      <TableCell className="startup-name">Add startup</TableCell>
      <TableCell className="startup-link-logo" data-testid="select-input">
        <StartupSelect
          data-testid="add-startup-input"
          assignedStartupIds={props.assignedStartupsIds}
          handleSelectStartup={handleAssignStartup}
          selectedStartup={selectedStartup}
        />
        <StyledCloseIcon
          onClick={() => {
            props.setIsAddingStartup(false);
          }}
        />
      </TableCell>
      <TableCell className="rating-cell"></TableCell>
      <TableCell
        className={`assign-cluster ${
          isAssessStage ? "" : "highlighted-border"
        }`}
      ></TableCell>
      {isAssessStage && <TableCell className="highlighted-border"></TableCell>}
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell className="extra-high-cell"></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell className="extra-high-cell"></TableCell>
    </TableRow>
  ) : (
    <Flipped key="add-startup-button" flipId="add-startup-button">
      {(flippedProps) => (
        <tr
          {...flippedProps}
          onClick={() => {
            props.setIsAddingStartup(true);
          }}
          className="add-startup-button"
          data-testid="add-startup-button"
        >
          <td>
            <Typography color={theme.palette.common.black}>+</Typography>
          </td>
        </tr>
      )}
    </Flipped>
  );
}
