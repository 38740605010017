import { ReactElement } from "react";
import EditableStyledItem from "../../UI/EditableStyledItem";
import { Box } from "@mui/material";
import { LeadProjectUseCase } from "../../../Types/LeadProject";

interface UseCaseItemProps {
  useCase: LeadProjectUseCase;
  index: number;
  onCreateUseCase: (useCase: LeadProjectUseCase) => void;
  onUseCaseChange: (useCase: LeadProjectUseCase) => void;
  loader?: boolean;
  deleteUseCase: (id: number) => void;
  onChangeEditUseCase: (id: number | null) => void;
  currentEditUseCase: number | null;
}

export default function UseCaseItem(props: UseCaseItemProps): ReactElement {
  const handleUseCaseBlur = (currentUseCase: string) => {
    if (props.useCase.id === -1 && currentUseCase) {
      props.onCreateUseCase({
        ...props.useCase,
        name: currentUseCase,
      });
    } else if (props.useCase.id === -1 && !currentUseCase) {
      props.deleteUseCase(-1);
    } else if (
      currentUseCase !== props.useCase.name &&
      props.useCase.id !== -1
    ) {
      props.onUseCaseChange({
        ...props.useCase,
        name: currentUseCase,
      });
    }
  };

  const handleChangeUseCase = (name: string) => {
    props.onUseCaseChange({
      ...props.useCase,
      name,
    });
  };

  return (
    <Box key={props.index}>
      <EditableStyledItem
        id={props.useCase.id}
        index={props.index}
        value={props.useCase.name}
        currentEditId={props.currentEditUseCase}
        item="use-case"
        placeHolder="Add a problem..."
        onChange={handleChangeUseCase}
        onBlur={handleUseCaseBlur}
        onChangeEditId={props.onChangeEditUseCase}
        onDelete={() => {
          props.deleteUseCase(props.useCase.id);
        }}
        allowDrag={false}
      />
    </Box>
  );
}
