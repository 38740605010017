import {
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Box,
  styled,
} from "@mui/material";
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Opportunity } from "../../../../../../../Types/Opportunity";
import CustomTextField from "../../../../../../UI/InputFields/CustomTextField";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SelectInput } from "../../../../../../UI/InputFields/SelectInput";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $editMode }: { $editMode: boolean }) => ({
  "& .selectedForPilot-column": {
    paddingLeft: $editMode ? "1.6rem" : "2.7rem",
    "& .MuiButtonBase-root": { padding: "0" },
  },
  "& .MuiTextField-root": {
    marginBlock: "0",
    "& .MuiInputBase-root.Mui-readOnly": {
      paddingBlock: "0",
    },
  },
}));

interface OpportunityRowProps {
  allOpportunities: Opportunity[];
  opportunitiesToSelect?: Opportunity[];
  opportunitiesInConclusion: Opportunity[];
  setOpportunitiesInConclusion: Dispatch<SetStateAction<Opportunity[]>>;
  opportunity: Opportunity;
  editMode: boolean;
  handleOpportunityUpdate: (
    opportunity: Opportunity,
    index: number,
    updateType?: string
  ) => void;
  index: number;
  setOpportunities: Dispatch<SetStateAction<Opportunity[]>>;
}

const OpportunityRow = (props: OpportunityRowProps): ReactElement => {
  const [currentSelectValue, setCurrentSelectValue] = useState<number>(
    props.opportunity.id
  );
  const getSelectValues = () => {
    const withProductDemos = props.opportunitiesInConclusion.filter(
      (opp) => opp.productDemos.length > 0
    );
    return [
      ...(props.allOpportunities
        .filter((opp) => opp.isQualified)
        .filter((opp) => !withProductDemos.find((_opp) => _opp.id === opp.id))
        .filter((opp) => opp.id !== currentSelectValue)
        .filter((opp) => {
          const current = props.opportunitiesInConclusion.find(
            (opportunity) => opportunity.id === opp.id
          );
          return !current?.inAssessmentConclusion;
        })
        .map((opportunity) => {
          return {
            id: opportunity.id,
            name: opportunity.startup.name,
          };
        }) || []),
      {
        id: props.opportunity.id,
        name: props.opportunity.startup.name,
      },
    ];
  };

  const [selectValues, setSelectValues] = useState<
    { id: number; name: string }[]
  >(getSelectValues());

  useEffect(() => {
    setCurrentSelectValue(props.opportunity.id);
  }, [props.opportunity.id]);

  useEffect(() => {
    setSelectValues(getSelectValues());
  }, [props.opportunitiesInConclusion, currentSelectValue]);

  const statusUpdate = (status: string) => {
    const index = props.opportunitiesInConclusion.findIndex(
      (opportunity) => opportunity.id === props.opportunity.id
    );

    props.handleOpportunityUpdate(
      {
        ...props.opportunity,
        isSelectedForPilot: status === "selected" ? true : false,
      },
      index,
      "statusUpdate"
    );
  };

  const assessmentDecisionUpdate = (value: string) => {
    const index = props.opportunitiesInConclusion.findIndex(
      (opportunity) => opportunity.id === props.opportunity.id
    );

    props.handleOpportunityUpdate(
      {
        ...props.opportunity,
        assessmentDecision: value,
      },
      index
    );
  };

  const getIcon = (status: string): ReactElement => {
    if (status === "selected") {
      return (
        <CheckCircleIcon
          sx={{
            color: props.opportunity.isSelectedForPilot ? "#4B9F47" : "#bfbfbf",
            "&:hover": { color: "#4B9F47" },
          }}
        />
      );
    } else {
      return (
        <CancelOutlinedIcon
          sx={{
            color: props.opportunity.isSelectedForPilot ? "#bfbfbf" : "#000000",
            "&:hover": { color: "#000000" },
          }}
        />
      );
    }
  };

  const handleDelete = () => {
    const index = props.opportunitiesInConclusion.findIndex(
      (opportunity) => opportunity.id === props.opportunity.id
    );
    const updatedOpportunities = [
      ...props.opportunitiesInConclusion.slice(0, index),
      ...props.opportunitiesInConclusion.slice(index + 1),
    ];
    props.setOpportunitiesInConclusion(updatedOpportunities);
  };

  const handleEdit = (id: number) => {
    const updatedOpportunities = [...props.opportunitiesInConclusion];
    const index = props.opportunitiesInConclusion.findIndex(
      (opportunity) => opportunity.id === props.opportunity.id
    );
    const changeTo = props.allOpportunities.find((opp) => opp.id === id);
    const changeToIndex = props.opportunitiesInConclusion.findIndex(
      (opp) => opp.id === changeTo?.id
    );
    if (updatedOpportunities[index] && changeTo) {
      updatedOpportunities[index] = {
        ...updatedOpportunities[index],
        inAssessmentConclusion: false,
      };
      if (changeToIndex >= 0) {
        updatedOpportunities[changeToIndex] = {
          ...updatedOpportunities[index],
          inAssessmentConclusion: false,
        };
        updatedOpportunities[index] = {
          ...changeTo,
          inAssessmentConclusion: true,
        };
      } else {
        updatedOpportunities[index] = {
          ...changeTo,
          inAssessmentConclusion: true,
        };
      }
    }
    setCurrentSelectValue(id);
    props.setOpportunitiesInConclusion(updatedOpportunities);
  };
  useEffect(() => {
    setCurrentSelectValue(props.opportunity.id);
  }, [props.editMode]);

  return (
    <StyledTableRow
      $editMode={props.editMode}
      data-testid={"opportunity-" + props.opportunity.id}
    >
      <TableCell>
        <div style={{ width: "34px" }}></div>
      </TableCell>
      <TableCell>
        {props.editMode && props.opportunity.productDemos.length === 0 ? (
          <SelectInput
            id={"selectStartupForPilotId-" + props.index}
            selectValues={selectValues}
            value={currentSelectValue}
            editMode={props.editMode}
            required
            onChange={(e) => handleEdit(parseInt(e.target.value))}
          />
        ) : (
          <Typography>{props.opportunity.startup.name}</Typography>
        )}
      </TableCell>
      <TableCell className="selectedForPilot-column">
        {props.editMode ? (
          <Box display="flex" gap={1}>
            <IconButton onClick={() => statusUpdate("selected")}>
              {getIcon("selected")}
            </IconButton>
            <IconButton onClick={() => statusUpdate("notSelected")}>
              {getIcon("notSelected")}
            </IconButton>
          </Box>
        ) : (
          <div>
            {getIcon(
              props.opportunity.isSelectedForPilot ? "selected" : "notSelected"
            )}
          </div>
        )}
      </TableCell>
      <TableCell>
        <CustomTextField
          id={props.opportunity.id.toString()}
          dataTestId="assessment-decision"
          editMode={props.editMode}
          value={props.opportunity.assessmentDecision}
          onChange={(event) => assessmentDecisionUpdate(event.target.value)}
          fullWidth
          multiline
        />
      </TableCell>
      {props.editMode && props.opportunity.productDemos.length == 0 && (
        <TableCell>
          <div
            id={"delete-from-conclusion-" + props.index}
            onClick={handleDelete}
          >
            <IconButton edge="end" aria-label="delete" size="large">
              <DeleteIcon />
            </IconButton>
          </div>
        </TableCell>
      )}
    </StyledTableRow>
  );
};

export default OpportunityRow;
