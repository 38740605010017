import { Opportunity, StartupOpportunity } from "./Types/Opportunity";
import { ProjectStartupOpportunityAssociation } from "./Types/Project";
import { User } from "./Types/User";
import { stageScores } from "./Constants/FunnelStages";
import { File } from "./Types/File";
import { AxiosError } from "axios";

import * as helper from "./utils";

export function capitalizeFirstLetter(word: string): string {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  } else {
    return "";
  }
}

export const getUserRole = (user: User): string => {
  const roles = user.roles.map((role) => role.name);

  if (roles.includes("Admin")) {
    return "Admin";
  } else if (roles.includes("Venture Client Unit Head")) {
    return "Venture Client Unit Head";
  } else if (roles.includes("Venture Client Unit Connector")) {
    return "Venture Client Unit Connector";
  } else {
    return "Venture Client";
  }
};

function rgbToHex(r: number, g: number, b: number) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 0,
        g: 0,
        b: 0,
      };
}

// returns an array of colors between startColor and endColor according to steps
export function getRamp(
  startColor: string,
  endColor: string,
  steps = 4
): string[] {
  const ramp: string[] = [];

  ramp.push(startColor);

  const startColorRgb = hexToRgb(startColor);
  const endColorRgb = hexToRgb(endColor);

  const rInc = Math.round((endColorRgb.r - startColorRgb.r) / (steps + 1));
  const gInc = Math.round((endColorRgb.g - startColorRgb.g) / (steps + 1));
  const bInc = Math.round((endColorRgb.b - startColorRgb.b) / (steps + 1));

  for (let i = 0; i < steps; i++) {
    startColorRgb.r += rInc;
    startColorRgb.g += gInc;
    startColorRgb.b += bInc;

    ramp.push(rgbToHex(startColorRgb.r, startColorRgb.g, startColorRgb.b));
  }
  ramp.push(endColor);

  return ramp;
}

export const removeFormatting = (text: string): string => {
  if (text) {
    return text.replace(/(<\/?[^>]+(>|$)|&nbsp;?)/g, "");
  } else {
    return "";
  }
};

export const isCharLimitExceeded = (
  field: string,
  charLimit: number | undefined
): boolean => {
  const plainText = removeFormatting(field);
  if (charLimit && plainText.length > charLimit) {
    return true;
  } else {
    return false;
  }
};

export const compareOpportunitiesByRating = (
  opp1: Opportunity,
  opp2: Opportunity
): number => {
  if (opp1.rating === opp2.rating) {
    return +opp1.startup.totalFunding >= +opp2.startup.totalFunding ? -1 : 1;
  }
  if (opp1.rating === null) return 1;
  if (opp2.rating === null) return -1;
  return opp1.rating < opp2.rating ? -1 : 1;
};

export const thousandSeparator = (number: number | string): string => {
  if (typeof number !== "number") number = +number;
  return new Intl.NumberFormat("de-DE").format(number);
};

export const sortProjectByDateDescending = (
  project1: ProjectStartupOpportunityAssociation,
  project2: ProjectStartupOpportunityAssociation
): number => {
  const project1Date =
    project1.lastModifiedDate?.getTime() || project1.dateCreated.getTime();
  const project2Date =
    project2.lastModifiedDate?.getTime() || project2.dateCreated.getTime();

  if (project1Date === project2Date) {
    return 0;
  }
  return project1Date > project2Date ? -1 : 1;
};

export const getStartupStatus = (opportunity: StartupOpportunity): string => {
  const projectStageScore = stageScores[opportunity.project.funnelStage];
  const isBeyondDiscover = projectStageScore > stageScores["discover"];
  const isBeyondAssess = projectStageScore > stageScores["assess"];
  const isAdopt = projectStageScore === stageScores["adopt"];
  if (
    (isBeyondDiscover && !opportunity.isQualified) ||
    (isBeyondAssess && !opportunity.isSelectedForPilot)
  ) {
    return "Ruled Out";
  } else if (isBeyondAssess && opportunity.isSelectedForPilot) {
    return isAdopt ? "Adopted" : "Selected";
  } else return "Evaluation";
};

export const sortOpportunitiesByRelevance = (
  opportunity1: StartupOpportunity,
  opportunity2: StartupOpportunity
): number => {
  const sortingScore1 = calculateSortingScore(opportunity1);
  const sortingScore2 = calculateSortingScore(opportunity2);

  if (sortingScore1 < sortingScore2) return -1;
  else if (sortingScore1 > sortingScore2) return 1;
  else {
    if (sortingScore1 === 4 || sortingScore1 === 5) {
      const projectStatus1 = opportunity1.project.status;
      const projectStatus2 = opportunity2.project.status;

      if (projectStatus1 === "active" && projectStatus2 !== "active") return -1;
      if (projectStatus1 !== "active" && projectStatus2 === "active") return 1;
      if (projectStatus1 === "on hold" && projectStatus2 !== "on hold")
        return -1;
      if (projectStatus1 !== "on hold" && projectStatus2 === "on hold")
        return 1;
    }

    if (sortingScore1 < 6)
      return helper.sortProjectByDateDescending(
        opportunity1.project,
        opportunity2.project
      );
  }

  return 0;
};

export const calculateSortingScore = (
  opportunity: StartupOpportunity
): number => {
  const funnelStage = opportunity.project.funnelStage;
  const projectStatus = opportunity.project.status;
  const startupStatus = getStartupStatus(opportunity);

  if (startupStatus === "Adopted") return 1;

  if (["active", "on hold", "adopted"].includes(projectStatus)) {
    switch (startupStatus) {
      case "Selected":
        return 2;
      case "Evaluation":
        return 3;
      case "Ruled Out":
        return 4;
      default:
        break;
    }
  } else if (projectStatus === "archived") {
    if (stageScores[funnelStage] < stageScores["adopt"]) return 6;
    if (startupStatus === "Ruled Out") return 4;
  }
  return 7;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areObjectsShallowEqual = <T extends Record<string, any>>(
  object1: T,
  object2: T
): boolean => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  let areObjectsEqual = true;
  for (const key of keys1) {
    if (typeof object1[key] === "object") continue;
    if (object1[key] !== object2[key]) {
      areObjectsEqual = false;
    }
  }
  for (const key of keys2) {
    if (typeof object2[key] === "object") continue;
    if (object2[key] !== object1[key]) {
      areObjectsEqual = false;
    }
  }
  return areObjectsEqual;
};

export const findLogo = (
  files: File[],
  type: "Logo" | "companyLogo" = "Logo"
): string => {
  const file = files.find((file: File) => file.type === type);
  return file ? file.url : "";
};

export function getErrorMessage(error: AxiosError): string {
  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    return error.response.data.message || error.response.statusText;
  } else if (error.request) {
    // The request was made but no response was received
    return "No response was received from the server.";
  } else {
    // Something happened in setting up the request that triggered an Error
    return error.message;
  }
}

export function normalizeUrl(url: string): string {
  const httpPattern = /^(http:\/\/|https:\/\/)/;
  if (!httpPattern.test(url)) {
    return `http://${url}`;
  }
  return url;
}

export const isProjectBmwCheck = (ventureClientId: number): boolean => {
  return ventureClientId === 7;
};

export const formatFunding = (funding: number | string | null): string => {
  if (!funding) return "";
  return `$ ${funding} M`;
};

export const delay = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));
