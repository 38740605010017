import axios from "axios";
import { SolutionCluster } from "../../Types/SolutionCluster";

export class SolutionClusterHttpService {
  public static async createSolutionCluster(
    solutionCluster: SolutionCluster
  ): Promise<SolutionCluster> {
    const response = await axios.post<SolutionCluster>(
      `/api/solution-clusters`,
      solutionCluster
    );
    return response.data;
  }

  public static async deleteSolutionCluster(id: number): Promise<void> {
    await axios.delete<void>(`/api/solution-clusters/${id}`);
  }

  public static async updateSolutionCluster(
    soliutionCluster: SolutionCluster
  ): Promise<void> {
    await axios.put<void>(
      `/api/solution-clusters/${soliutionCluster.id}`,
      soliutionCluster
    );
  }
}
