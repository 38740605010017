import { FormControlLabel, Checkbox } from "@mui/material";
import { ChangeEvent, ReactElement } from "react";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import theme from "../../../theme";

interface BookmarkProps {
  checked: boolean;
  handleBookmark: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  label?: string;
  disableRipple?: boolean;
}

const Bookmark = (props: BookmarkProps): ReactElement => {
  const {
    checked = false,
    handleBookmark,
    className,
    label = "",
    disableRipple,
  } = props;

  return (
    <FormControlLabel
      className={className}
      onClick={(event) => event.stopPropagation()}
      control={
        <Checkbox
          name="bookmarked"
          data-testid="bookmark-btn"
          edge="start"
          checked={checked}
          onChange={handleBookmark}
          checkedIcon={
            <BookmarkOutlinedIcon
              style={{ fill: theme.palette.primary.main }}
            />
          }
          icon={<BookmarkBorderOutlinedIcon />}
          disableRipple={disableRipple}
        />
      }
      label={label}
    />
  );
};

export default Bookmark;
