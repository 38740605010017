import { ReactElement, useContext } from "react";
import { Opportunity } from "../../../../../../Types/Opportunity";
import { styled, Typography } from "@mui/material";
import { GlobalLoaderContext } from "../../../../../../Context/LoaderContext";
import { Close, PresentToAll } from "@mui/icons-material";
import theme from "../../../../../../theme";

const StyledRecommendCell = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
  lineHeight: theme.spacing(2),
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .recommend-close-button": {
    display: "none",
  },
  ":hover": {
    "& .recommend-close-button": {
      display: "inline-flex",
    },
  },
}));

interface RecommendStartupProps {
  opportunity: Opportunity;
  onChangeRecommended: (recommended: boolean) => void;
  isAddingStartup: boolean;
}

export default function RecommendStartup(
  props: RecommendStartupProps
): ReactElement {
  const { globalLoader } = useContext(GlobalLoaderContext);

  const handleRecommendStartup = (
    event: React.MouseEvent<HTMLElement>,
    isRecommended: boolean
  ) => {
    if (!globalLoader && !props.isAddingStartup) {
      event.stopPropagation();
      props.onChangeRecommended(isRecommended);
    }
  };

  return props.opportunity.recommendedForDemo ? (
    <StyledRecommendCell
      variant="subtitle1"
      onClick={(e) => handleRecommendStartup(e, false)}
      fontSize={theme.customTypography.fontSize12}
      data-testid={`recommended-startup-demo-${props.opportunity.id}`}
    >
      Recommended
      <Close className="recommend-close-button" fontSize="small" />
    </StyledRecommendCell>
  ) : (
    <StyledRecommendCell
      variant="subtitle1"
      onClick={(e) => handleRecommendStartup(e, true)}
      fontSize={theme.customTypography.fontSize12}
      color="#A5A5EF"
      data-testid={`not-recommended-startup-demo-${props.opportunity.id}`}
    >
      Recommend...
      <PresentToAll />
    </StyledRecommendCell>
  );
}
