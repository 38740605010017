import { ReactElement, ReactNode } from "react";
import theme from "../../theme";
import { Box, Button, Paper, Typography, styled } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";

interface Props {
  title: string;
  children?: ReactNode;
  fullHeight?: boolean;
  editAction?: () => void;
}

interface StyledPaperProps {
  $fullHeight?: boolean;
  children?: ReactNode;
}

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<StyledPaperProps>(({ theme, $fullHeight }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  height: $fullHeight ? "100%" : "fit-content",
}));

const StyledHeading = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
}));

const StyledContent = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  gap: theme.spacing(3),
}));

export default function ProjectLeadGridItem(props: Props): ReactElement {
  return (
    <StyledPaper
      variant="outlined"
      $fullHeight={props.fullHeight}
      data-testid="lead-project-grid-item"
    >
      <StyledHeading>
        <Typography variant="h6">{props.title}</Typography>
        {props.editAction && (
          <Button onClick={props.editAction} id="edit-action">
            <EditOutlined />
          </Button>
        )}
      </StyledHeading>
      <StyledContent>{props.children}</StyledContent>
    </StyledPaper>
  );
}
