import { Button, Grid, Typography, styled } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../theme";
import { LeadProject } from "../../Types/LeadProject";
import ProjectLeadKanbanCard from "./ProjectLeadKanbanCard";

const StyledGrid = styled(Grid)(() => ({
  alignContent: "flex-start",
  justifyContent: "center",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  border: "1px solid rgba(0, 0, 0, 0.12)",
  position: "relative",
  minHeight: "75vh",
}));

interface ProjectKanbanLeadsProps {
  handleModalOpen: () => void;
  refreshData: boolean;
  leadProjects: LeadProject[];
}
export default function ProjectLeadsKanban(
  props: ProjectKanbanLeadsProps
): ReactElement {
  return (
    <StyledGrid
      container
      item
      spacing={1}
      xs={12}
      sm={2}
      data-testid="lead-column"
    >
      <Grid
        item
        xs={12}
        display="inline-flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">Leads</Typography>
        <Typography variant="caption" data-testid="card-number-display">
          ({props.leadProjects.length})
        </Typography>
      </Grid>
      <Button data-testid="new-lead-button" onClick={props.handleModalOpen}>
        + New Lead
      </Button>
      {props.leadProjects
        ?.sort((a, b) => {
          return b.dateCreated.getTime() - a.dateCreated.getTime();
        })
        .map((lead) => (
          <Grid key={lead.id} width="100%" item>
            <ProjectLeadKanbanCard leadProject={lead} />
          </Grid>
        ))}
    </StyledGrid>
  );
}
