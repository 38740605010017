import { Box, Button } from "@mui/material";
import { ReactElement } from "react";
import { funnelStages } from "../../../../Constants/FunnelStages";
import { Project, FunnelStage } from "../../../../Types/Project";
import { capitalizeFirstLetter } from "../../../../utils";

interface Props {
  projectData: Project;
  activeStep: number;
  setFunnelStage: (funnelStage: FunnelStage) => Promise<void>;
  moveProjectToTheNewStage: () => Promise<void>;
  getCurrentFunnelStageIndex: () => number;
  getNextFunnelStage: () => FunnelStage;
}

export default function FunnelButton(props: Props): ReactElement {
  const isFunnelStageDefined = props.projectData?.funnelStage;
  const isCurrentStageActive =
    props.activeStep === props.getCurrentFunnelStageIndex();
  const isAdoptStage = props.projectData?.funnelStage === "adopt";

  return (
    <Box display="flex" flexDirection="row" gap={2} marginLeft="auto">
      {isFunnelStageDefined && !isCurrentStageActive && (
        <Button
          variant="text"
          onClick={() => props.setFunnelStage(funnelStages[props.activeStep])}
        >
          Set to {capitalizeFirstLetter(funnelStages[props.activeStep])}
        </Button>
      )}

      {isFunnelStageDefined && !isAdoptStage && (
        <Button
          color="secondary"
          variant="contained"
          onClick={props.moveProjectToTheNewStage}
        >
          Move to {capitalizeFirstLetter(props.getNextFunnelStage())}
        </Button>
      )}
    </Box>
  );
}
