import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Drawer,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { ReactElement, useState } from "react";
import { StartupFilterCriteria } from "../../Types/Startup";

interface FilterDrawerProps {
  open: boolean;
  setFilterDrawerOpen: (state: boolean) => void;
  handleFilters: (state: StartupFilterCriteria) => void;
  activeFilters: StartupFilterCriteria;
}
const investmentStage = [
  "Pre-Seed",
  "Seed - Accelerator (no equity)",
  "Seed - Accelerator (equity)",
  "Seed - Angel",
  "Seed - VC",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series E",
  "Series F",
];
const employees = ["<10", "<25", "<50", "<100", "<500", ">500", "Undisclosed"];

//add default value of filters
const defaultFilters = {
  currentInvestmentStage: "",
  employees: "",
};

export function FilterDrawer(props: FilterDrawerProps): ReactElement {
  const [filterOptions, setFilterOptions] = useState(defaultFilters);

  const resetFilters = () => {
    if (Object.keys(props.activeFilters).length != 0) {
      props.handleFilters({});
    }
    setFilterOptions(defaultFilters);
    props.setFilterDrawerOpen(false);
  };

  const handleAcceptFilterChanges = () => {
    const validFilters: StartupFilterCriteria = Object.entries(
      filterOptions
    ).reduce((acc, [key, value]) => {
      // remove empty strings and undefined values (businessUnitId / ventureClientId e.g.)
      if (value !== "" && value !== undefined)
        acc = Object.assign({ [key]: value }, acc);
      return acc;
    }, {} as StartupFilterCriteria);

    if (JSON.stringify(validFilters) != JSON.stringify(props.activeFilters)) {
      props.handleFilters(validFilters);
    }
    props.setFilterDrawerOpen(false);
  };

  const handleSelectFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFilterOptions({ ...filterOptions, [name]: value });
  };

  return (
    <Drawer variant="temporary" anchor="right" open={props.open}>
      <Toolbar />
      <Box
        role="presentation"
        display="flex"
        flexDirection="column"
        p={3}
        width="320px"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h6">Filters</Typography>

          <Button
            id="filter-drawer-close-button"
            sx={{ ml: "auto" }}
            color="primary"
            onClick={() => props.setFilterDrawerOpen(false)}
          >
            <Close />
          </Button>
        </Box>
        <Divider sx={{ mb: 4 }} />
        <Box gap={2} display="flex" flexDirection="column">
          <TextField
            label="Funding Stage"
            id="currentInvestmentStage"
            name="currentInvestmentStage"
            variant="outlined"
            select
            value={filterOptions.currentInvestmentStage}
            onChange={handleSelectFilter}
          >
            <MenuItem key={"none"} value={""}>
              None
            </MenuItem>
            {investmentStage.map((entity) => (
              <MenuItem key={entity} value={entity}>
                {entity}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Employees"
            id="employees"
            name="employees"
            variant="outlined"
            select
            value={filterOptions.employees}
            onChange={handleSelectFilter}
          >
            <MenuItem key={"none"} value={""}>
              None
            </MenuItem>
            {employees.map((entity) => (
              <MenuItem key={entity} value={entity}>
                {entity}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mt: 2 }}
        >
          <Button
            onClick={resetFilters}
            variant="outlined"
            id="reset-startup-filters"
          >
            Reset
          </Button>
          <Button
            sx={{ ml: 2 }}
            onClick={handleAcceptFilterChanges}
            color="secondary"
            variant="contained"
            id="apply-startup-filters"
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
