import React, {
  ReactElement,
  Reducer,
  useEffect,
  useState,
  Fragment,
  useReducer,
  useContext,
} from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Grid, Box, Button, Typography } from "@mui/material";
import { ProjectHttpService } from "../../../../Http/Project/Project.http.service";
import { Project } from "../../../../Types/Project";
import { SelectInput } from "../../../UI/InputFields/SelectInput";

import CustomTextField from "../../../UI/InputFields/CustomTextField";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
interface Props {
  projectData: Project;
  handleSave: () => void;
}

export default function Form(props: Props): ReactElement {
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.projectData
  );
  const {
    setGlobalEditMode,
    globalEditMode,
    setShouldSaveProject,
    shouldSaveProject,
  } = useContext(GlobalProjectEditContext);

  useEffect(() => {
    setProject(props.projectData);
  }, [props.projectData]);

  useEffect(() => {
    setGlobalEditMode(editMode);
  }, [editMode]);

  useEffect(() => {
    const saveChanges = async () => {
      if (shouldSaveProject) {
        await saveProject();
        setShouldSaveProject(false);
      }
    };

    saveChanges();
  }, [shouldSaveProject]);

  const cancelEdit = () => {
    setEditMode(false);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const adoptionStatusSelectValues = [
    { id: "Adoption Preparation", name: "Adoption Preparation" },
    { id: "Implement Solution", name: "Implement Solution" },
    { id: "Rollout Solution", name: "Rollout Solution" },
  ];

  const saveProject = async () => {
    await ProjectHttpService.updateProject(project as Project);
    props.handleSave();
    setEditMode(false);
  };

  return (
    <Fragment>
      <form noValidate autoComplete="off">
        <Box justifyContent="space-between" flexDirection="row" display="flex">
          <Typography variant="h6">Adoption Insights</Typography>
          {!editMode && !globalEditMode && (
            <Button onClick={() => setEditMode(true)} id="edit-form-button">
              <EditOutlinedIcon />
            </Button>
          )}
        </Box>
        <Typography variant={"h6"} fontWeight="medium" mt={6}>
          Scale
        </Typography>
        {project && (
          <Grid spacing={2} container>
            <Grid item>
              <SelectInput
                id="adoptionStatus"
                labelText="Adoption Status"
                selectValues={adoptionStatusSelectValues}
                value={project.adoptionStatus}
                onChange={(e) => setProject({ adoptionStatus: e.target.value })}
                editMode={editMode}
              />
            </Grid>
            <Grid item>
              <CustomTextField
                type="date"
                id="adoptionDate"
                label="Date of Adoption"
                editMode={editMode}
                value={project.adoptionDate}
                onChange={(e) =>
                  setProject({ adoptionDate: e.target.value || null })
                }
              />
            </Grid>
            <Grid item>
              <CustomTextField
                id="potentialLaunch"
                type="date"
                label="Potential Launch of Solution"
                value={project.potentialLaunch}
                editMode={editMode}
                onChange={(e) =>
                  setProject({ potentialLaunch: e.target.value || null })
                }
              />
            </Grid>
            <Grid item>
              <CustomTextField
                id="adoptionResources"
                label="Resources for Adoption"
                editMode={editMode}
                value={project.adoptionResources}
                onChange={(e) =>
                  setProject({ adoptionResources: e.target.value })
                }
                maxCharacter={255}
                multiline
              />
            </Grid>
          </Grid>
        )}
      </form>

      <Box
        sx={{
          justifyContent: "flex-end",
          flexDirection: "row",
          display: editMode ? "flex" : "none",
          m: 2,
        }}
      >
        <Button
          autoFocus
          onClick={cancelEdit}
          color="primary"
          id="cancel-project-button"
        >
          Cancel
        </Button>
        <Button
          onClick={saveProject}
          variant="contained"
          color="secondary"
          id="save-project-button"
          sx={{ marginLeft: "15px" }}
        >
          Save Project
        </Button>
      </Box>
    </Fragment>
  );
}
