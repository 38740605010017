import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement, useState } from "react";
import styled from "@mui/styles/styled";
import { ProjectLeadMatch } from "../../../Types/ClientContact";
import { StartupMatch } from "../../../Types/Startup";
import { useSnackbar } from "notistack";
import DetailsClientContactModal from "../../Contacts/DetailsClientContactModal";

interface ProjectInfoCardProps {
  startupPartner: StartupMatch;
  leadProject: ProjectLeadMatch;
}
const ProjectLeadContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: theme.spacing(4),
  columnGap: theme.spacing(2),
  cursor: "pointer",
}));

const ProjectLeadText = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  "&.startupmatch-text": {
    marginLeft: "18%",
    transform: "translateX(-20px)",
  },
  "&.projectmatch-text": {
    marginRight: "18%",
    transform: "translateX(20px)",
  },
}));

const DecorativeCircle = styled(Box)(({ theme }) => ({
  outline: `3px solid ${theme.palette.grey[300]}`,
  borderRadius: "10px",
  minWidth: "80px",
  width: "80px",
  aspectRatio: "1/1",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& img": {
    maxWidth: " 90%",
  },
}));

const StartupMatchContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  margin: theme.spacing(6, "auto", 4),
  maxWidth: "320px",
}));

const StartupLogoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "50%",
  textAlign: "center",
  alignItems: "center",
  "& p": {
    maxWidth: "100%",
  },
  cursor: "pointer",
  "&:hover": {
    "& .lead-logo, & .startup-logo": {
      outlineWidth: "5px",
    },
    "& .projectmatch-text, & .startupmatch-text": {
      color: theme.palette.primary.main,
    },
  },
}));

const DecorativeCircleMatch = styled(Box)(({ theme }) => ({
  outline: `3px solid ${theme.palette.grey[300]}`,
  borderRadius: "10px",
  height: "120px",
  width: "120px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  transition: `all .1s ${theme.transitions.easing.easeInOut}`,
  "&.lead-logo": {
    transform: "translateX(20px) rotate(15deg)",
  },
  "&.startup-logo": {
    transform: "translateX(-20px) rotate(-15deg)",
  },
  "& img": {
    maxWidth: "90%",
    maxHeight: "auto",
  },
  marginBottom: "24px",
}));

export default function ProjectInfoCard(
  props: ProjectInfoCardProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [openClientContact, setOpenClientContact] = useState(false);

  const handleEditSave = () => {
    window.location.reload();
  };

  const clientContactDetailsOpen = () => {
    if (props.leadProject.name) {
      setOpenClientContact(true);
    } else {
      enqueueSnackbar("Project leader not found", {
        variant: "error",
      });
    }
  };

  return (
    <div data-testid="project-info-section">
      {!props.startupPartner ? (
        <ProjectLeadContainer
          data-testid="lead-project-container"
          onClick={clientContactDetailsOpen}
        >
          <DecorativeCircle>
            <img
              src={
                props.leadProject.logo
                  ? props.leadProject.logo
                  : "/images/logo_placeholder.svg"
              }
              alt="venture client logo"
            />
          </DecorativeCircle>
          <ProjectLeadText>
            <Typography variant="subtitle2">
              {props.leadProject.name}
            </Typography>
            <Typography variant="body2">{props.leadProject.title}</Typography>
          </ProjectLeadText>
        </ProjectLeadContainer>
      ) : (
        <StartupMatchContainer>
          {/* lead project */}

          <StartupLogoBox
            className="lead-box"
            data-testid="lead-box"
            onClick={clientContactDetailsOpen}
          >
            <DecorativeCircleMatch className="lead-logo">
              <img
                src={
                  props.leadProject.logo
                    ? props.leadProject.logo
                    : "/images/logo_placeholder.svg"
                }
                alt="venture client logo"
              />
            </DecorativeCircleMatch>
            <ProjectLeadText className="projectmatch-text">
              <Typography variant="subtitle2">
                {props.leadProject.name}
              </Typography>
              <Typography variant="body2">{props.leadProject.title}</Typography>
            </ProjectLeadText>
          </StartupLogoBox>

          {/* startup */}
          <StartupLogoBox
            className="startup-box"
            data-testid="startup-box"
            onClick={() => {
              window.open(`/startups/${props.startupPartner?.id}`, "_blank");
            }}
          >
            <DecorativeCircleMatch className="startup-logo">
              <img
                id="startupMatch-logo"
                src={
                  props.startupPartner?.logo
                    ? props.startupPartner?.logo
                    : "/images/27pilots_Logo.png"
                }
                alt={
                  props.startupPartner.logo ? "startup logo" : "27Pilots Logo"
                }
              />
            </DecorativeCircleMatch>
            <ProjectLeadText className="startupmatch-text">
              <Typography variant="subtitle2">
                {props.startupPartner.name}
              </Typography>
            </ProjectLeadText>
          </StartupLogoBox>
        </StartupMatchContainer>
      )}
      {openClientContact && (
        <DetailsClientContactModal
          setModalOpen={setOpenClientContact}
          modalOpen={openClientContact}
          contact={props.leadProject}
          handleSave={handleEditSave}
          mustFetch
        />
      )}
    </div>
  );
}
