import { Box, Typography, styled } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";
import { StartupOpportunity } from "../../../../Types/Opportunity";
import { sortOpportunitiesByRelevance } from "../../../../utils";
import ProjectCard from "./ProjectCard";
import ProjectStats from "./ProjectStats";

const ProjectSectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}));

interface ProjectSectionProps {
  opportunities: StartupOpportunity[];
  startupName: string;
}

export default function ProjectSection(
  props: ProjectSectionProps
): ReactElement {
  return (
    <ProjectSectionWrapper data-testid="relatedProjectsSection">
      <Typography variant="h6">Related Projects</Typography>
      <ProjectStats opportunities={props.opportunities} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(3),
        }}
      >
        {props.opportunities
          .sort((opportunity1, opportunity2) =>
            sortOpportunitiesByRelevance(opportunity1, opportunity2)
          )
          .map((opportunity) => (
            <ProjectCard
              key={opportunity.id}
              opportunity={opportunity}
              startupName={props.startupName}
            />
          ))}
        {props.opportunities.length === 0 && (
          <Typography color="text.secondary" variant="caption">
            This startup has not been assigned to any projects yet.
          </Typography>
        )}
      </Box>
    </ProjectSectionWrapper>
  );
}
