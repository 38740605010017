import axios from "axios";
import { CreateImpactValue, ImpactValue } from "../../Types/ImpactValue";
import { FunnelStage } from "../../Types/Project";

export class ImpactValueHttpService {
  public static async createImpactValue(
    impactValue: CreateImpactValue
  ): Promise<ImpactValue> {
    const response = await axios.post<ImpactValue>(
      `/api/impact-values`,
      impactValue
    );
    return response.data;
  }

  public static async getImpactValues(
    id: number,
    stage: FunnelStage
  ): Promise<ImpactValue[]> {
    const response = await axios.get<ImpactValue[]>(
      `/api/projects/${id}/impact-values`,
      {
        params: { stage },
      }
    );
    return response.data;
  }

  public static async deleteImpactValue(id: number): Promise<void> {
    await axios.delete<void>(`/api/impact-values/${id}`);
  }
}
