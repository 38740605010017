import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  styled,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, useEffect, useState } from "react";
import CustomTextField from "../../../../../../UI/InputFields/CustomTextField";
import {
  Opportunity,
  RejectionReason,
} from "../../../../../../../Types/Opportunity";
import theme from "../../../../../../../theme";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { findLogo } from "../../../../../../../utils";
import { RejectionReasonHttpService } from "../../../../../../../Http/RejectionReasons/RejectionReasons.http.service";

interface AddSelectionReasonsModalProps {
  opportunity: Opportunity;
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  handleRejectionReasonChange: (
    updatedRejectionReasons: RejectionReason[],
    updatedDescription: string
  ) => void;
}

const ActionButtons = styled("div")(() => ({
  marginLeft: "auto",
  height: "38px",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

const InfoSection = styled("div")(() => ({
  marginTop: theme.spacing(2),
  display: "flex",
  justifyContent: "start",
  columnGap: theme.spacing(1),
}));

const StartupInfo = styled("div")(() => ({
  display: "flex",
  marginBottom: theme.spacing(2),
  columnGap: theme.spacing(2.5),
  "& .MuiTypography-caption:first-of-type": {
    marginRight: "auto",
  },
}));

const SelectionRationaleInfo = styled("div")(() => ({
  display: "grid",
}));

const AddSelectionReasonsModal = (
  props: AddSelectionReasonsModalProps
): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [description, setDescription] = useState(
    props.opportunity.rejectionDescription
  );

  const [projectRejectionReasons, setProjectRejectionReasons] = useState<
    RejectionReason[]
  >([]);

  const [editMode, setEditMode] = useState(
    !props.opportunity.rejectionReasons?.length
  );
  const [selectedReasonsIds, setSelectedReasonsIds] = useState<number[]>(
    props.opportunity.rejectionReasons
      ? props.opportunity.rejectionReasons?.map((reason) => reason.id)
      : []
  );

  const updateReasonsIdList = (id: number) => {
    let updatedReasonsIdList: number[] = selectedReasonsIds;

    if (selectedReasonsIds?.length && selectedReasonsIds.includes(id)) {
      updatedReasonsIdList = updatedReasonsIdList.filter(
        (selectedid) => selectedid !== id
      );
    } else {
      updatedReasonsIdList = [...selectedReasonsIds, id];
    }
    return updatedReasonsIdList;
  };

  const handleChange = (id: number) => {
    const updatedReasonsIdList = updateReasonsIdList(id);
    setSelectedReasonsIds(updatedReasonsIdList);
  };

  const getUpdatedReasons = () => {
    const updated = projectRejectionReasons.filter((reason) =>
      selectedReasonsIds.includes(reason.id)
    );
    return updated;
  };

  const handleSave = () => {
    if (!selectedReasonsIds.length) {
      enqueueSnackbar("Please provide a reason", {
        variant: "error",
      });
    } else {
      props.handleRejectionReasonChange(getUpdatedReasons(), description);
      setEditMode(false);
      props.setModalOpen(false);
    }
  };

  const opportunityLogo = props.opportunity.startup.files
    ? findLogo(props.opportunity.startup.files) || null
    : null;

  useEffect(() => {
    RejectionReasonHttpService.getRejectionReasonsByVentureClientName().then(
      (response) => {
        setProjectRejectionReasons(response);
      }
    );
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.modalOpen}
      onClick={(event) => {
        event.stopPropagation();
      }}
      onClose={() => props.setModalOpen(false)}
      data-testid={`${props.opportunity.startup.name}-selection-reasons-modal`}
    >
      <DialogTitle>
        <Box display="flex">
          Selection Rationale
          <ActionButtons>
            <Button
              onClick={() => {
                props.setModalOpen(false);
                setSelectedReasonsIds([]);
                setDescription("");
              }}
            >
              Close
            </Button>
            {editMode ? (
              <Button onClick={() => handleSave()}>Save</Button>
            ) : (
              <Button onClick={() => setEditMode(true)}>Edit</Button>
            )}
          </ActionButtons>
        </Box>
        <InfoSection>
          <InfoOutlinedIcon />
          <Typography variant="caption">
            It is recommended to assess A-rated startups based on the specific
            criteria and requirements of the use case in order to find the best
            fit.
          </Typography>
        </InfoSection>
      </DialogTitle>

      <DialogContent dividers>
        <Box mt={theme.spacing(1)} display={"grid"} rowGap={theme.spacing(1)}>
          {opportunityLogo && (
            <img
              style={{ height: theme.spacing(3) }}
              src={opportunityLogo}
              alt="logo associated to the startup"
            />
          )}
          <StartupInfo>
            <Typography
              variant="caption"
              color={theme.palette.other.secondaryAction}
            >
              {props.opportunity.startup.name}
            </Typography>
            <Typography variant="caption">
              {props.opportunity.startup.billingCountry}
            </Typography>
            <Typography variant="caption">
              {props.opportunity.startup.dateFounded?.getFullYear()}
            </Typography>
            <Typography variant="caption" fontWeight={"bold"}>
              {props.opportunity.startup.currentInvestmentStage}
            </Typography>
            <Typography variant="caption" fontWeight={"bold"}>
              {props.opportunity.startup.fundingIsUndisclosed
                ? null
                : `$${props.opportunity.startup.totalFunding}M`}
            </Typography>
          </StartupInfo>
          {!editMode && (
            <SelectionRationaleInfo>
              {props.opportunity.rejectionReasons?.map((reason) => {
                return (
                  <Typography
                    key={`list-of-reasons-${props.opportunity.id}-${reason.id}`}
                    variant="caption"
                    fontWeight={"bold"}
                  >
                    • {reason.name}
                  </Typography>
                );
              })}
              <Typography
                sx={{ paddingBlock: theme.spacing(1) }}
                variant="caption"
              >
                {props.opportunity.rejectionDescription}
              </Typography>
            </SelectionRationaleInfo>
          )}
          {editMode && (
            <>
              <FormControl sx={{ width: "-webkit-fill-available" }}>
                <InputLabel id="selection-rationale-label">
                  Select Reason(s)
                </InputLabel>
                <Select
                  labelId="selection-rationale-label"
                  id="selection-rationale-checkbox"
                  data-testid="rejection-reasons-dropdown"
                  value={selectedReasonsIds}
                  multiple
                  input={<OutlinedInput label="Select Reason(s)" />}
                  renderValue={() => {
                    const value = selectedReasonsIds?.map(
                      (selectedReasonId) => {
                        return projectRejectionReasons.find(
                          (reason) => reason.id === selectedReasonId
                        )?.name;
                      }
                    );
                    return value ? value.join(", ") : "";
                  }}
                >
                  {projectRejectionReasons.map((reason) => (
                    <MenuItem
                      key={reason.id}
                      value={reason.id}
                      data-testid={`list-of-reasons-${reason.id}`}
                      onClick={() => {
                        handleChange(reason.id);
                      }}
                    >
                      <Checkbox
                        checked={selectedReasonsIds?.includes(reason.id)}
                      />
                      <ListItemText primary={reason.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <CustomTextField
                id="reson-description"
                label="Description"
                fullWidth
                editMode={true}
                value={description}
                onChange={(event) => {
                  {
                    setDescription(event.target.value);
                  }
                }}
              />
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddSelectionReasonsModal;
