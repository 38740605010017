import { ReactElement, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  UserContext,
  isVentureClientUnitHead,
} from "../../../../Context/UserContext";
import StageValidator from "../../../../StageValidation/StageValidator";
import { Project } from "../../../../Types/Project";

interface Props {
  modalOpen: boolean;
  setValidationDialogOpen: (arg: boolean) => void;
  projectData: Project;
  overrideValidationRules: () => void;
}

function StageValidationModal(props: Props): ReactElement {
  const user = useContext(UserContext);
  const userIsVentureClientUnitHead = isVentureClientUnitHead(user);

  return (
    <Dialog
      open={props.modalOpen}
      onClose={() => props.setValidationDialogOpen(false)}
      id="required-properties-dialog"
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        Missing Information!
      </DialogTitle>
      <DialogContent sx={{ minWidth: "400px" }}>
        The following data is required:
        <Box component="ul" sx={{ paddingInlineStart: "24px" }}>
          {StageValidator.getInvalidProperties(props.projectData).map(
            (property) => (
              <li key={property}>{property}</li>
            )
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        {userIsVentureClientUnitHead && (
          <Button
            onClick={() => props.overrideValidationRules()}
            color="warning"
          >
            Move anyway
          </Button>
        )}
        <Button onClick={() => props.setValidationDialogOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StageValidationModal;
