import { StartupOpportunity } from "../../Types/Opportunity";
import { Startup, StartupDTO } from "../../Types/Startup";
import MapFile from "../File/File.mapper";

export default class StartupMapper {
  public static map(startup: StartupDTO): Startup {
    return {
      ...startup,
      dateCreated: new Date(startup.dateCreated),
      dateFounded: startup.dateFounded ? new Date(startup.dateFounded) : null,
      files: startup.files ? MapFile.map(startup.files) : [],
      lastModifiedDate: startup.lastModifiedDate
        ? new Date(startup.lastModifiedDate)
        : null,
      ...(startup.opportunities?.length && {
        opportunities: startup.opportunities.map(
          (StartupOpportunity: StartupOpportunity) =>
            StartupOpportunityMapper.map(StartupOpportunity)
        ),
      }),
    };
  }
}

export class StartupOpportunityMapper {
  static map(opportunity: StartupOpportunity): StartupOpportunity {
    return {
      ...opportunity,
      project: {
        ...opportunity.project,
        lastModifiedDate: opportunity.project.lastModifiedDate
          ? new Date(opportunity.project.lastModifiedDate)
          : null,
        dateCreated: new Date(opportunity.project.dateCreated),
      },
    };
  }
}
