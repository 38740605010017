import { funnelStages } from "../Constants/FunnelStages";
import { File } from "../Types/File";
import { Kpi } from "../Types/Kpi";
import { Project } from "../Types/Project";
import { capitalizeFirstLetter } from "../utils";
import { KeyNamePair, stageRules } from "./StageRules";

export const getSelectedStartupName = (
  project: Project
): string | undefined => {
  return project?.opportunities?.find(
    (opportunity) => opportunity.isSelectedForPilot === true
  )?.startup.name;
};

export const checkQualifiedOpportunities = (project: Project): boolean => {
  return !!project?.opportunities?.find(
    (opportunity) => opportunity.isQualified === true
  );
};

export const checkFiles = (
  project: Project,
  keyNamePair: KeyNamePair,
  invalidProperties: string[],
  funnelStage: string
): string[] => {
  if (keyNamePair.scope === "project") {
    const fileFound = !!project.files.find(
      (file) => file.type === keyNamePair.name
    );
    if (!fileFound) {
      invalidProperties.push(keyNamePair.name);
    }
  } else if (keyNamePair.scope === "opportunity") {
    project.opportunities
      .filter((opp) =>
        funnelStage === "discover"
          ? 1
          : funnelStage === "assess" && opp.isQualified
          ? 1
          : opp.isSelectedForPilot
          ? 1
          : 0
      )
      .forEach((opportunity) => {
        const fileFound = !!opportunity.files.find(
          (file: File) => file.type === keyNamePair.name
        );
        if (!fileFound) {
          if (funnelStage === "discover" || funnelStage === "assess") {
            invalidProperties.push(
              keyNamePair.name + " for " + opportunity.startup.name
            );
          } else {
            invalidProperties.push(keyNamePair.name);
          }
        }
      });
  } else if (keyNamePair.scope === "productDemo") {
    project.opportunities
      .filter((opportunity) => opportunity.productDemos.length > 0)
      .forEach((opportunity) => {
        const fileFound = !!opportunity.files.find(
          (file) => file.type === keyNamePair.name
        );

        if (!fileFound) {
          invalidProperties.push(
            keyNamePair.name + " for " + opportunity.startup.name
          );
        }
      });
  }
  return invalidProperties;
};

export const checkSubFields = (
  // eslint-disable-next-line
  section: any,
  requiredFields: string[],
  fieldNames?: { [key: string]: string }
): string[] => {
  const emptyFields: string[] = [];
  requiredFields.forEach((field) => {
    const _field = fieldNames?.[field] ?? field;
    // eslint-disable-next-line
    section.forEach((member: any) => {
      if (!member[field]) {
        emptyFields.push(capitalizeFirstLetter(_field));
      }
    });
  });

  return Array.from(new Set(emptyFields)); //remove duplications
};

export default class StageValidator {
  static getInvalidProperties(project: Project): string[] {
    const invalidProperties: string[] = [];
    const stageIndex = funnelStages.indexOf(project.funnelStage);

    let rules;
    for (let i = 0; i <= stageIndex; i++) {
      if (i < stageIndex) {
        rules = stageRules[funnelStages[i]].filter(
          (rule) => !rule.stageSpecific
        );
      } else {
        rules = stageRules[funnelStages[i]];
      }

      rules.forEach((keyNamePair: KeyNamePair) => {
        const property =
          keyNamePair.key !== "isSelected" &&
          keyNamePair.key !== "isQualified" &&
          keyNamePair.key !== "productDemos" &&
          keyNamePair.key !== "files" &&
          keyNamePair.key !== "pilotOutcomeReason"
            ? project[keyNamePair.key]
            : keyNamePair.key;

        //for key in project model
        if (!property) {
          invalidProperties.push(keyNamePair.name);
          return;
        }

        //Case: No startups was marked as selected before moving the project to Buy stage
        if (
          keyNamePair.key === "isSelected" &&
          getSelectedStartupName(project) === undefined
        ) {
          invalidProperties.push(keyNamePair.name);
        }

        //Case: No startup were marked as qualified before moving the project to Assess stage
        if (
          keyNamePair.key === "isQualified" &&
          !checkQualifiedOpportunities(project)
        ) {
          invalidProperties.push(keyNamePair.name);
        }

        if (keyNamePair.key === "productDemos") {
          const requiredFieldsLabel = ["date"];
          const emptyFields = project.opportunities
            ?.filter((opp) => opp.isQualified)
            .flatMap((opp) => {
              return checkSubFields(opp.productDemos, requiredFieldsLabel);
            });

          if (emptyFields?.length) {
            Array.from(new Set(emptyFields)).forEach((field) => {
              invalidProperties.push(`${field} happening for Product Demos`);
            });
          }
        }

        if (stageIndex >= 0) {
          project.opportunities.forEach((opportunity) => {
            if (
              !opportunity.isQualified &&
              opportunity.rejectionReasons?.length === 0
            ) {
              if (
                !invalidProperties.includes(
                  "Provide Startup(s) rule out reasons"
                )
              ) {
                invalidProperties.push("Provide Startup(s) rule out reasons");
              }
            }
          });
        }

        if (keyNamePair.key === "files") {
          const invalidFiles: string[] = [];
          const missingFiles = checkFiles(
            project,
            keyNamePair,
            invalidFiles,
            funnelStages[i]
          );
          invalidProperties.push(...missingFiles);
        }

        if (keyNamePair.key === "objectives") {
          const requiredFieldsLabel = ["name"];
          const emptyFields = checkSubFields(
            project.objectives,
            requiredFieldsLabel
          );

          emptyFields.forEach((field) => {
            invalidProperties.push(`${field} for Objectives`);
          });

          if (stageIndex === 3) {
            let unMeasuredKpiFound = false;
            for (const objective of project.objectives) {
              if (unMeasuredKpiFound) {
                break;
              }
              for (const kpi of objective.kpis) {
                const isMeasurementSet = this.hasKpiMeasurement(kpi);
                if (!isMeasurementSet) {
                  unMeasuredKpiFound = true;
                  invalidProperties.push("Measurement for Kpi");
                  break;
                }
              }
            }
          }
        }

        if (keyNamePair.key === "meetings") {
          const requiredFieldsLabel = [
            "deliverables",
            "dateStart",
            "type",
            "generalMeetingNotes",
            "nextSteps",
          ];
          const filteredProject = this.filterProjectInitialMeetings(project);

          const meetingFieldNames = {
            generalMeetingNotes: "Meeting Notes",
            nextSteps: "Meeting Notes",
          };

          const emptyFields = checkSubFields(
            filteredProject.meetings,
            requiredFieldsLabel,
            meetingFieldNames
          );

          emptyFields.forEach((field) => {
            invalidProperties.push(`${field} for Meetings`);
          });
        }

        if (keyNamePair.key === "solutionClusters") {
          const requiredFieldsLabel = ["name", "description"];
          const emptyFields = checkSubFields(
            project.solutionClusters,
            requiredFieldsLabel
          );

          emptyFields.forEach((field) => {
            invalidProperties.push(`${field} for Solution Clusters`);
          });
        }

        if (
          keyNamePair.key === "pilotOutcomeReason" &&
          project.pilotOutcome === "Negative" &&
          project.pilotOutcomeReason === null
        ) {
          invalidProperties.push("Pilot Project Evaluation Reason");
        }
      });
    }

    return invalidProperties;
  }

  public static hasKpiMeasurement(kpi: Kpi): boolean {
    if (
      kpi.measurement ||
      kpi.fulfilled !== null ||
      kpi.isNotMeasured === true
    ) {
      return true;
    }
    return false;
  }

  public static filterProjectInitialMeetings(project: Project): Project {
    return {
      ...project,
      meetings: project.meetings?.filter((meeting) => !meeting.isInitial),
    };
  }
}
