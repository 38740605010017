import axios from "axios";
import { Meeting, CreateMeetingDTO } from "../../Types/Meeting";

export class MeetingHttpService {
  public static async createMeeting(
    meeting: CreateMeetingDTO
  ): Promise<Meeting> {
    const response = await axios.post<Meeting>(`/api/meetings`, meeting);
    return response.data;
  }

  public static async deleteMeeting(id: number): Promise<void> {
    await axios.delete<void>(`/api/meetings/${id}`);
  }

  public static async updateMeeting(meeting: Meeting): Promise<void> {
    await axios.put<void>(`/api/meetings/${meeting.id}`, meeting);
  }
}
