import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  Typography,
  DialogActions,
} from "@mui/material";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { KpiHttpService } from "../../../../Http/Kpi/Kpi.http.service";
import { getErrorMessage } from "../../../../utils";
import { useSnackbar } from "notistack";
import ObjectiveHttpService from "../../../../Http/Objective/Objective.Http.service";
import { ReactElement } from "react";
import { SolutionClusterHttpService } from "../../../../Http/SolutionCluster/SolutionCluster.http.service";
import OpportunityHttpService from "../../../../Http/Opportunity/Opportunity.Http.service";
import { MeetingHttpService } from "../../../../Http/Meeting/Meeting.Http.service";
import { CalculationHttpService } from "../../../../Http/Calculation/Calculation.http.service";
import { VariableHttpService } from "../../../../Http/Variable/Variable.http.service";
import { LeadProjectsHttpService } from "../../../../Http/LeadProjects/LeadProjects.http.service";

interface Props {
  id: number;
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  entity:
    | "KPI"
    | "Objective"
    | "Solution Cluster"
    | "Opportunity"
    | "Meeting"
    | "Variable"
    | "Calculation"
    | "Project Lead Leader"
    | "Project Lead Startup";
  handleSave: (id: number) => void;
  parentId?: number;
}

async function deleteEntity(
  entity: string,
  id: number,
  parentId?: number
): Promise<void> {
  switch (entity) {
    case "KPI":
      return KpiHttpService.deleteKpi(id);
    case "Objective":
      return ObjectiveHttpService.deleteObjective(id);
    case "Solution Cluster":
      return SolutionClusterHttpService.deleteSolutionCluster(id);
    case "Opportunity":
      return OpportunityHttpService.deleteOpportunity(id);
    case "Meeting":
      return MeetingHttpService.deleteMeeting(id);
    case "Variable":
      return VariableHttpService.deleteVariable(id);
    case "Calculation":
      return CalculationHttpService.deleteCalculation(id);
    case "Project Lead Leader":
      return LeadProjectsHttpService.deleteLeadProjectLeader(
        parentId as number,
        id
      );
    case "Project Lead Startup":
      return;
  }
}

const DeleteModal = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const title = `Delete ${props.entity}`;
  const content = `You are about to delete the ${props.entity}. Are you sure you want to continue?`;

  const handleDeleteEntity = async () => {
    await deleteEntity(props.entity, props.id, props.parentId)
      .then(() => {
        props.setModalOpen(false);
        props.handleSave(props.id);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(
          `Could not delete the ${props.entity}: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      });
  };

  return (
    <Dialog
      open={props.modalOpen}
      maxWidth="sm"
      data-testid={`delete-${props.entity}-modal`}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers sx={{ borderBottom: "none" }}>
        <Box display="flex" alignItems="center" gap={1}>
          <WarningOutlinedIcon color="error" sx={{ fontSize: "2rem" }} />
          <Typography> {content}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={() => props.setModalOpen(false)}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" onClick={handleDeleteEntity}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
