import { ReactElement, useState } from "react";
import theme from "../../../theme";
import { Box, Button } from "@mui/material";
import AddProjectLeadStartupModal from "./AddProjectLeadStartupModal";
import { LeadProject } from "../../../Types/LeadProject";
import { getErrorMessage } from "../../../utils";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import StartupsCarousel from "../../Startup/StartupsCarousel";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";

interface ProjectLeadStartupsProps {
  leadProject: LeadProject;
  onRefresh: () => void;
}
export default function ProjectLeadStartups(
  props: ProjectLeadStartupsProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const deleteProjectLeadOpportunity = (leadOpportunityId: number) => {
    LeadProjectsHttpService.deleteLeadOpportunity(
      props.leadProject.id,
      leadOpportunityId
    )
      .then(() => {
        props.onRefresh();
      })
      .catch((error: AxiosError) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, { variant: "error" });
      });
  };

  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        gap={theme.spacing(4)}
      >
        {props.leadProject.opportunities.length > 0 && (
          <StartupsCarousel
            opportunities={props.leadProject.opportunities}
            onDelete={deleteProjectLeadOpportunity}
          />
        )}
        <Button
          variant="text"
          color="primary"
          sx={{ textTransform: "none", alignSelf: "flex-end" }}
          onClick={handleModalOpen}
        >
          Add Startup
        </Button>
      </Box>
      {modalOpen && (
        <AddProjectLeadStartupModal
          leadProject={props.leadProject}
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
          onRefresh={props.onRefresh}
        />
      )}
    </>
  );
}
