import axios from "axios";
import { File as DocumentFile } from "../../Types/File";

export class FileHttpService {
  public static async upload(
    file: File,
    entityId: number,
    entityType: string,
    type: string
  ): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("entityType", entityType);
    formData.append("entityId", `${entityId}`);
    formData.append("type", type);
    await axios.post<void>(`/api/files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  public static async download(
    fileId: number,
    fileName: string
  ): Promise<void> {
    const { data } = await axios.get<Blob>(`/api/files/${fileId}`, {
      responseType: "blob",
    });

    const downloadURL = window.URL.createObjectURL(data);

    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = downloadURL;
    tempLink.setAttribute("download", fileName);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
  }

  public static async preview(fileId: number): Promise<void> {
    const { data } = await axios.get<Blob>(`/api/files/${fileId}`, {
      responseType: "blob",
    });

    const file = new Blob([data], { type: "application/pdf" });
    const fileURL = window.URL.createObjectURL(file);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = fileURL;
    tempLink.setAttribute("href", fileURL);
    tempLink.setAttribute("target", "_blank");
    document.body.appendChild(tempLink);
    tempLink.click();
  }

  public static async update(file: DocumentFile): Promise<void> {
    await axios.put<void>(`/api/files/${file.id}`, file);
  }

  public static async delete(fileId: number): Promise<void> {
    await axios.delete<void>(`/api/files/${fileId}`);
  }
}
