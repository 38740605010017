import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  TextField,
  styled,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState, useReducer, Reducer, ReactElement } from "react";
import { useSnackbar } from "notistack";
import theme from "../../../../theme";
import { VariableHttpService } from "../../../../Http/Variable/Variable.http.service";
import { getErrorMessage } from "../../../../utils";
import { VariableType, VariableUnit } from "../../../../Types/ImpactValue";
import {
  NumberFormatCustom,
  NumberFormatGeneral,
} from "../../../UI/InputFields/NumberFormat";

const ActionButtons = styled("div")(() => ({
  marginLeft: "auto",
  height: "38px",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  marginBlock: theme.spacing(3, 4),
  flexWrap: "wrap",
  "& > div:first-of-type": {
    flexBasis: "100%",
  },
  "& > div:not(:first-of-type)": {
    flex: 1,
  },
}));

const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  "& .MuiRadio-root": {
    padding: 0,
  },
  "& .MuiFormControlLabel-label": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root.Mui-error, .MuiInputLabel-asterisk.Mui-error": {
    color: theme.palette.warning.main,
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.warning.main,
    borderWidth: "1px",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: theme.palette.warning.main,
  },
}));

interface Props {
  calculationId: number;
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleImpactValue: () => void;
  variable?: VariableType;
  hasSingleVariable?: boolean;
}

const ManageVariableModal = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [newVariable, setNewVariable] = useReducer<
    Reducer<VariableType, Partial<VariableType>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    props.variable ||
      ({
        calculationId: props.calculationId,
        operator: props.hasSingleVariable ? null : "x",
        unit: "Euro",
      } as VariableType)
  );

  const isCreateMode = !props.variable;

  const newVariableFields = {
    Name: newVariable.name,
    Quantity: newVariable.value,
  };

  const checkRequiredFields = () => {
    const emptyRequiredFieldsArray: string[] = [];
    for (const [key, value] of Object.entries(newVariableFields)) {
      if (("" + value).trim().length === 0 || !value) {
        emptyRequiredFieldsArray.push(key);
      }
    }
    return emptyRequiredFieldsArray;
  };

  const showError = (value: string) => {
    enqueueSnackbar(`Please enter ${value}`, {
      variant: "error",
    });
  };

  const createVariable = async () => {
    const emptyRequiredFields = checkRequiredFields();
    if (emptyRequiredFields.length > 0) {
      return showError(emptyRequiredFields[0]);
    }

    setIsLoading(true);
    await VariableHttpService.createVariable(newVariable)
      .then(() => {
        props.handleImpactValue();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not create Variable: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const editVariable = async () => {
    const emptyRequiredFields = checkRequiredFields();
    if (emptyRequiredFields.length > 0) {
      return showError(emptyRequiredFields[0]);
    }

    setIsLoading(true);
    await VariableHttpService.updateVariable(newVariable)
      .then(() => {
        props.handleImpactValue();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update Variable: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewVariable({ unit: (event.target.value as VariableUnit) || null });
  };

  return (
    <>
      <Dialog
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        maxWidth="md"
        fullWidth
        data-testid="manage-variable-modal"
      >
        <DialogTitle display="flex" data-testid="manage-variable-header">
          {isCreateMode ? "New Variable" : "Edit Variable"}

          <ActionButtons>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <Button onClick={() => props.setModalOpen(false)}>Close</Button>
                <Button onClick={isCreateMode ? createVariable : editVariable}>
                  Save
                </Button>
              </>
            )}
          </ActionButtons>
        </DialogTitle>
        <DialogContent dividers>
          <FormContainer data-testid="manage-variable-form">
            <Box display="flex" gap={5}>
              <StyledTextField
                type="currency"
                label="Quantity"
                required
                error={!newVariable.value}
                value={newVariable.value || ""}
                onChange={(event) => {
                  const value = event.target.value;
                  setNewVariable({ value: value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ flex: 0.7 }}
                helperText="Provide a quantitative value for this variable"
                inputProps={{ "data-testid": "variable-quantity-input" }}
                InputProps={{
                  ...(newVariable.unit === "Euro" && {
                    // eslint-disable-next-line
                    inputComponent: NumberFormatCustom as any,
                  }),
                  ...(newVariable.unit === null && {
                    // eslint-disable-next-line
                    inputComponent: NumberFormatGeneral as any,
                  }),
                }}
              />
              <StyledRadioGroup
                value={newVariable.unit ?? ""}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Euro"
                  control={<Radio />}
                  label="Monetary Value"
                />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="Non-monetary Value"
                />
              </StyledRadioGroup>
            </Box>
            <StyledTextField
              label="Name"
              required
              placeholder="e.g. Material Cost"
              error={!newVariable.name}
              value={newVariable.name || ""}
              variant="outlined"
              onChange={(event) => setNewVariable({ name: event.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
              helperText="Provide a descriptive name for this variable"
              inputProps={{ "data-testid": "variable-name-input" }}
            />
          </FormContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ManageVariableModal;
