import {
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Theme,
  DialogActions,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  ReactElement,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { ProjectHttpService } from "../../../Http/Project/Project.http.service";
import { Project, ProjectStatusChangeReason } from "../../../Types/Project";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import BusinessUnitSelect from "../../UI/InputFields/BusinessUnitSelect";
import CustomTextField from "../../UI/InputFields/CustomTextField";
import FocusAreaHttpService from "../../../Http/FocusArea/FocusArea.http.service";
import UserSelectInput from "../../UI/InputFields/UserSelect";
import { useSnackbar } from "notistack";
import { MultiSelectInput } from "../../UI/InputFields/MultiSelectInput";
import CreateFocusAreaModal from "./CreateFocusAreaModal";
import { FocusArea } from "../../../Types/VentureClient";
import { SelectOption } from "../../../Types/Common";
import {
  isVentureClientUnitHead,
  UserContext,
} from "../../../Context/UserContext";
import { StatusChangeReasonsHttpService } from "../../../Http/StatusChangeReasons/StatusChangeReasons.http.service";

interface Props {
  handleModalClose: () => void;
  handleSave: () => void;
  modalOpen: boolean;
  projectData: Project;
}

interface editProjectRequiredFields {
  Name: string;
  "Short Description": string;
  "Organizational Unit": number | null;
  "Impact Type": string;
}

const useStyles = makeStyles((theme: Theme) => ({
  section_wrapper: {
    width: "100%",
  },
  input_form: {
    display: "flex",
    flexWrap: "wrap",
  },
  input_form_content_header: {
    marginBottom: theme.spacing(1.5),
  },
  modal_actions: {
    margin: theme.spacing(2),
  },
  section_wrapper_margin: {
    width: "100%",
    marginBlock: theme.spacing(1.5),
  },
}));

const ON_HOLD_DEADLINE_TOOLTIP =
  "This field defines the deadline on which the 'on hold' status will run out. Upon reaching this date, the project will return to the status 'active'.";

const impactTypeOptions = ["Cost", "Revenue", "Cost & Revenue"];

function EditProjectModal(props: Props): ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const user = useContext(UserContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.projectData
  );
  const [focusAreas, setFocusAreaSelectOptions] = useState<FocusArea[]>([]);
  const [createFocusAreaModalOpen, setCreateFocusAreaModalOpen] =
    useState<boolean>(false);
  const [reasons, setReasons] = useState<ProjectStatusChangeReason[]>([]);
  const statusOptions =
    project.funnelStage === "adopt"
      ? ["active", "on hold", "archived", "adopted"]
      : ["active", "on hold", "archived"];

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setProject(props.projectData);
    FocusAreaHttpService.getAll().then((focusAreas) => {
      setFocusAreaSelectOptions(focusAreas);
    });
  }, []);

  useEffect(() => {
    if (project.status === "on hold") {
      StatusChangeReasonsHttpService.getReasons("on hold").then((res) =>
        setReasons(res)
      );
    }
    if (project.status === "archived") {
      StatusChangeReasonsHttpService.getReasons("archived").then((res) =>
        setReasons(res)
      );
    }
  }, [project.status]);

  const editProjectRequiredFields: editProjectRequiredFields = {
    Name: project.name,
    "Short Description": project.shortDescription,
    "Organizational Unit": project.businessUnitId,
    "Impact Type": project.impactType,
  };

  const checkRequiredFields = () => {
    const emptyRequiredFieldsArray: string[] = [];
    for (const [key, value] of Object.entries(editProjectRequiredFields)) {
      if (("" + value).trim().length === 0 || !value) {
        emptyRequiredFieldsArray.push(key);
      }
    }
    return emptyRequiredFieldsArray;
  };

  const isActive = () => project.status === "active";
  const isArchived = () => project.status === "archived";
  const isAdopted = () => project.status === "adopted";
  const hasComment = () => !!project.statusComment;
  const isOnHold = () => project.status === "on hold";
  const hasReason = () => !!project.statusReasonId;
  const hasOnHoldDeadline = () => !!project.onHoldDeadline;

  const showError = (value: string) => {
    enqueueSnackbar(`Please Enter ${value}`, {
      variant: "error",
    });
  };

  const saveProject = async () => {
    if (isOnHold() && (!hasComment() || !hasReason() || !hasOnHoldDeadline()))
      return showError("Comment, Reason and Date");

    if (!isActive() && !isAdopted() && (!hasComment() || !hasReason())) {
      return showError("Comment and Reason");
    }

    const emptyRequiredFields = checkRequiredFields();
    if (emptyRequiredFields.length > 0) {
      return showError(emptyRequiredFields[0]);
    }

    await ProjectHttpService.updateProject(project as Project);
    props.handleModalClose();
    props.handleSave();
  };

  const handleSelectBusinessUnit = (unitId: number) => {
    setProject({ businessUnitId: unitId });
  };

  const handleAddFocusArea = (focusArea: FocusArea) => {
    setProject({
      focusAreas: [...project.focusAreas, focusArea],
    });
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.modalOpen}
        onClose={props.handleModalClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        data-testid="edit-project-modal"
      >
        <DialogTitle id="responsive-dialog-title">Edit Project</DialogTitle>
        <DialogContent>
          {project && (
            <form className={classes.input_form} noValidate autoComplete="off">
              <Box className={classes.section_wrapper}>
                <Typography
                  variant="body1"
                  className={classes.input_form_content_header}
                >
                  Basic Information
                </Typography>
                <Grid container spacing={theme.spacing(2)} ml="0" width="100%">
                  <CustomTextField
                    id="name"
                    label="Name"
                    editMode={true}
                    value={project.name}
                    onChange={(e) => setProject({ name: e.target.value })}
                    fullWidth
                    required
                  />

                  <CustomTextField
                    id="shortDescription"
                    label="Short Description"
                    editMode={true}
                    value={project.shortDescription}
                    onChange={(e) =>
                      setProject({ shortDescription: e.target.value })
                    }
                    fullWidth
                    required
                    multiline
                    minRows={3}
                  />
                </Grid>
              </Box>

              <Box className={classes.section_wrapper}>
                <Grid container spacing={theme.spacing(2)}>
                  <Grid item xs={12}>
                    <BusinessUnitSelect
                      defaultBusinessUnitId={project.businessUnitId}
                      handleSelectBusinessUnit={handleSelectBusinessUnit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MultiSelectInput
                      fieldId="focusArea"
                      labelText="Focus Area"
                      selectValues={focusAreas as FocusArea[]}
                      value={(project.focusAreas as SelectOption[]) || []}
                      onChange={(focusAreas) => {
                        setProject({ focusAreas: focusAreas as FocusArea[] });
                      }}
                      addIcon={isVentureClientUnitHead(user) ? true : false}
                      setModalOpen={() => setCreateFocusAreaModalOpen(true)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectInput
                      id="impactType"
                      labelText="Business Impact Type"
                      value={project.impactType}
                      onChange={(e) =>
                        setProject({ impactType: e.target.value })
                      }
                      selectValues={
                        impactTypeOptions.map((type) => {
                          return {
                            id: type,
                            name: type,
                          };
                        }) || []
                      }
                      editMode
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>

              {isVentureClientUnitHead(user) && (
                <Box className={classes.section_wrapper_margin}>
                  <Typography
                    variant="body1"
                    className={classes.input_form_content_header}
                  >
                    Project Owner
                  </Typography>
                  <Grid container spacing={theme.spacing(2)}>
                    <Grid item xs={12}>
                      <UserSelectInput
                        onChange={(user) => {
                          setProject({
                            projectOwner: user,
                            projectOwnerId: user?.id,
                          });
                        }}
                        user={project.projectOwner}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}

              <Box className={classes.section_wrapper_margin}>
                <Typography
                  variant="body1"
                  className={classes.input_form_content_header}
                >
                  Project Status
                </Typography>
                <Grid container spacing={theme.spacing(2)} ml="0" width="100%">
                  <SelectInput
                    id="status"
                    labelText="Status"
                    value={project.status}
                    onChange={(e) =>
                      setProject({
                        status: e.target.value,
                        statusReasonId: null,
                      })
                    }
                    selectValues={
                      statusOptions.map((type) => {
                        return {
                          id: type,
                          name: type,
                        };
                      }) || []
                    }
                    editMode
                    required
                    fullWidth
                  />
                  <Grid container spacing={theme.spacing(2)}>
                    {(isOnHold() || isArchived()) && (
                      <Grid item flex={1}>
                        <SelectInput
                          id="reason"
                          labelText="Reason"
                          value={project.statusReasonId}
                          onChange={(e) =>
                            setProject({
                              statusReasonId: +e.target.value,
                            })
                          }
                          selectValues={
                            reasons &&
                            reasons.map((reason) => {
                              return {
                                id: reason.id,
                                name: reason.description,
                              };
                            })
                          }
                          editMode
                          fullWidth
                          required
                        />
                      </Grid>
                    )}
                    {project.status === "on hold" && (
                      <Grid item xs={6}>
                        <CustomTextField
                          id="onHoldDeadline"
                          dataTestId="on-hold-deadline"
                          label="On Hold until"
                          type="date"
                          editMode
                          value={project.onHoldDeadline}
                          toolTipText={ON_HOLD_DEADLINE_TOOLTIP}
                          onChange={(e) => {
                            setProject({
                              onHoldDeadline: e.target.value,
                            });
                          }}
                          minDate={new Date().toLocaleDateString("fr-ca")}
                          required
                          fullWidth
                        />
                      </Grid>
                    )}
                  </Grid>
                  <CustomTextField
                    id="statusComment"
                    dataTestId="statusComment"
                    label="Comment"
                    editMode={true}
                    value={project.statusComment}
                    onChange={(e) =>
                      setProject({ statusComment: e.target.value })
                    }
                    fullWidth
                    required={isOnHold() || isArchived()}
                    multiline
                    minRows={1}
                  />
                </Grid>
              </Box>
            </form>
          )}
        </DialogContent>
        <DialogActions className={classes.modal_actions}>
          <Button
            onClick={props.handleModalClose}
            color="primary"
            data-testid="cancel-project-button"
          >
            Cancel
          </Button>
          <Button
            onClick={saveProject}
            variant="contained"
            color="secondary"
            data-testid="save-project-button"
          >
            Save Project
          </Button>
        </DialogActions>
      </Dialog>
      {createFocusAreaModalOpen && (
        <CreateFocusAreaModal
          modalOpen={createFocusAreaModalOpen}
          setModalOpen={() => setCreateFocusAreaModalOpen(false)}
          handleAddFocusArea={handleAddFocusArea}
        />
      )}
    </>
  );
}
export default EditProjectModal;
