import {
  LeadProject,
  LeadProjectDTO,
  LeadProjectLeader,
  LeadProjectUseCase,
} from "../../Types/LeadProject";

export default class LeadProjectMapper {
  public static map(leadProject: LeadProjectDTO): LeadProject {
    return {
      ...leadProject,
      dateCreated: new Date(leadProject.dateCreated),
      lastModifiedDate: leadProject.lastModifiedDate
        ? new Date(leadProject.lastModifiedDate)
        : undefined,
      useCases: this.mapUseCases(leadProject.useCases),
      leaders: this.mapleadProjectLeaders(leadProject.leaders),
    };
  }

  public static mapUseCases(
    useCases: LeadProjectUseCase[]
  ): LeadProjectUseCase[] {
    return useCases.sort((a, b) => a.id - b.id);
  }

  public static mapleadProjectLeaders(
    leaders: LeadProjectLeader[]
  ): LeadProjectLeader[] {
    return leaders.sort((a, b) => a.id - b.id);
  }
}
