import { ReactElement } from "react";
import { TableCell } from "@mui/material";
import { Opportunity } from "../../../../../../Types/Opportunity";
import LimitedNameList from "../LimitedNameList/LimitedNameList";

interface DetailsViewOpportunityProps {
  opportunity: Opportunity;
}

export function DetailsViewRowLabels(): ReactElement {
  return (
    <>
      <TableCell>Founding Year</TableCell>
      <TableCell>Location</TableCell>
      <TableCell>Employees</TableCell>
      <TableCell className="extra-high-cell">Reference Clients</TableCell>
      <TableCell>Funding Stage</TableCell>
      <TableCell>Funding Amount (in USD)</TableCell>
      <TableCell className="extra-high-cell highlighted-border">
        Investor Names
      </TableCell>
    </>
  );
}

export function DetailsViewStartupCells(
  props: DetailsViewOpportunityProps
): ReactElement {
  return (
    <>
      <TableCell>
        {props.opportunity.startup.dateFounded?.getFullYear() || "-"}
      </TableCell>
      <TableCell>{props.opportunity.startup.billingCountry || "-"}</TableCell>
      <TableCell>{props.opportunity.startup.employees || "-"}</TableCell>
      <TableCell className="extra-high-cell">
        {props.opportunity.startup.referenceClients?.length ? (
          <LimitedNameList
            entityNames={props.opportunity.startup.referenceClients}
          />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell>
        {props.opportunity.startup.currentInvestmentStage || "-"}
      </TableCell>
      <TableCell>
        {props.opportunity.startup.fundingIsUndisclosed
          ? "Funding is undisclosed"
          : props.opportunity.startup.totalFunding
          ? `$${props.opportunity.startup.totalFunding}M`
          : "-"}
      </TableCell>
      <TableCell className="extra-high-cell highlighted-border">
        {props.opportunity.startup.investors?.length ? (
          <LimitedNameList entityNames={props.opportunity.startup.investors} />
        ) : (
          "-"
        )}
      </TableCell>
    </>
  );
}
