import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import theme from "../../../theme";
import StartupSelect from "../../UI/InputFields/StartupSelect";
import { Startup } from "../../../Types/Startup";
import { LeadProject } from "../../../Types/LeadProject";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";

interface AddProjectLeadStartupModalProps {
  leadProject: LeadProject;
  handleModalClose: () => void;
  modalOpen: boolean;
  onRefresh: () => void;
}

const ActionButtons = styled(DialogActions)(({ theme }) => ({
  margin: theme.spacing(2),
}));

export default function AddProjectLeadStartupModal(
  props: AddProjectLeadStartupModalProps
): ReactElement {
  const [selectedStartup, setSelectedStartup] = useState<Startup | null>();

  const handleSelectStartup = (startup?: Startup | null) => {
    setSelectedStartup(startup);
  };

  const handleAddStartup = () => {
    if (!selectedStartup) return;
    LeadProjectsHttpService.addLeadOpportunity({
      leadProjectId: props.leadProject.id,
      startupId: selectedStartup.id,
    }).then(() => {
      props.onRefresh();
      props.handleModalClose();
    });
  };

  return (
    <Fragment>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={props.modalOpen}
        onClose={props.handleModalClose}
        data-testid="add-problem-owner-modal"
      >
        <DialogTitle>Add Startup</DialogTitle>
        <DialogContent>
          <form
            noValidate
            autoComplete="off"
            style={{ paddingTop: theme.spacing(1) }}
          >
            <StartupSelect
              label="Startup"
              handleSelectStartup={handleSelectStartup}
              assignedStartupIds={props.leadProject.opportunities.map(
                (startup) => startup.id
              )}
              selectedStartup={selectedStartup}
            />
          </form>
        </DialogContent>
        <ActionButtons>
          <Button autoFocus onClick={props.handleModalClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAddStartup}
            variant="contained"
            color="secondary"
          >
            Add Startup
          </Button>
        </ActionButtons>
      </Dialog>
    </Fragment>
  );
}
