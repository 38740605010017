import { Box, Button, List, Paper, Typography } from "@mui/material";
import {
  ReactElement,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { FileHttpService } from "../../../Http/File/File.http.service";
import { Project } from "../../../Types/Project";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { File as DocumentFile } from "../../../Types/File";
import theme from "../../../theme";
import { FilesListSection } from "./FilesListSection";
import { OpportunityFilesSection } from "./OpportunityFilesSection";
import { Opportunity } from "../../../Types/Opportunity";
import { FileUploadSection } from "./UploadFileModal";
import { GlobalProjectEditContext } from "../../../Context/ProjectDetailsContext";
import { otherFileTypes } from "../../../Constants/Files";

interface ProjectFileSectionProps {
  project: Project;
  projectFileTypes?: string[];
  opportunityFileTypes?: string[];
  handleSave: () => void;
  stage: string;
}

export function ProjectFileSection(
  props: ProjectFileSectionProps
): ReactElement {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [lastUploadFile, setLastUploadFile] = useState<number>(0);
  const [editMode, setEditMode] = useState(false);
  const [filesToDelete, setFilesToDelete] = useState<number[]>([]);
  const [opportunitiesWithFiles, setOpportunitiesWithFiles] = useState<
    Opportunity[]
  >([]);
  const [stageSpecificOpportunities, setStageSpecificOpportunities] = useState<
    Opportunity[]
  >([]);
  const [localFiles, setLocalFiles] = useReducer<
    Reducer<
      {
        [key: string | number]: DocumentFile[] | undefined;
      },
      Partial<{
        [key: string | number]: DocumentFile[] | undefined;
      }>
    >
  >((state, newState) => ({ ...state, ...newState }), {});

  const { setGlobalEditMode, globalEditMode } = useContext(
    GlobalProjectEditContext
  );

  useEffect(() => {
    const files: { [key: string | number]: DocumentFile[] } = {};
    const filteredOpportunitiesWithFiles: Opportunity[] = [];
    files["project"] = filterProjectFiles(props.project.files);

    props.project.opportunities?.forEach((opportunity) => {
      const filteredFiles = filterOpportunityFiles(opportunity.files);
      if (filteredFiles && filteredFiles.length) {
        files[opportunity.id] = filteredFiles;
        filteredOpportunitiesWithFiles.push(opportunity);
      }
    });
    setOpportunitiesWithFiles(filteredOpportunitiesWithFiles);
    setLocalFiles(files);
  }, [props.project.files, refresh]);

  useEffect(() => {
    const filteredOpportunities: Opportunity[] =
      props.project.opportunities?.filter((opportunity) => {
        if (props.stage === "discover") {
          return true;
        } else if (props.stage === "assess") {
          return !!opportunity.isQualified;
        } else {
          return !!opportunity.isSelectedForPilot;
        }
      }) || [];
    setStageSpecificOpportunities(filteredOpportunities);
  }, [props.project.files]);

  useEffect(() => {
    setGlobalEditMode(editMode);
  }, [editMode]);

  const filterProjectFiles = (files: DocumentFile[]): DocumentFile[] => {
    return files?.filter(
      (file) =>
        props.stage === file.type || props.projectFileTypes?.includes(file.type)
    );
  };

  const filterOpportunityFiles = (files: DocumentFile[]): DocumentFile[] => {
    if (props.opportunityFileTypes) {
      return files.filter(
        (file) =>
          props.opportunityFileTypes?.includes(file.type) ||
          props.stage === file.type
      );
    } else return [];
  };

  const cancelChanges = () => {
    //trigger useEffect
    setRefresh((prevState) => !prevState);
    setFilesToDelete([]);
    setEditMode(false);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const saveChanges = async (): Promise<void> => {
    for (const fileId of filesToDelete) {
      await FileHttpService.delete(fileId);
    }
    //update every file inside state
    await Object.values(localFiles).forEach((entry) => {
      entry?.forEach((file) => {
        FileHttpService.update(file);
      });
    });
    setFilesToDelete([]);
    setEditMode(false);
    props.handleSave();
  };

  const deleteFile = (fileId: number, objectKey: string | number): void => {
    const changedFileSet: DocumentFile[] = localFiles[objectKey] || [];
    setFilesToDelete((prevState) => [...prevState, fileId]);
    const filteredFiles = changedFileSet.filter((file) => file.id !== fileId);
    setLocalFiles({ [objectKey]: filteredFiles });
  };

  const handleFileRename = (
    id: number,
    name: string,
    objectKey: string | number
  ) => {
    const files = JSON.parse(JSON.stringify(localFiles));
    const changedFileSet = files[objectKey];
    if (changedFileSet) {
      const changedFile = changedFileSet.find(
        (file: DocumentFile) => file.id === id
      );
      changedFile.name = name;
      setLocalFiles({ [objectKey]: changedFileSet });
    }
  };
  const displayfiles: DocumentFile[] = localFiles["project"]
    ? localFiles["project"].filter(
        (file: DocumentFile) => !otherFileTypes.includes(file.type)
      )
    : [];
  const otherFiles: DocumentFile[] = localFiles["project"]
    ? localFiles["project"].filter((file: DocumentFile) =>
        otherFileTypes.includes(file.type)
      )
    : [];

  return (
    <Paper sx={{ padding: theme.spacing(4) }} variant="outlined">
      <Box
        justifyContent="space-between"
        flexDirection="row"
        display="flex"
        mb={2}
      >
        <Typography variant={"h6"}>Files</Typography>
        <Box>
          {!editMode && !globalEditMode && (
            <Button
              onClick={() => setEditMode(true)}
              id="edit-files-button"
              data-testid="edit-files-button"
            >
              <EditOutlinedIcon />
            </Button>
          )}
        </Box>
      </Box>
      <List dense data-testid="project-files">
        <FilesListSection
          files={displayfiles}
          mapId={"project"}
          deleteFile={deleteFile}
          editMode={editMode}
          handleFileRename={handleFileRename}
          stage={props.stage}
          projectId={props.project.id}
          handleSave={props.handleSave}
        />
      </List>
      {localFiles && (
        <OpportunityFilesSection
          localFiles={localFiles}
          deleteFile={deleteFile}
          editMode={editMode}
          handleFileRename={handleFileRename}
          opportunities={opportunitiesWithFiles}
          lastUploadFileId={lastUploadFile}
          projectStage={props.stage}
          handleSave={props.handleSave}
        />
      )}
      {otherFiles && (
        <FilesListSection
          handleSave={props.handleSave}
          files={otherFiles}
          mapId={"project"}
          deleteFile={deleteFile}
          editMode={editMode}
          handleFileRename={handleFileRename}
          stage={props.stage}
        />
      )}

      {editMode ? (
        <Box justifyContent="flex-end" flexDirection="row" display="flex" m={2}>
          <Button
            autoFocus
            onClick={cancelChanges}
            color="primary"
            id="cancel-files-button"
          >
            Cancel
          </Button>
          <Button
            onClick={saveChanges}
            variant="contained"
            color="secondary"
            id="save-files-button"
            sx={{ ml: 2 }}
          >
            Save Changes
          </Button>
        </Box>
      ) : (
        !globalEditMode && (
          <FileUploadSection
            project={props.project}
            setLastUploadFile={setLastUploadFile}
            opportunityFileTypes={props.opportunityFileTypes || []}
            projectFileTypes={props.projectFileTypes || []}
            stageSpecificOpportunities={stageSpecificOpportunities}
            handleSave={props.handleSave}
            projectStage={props.stage}
          />
        )
      )}
    </Paper>
  );
}
