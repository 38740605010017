import { ReactElement } from "react";
import { Project } from "../../../../../Types/Project";
import Grid from "@mui/material/Grid";
import createStyles from "@mui/styles/createStyles";
import Form from "./Form";
import makeStyles from "@mui/styles/makeStyles";
import ProjectSummary from "../../../SharedComponents/ProjectSummary";
import { Box, Paper, Theme } from "@mui/material";
import { ProjectFileSection } from "../../../../UI/FilesComponent/ProjectFilesSection";
interface Props {
  projectData: Project;
  handleSave: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(4),
    },
  })
);

export default function DataView(props: Props): ReactElement {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid
        component={Paper}
        variant="outlined"
        className={classes.grid}
        item
        xs={8}
      >
        <Form projectData={props.projectData} handleSave={props.handleSave} />
      </Grid>
      <Grid
        item
        container
        xs={4}
        style={{ paddingTop: "0" }}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        alignContent="flex-start"
        gap={2}
      >
        <Grid item xs={12}>
          <Box>
            <ProjectSummary
              handleSave={props.handleSave}
              projectDetails={props.projectData}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ProjectFileSection
            handleSave={props.handleSave}
            project={props.projectData}
            projectFileTypes={[
              "Other",
              "Assessment Report",
              "Qualified List Presentation",
              "Business Case - Assess",
            ]}
            opportunityFileTypes={[
              "Demo Protocol",
              "Demo Deck",
              "NDA",
              "Strategic Onepager",
              "Product Deck",
              "SF Confirmation",
              "Internal Product Demo Briefing",
              "External Product Demo Briefing",
              "Follow-up Protocol",
            ]}
            stage="assess"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
