import { Project } from "../Types/Project";

export interface KeyNamePair {
  key: keyof Project | "isSelected" | "isQualified" | "productDemos";
  name: string;
  scope: "opportunity" | "project" | "productDemo";
  stageSpecific: boolean;
}

export const discover: KeyNamePair[] = [
  { key: "deadline", name: "Deadline", scope: "project", stageSpecific: false },
  {
    key: "pilotStartDate",
    name: "Starting Date of Pilot Project",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "projectLeaderId",
    name: "Project Leader",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "programManagerId",
    name: "Venture Client Program Manager",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "impactType",
    name: "Impact Type",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "startupOpportunity",
    name: "Startup Opportunity",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "availabilityOfStartups",
    name: "Availability of Startups",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "ventureCapital",
    name: "Invested Amount of Venture Capital",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "isQualified",
    name: "Select Startup(s) for Assess Phase",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "solutionClusters",
    name: "Solution Clusters",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "files",
    name: "Problem Deep Dive",
    scope: "project",
    stageSpecific: false,
  },
  { key: "files", name: "Curated List", scope: "project", stageSpecific: true },
  {
    key: "files",
    name: "Curated List Presentation",
    scope: "project",
    stageSpecific: true,
  },
];
export const assess: KeyNamePair[] = [
  {
    key: "isSelected",
    name: "Select Startup for Buy stage",
    scope: "opportunity",
    stageSpecific: false,
  },
  {
    key: "keyFindings",
    name: "Key Problem Findings",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "recommendations",
    name: "Recommendations",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "productDemos",
    name: "Product Demos",
    scope: "opportunity",
    stageSpecific: true,
  },
  {
    key: "files",
    name: "Qualified List Presentation",
    scope: "project",
    stageSpecific: true,
  },
  {
    key: "files",
    name: "Assessment Report",
    scope: "project",
    stageSpecific: true,
  },
  {
    key: "files",
    name: "Demo Deck",
    scope: "productDemo",
    stageSpecific: true,
  },
  {
    key: "files",
    name: "Demo Protocol",
    scope: "productDemo",
    stageSpecific: true,
  },
  {
    key: "files",
    name: "Internal Product Demo Briefing",
    scope: "productDemo",
    stageSpecific: false,
  },
  {
    key: "files",
    name: "External Product Demo Briefing",
    scope: "productDemo",
    stageSpecific: false,
  },
  { key: "files", name: "NDA", scope: "productDemo", stageSpecific: false },
];
export const buy: KeyNamePair[] = [
  {
    key: "adoptionOwnerId",
    name: "Adoption Owner (Venture Client)",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "projectLeadStartupId",
    name: "Project Lead (Startup)",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "firstOffer",
    name: "First Offer",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "purchaseOrderAmount",
    name: "Purchase Order Amount",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "pilotBackground",
    name: "Pilot Background",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "objectives",
    name: "Objectives",
    scope: "project",
    stageSpecific: false,
  },
  { key: "phases", name: "Phases", scope: "project", stageSpecific: false },
  { key: "meetings", name: "Meetings", scope: "project", stageSpecific: false },
  {
    key: "files",
    name: "Request for Proposal",
    scope: "opportunity",
    stageSpecific: false,
  },
  { key: "files", name: "Offer", scope: "opportunity", stageSpecific: false },
  {
    key: "files",
    name: "Pilot Fact Sheet",
    scope: "opportunity",
    stageSpecific: false,
  },
  {
    key: "files",
    name: "Business Case - Buy",
    scope: "opportunity",
    stageSpecific: false,
  },
];
export const pilot: KeyNamePair[] = [
  {
    key: "learnings",
    name: "Learnings",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "achievements",
    name: "Achievements",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "pilotOutcome",
    name: "Pilot Project Evaluation",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "pilotOutcomeReason",
    name: "Pilot Project Evaluation",
    scope: "project",
    stageSpecific: false,
  },
  {
    key: "files",
    name: "Kickoff Deck",
    scope: "opportunity",
    stageSpecific: false,
  },
  {
    key: "files",
    name: "Kickoff Protocol",
    scope: "opportunity",
    stageSpecific: false,
  },
  {
    key: "files",
    name: "MS I Deck",
    scope: "opportunity",
    stageSpecific: false,
  },
  {
    key: "files",
    name: "MS I Protocol",
    scope: "opportunity",
    stageSpecific: false,
  },
  {
    key: "files",
    name: "Project Report",
    scope: "opportunity",
    stageSpecific: false,
  },
  {
    key: "files",
    name: "Conclusion Protocol",
    scope: "opportunity",
    stageSpecific: false,
  },
];
export const adopt: KeyNamePair[] = [];

export const stageRules = {
  discover: discover,
  assess: assess,
  buy: buy,
  pilot: pilot,
  adopt: adopt,
};
