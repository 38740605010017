import React, { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import theme from "../../theme";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: "flex",
      width: "510px",
      flexDirection: "column",
      justifyContent: "center",
      padding: "20px",
      zIndex: 1,
      "& input:-webkit-autofill": {
        transition: "background-color 600000s 0s, color 600000s 0s",
      },
    },
  })
);

interface Props {
  setSignedIn: (x: boolean) => void;
  setFormType: (x: string) => void;
  // eslint-disable-next-line
  setUser: (x: any) => void;
}

export default function SignIn(props: Props): ReactElement {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [isHolcim, setIsHolcim] = useState(false);

  const textFieldForPasswordRef = React.useRef<HTMLInputElement>(null);
  const metadata = { tenantId: "vclms" };
  const baseUrl = window.location.origin;

  useEffect(() => {
    if (baseUrl.includes("holcim-vclms")) {
      setIsHolcim(true);
    }
  }, []);

  const handleSignIn = async () => {
    try {
      const user = await Auth.signIn(email, password, metadata);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        props.setUser(user);
        handleFormChange("activateAccount");
      } else {
        props.setSignedIn(true);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  const handleFormChange = (type: string) => {
    props.setFormType(type);
  };

  const handleSignInSSO = async () => {
    try {
      await Auth.federatedSignIn({ customProvider: "Holcim" });
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <Box className={classes.form}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="h4" align="center" sx={{ fontWeight: 500 }}>
          Venture Client Management System
        </Typography>
        <Typography align="center">
          A Venture Client Solution by 27pilots
        </Typography>
        <TextField
          id="outlined-required"
          label="Email"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value as string)}
          inputProps={{
            onKeyPress: (event) => {
              if (event.key === "Enter" && textFieldForPasswordRef.current) {
                textFieldForPasswordRef.current.focus();
              }
            },
          }}
        />
        <TextField
          id="outlined-disabled"
          label="Password"
          value={password}
          autoComplete="new-password"
          type={showPassword ? "text" : "password"}
          inputRef={textFieldForPasswordRef}
          onChange={(e) => setPassword(e.target.value as string)}
          inputProps={{
            onKeyPress: (event) => {
              if (event.key === "Enter") {
                handleSignIn();
              }
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="password-visibility-toggle"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  sx={{
                    "& .MuiSvgIcon-root": { color: "white" },
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography
          textAlign="right"
          sx={{
            "&:hover": {
              color: "red",
            },
            cursor: "pointer",
          }}
          onClick={() => handleFormChange("resetPasswordToken")}
        >
          forgot password?
        </Typography>
        <Box display="flex" flexDirection="column" gap={2} margin="0 auto">
          <Button
            type="submit"
            onClick={handleSignIn}
            variant="contained"
            color="secondary"
            id="save-project-button"
            sx={{ height: "40px", width: "210px" }}
          >
            Sign In
          </Button>
          {isHolcim && (
            <>
              <Typography alignSelf="center">or</Typography>
              <Button
                type="submit"
                onClick={handleSignInSSO}
                variant="outlined"
                color="secondary"
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  height: "40px",
                  color: "white",
                  padding: theme.spacing(1),
                }}
              >
                <img src={"/images/google_logo.svg"} alt="google logo" />
                Sign In with Google
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
