import axios from "axios";
import { CreateVariable, VariableType } from "../../Types/ImpactValue";

export class VariableHttpService {
  public static async createVariable(variable: CreateVariable): Promise<void> {
    await axios.post<VariableType>(`/api/variables`, variable);
  }

  public static async updateVariable(variable: VariableType): Promise<void> {
    await axios.put<void>(`/api/variables/${variable.id}`, variable);
  }

  public static async deleteVariable(id: number): Promise<void> {
    await axios.delete<void>(`/api/variables/${id}`);
  }
}
