import { ProductDemo } from "../../Types/ProductDemo";

export default class ProductDemoMapper {
  static map(ProductDemoDTO: ProductDemo): ProductDemo {
    return {
      ...ProductDemoDTO,
      date: ProductDemoDTO.date
        ? new Date(ProductDemoDTO.date).toLocaleDateString("fr-ca")
        : null,
    };
  }
}
