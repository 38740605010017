import { Card, Typography, Chip, Theme } from "@mui/material";
import { ReactElement } from "react";
import { ProjectEvent } from "../../../Types/Analytics";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import React from "react";

interface EventCardProps {
  event: ProjectEvent;
  openProjectDetails: (projectId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventCard: {
      width: "150px",
      flexShrink: "0",
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: "none",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
    },
    businessUnitChip: {
      maxWidth: "100%",
      backgroundColor: theme.palette.grey[600],
      marginBlock: theme.spacing(1),
      fontSize: "10px",
      fontWeight: "bold",
      color: "white",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      pointerEvents: "none",
    },
    startupName: {
      fontSize: "14px",
      color: theme.palette.grey[700],
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1 /* number of lines to show */,
      WebkitBoxOrient: "vertical",
    },
    eventDays: {
      fontSize: "40px",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      lineHeight: 1,
    },
    eventMonth: {
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    eventType: {
      fontSize: "14px",
      fontWeight: "bold",
      color: theme.palette.grey[700],
    },
  })
);

function EventCard({
  event,
  openProjectDetails,
}: EventCardProps): ReactElement {
  const classes = useStyles({ start: false, end: false });
  const days = event.date.toLocaleDateString("en-EN", {
    day: "numeric",
  });
  const month = event.date.toLocaleDateString("en-EN", {
    month: "long",
  });
  const { id, businessUnit, type, startupName, projectId } = event;

  return (
    <Card
      data-testid={`event-${id}`}
      className={classes.eventCard}
      style={{ direction: "ltr" }}
      onClick={() => openProjectDetails(projectId)}
    >
      <Typography className={classes.eventDays}>{days}</Typography>
      <Typography className={classes.eventMonth}>{month}</Typography>
      <Chip
        className={classes.businessUnitChip}
        size="small"
        label={businessUnit}
      />
      <Typography className={classes.eventType}>{type}:</Typography>
      <Typography className={classes.startupName}>
        {startupName ?? "-"}
      </Typography>
    </Card>
  );
}

export const MemoizedEventCard = React.memo(EventCard);
