import { useState, useEffect } from "react";

type Dispatch<A> = (value: A) => void;
type SetState<T> = Dispatch<T | ((prevState: T) => T)>;

function useLocalStorage<T>(key: string, initialValue: T): [T, SetState<T>] {
  const [value, setValue] = useState<T>(() => {
    const jsonValue = localStorage.getItem(key);
    if (jsonValue != null) return JSON.parse(jsonValue);
    return initialValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export default useLocalStorage;
