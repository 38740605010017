import { File as DocumentFile } from "../../../Types/File";
import { Opportunity } from "../../../Types/Opportunity";
import React, { ReactElement, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FilesListSection } from "./FilesListSection";
import MapFile from "../../../Http/File/File.mapper";

interface OpportunityFilesProps {
  localFiles: { [key: string | number]: DocumentFile[] | undefined };
  deleteFile: (fileId: number, objectKey: number | string) => void;
  editMode: boolean;
  handleFileRename: (
    id: number,
    name: string,
    opportunityId: number | string
  ) => void;
  opportunities: Opportunity[];
  lastUploadFileId: number;
  projectStage: string;
  handleSave: () => void;
}

const StyledOpportunityFiles = styled(Box)(({ theme }) => ({
  "& .MuiAccordion-root:before": {
    display: "none",
  },
  "& .MuiAccordion-root": {
    borderBottom: `${theme.spacing(0.25)} ${
      theme.palette.background.default
    } solid`,
    borderTop: `${theme.spacing(0.25)} ${
      theme.palette.background.default
    } solid`,
    margin: "0",
    boxShadow: "none",
  },

  "& .MuiAccordion-root:not(:first-of-type)": {
    borderTop: "none",
  },

  "& .MuiAccordionSummary-root": {
    padding: "0",
    borderRight: "none",
    justifyContent: "space-between",
  },
  "& .MuiAccordionDetails-root": {
    padding: "0",
  },
  "& .startup-name .MuiAccordionSummary-content ": {
    backgroundColor: theme.palette.primary.main,
    padding: ` 0 ${theme.spacing(1)}`,
    borderRadius: theme.shape.borderRadius,
    marginRight: "auto",
    maxWidth: "max-content",
    margin: `${theme.spacing(2.5)} 0`,
    fontWeight: "bold",
  },
}));

export function OpportunityFilesSection(
  props: OpportunityFilesProps
): ReactElement {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const theme = useTheme();
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (props.lastUploadFileId) {
      setExpanded("panel-" + props.lastUploadFileId);
    }
  }, [props.lastUploadFileId]);

  return (
    <StyledOpportunityFiles theme={theme}>
      {props.opportunities.map((opportunity: Opportunity) => {
        let mappedFiles;
        if (["buy", "pilot", "adopt"].includes(props.projectStage)) {
          opportunity.isSelectedForPilot
            ? (mappedFiles = MapFile.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                props.localFiles[opportunity.id] as any
              ))
            : null;
        } else {
          mappedFiles = MapFile.map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props.localFiles[opportunity.id] as any
          );
        }
        return ["discover", "assess"].includes(props.projectStage) &&
          mappedFiles ? (
          <Accordion
            expanded={expanded === "panel-" + opportunity.id}
            onChange={handleAccordionChange("panel-" + opportunity.id)}
            key={opportunity.id}
            data-testid="accordion-wrapper"
          >
            <AccordionSummary
              className="startup-name"
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"panel-" + opportunity.id + "-content"}
              id={"panel-" + opportunity.id}
            >
              <Typography color="white" variant="subtitle2">
                {opportunity.startup.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FilesListSection
                key={opportunity.id}
                files={mappedFiles}
                mapId={opportunity.id}
                deleteFile={props.deleteFile}
                editMode={props.editMode}
                handleFileRename={props.handleFileRename}
                projectId={opportunity.projectId}
                handleSave={props.handleSave}
              />
            </AccordionDetails>
          </Accordion>
        ) : (
          mappedFiles && (
            <FilesListSection
              key={opportunity.id}
              files={mappedFiles}
              mapId={opportunity.id}
              deleteFile={props.deleteFile}
              editMode={props.editMode}
              handleFileRename={props.handleFileRename}
              stage={props.projectStage}
              projectId={opportunity.projectId}
              handleSave={props.handleSave}
              data-testid="files-section"
            />
          )
        );
      })}
    </StyledOpportunityFiles>
  );
}
