import {
  ListItem,
  IconButton,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { ReactElement, useState } from "react";
import { File as DocumentFile, FileIcons } from "../../../Types/File";
import { FileHttpService } from "../../../Http/File/File.http.service";
import { useSnackbar } from "notistack";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArticleIcon from "@mui/icons-material/Article";
import TableChartIcon from "@mui/icons-material/TableChart";
import DatasetIcon from "@mui/icons-material/Dataset";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { Delete, Download } from "@mui/icons-material";

interface FilesItemProps {
  file: DocumentFile;
  index: number;
  mapId: number | string;
  deleteFile: (fileId: number, objectKey: number | string) => void;
  editMode: boolean;
  stage?: string;
  handleFileRename: (id: number, name: string, mapId: number | string) => void;
  projectId?: number;
  handleSave?: () => void;
}

const FileItem = (props: FilesItemProps): ReactElement | null => {
  const { file } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [fileIdsBeingDownloaded, setFileIdsBeingDownloaded] = useState<
    number[]
  >([]);
  const [showPreviewIcon, setShowPreviewIcon] = useState<number | null>();
  const debouncedHandleFileRename = debounce(props.handleFileRename, 400);

  const previewFile = async (
    fileId: number,
    fileFormat: string
  ): Promise<void> => {
    if (fileFormat === "PDF") {
      try {
        await FileHttpService.preview(fileId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        enqueueSnackbar(
          `Something went wrong with previewing the file: ${error.message}`,
          {
            variant: "error",
          }
        );
      }
    }
  };

  const date = new Date(file.dateCreated);
  const displayDate = file.dateCreated
    ? date.toLocaleString("en-UK", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : "";

  const getFileIcon = (fileIcon: FileIcons) => {
    switch (fileIcon) {
      case "Article": {
        return <ArticleIcon color="primary" data-testid="article-icon" />;
        break;
      }
      case "TableChart": {
        return <TableChartIcon color="primary" data-testid="tablechart-icon" />;
        break;
      }
      case "Dataset": {
        return <DatasetIcon color="primary" data-testid="dataset-icon" />;
        break;
      }
      case "Document": {
        return <StickyNote2Icon color="primary" data-testid="document-icon" />;
        break;
      }
      case "Image": {
        return <HeatPumpIcon color="primary" data-testid="image-icon" />;
        break;
      }
      default: {
        return <SummarizeIcon color="primary" data-testid="other-icon" />;
      }
    }
  };

  const downloadFile = async (
    fileId: number,
    fileName: string
  ): Promise<void> => {
    try {
      setFileIdsBeingDownloaded((prevState: number[]) => [
        ...prevState,
        fileId,
      ]);
      await FileHttpService.download(fileId, fileName);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      enqueueSnackbar(
        `Something went wrong with downloading the file: ${error.message}`,
        {
          variant: "error",
        }
      );
    }
    setFileIdsBeingDownloaded((prevState: number[]) =>
      prevState.filter((id: number) => id !== fileId)
    );
  };

  const handleIconOnMouseOver = (id: number, fileFormat: string) => {
    if (fileFormat === "PDF") {
      setShowPreviewIcon(id);
    }
  };

  return (
    <ListItem
      disableGutters
      sx={{ padding: "0 30px 0 0" }}
      key={file.id}
      data-testid={"FileItem-" + file.id}
      secondaryAction={
        <IconButton
          id="file-action-icon"
          data-testid={props.editMode ? "delete-button" : "download-button"}
          onClick={() =>
            props.editMode
              ? props.deleteFile(file.id, props.mapId)
              : downloadFile(file.id, file.name)
          }
          edge="end"
          aria-label="delete"
        >
          {props.editMode && <Delete />}
          {!props.editMode &&
            (fileIdsBeingDownloaded.includes(file.id) ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              <Download />
            ))}
        </IconButton>
      }
    >
      <ListItemAvatar data-testid="file-icon" sx={{ minWidth: "36px" }}>
        {showPreviewIcon === file.id ? (
          <OpenInNewIcon data-testid="preview-button" color="primary" />
        ) : (
          getFileIcon(file.fileIcon)
        )}
      </ListItemAvatar>
      <ListItemText
        secondary={
          props.editMode ? (
            ""
          ) : file.type === props.stage ? (
            "Other"
          ) : (
            <>
              <span> {displayDate}</span>
              <span style={{ paddingInline: "7px" }}>{"•"}</span>
              <span>{file.format}</span>
            </>
          )
        }
      >
        {props.editMode ? (
          <TextField
            label="File Name"
            id={`file-name-${file.id}`}
            variant="outlined"
            fullWidth
            defaultValue={file.name}
            onChange={(e) =>
              debouncedHandleFileRename(file.id, e.target.value, props.mapId)
            }
            sx={{
              paddingRight: "1rem",
            }}
          />
        ) : (
          <Typography
            onMouseOver={() => handleIconOnMouseOver(file.id, file.format)}
            onMouseOut={() => {
              setShowPreviewIcon(null);
            }}
            onClick={() => previewFile(file.id, file.format)}
            noWrap
            sx={{ cursor: "pointer", paddingRight: "1rem" }}
          >
            {file.displayName}
          </Typography>
        )}
      </ListItemText>
    </ListItem>
  );
};

export default FileItem;
