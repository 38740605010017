import { Fragment, ReactElement, useEffect, useState } from "react";
import { User } from "../../../Types/User";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import UserHttpService from "../../../Http/User/User.Http.service";
import { useSnackbar } from "notistack";

interface Props {
  onChange: (args: User | undefined) => void;
  user: User;
}

export default function UserSelectInput(props: Props): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const getInputValues = async () => {
      setIsLoading(true);
      try {
        await UserHttpService.getUsers(null).then((users) => {
          setUsers(users);
        });
      } catch (error) {
        enqueueSnackbar("Could not get users", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };
    getInputValues();
  }, []);

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      isOptionEqualToValue={(option: User, value: User) =>
        option.id === value.id
      }
      onChange={(_, selectedUser) => {
        props.onChange(selectedUser as User);
      }}
      value={props.user}
      getOptionLabel={(option) => option.name}
      options={users}
      noOptionsText="No user found"
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Project Owner"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
