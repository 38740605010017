import { Box, Button, Typography, styled } from "@mui/material";
import { ReactElement } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Requirement } from "../../../../../../Types/Requirement";
import SolutionRequirementItem from "./SolutionRequirementItem";
import theme from "../../../../../../theme";
import useRequirements from "../../../../../../Hooks/useRequirements";

interface SolutionRequirementsProps {
  requirements: Requirement[];
  projectId: number;
  handleSaveNoScroll: () => void;
}

const StyledBox = styled(Box)(() => ({
  justifyContent: "space-between",
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(6),
}));

export default function SolutionRequirements(
  props: SolutionRequirementsProps
): ReactElement {
  const {
    requirements,
    currentEditRequirement,
    setCurrentEditRequirement,
    addRequirement,
    deleteRequirement,
    changeRequirement,
    saveRequirement,
    handleDragEnd,
  } = useRequirements({
    initialRequirements: props.requirements,
    projectId: props.projectId,
    handleSaveNoScroll: props.handleSaveNoScroll,
  });

  return (
    <>
      <StyledBox>
        <Typography variant="h6">Solution Requirements</Typography>
        <Button
          onClick={addRequirement}
          disabled={!!currentEditRequirement}
          sx={{ transition: "all 0.3s ease" }}
          variant="contained"
          color="secondary"
          data-testid="new-requirement-button"
        >
          New Requirement
        </Button>
      </StyledBox>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="requirementList">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: snapshot.isDraggingOver
                  ? theme.palette.grey[200]
                  : "none",
              }}
              onBlur={() => setCurrentEditRequirement(null)}
            >
              {requirements?.length ? (
                requirements.map((requirement, index) => (
                  <SolutionRequirementItem
                    key={requirement.id}
                    index={index}
                    currentEditRequirement={currentEditRequirement}
                    onChangeEditRequirement={setCurrentEditRequirement}
                    deleteRequirement={deleteRequirement}
                    onRequirementChange={changeRequirement}
                    onCreateRequirement={saveRequirement}
                    requirement={requirement}
                  />
                ))
              ) : (
                <Typography variant="body1">
                  There are no solution requirements yet. Go ahead and add the
                  first one.
                </Typography>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
