import { AppBar, Tabs, Tab, styled } from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import { Project } from "../../../../Types/Project";
import DataView from "./DataView/DataView";
import CuratedList from "./CuratedList/CuratedList";
import { TabPanel, a11yProps } from "../../../UI/TabPanel";

interface DiscoverStageProps {
  projectData: Project;
  handleSave: () => void;
  handleSaveNoScroll: () => void;
  handleChangeTab: (fn: () => void) => void;
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: theme.palette.background.default,
  marginTop: theme.spacing(2),
}));

export default function DiscoverStage(props: DiscoverStageProps): ReactElement {
  const [value, setValue] = useState(0);
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    props.handleChangeTab(() => setValue(newValue));
  };
  //function updates the whole Data view
  //triggered when project data is updated in the child component

  return (
    <Fragment>
      <StyledAppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="DATA VIEW" {...a11yProps(0)} />
          <Tab label="Curated List" {...a11yProps(1)} />
        </Tabs>
      </StyledAppBar>
      <TabPanel value={value} index={0}>
        {props.projectData && (
          <DataView
            handleSave={props.handleSave}
            projectData={props.projectData}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CuratedList
          project={props.projectData}
          handleSave={props.handleSaveNoScroll}
        />
      </TabPanel>
    </Fragment>
  );
}
