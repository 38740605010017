import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  MenuItem,
  Autocomplete,
  IconButton,
  styled,
  debounce,
  CircularProgress,
  Checkbox,
  DialogActions,
  FormControlLabel,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useContext, useReducer, Reducer, ReactElement } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import { UserContext } from "../../../../../Context/UserContext";
import { KpiHttpService } from "../../../../../Http/Kpi/Kpi.http.service";
import { UnitHttpService } from "../../../../../Http/Unit/Unit.http.service";
import { Kpi, Unit } from "../../../../../Types/Kpi";
import theme from "../../../../../theme";
import { getErrorMessage } from "../../../../../utils";
import CreateEntityByName from "../../../../UI/Modals/CreateEntityByName/CreateEntityByName";

const ActionButtons = styled("div")(() => ({
  marginLeft: "auto",
  height: "38px",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

const InfoSection = styled(Box)(() => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(6),
  backgroundColor: theme.palette.other.surfaceBright,
  borderRadius: theme.shape.borderRadius * 3,
  boxShadow:
    "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  marginBlock: theme.spacing(3, 4),
  flexWrap: "wrap",
  "& > div:first-of-type": {
    flexBasis: "100%",
  },
  "& > div:not(:first-of-type)": {
    flex: 1,
  },
}));

const BinaryInfoSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "start",
  gap: theme.spacing(2),
  flex: "2",
}));

const operators = [">", "<", "=", "<=", ">="];

const isFulfilled = (
  operator: string | null,
  target: number,
  measurement: number | null
) => {
  if (measurement === null) return null;

  switch (operator) {
    case ">":
      return measurement > target;
    case "<":
      return measurement < target;
    case "=":
      return measurement == target;
    case "<=":
      return measurement <= target;
    case ">=":
      return measurement >= target;
    default:
      throw new Error("Invalid operation");
  }
};

const shouldNotSearch = (
  event: React.SyntheticEvent<Element, Event>,
  newValue: string,
  units: Unit[]
) => {
  if (event === null || units.some((unit) => unit.name.includes(newValue))) {
    return true;
  }
  return false;
};

interface Props {
  objectiveId: number;
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  kpi?: Kpi;
  refreshObjective?: (kpiId: number, isKpiFulfilled: boolean | null) => void;
  updateKpi: (objectiveId: number, updatedKpi: Kpi) => void;
  createKpi: (newKpi: Kpi) => void;
}

const ManageKpiModal = (props: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const user = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [units, setUnits] = useState<Unit[]>([]);
  const [newKpi, setNewKpi] = useReducer<Reducer<Kpi, Partial<Kpi>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.kpi || ({ objectiveId: props.objectiveId, target: null } as Kpi)
  );
  const [isInfoSectionOpen, setIsInfoSectionOpen] = useState(true);
  const [createUnitModalOpen, setCreateUnitModalOpen] = useState(false);

  const isBuyStageActive = activeStep === 2;
  const isPilotStageActive = activeStep === 3;
  const isBinaryKpi = newKpi.operator === "binary";
  const isCreateMode = !props.kpi;

  const searchForUnits = async (searchValue: string): Promise<void> => {
    if (searchValue) {
      setIsLoading(true);
      await UnitHttpService.getUnitsByName(searchValue)
        .then((units) => setUnits(units))
        .catch((error) => {
          const errorMessage = getErrorMessage(error);
          enqueueSnackbar(`Could not get units: ${errorMessage}`, {
            variant: "error",
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  const addUnit = (newUnit: Unit) => {
    setNewKpi({ unitId: newUnit.id, unit: newUnit });
    setUnits([...units, newUnit]);
  };

  const createKpi = async () => {
    setIsLoading(true);
    await KpiHttpService.createKpi(newKpi)
      .then((kpi: Kpi) => {
        props.createKpi(kpi);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not create KPI: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const editKpi = async () => {
    setIsLoading(true);
    await KpiHttpService.updateKpi(newKpi)
      .then(() => {
        props.updateKpi?.(newKpi.objectiveId, newKpi);
        props.refreshObjective &&
          props.refreshObjective(props.kpi?.id as number, newKpi.fulfilled);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update KPI: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const debouncedSearchForUnits = debounce(searchForUnits, 500);

  return (
    <>
      <Dialog
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        maxWidth="md"
        fullWidth
        data-testid="manage-kpi-modal"
      >
        <DialogTitle display="flex" data-testid="manage-kpi-header">
          {isCreateMode ? "New KPI" : "Edit KPI"}

          <ActionButtons>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <Button onClick={() => props.setModalOpen(false)}>Close</Button>
                <Button onClick={isCreateMode ? createKpi : editKpi}>
                  Save
                </Button>
              </>
            )}
          </ActionButtons>
        </DialogTitle>
        <DialogContent dividers>
          {isCreateMode && isInfoSectionOpen && (
            <InfoSection data-testid="info-section">
              <Box display="flex" alignItems="center" gap={1}>
                <InfoIcon color="primary" sx={{ fontSize: "2rem" }} />
                <Typography>Hello {user?.name}</Typography>
                <IconButton
                  sx={{ ml: "auto" }}
                  onClick={() => setIsInfoSectionOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box p={theme.spacing(2, 2, 0)}>
                <Typography variant="body2">
                  You are about to create a new KPI and assign it to one of this
                  project’s objectives.
                  <br />
                  <br />
                  <b>Key Performance Indicators (KPIs)</b> are target values or
                  outcomes that define the success criteria for each of the
                  project objectives.
                  <br />
                  <br />
                  Here are a few tips to make things easier:
                </Typography>
                <List
                  dense
                  sx={{
                    listStyleType: "disc",
                    pl: 3,
                  }}
                >
                  <ListItem disablePadding sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="Ensure that your KPI directly aligns with the objective to
                      which it is assigned and enables the project team to
                      evaluate whether the objective has been achieved"
                    />
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="Make the KPI specific and measurable by either defining a
                    quantitative threshold value or an outcome which can be
                    assessed on a binary dimension (fulfilled or unfulfilled)."
                    />
                  </ListItem>
                </List>
              </Box>
            </InfoSection>
          )}

          {isBuyStageActive ? (
            <FormContainer data-testid="manage-kpi-form">
              <TextField
                label="KPI"
                multiline
                value={newKpi.description || ""}
                variant="outlined"
                onChange={(event) =>
                  setNewKpi({ description: event.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="e.g. Material Weight"
                helperText={
                  isCreateMode &&
                  "Describe the KPI in a few words. Clearly explain what will be measured or evaluated."
                }
                inputProps={{ "data-testid": "kpi-description-input" }}
              />
              <TextField
                select
                label="Type"
                value={newKpi.operator || ""}
                onChange={(event) => {
                  if (event.target.value === "binary") {
                    setNewKpi({
                      operator: event.target.value,
                      target: null,
                      unitId: null,
                    });
                  } else {
                    setNewKpi({
                      operator: event.target.value,
                    });
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={
                  isCreateMode &&
                  "You can select a binary KPI or define a quantitative threshold value by choosing an operator."
                }
                inputProps={{ "data-testid": "kpi-operator-input" }}
              >
                <MenuItem divider key={-1} value="binary">
                  binary
                </MenuItem>
                <Typography variant="caption" color="text.disabled" p={2}>
                  Quantitative
                </Typography>
                {operators.map((operator, index) => (
                  <MenuItem key={index} value={operator} sx={{ pl: 3.5 }}>
                    {operator}
                  </MenuItem>
                ))}
              </TextField>

              {isBinaryKpi ? (
                <BinaryInfoSection data-testid="binary-info-section">
                  <InfoIcon sx={{ fontSize: "2rem" }} />
                  <Typography>
                    A binary KPI defines an outcome to be evaluated during the
                    pilot project which can either be fulfilled or unfulfilled.
                  </Typography>
                </BinaryInfoSection>
              ) : (
                <>
                  <TextField
                    label="Value"
                    type="number"
                    value={newKpi.target !== null ? newKpi.target : ""}
                    onChange={(event) => {
                      const value = event.target.value
                        ? +event.target.value
                        : null;
                      setNewKpi({ target: value });
                    }}
                    helperText={isCreateMode && "Provide a quantitative value."}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isCreateMode && !newKpi.operator}
                    inputProps={{ "data-testid": "kpi-target-input" }}
                  />
                  <Autocomplete
                    forcePopupIcon={false}
                    isOptionEqualToValue={(option: Unit, value: Unit) =>
                      option.id === value.id
                    }
                    onInputChange={(event, newValue: string) => {
                      if (shouldNotSearch(event, newValue, units)) return;
                      debouncedSearchForUnits(newValue);
                    }}
                    onChange={(_, selectedUnit) => {
                      setNewKpi({
                        unitId: selectedUnit?.id || null,
                        unit: selectedUnit,
                      });
                    }}
                    value={newKpi.unit || null}
                    disabled={isCreateMode && !newKpi.operator}
                    filterOptions={(options) => options}
                    getOptionLabel={(option) => option.name ?? ""}
                    options={units}
                    noOptionsText="No Unit found"
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Unit"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          helperText={
                            isCreateMode &&
                            "Define a measurement unit, if applicable."
                          }
                          placeholder="mm"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {params.InputProps.endAdornment}
                                <IconButton
                                  data-testid="add-new-unit"
                                  onClick={() => setCreateUnitModalOpen(true)}
                                  disabled={isCreateMode && !newKpi.operator}
                                >
                                  {isLoading ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <AddIcon fontSize="small" />
                                  )}
                                </IconButton>
                              </>
                            ),
                          }}
                          inputProps={{
                            ...params.inputProps,
                            "data-testid": "kpi-unit-input",
                          }}
                        />
                      );
                    }}
                  />
                </>
              )}
            </FormContainer>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              data-testid="manage-kpi-content"
            >
              <Typography>{props.kpi?.description}</Typography>
              {!isBinaryKpi && (
                <Box display="flex" alignItems="center" gap={1.5}>
                  <Typography>{props.kpi?.operator}</Typography>
                  <Typography fontSize="2rem">{props.kpi?.target}</Typography>
                  <Typography alignSelf="start">
                    {props.kpi?.unit?.name}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        {isPilotStageActive && (
          <DialogActions
            sx={{
              padding: theme.spacing(3),
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: theme.palette.other.secondaryAction,
                    },
                  }}
                  checked={newKpi.isNotMeasured}
                  onChange={(_, checked) => {
                    setNewKpi({
                      isNotMeasured: checked,
                      measurement: null,
                      fulfilled: null,
                    });
                  }}
                />
              }
              label="Don't set a value for this KPI"
            />
            {isBinaryKpi ? (
              <TextField
                sx={{ ml: "auto", width: "200px" }}
                select
                label="Value"
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  newKpi.fulfilled === true
                    ? "fulfilled"
                    : newKpi.fulfilled === false
                    ? "Not fulfilled"
                    : ""
                }
                onChange={(event) => {
                  setNewKpi({ fulfilled: event.target.value === "fulfilled" });
                }}
                disabled={newKpi.isNotMeasured}
                inputProps={{
                  "data-testid": "kpi-measurement-input",
                }}
              >
                <MenuItem value="fulfilled">Fulfilled</MenuItem>
                <MenuItem value="Not fulfilled">Not Fulfilled</MenuItem>
              </TextField>
            ) : (
              <>
                <TextField
                  label="Value"
                  type="number"
                  value={newKpi.measurement || ""}
                  disabled={newKpi.isNotMeasured}
                  onChange={(event) => {
                    const measurement = event.target.value
                      ? +event.target.value
                      : null;
                    const targetValue = props.kpi?.target || 0;
                    const operator = props.kpi?.operator || null;
                    const fulfilled = isFulfilled(
                      operator,
                      targetValue,
                      measurement
                    );
                    setNewKpi({ measurement, fulfilled, isNotMeasured: false });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    "data-testid": "kpi-measurement-input",
                  }}
                  sx={{ marginInline: theme.spacing("auto", 1) }}
                />
                <Typography
                  alignSelf="start"
                  color={newKpi.isNotMeasured ? "text.disabled" : "inherit"}
                >
                  {props.kpi?.unit?.name}
                </Typography>
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
      {createUnitModalOpen && (
        <CreateEntityByName
          name="unit"
          label="Unit"
          modalOpen={createUnitModalOpen}
          setModalOpen={() => setCreateUnitModalOpen(false)}
          handleAdd={addUnit}
        />
      )}
    </>
  );
};

export default ManageKpiModal;
