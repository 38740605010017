import { createContext } from "react";

export type LoaderContextType = {
  globalLoader: boolean;
  setGlobalLoader: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GlobalLoaderContext = createContext<LoaderContextType>({
  globalLoader: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setGlobalLoader: () => {},
});
