import { ReactElement, useContext, useState } from "react";
import {
  CalculationType,
  CreateCalculation,
  CreateImpactValue,
  ImpactValue,
  ImpactValueStage,
} from "../../../../Types/ImpactValue";
import { Box, Button, styled } from "@mui/material";
import Calculation from "./Calculation";
import { CalculationHttpService } from "../../../../Http/Calculation/Calculation.http.service";
import AddIcon from "@mui/icons-material/Add";
import { calculate, hasMonetaryValue } from "./helper/helper";
import { ImpactValueHttpService } from "../../../../Http/ImpactValue/ImpactValue.http.service";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import { funnelStages } from "../../../../Constants/FunnelStages";

const NewCalculationButton = styled(Button)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  justifyContent: "end",
  marginLeft: "auto",
  marginTop: theme.spacing(2),
  color: theme.palette.other.secondaryAction,
  textTransform: "none",
  fontWeight: 400,
}));

interface Props {
  type: string;
  calculations: CalculationType[];
  handleImpactvalue: () => void;
  setImpactValues: (impactValues: ImpactValue[]) => void;
  fetchImpactValues: () => void;
  impactValues: ImpactValue[];
  projectId: number;
}

const CalculationContainer = (props: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const [expandedCalculationId, setExpandedCalculationId] = useState<
    number | null
  >(null);
  const funnelStage = funnelStages[activeStep || 0] as ImpactValueStage;

  const {
    calculations,
    type,
    handleImpactvalue,
    setImpactValues,
    fetchImpactValues,
    impactValues,
    projectId,
  } = props;

  const createImpactValue = async () => {
    const impactValueToBeCreated = {
      value: 0,
      stage: funnelStage,
      projectId: projectId,
    } as CreateImpactValue;

    const newImpactValue = await ImpactValueHttpService.createImpactValue(
      impactValueToBeCreated
    );

    setImpactValues([{ ...newImpactValue, calculations: [] }]);
    return newImpactValue.id;
  };

  const createNewCalculation = async () => {
    let impactValueId = impactValues?.[0]?.id;

    if (!impactValueId) {
      impactValueId = await createImpactValue();
    }

    const calculationToBeCreated = {
      name: type === "Cost" ? "Cost Saving Calculation" : "Revenue Calculation",
      type: type,
      impactValueId: impactValueId,
      variables: [],
    } as CreateCalculation;

    const newCalculation = await CalculationHttpService.createCalculation(
      calculationToBeCreated
    );

    fetchImpactValues();
    setExpandedCalculationId(newCalculation.id);
  };

  const handleExpand = (calculationId: number) => {
    setExpandedCalculationId(
      expandedCalculationId === calculationId ? null : calculationId
    );
  };

  return (
    <>
      <Box sx={{ pl: 4 }}>
        {calculations.map((calculation, index) => {
          const variables = calculation.variables;
          const result = hasMonetaryValue(variables) ? calculate(variables) : 0;
          return (
            <Calculation
              key={calculation.id}
              calculation={calculation}
              index={index}
              variables={variables}
              result={result}
              handleImpactValue={handleImpactvalue}
              expanded={expandedCalculationId === calculation.id}
              handleToggleExpand={() => handleExpand(calculation.id)}
              data-testid={`calculation-${calculation.id}`}
            />
          );
        })}
        <NewCalculationButton
          data-testid="add-new-calculation"
          onClick={createNewCalculation}
        >
          <AddIcon />
          Add {type} Calculation
        </NewCalculationButton>
      </Box>
    </>
  );
};

export default CalculationContainer;
