import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { SelectOption } from "../../Types/Common";
import { ProjectFilterCriteria } from "../../Types/Project";
import { BusinessUnitHttpService } from "../../Http/BusinessUnit/BusinessUnit.http.service";
import { SelectInput } from "../UI/InputFields/SelectInput";
import { FocusArea } from "../../Types/VentureClient";
import FocusAreaHttpService from "../../Http/FocusArea/FocusArea.http.service";
import Bookmark from "../UI/InputFields/Bookmark";
import { User } from "../../Types/User";
import { useSnackbar } from "notistack";
import UserHttpService from "../../Http/User/User.Http.service";
import { KanbanFilterContext } from "../../Context/KanbanFilterContext";

interface ProjectKanbanFilterProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  refresh: () => void;
}

const StyledCheckbox = styled(FormControlLabel)(() => ({
  marginLeft: 0,
}));

const StyledBookmarkIcon = styled(Bookmark)(({ theme }) => ({
  display: "flex",
  gap: "5px",
  // marginInline: 0,
  marginLeft: -1,
  "& > span:nth-of-type(1)": {
    padding: "6px",
  },
  "& svg": {
    fontSize: "24px",
    fill: theme.palette.text.secondary,
  },
}));

export function ProjectKanbanFilter(
  props: ProjectKanbanFilterProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [organizationalUnitSelectOptions, setOrganizationalUnitSelectOptions] =
    useState<SelectOption[]>([]);
  const [focusAreaSelectOptions, setFocusAreaSelectOptions] = useState<
    FocusArea[]
  >([]);
  const { activeFilters, setFilterToLocalStorage } =
    useContext(KanbanFilterContext);
  const [kanbanFilter, setKanbanFilter] = useState(activeFilters);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const getInputValues = async () => {
      try {
        await FocusAreaHttpService.getAll().then((focusAreas) => {
          setFocusAreaSelectOptions(focusAreas);
        });
        await UserHttpService.getUsers(null, true).then((users) => {
          setUsers(users);
        });
      } catch (error) {
        enqueueSnackbar("Something went wrong with", {
          variant: "error",
        });
      }
    };
    getInputValues();
  }, []);

  const resetFilters = () => {
    setFilterToLocalStorage();
    props.refresh();
    props.setOpen(false);
  };

  const handleAcceptFilterChanges = () => {
    const newFilterCriteria: ProjectFilterCriteria = Object.entries(
      kanbanFilter
    ).reduce((acc, [key, value]) => {
      // remove empty strings and undefined values (businessUnitId / ventureClientId e.g.)
      if (value !== undefined) acc = Object.assign({ [key]: value }, acc);
      return acc;
    }, {} as ProjectFilterCriteria);

    setFilterToLocalStorage(newFilterCriteria);
    props.refresh();
    props.setOpen(false);
  };

  useEffect(() => {
    BusinessUnitHttpService.getBusinessUnits().then((orgnanizationalUnits) =>
      setOrganizationalUnitSelectOptions(
        orgnanizationalUnits.map((unit) => {
          return { name: unit.name, id: unit.id };
        })
      )
    );
  }, []);

  const handleFilterByStatus = (statusOption: string) => {
    let updatedKanbanFilters;
    if (!kanbanFilter.status || !Array.isArray(kanbanFilter.status)) {
      updatedKanbanFilters = kanbanFilter.status ? [kanbanFilter.status] : [];
    } else {
      updatedKanbanFilters = kanbanFilter.status;
    }
    const filterIndex = updatedKanbanFilters.indexOf(statusOption);

    // if statusOption does not exist => add to array, if not => remove from the array
    filterIndex === -1
      ? updatedKanbanFilters?.push(statusOption)
      : updatedKanbanFilters?.splice(filterIndex, 1);

    setKanbanFilter({
      ...kanbanFilter,
      status: updatedKanbanFilters,
    });
  };

  const handleFilterByBookmark = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setKanbanFilter({ ...kanbanFilter, bookmarked: event.target.checked });
    } else {
      const updatedFilter = kanbanFilter;
      delete updatedFilter.bookmarked;
      setKanbanFilter({ ...updatedFilter });
    }
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={props.open}
      data-testid="projectKanbanFilter"
    >
      <Toolbar />
      {kanbanFilter && (
        <Box
          role="presentation"
          display="flex"
          flexDirection="column"
          p={3}
          width="400px"
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Filters</Typography>
            <Button
              id="filter-drawer-close-button"
              sx={{ ml: "auto" }}
              color="primary"
              onClick={() => props.setOpen(false)}
            >
              <Close />
            </Button>
          </Box>
          <Divider />
          <Grid container display="flex" flexDirection="row">
            <Grid item xs={12} display="flex" flexDirection="column" mt={2}>
              <FormGroup row>
                <StyledCheckbox
                  control={
                    <Checkbox
                      name="active"
                      data-testid="filter-checkbox-active"
                      edge="start"
                      disableRipple
                      checked={kanbanFilter.status?.includes("active")}
                      onChange={(event) => {
                        handleFilterByStatus(event.target.name);
                      }}
                    />
                  }
                  label="active"
                />
                <StyledCheckbox
                  control={
                    <Checkbox
                      name="on hold"
                      data-testid="filter-checkbox-on-hold"
                      edge="start"
                      disableRipple
                      checked={kanbanFilter.status?.includes("on hold")}
                      onChange={(event) => {
                        handleFilterByStatus(event.target.name);
                      }}
                    />
                  }
                  label="on hold"
                />
                <StyledCheckbox
                  control={
                    <Checkbox
                      name="archived"
                      data-testid="filter-checkbox-archived"
                      edge="start"
                      disableRipple
                      checked={kanbanFilter.status?.includes("archived")}
                      onChange={(event) => {
                        handleFilterByStatus(event.target.name);
                      }}
                    />
                  }
                  label="archived"
                />
                <StyledCheckbox
                  control={
                    <Checkbox
                      name="adopted"
                      data-testid="filter-checkbox-adopted"
                      edge="start"
                      disableRipple
                      checked={activeFilters.status?.includes("adopted")}
                      onChange={(event) => {
                        handleFilterByStatus(event.target.name);
                      }}
                    />
                  }
                  label="adopted"
                />
              </FormGroup>
              <Grid item xs={12} display="flex" ml="3px">
                <StyledBookmarkIcon
                  checked={kanbanFilter.bookmarked as boolean}
                  handleBookmark={handleFilterByBookmark}
                  label="bookmarked"
                  disableRipple
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                deselectPossible
                labelText="Project Owner"
                selectValues={users}
                value={kanbanFilter.projectOwnerId || ""}
                onChange={(event) =>
                  setKanbanFilter({
                    ...kanbanFilter,
                    projectOwnerId: event.target.value
                      ? parseInt(event.target.value)
                      : undefined,
                  })
                }
                editMode={true}
              />
            </Grid>
            <Grid item xs={12} display="flex">
              <SelectInput
                deselectPossible
                labelText="Organizational Unit"
                selectValues={organizationalUnitSelectOptions}
                value={kanbanFilter.businessUnitId || ""}
                onChange={(event) =>
                  setKanbanFilter({
                    ...kanbanFilter,
                    businessUnitId: parseInt(event.target.value) || null,
                  })
                }
                editMode={true}
              />
            </Grid>
            <Grid item xs={12} display="flex">
              <SelectInput
                deselectPossible
                labelText="Focus Area"
                selectValues={focusAreaSelectOptions}
                value={kanbanFilter.focusAreaId || ""}
                onChange={(event) =>
                  setKanbanFilter({
                    ...kanbanFilter,
                    focusAreaId: parseInt(event.target.value),
                  })
                }
                editMode={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              gap={2}
              justifyContent="end"
              mt={2}
            >
              <Button onClick={() => resetFilters()} variant="outlined">
                Reset
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleAcceptFilterChanges}
              >
                Accept
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Drawer>
  );
}
