import { ReactElement } from "react";
import CustomCarousel from "../UI/CarouselComponent/CustomCarousel";
import { Box, Card, CardContent, Typography, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import theme from "../../theme";
import { formatFunding } from "../../utils";
import FadingDeleteButton from "../ProjectLead/ProjectLeadLeaders/FadingDeleteButton";
import { LeadOpportunity } from "../../Types/LeadProject";

interface StartupsCarouselProps {
  opportunities: LeadOpportunity[];
  onDelete?: (id: number) => void;
}

const DeleteContainer = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  right: 0,
  margin: theme.spacing(1),
}));

const CarouselItem = styled(Card)(({ theme }) => ({
  width: "180px",
  margin: "10px",
  height: "110px",
  boxShadow: theme.shadows[2],
  position: "relative",
  "&:hover ": {
    "& .deleteIcon": {
      opacity: 1,
    },
  },
}));

export default function StartupsCarousel(
  props: StartupsCarouselProps
): ReactElement {
  return (
    <CustomCarousel>
      {props.opportunities.map((leadOpportunity, index) => (
        <CarouselItem
          key={index}
          data-testid={`carousel-card-${leadOpportunity.id}`}
        >
          <NavLink
            to={`/startups/${leadOpportunity.startup.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CardContent
              sx={{
                padding: theme.spacing(1),
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography color="primary" variant="subtitle2" mb={1}>
                {leadOpportunity.startup.name}
              </Typography>
              <Box
                display="flex"
                gap={theme.spacing(1)}
                marginBottom={theme.spacing(2)}
                color="text.secondary"
                sx={{
                  ".MuiTypography-root": {
                    fontSize: "10px",
                  },
                }}
              >
                {leadOpportunity.startup.dateFounded !== null && (
                  <Typography>
                    {new Date(
                      leadOpportunity.startup.dateFounded
                    ).getFullYear()}
                  </Typography>
                )}
                {leadOpportunity.startup.currentInvestmentStage !== null && (
                  <Typography
                    title={leadOpportunity.startup.currentInvestmentStage}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {leadOpportunity.startup.currentInvestmentStage}
                  </Typography>
                )}
                <Typography flexShrink={0}>
                  {leadOpportunity.startup.fundingIsUndisclosed
                    ? "Undisclosed"
                    : leadOpportunity.startup.totalFunding !== null
                    ? formatFunding(leadOpportunity.startup.totalFunding)
                    : ""}
                </Typography>
              </Box>
              <Typography
                color="text.secondary"
                variant="caption"
                fontSize="10px"
              >
                {leadOpportunity.startup.shortDescription}
              </Typography>
            </CardContent>
          </NavLink>
          {props.onDelete && (
            <DeleteContainer>
              <FadingDeleteButton
                id={leadOpportunity.id}
                entity="Project Lead Startup"
                onDelete={() => props.onDelete?.(leadOpportunity.id)}
              />
            </DeleteContainer>
          )}
        </CarouselItem>
      ))}
    </CustomCarousel>
  );
}
