import { Add } from "@mui/icons-material";
import { Divider, MenuItem, TextField, Theme } from "@mui/material";
import { ChangeEvent, ReactElement } from "react";
import { SelectOption } from "../../../Types/Common";
import CustomToolTip from "../CustomToolTip";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export interface SelectInputProps {
  id?: string;
  selectValues: SelectOption[];
  labelText?: string;
  value: number | string | null | undefined;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  required?: boolean;
  disabled?: boolean;
  showAddNewSelectOption?: boolean;
  addNewSelectOptionText?: string;
  handleAddNewSelectOption?: () => void;
  deselectPossible?: boolean;
  editMode: boolean;
  fullWidth?: boolean;
  toolTipText?: string;
  ignoreValidation?: boolean;
  disableSorting?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBlock: theme.spacing(2),
      minWidth: "35ch",

      "& .MuiInputLabel-root.Mui-error, .MuiInputLabel-asterisk.Mui-error": {
        color: theme.palette.warning.main,
      },
      "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.warning.main,
      },
      "& .MuiInput-underline:before, .MuiInput-underline:hover:before": {
        borderBottom: "none",
      },
    },
  })
);

export function SelectInput(props: SelectInputProps): ReactElement {
  const classes = useStyles();
  return (
    <>
      {props.editMode ? (
        <TextField
          id={props.id}
          data-testid={props.id}
          select
          className={`${classes.root} ${props.className}`}
          disabled={props.disabled}
          label={
            !!!props.toolTipText ? (
              props.labelText
            ) : (
              <>
                <span>{props.labelText}</span>
                {props.editMode && (
                  <CustomToolTip
                    id={`${props.id}Tooltip`}
                    key={`${props.id}Tooltip`}
                    toolTipText={props.toolTipText || ""}
                  />
                )}
              </>
            )
          }
          value={props.value || ""}
          fullWidth={props.fullWidth}
          InputLabelProps={{
            shrink: true,
            sx: {
              marginTop: props.editMode && !!props.toolTipText ? "-7px" : "0px",
              paddingRight: "8px",
            },
          }}
          variant={props.editMode ? "outlined" : "standard"}
          focused={false}
          InputProps={{
            readOnly: !props.editMode,
          }}
          onChange={props.onChange}
          required={props.required}
          error={
            props.editMode &&
            props.required &&
            !props.value &&
            !props.ignoreValidation
          }
        >
          {props.deselectPossible && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}

          {props.selectValues
            .sort(function (option1, option2) {
              if (props.disableSorting) {
                return 0;
              }
              return option1.name.localeCompare(option2.name, "en", {
                numeric: true,
              });
            })
            .map((selectOption, index) => (
              <MenuItem
                id={"menuItem-" + index}
                data-cy={"option-" + selectOption.name}
                key={selectOption.id}
                value={selectOption.id}
              >
                {selectOption.name}
              </MenuItem>
            ))}

          {props.showAddNewSelectOption && [
            <Divider key="1" />,
            <MenuItem
              key="2"
              disableRipple
              onClick={props.handleAddNewSelectOption}
            >
              <Add />
              {props.addNewSelectOptionText}
            </MenuItem>,
          ]}
        </TextField>
      ) : (
        <TextField
          label={props.labelText}
          fullWidth={props.fullWidth}
          id={props.id}
          InputLabelProps={{
            shrink: true,
          }}
          value={
            props.selectValues.find((option) => option.id === props.value)
              ?.name || ""
          }
          className={classes.root}
          variant="standard"
          focused={false}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      )}
    </>
  );
}
