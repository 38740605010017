import { Auth } from "aws-amplify";
import axios from "axios";
export default class RequestInterceptors {
  static addAuthHeaderInterceptor(): void {
    axios.interceptors.request.use(async function (config) {
      const session = await Auth.currentSession();

      config.headers.Authorization =
        "Bearer " + session.getIdToken().getJwtToken();

      return config;
    });
  }
}
