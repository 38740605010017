import { ReactElement, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  Typography,
  styled,
} from "@mui/material";
import EditPhaseModal from "./EditPhaseModal";
import theme from "../../../../../../../theme";
import Phase from "../../../../../../../Types/Phase";

const ActionButtons = styled("div")(() => ({
  marginLeft: "auto",
  height: "38px",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

interface Props {
  index: number;
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  phase: Phase;
  handlePhaseEdit: (phase: Phase) => void;
}

const ViewPhaseModal = (props: Props): ReactElement => {
  const [isEditPhaseModalOpen, setIsEditPhaseModalOpen] = useState(false);

  return (
    <>
      <Dialog
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        maxWidth="sm"
        fullWidth
        data-testid="view-phase-modal"
      >
        <DialogTitle display="flex">
          Phase {props.index}
          <ActionButtons>
            <Button onClick={() => setIsEditPhaseModalOpen(true)}>Edit</Button>
            <Button onClick={() => props.setModalOpen(false)}>Close</Button>
          </ActionButtons>
        </DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={2}>
            {props.phase.name && (
              <Typography variant="h6" data-testid="phase-name">
                {props.phase.name}
              </Typography>
            )}
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" color={theme.palette.grey[500]}>
                Duration
              </Typography>
              <Typography variant="caption" color={theme.palette.grey[600]}>
                {props.phase.duration || "-"} days
              </Typography>
            </Box>
            {props.phase.description && (
              <Typography
                variant="body2"
                fontSize={theme.customTypography.fontSize12}
                data-testid="phase-description"
              >
                {props.phase.description}
              </Typography>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      {isEditPhaseModalOpen && (
        <EditPhaseModal
          modalOpen={isEditPhaseModalOpen}
          setModalOpen={setIsEditPhaseModalOpen}
          phase={props.phase}
          handlePhaseEdit={props.handlePhaseEdit}
        />
      )}
    </>
  );
};

export default ViewPhaseModal;
