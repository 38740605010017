import { Box, IconButton, TextField, Typography, styled } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Requirement } from "../../../../../../Types/Requirement";
import { DeleteOutline, DragIndicator } from "@mui/icons-material";
import theme from "../../../../../../theme";

interface Props {
  index: number;
  requirement: Requirement;
  onCreateRequirement: (requirement: Requirement) => void;
  onRequirementChange: (requirement: Requirement) => void;
  loader?: boolean;
  deleteRequirement: (id: number) => void;
  onChangeEditRequirement: (id: number | null) => void;
  currentEditRequirement: number | null;
}

const StyledBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  "& fieldset": { border: "none" },
  borderTop: 1,
  borderColor: "#D7E2FF",
}));

const StyledTextField = styled(TextField)(() => ({
  flexGrow: 1,
  border: "none",
  transition: "max-width 0.3s ease, padding 0.3s ease",
  textarea: {
    ":focus": {
      color: "#94A5FF",
    },
  },
}));

const MAX_INPUT_LENGTH = 50;

const SolutionRequirementItem = (props: Props): ReactElement => {
  const [currentDescription, setCurrentDescription] = useState(
    props.requirement.description
  );
  const [blur, setBlur] = useState(false);

  const buttonRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      currentDescription !== props.requirement.description &&
      props.requirement.id !== -1
    )
      props.onRequirementChange({
        ...props.requirement,
        description: currentDescription,
      });
  }, [props.currentEditRequirement]);

  const handleDescriptionBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget === buttonRef.current) return e.stopPropagation();
    if (props.requirement.id === -1 && currentDescription) {
      props.onCreateRequirement({
        ...props.requirement,
        description: currentDescription,
      });
    } else if (props.requirement.id === -1 && !currentDescription) {
      props.deleteRequirement(-1);
    }
  };

  const handleEnterKeyDown = () => {
    props.onRequirementChange({
      ...props.requirement,
      description: currentDescription,
    });
    props.onChangeEditRequirement(null);
    setBlur(true);
  };

  const handleEscapeKeyDown = () => {
    setCurrentDescription(props.requirement.description);
    props.onChangeEditRequirement(null);
    setBlur(true);
  };

  useEffect(() => {
    if (blur) {
      inputRef.current?.blur();
      setBlur(false);
    }
  }, [blur]);

  const isEditing = props.requirement.id === props.currentEditRequirement;

  return (
    <Draggable
      key={props.requirement.id}
      draggableId={props.requirement.id?.toString()}
      index={props.index}
    >
      {(provided, snapshot) => (
        <StyledBox
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps.style,
            background: snapshot.isDragging ? theme.palette.grey[300] : "none",
          }}
        >
          <IconButton
            className="drag-handle-button"
            {...provided.dragHandleProps}
            disabled={!!props.currentEditRequirement}
            color="inherit"
            sx={{
              transition: "all 0.3s ease-in-out",
              width: !!props.currentEditRequirement ? 0 : 40,
              opacity: !!props.currentEditRequirement ? 0 : 100,
            }}
          >
            <DragIndicator
              fontSize="small"
              sx={{
                transition: "all 0.3s ease",
                width: !!props.currentEditRequirement ? 0 : 24,
              }}
            />
          </IconButton>
          <StyledTextField
            maxRows={3}
            inputRef={inputRef}
            value={currentDescription}
            sx={{ input: { cursor: isEditing ? "text" : "pointer" } }}
            onChange={(e) => {
              setCurrentDescription(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleEnterKeyDown();
              }
              if (event.key === "Escape") {
                handleEscapeKeyDown();
              }
            }}
            onBlur={handleDescriptionBlur}
            onFocus={() => {
              props.onChangeEditRequirement(props.requirement.id);
            }}
            data-testid={"requirement-" + props.requirement.id}
            placeholder="Add a requirement..."
            id="requirementsInput"
            inputProps={{ maxLength: MAX_INPUT_LENGTH }}
            autoFocus={props.currentEditRequirement === -1}
            multiline
          />
          <Box
            className="delete-button"
            display="flex"
            alignItems="center"
            sx={{
              transition: "opacity 0.3s ease",
              opacity: isEditing ? 100 : 0,
              display: isEditing ? "flex" : "none",
              color: theme.palette.grey[500],
            }}
          >
            <Typography>{`${currentDescription.length}/${MAX_INPUT_LENGTH}`}</Typography>
            <IconButton
              onClick={() => {
                props.deleteRequirement(props.requirement.id);
              }}
              data-testid={"delete-requirement-" + props.requirement.id}
              ref={buttonRef}
            >
              <DeleteOutline />
            </IconButton>
          </Box>
        </StyledBox>
      )}
    </Draggable>
  );
};

export default SolutionRequirementItem;
