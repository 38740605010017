import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Dispatch,
  Fragment,
  ReactElement,
  SetStateAction,
  useState,
} from "react";
import { ClientContactDTO } from "../../../../../../../Types/ClientContact";
import { ProductDemo } from "../../../../../../../Types/ProductDemo";
import { Project } from "../../../../../../../Types/Project";
import { StartupContactDTO } from "../../../../../../../Types/StartupContact";
import ClientContactSelect from "../../../../../../UI/InputFields/ClientContactSelect";
import CustomTextField from "../../../../../../UI/InputFields/CustomTextField";
import StartupContactSelect from "../../../../../../UI/InputFields/StartupContactSelect";
import { SelectInput } from "../../../../../../UI/InputFields/SelectInput";

interface ProductDemosSectionProps {
  project: Project;
  productDemos: ProductDemo[];
  setProductDemos: Dispatch<SetStateAction<ProductDemo[]>>;
  setProductDemoIdsToDelete: Dispatch<SetStateAction<number[]>>;
  editMode: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    demoContainer: {
      paddingTop: "16px",
      "& .MuiTextField-root": {
        margin: "16px 0",
        minWidth: "unset",
      },
      "& .MuiTableCell-root": {
        padding: "0 16px 0 0",
      },
    },
  })
);

const shouldAddContact = (
  contact?: ClientContactDTO,
  list?: ClientContactDTO[]
) => contact && !list?.some((member) => member.id === contact.id);

export default function ProductDemosSection(
  props: ProductDemosSectionProps
): ReactElement {
  const classes = useStyles();

  const noQualifiedStartups =
    props.project.opportunities.filter((opp) => opp.isQualified).length !== 0;

  const handleEdit = (productDemo: ProductDemo, index: number) => {
    const newProductDemos = [...props.productDemos];
    newProductDemos[index] = productDemo;
    props.setProductDemos(newProductDemos);
  };

  const getInitialContacts = () => {
    const { projectLeader, programManager, projectTeam } = props.project;

    const contacts = [...projectTeam];

    if (shouldAddContact(projectLeader, contacts)) {
      contacts.unshift(projectLeader);
    }

    if (shouldAddContact(programManager, contacts)) {
      programManager && contacts.push(programManager);
    }

    return contacts;
  };

  const handleAdd = () => {
    const newProductDemo: ProductDemo = {
      id: 0,
      date: null,
      opportunityId: props.project.opportunities
        ? props.project.opportunities.filter((opp) => opp.isQualified)[0].id
        : null,
      location: "",
      startupParticipants: [],
      ventureClientParticipants: getInitialContacts(),
    };

    props.setProductDemos([...props.productDemos, newProductDemo]);
  };

  const handleDelete = (index: number) => {
    if (props.productDemos[index].id) {
      props.setProductDemoIdsToDelete((prevValue) => [
        ...prevValue,
        props.productDemos[index].id,
      ]);
    }
    const newDemo = [...props.productDemos];
    newDemo.splice(index, 1);
    props.setProductDemos(newDemo);
  };

  return (
    <Fragment>
      <TableContainer className={classes.demoContainer}>
        <Table aria-label="Product Demos">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell width="30%">Startup</TableCell>
              <TableCell width="30%">Date</TableCell>
              <TableCell width="40%">Link to join the meeting</TableCell>
              {props.editMode && <TableCell width="10%" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.productDemos.map((productDemo, index: number) => (
              <Row
                index={index}
                key={index}
                project={props.project}
                productDemo={productDemo}
                ventureClientParticipants={
                  productDemo.ventureClientParticipants
                }
                editMode={props.editMode}
                onEdit={(productDemo) => handleEdit(productDemo, index)}
                onDelete={() => handleDelete(index)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.editMode && noQualifiedStartups && (
        <Button onClick={handleAdd} data-testid="addProductDemo">
          <AddIcon sx={{ fontSize: 25 }} /> Product Demo
        </Button>
      )}
    </Fragment>
  );
}

interface RowProps {
  index: number;
  project: Project;
  productDemo: ProductDemo;
  ventureClientParticipants: ClientContactDTO[];
  editMode: boolean;
  onEdit: (productDemo: ProductDemo) => void;
  onDelete: () => void;
}

function addClientContactOrderIds(
  contacts: ClientContactDTO[]
): ClientContactDTO[] {
  contacts.forEach((contact, index) => {
    if (contact.ProductDemosClientContact) {
      contact.ProductDemosClientContact.orderId = index + 1;
    } else {
      contact.ProductDemosClientContact = { orderId: index + 1 };
    }
  });
  return contacts;
}

function addStartupContactOrderIds(
  contacts: StartupContactDTO[]
): StartupContactDTO[] {
  contacts.forEach((contact, index) => {
    if (contact.ProductDemosStartupContact) {
      contact.ProductDemosStartupContact.orderId = index + 1;
    } else {
      contact.ProductDemosStartupContact = { orderId: index + 1 };
    }
  });
  return contacts;
}

export const exportedForTesting = {
  addClientContactOrderIds,
  addStartupContactOrderIds,
};

function Row(props: RowProps): ReactElement {
  const [open, setOpen] = useState(false);

  const handleVentureClientParticipantsChange = (
    contacts: ClientContactDTO[]
  ) => {
    props.onEdit({
      ...props.productDemo,
      ventureClientParticipants: addClientContactOrderIds(contacts),
    });
  };

  const handleStartupParticipantsChange = (contacts: StartupContactDTO[]) => {
    props.onEdit({
      ...props.productDemo,
      startupParticipants: addStartupContactOrderIds(contacts),
    });
  };

  return (
    <Fragment>
      <TableRow
        data-testid={"demo-" + props.productDemo.id}
        sx={{ "& > *": { borderBottom: "none" } }}
        key={props.productDemo.id}
      >
        <TableCell>
          <IconButton
            data-testid={`button-expand-product-demo-${props.productDemo.id}`}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen((prevState) => !prevState)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <SelectInput
            fullWidth
            id={"startupId-" + props.index}
            selectValues={
              props.project.opportunities
                ?.filter((opportunity) => opportunity.isQualified)
                .map((opportunity) => {
                  return { id: opportunity.id, name: opportunity.startup.name };
                }) || []
            }
            value={props.productDemo.opportunityId}
            editMode={props.editMode}
            required
            onChange={(e) =>
              props.onEdit({
                ...props.productDemo,
                opportunityId: parseInt(e.target.value),
              })
            }
          />
        </TableCell>
        <TableCell>
          <CustomTextField
            id={"date-" + props.index}
            type="date"
            value={props.productDemo.date}
            fullWidth
            editMode={props.editMode}
            onChange={(e) =>
              props.onEdit({
                ...props.productDemo,
                date: e.target.value || null,
              })
            }
          />
        </TableCell>
        <TableCell>
          <CustomTextField
            id={"link-" + props.index}
            value={props.productDemo.location}
            editMode={props.editMode}
            fullWidth
            onChange={(e) =>
              props.onEdit({ ...props.productDemo, location: e.target.value })
            }
          />
        </TableCell>
        {props.editMode && (
          <TableCell>
            <div id={"delete-demo-" + props.index} onClick={props.onDelete}>
              <IconButton edge="end" aria-label="delete" size="large">
                <DeleteIcon />
              </IconButton>
            </div>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ClientContactSelect
                  labelText="Venture Client Participants"
                  ventureClientId={props.project.businessUnit?.ventureClient.id}
                  onChange={handleVentureClientParticipantsChange}
                  fieldId="ventureClientParticipants"
                  editMode={props.editMode}
                  multiSelect={true}
                  contactData={props.productDemo.ventureClientParticipants}
                />
              </Grid>
              <Grid item xs={6}>
                <StartupContactSelect
                  editMode={props.editMode}
                  labelText="Startup Participants"
                  startupId={
                    props.project.opportunities?.find(
                      (opportunity) =>
                        opportunity.id == props.productDemo.opportunityId
                    )?.startupId
                  }
                  onChange={handleStartupParticipantsChange}
                  contactData={props.productDemo.startupParticipants}
                  fieldId="startupParticipants"
                  multiSelect={true}
                />
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
