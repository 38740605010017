import React, { ReactElement } from "react";
import {
  Chip,
  ChipProps,
  Step,
  StepButton,
  Stepper,
  styled,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { capitalizeFirstLetter } from "../../../../utils";
import { FunnelStage, Project } from "../../../../Types/Project";
import { funnelStages, stageScores } from "../../../../Constants/FunnelStages";

interface StyledChipProps extends ChipProps {
  $funnelStage: string;
  $isActiveStage: boolean;
  $isHigherStage: boolean;
}

const StageDaysDisplay = styled(Chip, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<StyledChipProps>(
  ({ theme, $funnelStage, $isActiveStage, $isHigherStage }) => ({
    position: "absolute",
    left:
      $funnelStage === "discover"
        ? "12.7vw"
        : $funnelStage === "assess"
        ? "12.3vw"
        : $funnelStage === "buy"
        ? "11.1vw"
        : $funnelStage === "pilot"
        ? "11.7vw"
        : "0",
    top: "50%",
    transform: "translateY(-50%)",
    height: "18px",
    borderRadius: "5px",
    backgroundColor: $isActiveStage
      ? theme.palette.primary.main
      : $isHigherStage
      ? theme.palette.grey[400]
      : "#8494E0",
    color: "white",
    fontSize: "12px",
  })
);

const useStyles = makeStyles((theme: Theme) => ({
  column_divider: {
    flexGrow: 1,
    alignSelf: "center",
    margin: "0 1% 0 1%",
    border: "1px solid",
    borderColor: theme.palette.grey[400],
  },
  funnel_stepper: {
    marginTop: theme.spacing(3),
  },
}));

interface Props {
  activeStep: number;
  setActiveStep: (arg: number) => void;
  funnelStages: FunnelStage[];
  currentStageIndex: number;
  projectData: Project;
}

function StageStepper(props: Props): ReactElement {
  const classes = useStyles();

  return (
    <Stepper
      nonLinear
      className={classes.funnel_stepper}
      activeStep={props.activeStep}
      data-testid="stage-stepper"
    >
      {props.funnelStages.map((funnelStage: FunnelStage, index) => {
        const isLast = index === props.funnelStages.length - 1;
        const isActiveStage = funnelStage === props.projectData.funnelStage;
        const isHigherStage =
          stageScores[funnelStage] > props.currentStageIndex;
        const days = props.projectData.daysInStages?.[funnelStages[index]];
        return (
          <Step
            key={funnelStage}
            sx={{ position: "relative" }}
            completed={props.currentStageIndex === index}
            disabled={props.currentStageIndex < index}
          >
            <StepButton
              color="inherit"
              onClick={() => props.setActiveStep(index)}
              data-cy={"stage-button-" + funnelStage}
            >
              {capitalizeFirstLetter(funnelStage)}
            </StepButton>
            {!isLast && (days || !isHigherStage) && (
              <StageDaysDisplay
                label={days ? `${days}d` : "N/A"}
                $funnelStage={funnelStage}
                $isActiveStage={isActiveStage}
                $isHigherStage={isHigherStage}
              />
            )}
          </Step>
        );
      })}
    </Stepper>
  );
}

export default StageStepper;
