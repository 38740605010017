import { Box, Chip, Typography, styled } from "@mui/material";
import { ReactElement, useState } from "react";
import EditPhaseModal from "./EditPhaseModal";
import ViewPhaseModal from "./ViewPhaseModal";
import Phase from "../../../../../../../Types/Phase";
import theme from "../../../../../../../theme";
import { getApproximateDuration } from "./TimeplanAndMilestones";

const PhaseColumnWrapper = styled(Box)(() => ({
  display: "flex",
  height: "56px",
}));

const PhaseColumn = styled(Box)(() => ({
  width: "45%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  position: "relative",
  padding: theme.spacing(2),
  cursor: "pointer",
}));

const ProgressBarWrapper = styled("span")(() => ({
  width: "10%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ProgressBar = styled("span")(({ theme }) => ({
  width: "1px",
  height: "150%",
  backgroundColor: theme.palette.grey[300],
}));

const DurationDisplay = styled(Chip)(() => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.customBorderRadius.xs,
  backgroundColor: "transparent",
  height: "24px",
  color: theme.palette.grey[600],
  fontSize: theme.customTypography.fontSize12,
  pointerEvents: "none",
}));

const PhaseName = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  color: theme.palette.grey[600],
}));

const hasPhaseInfo = (obj: Phase) => {
  const keysToCheck = ["name", "description"] as const;
  return keysToCheck.some((key) => !!obj[key]);
};

interface Props {
  phase: Phase;
  handlePhaseEdit: (phase: Phase) => void;
  index: number;
}

const PhaseRow = (props: Props): ReactElement => {
  const [isEditPhaseModalOpen, setIsEditPhaseModalOpen] = useState(false);
  const [isViewPhaseModalOpen, setIsViewPhaseModalOpen] = useState(false);

  const handleManageModalOpen = () => {
    if (hasPhaseInfo(props.phase)) {
      setIsViewPhaseModalOpen(true);
    } else {
      setIsEditPhaseModalOpen(true);
    }
  };

  return (
    <>
      <PhaseColumnWrapper>
        <PhaseColumn sx={{ cursor: "default" }}></PhaseColumn>
        <ProgressBarWrapper>
          <ProgressBar />
        </ProgressBarWrapper>
        <PhaseColumn
          onClick={handleManageModalOpen}
          data-testid={`phase-row-${props.phase.id}`}
        >
          <DurationDisplay
            label={getApproximateDuration(props.phase.duration)}
          />
          <PhaseName variant="caption">
            Phase {props.index}{" "}
            {props.phase.name ? "- " + props.phase.name : ""}
          </PhaseName>
        </PhaseColumn>
      </PhaseColumnWrapper>
      {isEditPhaseModalOpen && (
        <EditPhaseModal
          setModalOpen={setIsEditPhaseModalOpen}
          modalOpen={isEditPhaseModalOpen}
          handlePhaseEdit={props.handlePhaseEdit}
          phase={props.phase}
        />
      )}
      {isViewPhaseModalOpen && (
        <ViewPhaseModal
          index={props.index}
          setModalOpen={setIsViewPhaseModalOpen}
          modalOpen={isViewPhaseModalOpen}
          handlePhaseEdit={props.handlePhaseEdit}
          phase={props.phase}
        />
      )}
    </>
  );
};

export default PhaseRow;
