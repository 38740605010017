import { Box, Paper, Typography, styled } from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../theme";
import { LeadProject } from "../../../Types/LeadProject";
import ProjectLeadGridItem from "../ProjectLeadGridItem";
import ProjectLeadSummaryEditModal from "./ProjectLeadSummaryEditModal";

interface Props {
  leadProject: LeadProject;
  onRefresh: () => void;
}
const OverviewWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  overflowWrap: "anywhere",
  width: "100%",
}));

const OverviewItemWrapper = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  gap: theme.spacing(3),
  width: "100%",
}));

const TruncatedText = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
}));

const StatusSectionWrapper = styled(Paper, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $status }: { $status: string }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  backgroundColor: $status === "active" ? "#f8f8ff" : theme.palette.grey[50],
  border: `1px solid  ${
    $status === "active" ? theme.palette.primary.main : theme.palette.divider
  }`,
}));

const StatusCommentWrapper = styled(Box)(() => ({
  "& .MuiTextField-root": {
    marginBottom: 0,
  },
}));

const StatusSectionHeading = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const dateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
} as const;

export default function ProjectLeadSummary(props: Props): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);

  const dateCreated = props.leadProject?.dateCreated?.toLocaleDateString(
    "de-DE",
    dateOptions
  );

  const lastModifiedDate =
    props.leadProject?.lastModifiedDate?.toLocaleDateString(
      "de-DE",
      dateOptions
    );

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const projectStatusDescription = (status: string) => {
    switch (status) {
      case "active":
        return "Status Update";
      case "archived":
        return "Project is archived";
      case "on hold":
        return "Project is on hold";
      default:
        return `Project is ${props.leadProject.status}`;
    }
  };

  return (
    <ProjectLeadGridItem title="Lead Summary" editAction={handleModalOpen}>
      <Box width="100%">
        <OverviewWrapper data-testid="overview">
          <OverviewItemWrapper>
            <OverviewItem
              value={props.leadProject.ventureClient?.name}
              label="Venture Client"
            />
            <OverviewItem
              value={props.leadProject.projectOwner?.name}
              label="Project Owner"
            />
          </OverviewItemWrapper>
        </OverviewWrapper>
        <p>{props.leadProject.shortDescription}</p>
        {props.leadProject.statusComment && (
          <StatusSectionWrapper
            data-testid="project-status-update"
            variant="outlined"
            $status={props.leadProject.status}
          >
            <StatusSectionHeading>
              <Typography variant="subtitle2">
                {projectStatusDescription(props.leadProject.status)}
              </Typography>
              <Typography color="text.secondary" variant="caption">
                {`${lastModifiedDate ?? ""} ${
                  props.leadProject.lastModifiedBy?.name
                    ? `by ${props.leadProject.lastModifiedBy?.name}`
                    : ""
                }`}
              </Typography>
            </StatusSectionHeading>
            <StatusCommentWrapper>
              <Typography whiteSpace="pre-wrap">
                {props.leadProject.statusComment}
              </Typography>
            </StatusCommentWrapper>
          </StatusSectionWrapper>
        )}
        <Box mt={7}>
          <Typography color="text.secondary" variant="caption" component="p">
            Created: {dateCreated}
          </Typography>
          <Typography color="text.secondary" variant="caption" component="p">
            {lastModifiedDate &&
              `Last Updated: ${lastModifiedDate} ${
                props.leadProject.lastModifiedBy?.name
                  ? `by ${props.leadProject.lastModifiedBy.name}`
                  : ""
              }`}
          </Typography>
        </Box>
      </Box>
      {modalOpen && (
        <ProjectLeadSummaryEditModal
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
          leadProject={props.leadProject}
          onRefresh={props.onRefresh}
        />
      )}
    </ProjectLeadGridItem>
  );
}

interface OverviewItemProps {
  label: string;
  value: string;
}

const OverviewItem = (props: OverviewItemProps) => {
  return (
    <Box overflow="hidden" flex={1}>
      <TruncatedText variant="body1">{props.value}</TruncatedText>
      <TruncatedText color="text.secondary" variant="caption">
        {props.label}
      </TruncatedText>
    </Box>
  );
};
