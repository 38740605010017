import { createContext } from "react";
import { Role, User } from "../Types/User";

export const UserContext = createContext<User | undefined>(undefined);

export const isVentureClientUnitHead = (user: User | undefined): boolean => {
  if (user === undefined) {
    throw new Error("user is undefined");
  }

  return !!user.roles.find(
    (role: Role) => role.name === "Venture Client Unit Head"
  );
};
