import axios from "axios";
import { RejectionReason } from "../../Types/Opportunity";

export class RejectionReasonHttpService {
  public static async getRejectionReasonsByVentureClientName(): Promise<
    RejectionReason[]
  > {
    const response = await axios.get<RejectionReason[]>(
      `/api/rejection-reasons`
    );
    return response.data;
  }
}
