import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { ReactElement, useEffect, useState, Fragment } from "react";
import { BusinessUnitHttpService } from "../../Http/BusinessUnit/BusinessUnit.http.service";
import { BusinessUnit } from "../../Types/BusinessUnit";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CreateBusinessUnitModal from "./CreateBusinessUnitModal";
import BusinessUnitDetailsModal from "./BusinessUnitDetailsModal";
import EditBusinessUnitModal from "./EditBusinessUnitModal";

export default function BusinessUnitsList(): ReactElement {
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] =
    useState<BusinessUnit>();
  const [searchByName, setSearchByName] = useState<string>("");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [createBusinessUnitModalOpen, setCreateBusinessUnitModalOpen] =
    useState<boolean>(false);
  const [detailsBusinessUnitModalOpen, setDetailsBusinessUnitModalOpen] =
    useState<boolean>(false);
  const [editBusinessUnitModalOpen, setEditBusinessUnitModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    BusinessUnitHttpService.getBusinessUnits().then((data) => {
      setBusinessUnits(data);
    });
  }, [refresh]);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchByName(e.target.value);
  };

  //Organizational Unit Create View handlers
  const handleCreateBusinessUnitModalClose = () => {
    setCreateBusinessUnitModalOpen(false);
  };
  const handleCreateBusinessUnitModalOpen = () => {
    setCreateBusinessUnitModalOpen(true);
  };

  //Organizational Unit Details View handlers
  const handleDetailsBusinessUnitModalClose = () => {
    setDetailsBusinessUnitModalOpen(false);
  };

  const handleBusinessUnitDetailsModalOpen = (businessUnit: BusinessUnit) => {
    setSelectedBusinessUnit(businessUnit);
    setDetailsBusinessUnitModalOpen(true);
  };

  //Organizational Unit Edit View handlers
  const handleEditBusinessUnitModalClose = () => {
    setEditBusinessUnitModalOpen(false);
    setSelectedBusinessUnit({} as BusinessUnit);
  };

  const handleEditBusinessUnitModalOpen = () => {
    setDetailsBusinessUnitModalOpen(false);
    setEditBusinessUnitModalOpen(true);
  };

  return (
    <Fragment>
      <Box display="flex" justifyContent="flex-end" mb={2} gap={3}>
        <TextField
          id="search-business-units"
          variant="standard"
          value={searchByName}
          onChange={handleSearch}
          placeholder="search by name"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchByName ? (
                  <Button onClick={() => setSearchByName("")} id="clear-search">
                    <CloseIcon />
                  </Button>
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
        />
        <Button
          onClick={handleCreateBusinessUnitModalOpen}
          variant="outlined"
          id="add-business-unit"
        >
          + Add Organizational Unit
        </Button>
      </Box>
      <CreateBusinessUnitModal
        handleModalClose={handleCreateBusinessUnitModalClose}
        modalOpen={createBusinessUnitModalOpen}
        handleSave={handleRefresh}
      />

      <TableContainer
        id="business-units-list"
        component={Paper}
        variant="outlined"
      >
        <Table sx={{ minWidth: 200 }} aria-label="Organizational Units table">
          <TableHead>
            <TableRow>
              <TableCell>Organizational Unit Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Venture Client Program Manager</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ cursor: "pointer" }}>
            {businessUnits
              .filter((businessUnit: BusinessUnit) =>
                businessUnit.name
                  .toLocaleLowerCase()
                  .includes(searchByName.toLocaleLowerCase())
              )
              .sort((option1, option2) =>
                option1.name.localeCompare(option2.name)
              )
              .map((businessUnit: BusinessUnit) => (
                <TableRow
                  hover
                  key={businessUnit.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() =>
                    handleBusinessUnitDetailsModalOpen(businessUnit)
                  }
                >
                  <TableCell component="th">{businessUnit.name}</TableCell>
                  <TableCell>{businessUnit.shortDescription}</TableCell>
                  <TableCell>{businessUnit.mainContact?.name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedBusinessUnit && (
        <BusinessUnitDetailsModal
          businessUnit={selectedBusinessUnit}
          handleModalClose={handleDetailsBusinessUnitModalClose}
          handleEditModal={handleEditBusinessUnitModalOpen}
          modalOpen={detailsBusinessUnitModalOpen}
        />
      )}
      {selectedBusinessUnit && (
        <EditBusinessUnitModal
          handleModalClose={handleEditBusinessUnitModalClose}
          modalOpen={editBusinessUnitModalOpen}
          handleSave={handleRefresh}
          businessUnit={selectedBusinessUnit}
        />
      )}
    </Fragment>
  );
}
