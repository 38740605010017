import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  styled,
  Badge,
} from "@mui/material";
import { Opportunity } from "../../../../../../../../Types/Opportunity";
import { SolutionCluster } from "../../../../../../../../Types/SolutionCluster";
import theme from "../../../../../../../../theme";
import { ReactElement, useState } from "react";
import ManageSolutionClusterModal from "../ManageSolutionClusterModal";

import ClusterCard from "./ClusterCard";

const ActionButton = styled("div")(() => ({
  marginLeft: "auto",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

const TitleBadge = styled(Badge)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  "& span": {
    width: "25px",
  },
}));

interface SolutionClusterOverviewModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  solutionClusters: SolutionCluster[];
  opportunities: Opportunity[];
  handleSave: () => void;
}

const SolutionClusterOverviewModal = (
  props: SolutionClusterOverviewModalProps
): ReactElement => {
  const [createClusterModalOpen, setCreateClusterModalOpen] = useState(false);
  const hasNoClusters = props.solutionClusters.length === 0;

  return (
    <>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        data-testid="solution-clusters-overview-modal"
        PaperProps={{
          style: { borderRadius: theme.customBorderRadius.md },
        }}
      >
        <DialogTitle display="flex">
          <Box>
            Solution Clusters
            <TitleBadge
              badgeContent={props.solutionClusters.length}
              color="primary"
            />
          </Box>
          <ActionButton>
            <Button
              onClick={() => setCreateClusterModalOpen(true)}
              variant={hasNoClusters ? "outlined" : undefined}
            >
              Add Cluster
            </Button>
            <Button onClick={() => props.setModalOpen(false)}>Close</Button>
          </ActionButton>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          {props.solutionClusters.map((solutionCluster) => {
            const relatedOpportunities = props.opportunities.filter(
              (opportunity) =>
                opportunity.solutionClusterId === solutionCluster.id
            );

            const numberOfOpportunities = relatedOpportunities.length;

            const totalFunding = relatedOpportunities
              .reduce((acc, opp) => {
                return (
                  acc +
                  (opp.startup.totalFunding
                    ? parseFloat(opp.startup.totalFunding)
                    : 0)
                );
              }, 0)
              .toFixed(2);

            return (
              <ClusterCard
                key={solutionCluster.id}
                solutionCluster={solutionCluster}
                numberOfOpportunities={numberOfOpportunities}
                totalFunding={totalFunding}
                handleSave={props.handleSave}
              />
            );
          })}
        </DialogContent>
      </Dialog>
      {createClusterModalOpen && (
        <ManageSolutionClusterModal
          modalOpen={createClusterModalOpen}
          setModalOpen={setCreateClusterModalOpen}
          handleSave={props.handleSave}
        />
      )}
    </>
  );
};

export default SolutionClusterOverviewModal;
