import axios from "axios";
import {
  CreateRequirementDTO,
  Requirement,
  UpdateRequirementDTO,
} from "../../Types/Requirement";

export class RequirementHttpService {
  public static async createRequirement(
    requirement: CreateRequirementDTO
  ): Promise<Requirement> {
    const res = await axios.post<Requirement>(`/api/requirements`, requirement);
    return res.data;
  }

  public static async deleteRequirement(id: number): Promise<void> {
    await axios.delete<void>(`/api/requirements/${id}`);
  }

  public static async updateRequirement(
    requirement: UpdateRequirementDTO
  ): Promise<void> {
    await axios.put<void>(`/api/requirements/${requirement.id}`, requirement);
  }
}
