import { MeetingDto, Meeting } from "../../Types/Meeting";

export default class MeetingMapper {
  static map(meetingDTO: MeetingDto): Meeting {
    return {
      ...meetingDTO,
      dateStart: meetingDTO.dateStart ? new Date(meetingDTO.dateStart) : null,
    };
  }
}
