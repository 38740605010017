import { Grid } from "@mui/material";
import { Dispatch, Fragment, ReactElement } from "react";
import { Project } from "../../../../../../Types/Project";
import ClientContactSelect from "../../../../../UI/InputFields/ClientContactSelect";
import StartupContactSelect from "../../../../../UI/InputFields/StartupContactSelect";

interface Props {
  project: Project;
  editMode: boolean;
  onChange: Dispatch<Partial<Project>>;
}
export default function PilotTeamSection(props: Props): ReactElement {
  return (
    <Fragment>
      {props.project && (
        <Grid container spacing={2}>
          <Grid item>
            <ClientContactSelect
              editMode={props.editMode}
              labelText="Adoption Owner (Venture Client)"
              fieldId="adoptionOwnerId"
              ventureClientId={props.project.businessUnit?.ventureClient.id}
              onChange={props.onChange}
              contactData={props.project.adoptionOwner}
              required
            />
          </Grid>
          <Grid item>
            <ClientContactSelect
              editMode={props.editMode}
              labelText="Purchasing Contact"
              fieldId="purchasingContactId"
              ventureClientId={props.project.businessUnit?.ventureClient.id}
              onChange={props.onChange}
              contactData={props.project.purchasingContact}
            />
          </Grid>
          <Grid item>
            <StartupContactSelect
              editMode={props.editMode}
              labelText="Project Lead (Startup)"
              startupId={
                props.project.opportunities?.filter(
                  (opp) => opp.isSelectedForPilot
                )[0]?.startupId
              }
              onChange={props.onChange}
              contactData={props.project.projectLeadStartup}
              fieldId="projectLeadStartupId"
              required
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
