import { ReactElement } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { LeadProject, LeadProjectLeader } from "../../../Types/LeadProject";
import ProjectLeadLeadersTableRow from "./ProjectLeadLeadersTableRow";

interface ProjectLeadLeadersProps {
  leaders: LeadProjectLeader[];
  onRefresh: () => void;
  leadProject: LeadProject;
}

function ProjectLeadLeadersTable(props: ProjectLeadLeadersProps): ReactElement {
  const { leaders } = props;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "20%" }}>Org. Unit</TableCell>
            <TableCell style={{ width: "30%" }}>Project Leader</TableCell>
            <TableCell style={{ width: "40%" }}>Use cases</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaders?.map((projectLeadLeader) => (
            <ProjectLeadLeadersTableRow
              key={projectLeadLeader.id}
              projectLeadLeader={projectLeadLeader}
              onRefresh={props.onRefresh}
              leadProject={props.leadProject}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProjectLeadLeadersTable;
