import { Box, Button, Typography, styled } from "@mui/material";
import { ReactElement, useContext, useEffect, useState } from "react";
import ObjectiveCard from "./ObjectiveCard";
import ManageObjectiveModal from "./ManageObjectiveModal";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import { Objective } from "../../../../Types/Objective";
import { Kpi } from "../../../../Types/Kpi";

interface Props {
  projectId: number;
  objectives: Objective[];
  handleSave: () => void;
}

const ObjectivesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(4),
}));

const NewObjectiveButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  textTransform: "none",
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 3),
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function ObjectivesSection(props: Props): ReactElement {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const [objectives, setObjectives] = useState(props.objectives);
  const [createObjectiveModalOpen, setCreateObjectiveModalOpen] =
    useState(false);

  const isBuyStageActive = activeStep === 2;

  useEffect(() => {
    setObjectives(props.objectives);
  }, [props.objectives]);

  const createObjective = (newObjective: Objective) => {
    setObjectives((prevObjectives) => [
      ...prevObjectives,
      { ...newObjective, kpis: [], id: -1 },
    ]);
    props.handleSave();
  };
  const updateObjective = (updatedObjective: Objective) => {
    setObjectives((prevObjectives) =>
      prevObjectives.map((obj) =>
        obj.id === updatedObjective.id ? updatedObjective : obj
      )
    );
    props.handleSave();
  };
  const deleteObjective = (id: number) => {
    setObjectives((prevObjectives) =>
      prevObjectives.filter((obj) => obj.id !== id)
    );
    props.handleSave();
  };
  const createKpi = (newKpi: Kpi) => {
    setObjectives((prevObjectives) =>
      prevObjectives.map((obj) =>
        obj.id === newKpi.objectiveId
          ? { ...obj, kpis: [...obj.kpis, newKpi] }
          : obj
      )
    );
    props.handleSave();
  };

  const updateKpi = (objectiveId: number, updatedKpi: Kpi) => {
    setObjectives((prevObjectives) =>
      prevObjectives.map((obj) =>
        obj.id === objectiveId
          ? {
              ...obj,
              kpis: obj.kpis.map((kpi) =>
                kpi.id === updatedKpi.id ? updatedKpi : kpi
              ),
            }
          : obj
      )
    );
    props.handleSave();
  };
  const deleteKpi = (id: number) => {
    setObjectives((prevObjectives) =>
      prevObjectives.map((obj) => ({
        ...obj,
        kpis: obj.kpis.filter((kpi) => kpi.id !== id),
      }))
    );
    props.handleSave();
  };

  return (
    <>
      <Box mt={6}>
        <Header>
          <Typography variant="h6">Objectives & KPIs</Typography>
          {isBuyStageActive && (
            <NewObjectiveButton
              variant="contained"
              onClick={() => setCreateObjectiveModalOpen(true)}
            >
              New Objective
            </NewObjectiveButton>
          )}
        </Header>
        <ObjectivesContainer>
          {objectives.map((objective) => {
            return (
              <ObjectiveCard
                key={objective.id}
                objective={objective}
                createObjective={createObjective}
                updateObjective={updateObjective}
                deleteObjective={deleteObjective}
                createKpi={createKpi}
                updateKpi={updateKpi}
                deleteKpi={deleteKpi}
              />
            );
          })}
        </ObjectivesContainer>
      </Box>
      {createObjectiveModalOpen && (
        <ManageObjectiveModal
          projectId={props.projectId}
          modalOpen={createObjectiveModalOpen}
          setModalOpen={setCreateObjectiveModalOpen}
          createObjective={createObjective}
        />
      )}
    </>
  );
}
