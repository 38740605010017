import { Link as RouterLink } from "react-router-dom";
import { TableRow, TableCell, styled, Link, IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LaunchIcon from "@mui/icons-material/Launch";
import OpportunityRating from "../OpportunityRating/OpportunityRating";
import { SolutionCluster } from "../../../../../../Types/SolutionCluster";
import { Requirement } from "../../../../../../Types/Requirement";
import { ReactElement, useState } from "react";
import {
  Opportunity,
  OpportunityRating as OpportunityRatingType,
  RejectionReason,
} from "../../../../../../Types/Opportunity";
import AssignCluster from "../AssignCluster/AssignCluster";
import { DetailsViewStartupCells } from "../DetailsView/DetailsView";
import RequirementComments from "../RequirementsView/RequirementsComments/RequirementsComments";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { normalizeUrl } from "../../../../../../utils";
import DeleteModal from "../../../../../UI/Modals/DeleteModal/DeleteModal";
import HighlightStartup from "../HighlightStartup/HighlightStartup";
import { Flipped } from "react-flip-toolkit";
import RecommendStartup from "../RecommendStartup/RecommendStartup";
import { RequirementsStartupCells } from "../RequirementsView/RequirementsStartupCells";
import { SelectionRationale } from "../SelectionRationale/SelectionRationale";
import { FunnelStage } from "../../../../../../Types/Project";

const CommentIndicator = styled("tr")(({ theme }) => ({
  width: "2px",
  backgroundColor: theme.palette.primary.main,
  zIndex: 2,
  position: "relative",
  "& td": {
    height: "100%",
    padding: 0,
  },
}));

const CommentIconIndicator = styled(ChatBubbleIcon)(({ theme }) => ({
  display: "none",
  position: "absolute",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "4px",
  top: "50%",
  padding: theme.spacing(0.5),
  left: theme.spacing(-1.75),
  height: "28px",
  width: "28px",
  color: theme.palette.common.white,
}));

const StyledTableRow = styled(TableRow)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const TruncatedTableCell = styled(TableCell)(({ theme }) => ({
  columnGap: theme.spacing(1.5),
  "& .truncated-link": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
  "& button": {
    display: "none",
    marginLeft: "auto",
    cursor: "pointer",
    color: theme.palette.common.white,
  },
  "&:hover": {
    button: {
      display: "grid",
      alignContent: "center",
    },
  },
}));

interface SolutionFitMatrixOpportunityProps {
  opportunity: Opportunity;
  opportunityLogo: string | null;
  detailsView: boolean;
  clusters: SolutionCluster[];
  requirements: Requirement[];
  expandedCommentsOpportunity: number | null;
  toggleComment: () => void;
  handleRequirementCommentChange: (
    selectedOpportunity: Opportunity,
    updatedRequirementId: number,
    updatedComment: string
  ) => void;
  handleRatingChange: (option: OpportunityRatingType) => void;
  handleChangeCluster: (cluster: number) => void;
  handleChangeRecommended: (recommended: boolean) => void;
  handleRequirementChange: (
    updatedRequirementId: number,
    updatedStatus: string
  ) => void;
  handleInsightsUpdate: (updatedInsights: string) => void;
  editingOpportunity: string | null;
  setEditingOpportunity: (id: string | null) => void;
  handleSave: () => void;
  isAddingStartup: boolean;
  projectFunnelStage: FunnelStage;
  currentProjectStage: FunnelStage;
  handleOpportunityStatusChange: (opportunity: Opportunity) => void;
  highlightedOpportunities: number[];
  isSelectionReasonVisible: boolean;
  handleRejectionReasonChange: (
    updatedRejectionReasons: RejectionReason[],
    getUpdatedReasons: string
  ) => void;
}
export default function SolutionFitMatrixOpportunity(
  props: SolutionFitMatrixOpportunityProps
): ReactElement {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const isRemovable = !props.opportunity.isSelectedForPilot;

  let isSelectionDisabled = false;
  if (!["assess", "discover"].includes(props.currentProjectStage)) {
    isSelectionDisabled = true;
  }

  const isAssessStage = props.projectFunnelStage === "assess";

  return (
    <Flipped
      delayUntil="add-startup-button"
      key={props.opportunity.id}
      flipId={props.opportunity.id}
    >
      {(flippedProps) => (
        <>
          <StyledTableRow
            {...flippedProps}
            sx={{ cursor: props.detailsView ? "auto" : "pointer" }}
            data-testid={`details-table-startup-${props.opportunity.id}`}
            className={`opportunity-column ${
              props.detailsView ? "details" : "requirements"
            } ${
              props.expandedCommentsOpportunity === props.opportunity.id &&
              !props.detailsView
                ? "opened-comments"
                : ""
            }
        ${
          props.highlightedOpportunities.includes(props.opportunity.id)
            ? "highlighted-selection"
            : ""
        } 
        ${isAssessStage ? "selected-for-pilot" : ""}`}
            onClick={() => {
              !props.detailsView && !props.isAddingStartup
                ? props.toggleComment()
                : null;
            }}
          >
            <TruncatedTableCell
              className="startup-name"
              key={`startup-name-cell-${props.opportunity.id}`}
              data-testid="startup-name"
            >
              <HighlightStartup
                opportunity={props.opportunity}
                currentProjectStage={props.currentProjectStage}
                projectFunnelStage={props.projectFunnelStage}
                handleOpportunityStatusChange={
                  props.handleOpportunityStatusChange
                }
                isSelectionDisabled={isSelectionDisabled}
              />
              <RouterLink
                className="truncated-link"
                onClick={(event) => event.stopPropagation()}
                target="_blank"
                to={"/startups/" + props.opportunity.startup.id}
              >
                {props.opportunity.startup.name}
              </RouterLink>

              {props.detailsView && (
                <IconButton
                  disabled={!isRemovable}
                  data-testid={`delete-opportunity-${props.opportunity.id}`}
                  onClick={() => {
                    if (!props.isAddingStartup) {
                      setDeleteModalOpen(true);
                    }
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )}
            </TruncatedTableCell>
            <TableCell
              className="startup-link-logo"
              data-testid="startup-link"
              sx={{
                justifyContent: props.opportunityLogo
                  ? "space-between"
                  : "center",
              }}
            >
              {props.opportunityLogo && (
                <RouterLink
                  onClick={(event) => event.stopPropagation()}
                  target="_blank"
                  to={"/startups/" + props.opportunity.startup.id}
                >
                  <img
                    src={props.opportunityLogo}
                    alt="logo associated to the startup"
                  />
                </RouterLink>
              )}
              <Link
                onClick={(event) => event.stopPropagation()}
                target="_blank"
                href={normalizeUrl(props.opportunity.startup.website)}
              >
                <LaunchIcon data-testid="startup-link-icon" />
              </Link>
            </TableCell>

            <TableCell className="rating-cell">
              <OpportunityRating
                opportunity={props.opportunity}
                setEditingOpportunity={props.setEditingOpportunity}
                editingOpportunity={props.editingOpportunity}
                onChangeRating={(option) => {
                  props.handleRatingChange(option);
                }}
                isAddingStartup={props.isAddingStartup}
              />
            </TableCell>
            <TableCell
              className={`assign-cluster ${
                isAssessStage ? "" : "highlighted-border"
              }`}
            >
              <AssignCluster
                onChangeCluster={(cluster) => {
                  props.handleChangeCluster(cluster);
                }}
                setEditingOpportunity={props.setEditingOpportunity}
                editingOpportunity={props.editingOpportunity}
                clusters={props.clusters}
                opportunity={props.opportunity}
                isAddingStartup={props.isAddingStartup}
              />
            </TableCell>
            {isAssessStage && (
              <TableCell className="assign-cluster highlighted-border">
                <RecommendStartup
                  onChangeRecommended={props.handleChangeRecommended}
                  opportunity={props.opportunity}
                  isAddingStartup={props.isAddingStartup}
                />
              </TableCell>
            )}
            {props.detailsView ? (
              <DetailsViewStartupCells opportunity={props.opportunity} />
            ) : (
              <RequirementsStartupCells
                setEditingOpportunity={props.setEditingOpportunity}
                editingOpportunity={props.editingOpportunity}
                opportunity={props.opportunity}
                requirements={props.requirements}
                requirementChange={props.handleRequirementChange}
                isSelectionReasonVisible={props.isSelectionReasonVisible}
              />
            )}
            {props.isSelectionReasonVisible && (
              <SelectionRationale
                opportunity={props.opportunity}
                handleRejectionReasonChange={props.handleRejectionReasonChange}
              />
            )}
          </StyledTableRow>
          {!props.detailsView && (
            <RequirementComments
              isSelectionReasonVisible={props.isSelectionReasonVisible}
              requirements={props.requirements}
              expandedComments={props.expandedCommentsOpportunity}
              commentChange={props.handleRequirementCommentChange}
              opportunity={props.opportunity}
              toggleComment={() => {
                if (!props.isAddingStartup) {
                  props.toggleComment();
                }
              }}
              handleInsightsUpdate={(updatedInsights: string) =>
                props.handleInsightsUpdate(updatedInsights)
              }
              funnelStage={props.projectFunnelStage}
            />
          )}
          {!props.detailsView && (
            <CommentIndicator
              onClick={() => {
                if (!props.isAddingStartup) {
                  props.toggleComment();
                }
              }}
              className="requirement-comment-indicator"
            >
              <td>
                <CommentIconIndicator />
              </td>
            </CommentIndicator>
          )}
          {deleteModalOpen && (
            <DeleteModal
              id={props.opportunity.id}
              modalOpen={deleteModalOpen}
              setModalOpen={setDeleteModalOpen}
              entity="Opportunity"
              handleSave={props.handleSave}
            />
          )}
        </>
      )}
    </Flipped>
  );
}
