import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { NavLink, Prompt, useHistory, useParams } from "react-router-dom";
import { ProjectHttpService } from "../../Http/Project/Project.http.service";
import { FunnelStage, Project } from "../../Types/Project";
import AdoptStage from "./FunnelStages/Adopt/AdoptStage";
import AssessStage from "./FunnelStages/Assess/AssessStage";
import BuyStage from "./FunnelStages/Buy/BuyStage";
import DiscoverStage from "./FunnelStages/Discover/DiscoverStage";
import PilotStage from "./FunnelStages/Pilot/PilotStage";
import StageConfirmationModal from "./SharedComponents/FunnelStageComponents/StageConfirmationModal";
import StageValidator, {
  getSelectedStartupName,
} from "../../StageValidation/StageValidator";
import { GlobalProjectEditContext } from "../../Context/ProjectDetailsContext";
import { useSnackbar } from "notistack";
import Bookmark from "../UI/InputFields/Bookmark";
import theme from "../../theme";
import { funnelStages } from "../../Constants/FunnelStages";
import StageStepper from "./SharedComponents/FunnelStageComponents/StageStepper";
import StageValidationModal from "./SharedComponents/FunnelStageComponents/StageValidationModal";
import FunnelButton from "./SharedComponents/FunnelStageComponents/FunnelButton";
import UnsavedChangesModal from "./UnsavedChangesModal";
import { Location } from "history";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  back: {
    display: "inline-flex",
    alignItems: "center",
  },
  bookmarkIcon: {
    marginInline: "6px",
    "& > span:nth-of-type(1)": {
      padding: "6px",
    },
    "& svg": {
      fontSize: "20px",
      fill: theme.palette.text.secondary,
    },
  },
}));

export default function ProjectDetailsPage(): ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const [refresh, setRefresh] = useState(false);
  const [projectData, setProjectData] = useState<Project>();
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const [selectedStep, setSelectedStep] = useState<number | null>(null);
  const [validationDialogOpen, setValidationDialogOpen] =
    useState<boolean>(false);
  const [proceedToBuyDialogOpen, setProceedToBuyDialogOpen] = useState(false);
  const [globalEditMode, setGlobalEditMode] = useState(false);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);
  const [navigationCallback, setNavigationCallback] = useState<
    (() => void) | null
  >();
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [shouldSaveProject, setShouldSaveProject] = useState(false);

  useEffect(() => {
    ProjectHttpService.getProjectById(id)
      .then((data) => {
        setProjectData(data);
        // this enables proper initial load of an actual stage
        let stepToDisplay = funnelStages.indexOf(data.funnelStage);
        // if the current step is not the actual funnel stage, this allows to not jump back to the proper stage
        // on saving the project (since this effect is triggered on project save)
        if (selectedStep !== null) {
          stepToDisplay = selectedStep;
        }

        if (data?.funnelStage === "discover") {
          setActiveStep(0);
        } else {
          setActiveStep(stepToDisplay);
        }
      })
      .catch(() => {
        history.push("/projects");
      });
  }, [refresh]);

  useEffect(() => {
    setSelectedStep(activeStep);
  }, [activeStep]);

  useEffect(() => {
    if (discardChanges || shouldSaveProject) {
      if (navigationCallback) {
        navigationCallback();
        setNavigationCallback(null);
        setNextLocation(null);
        setDiscardChanges(false);
        setShouldSaveProject(false);
        setGlobalEditMode(false);
      } else if (nextLocation) {
        history.push(nextLocation.pathname);
      }
    }
  }, [discardChanges, shouldSaveProject]);

  const handleBlockedNavigation = (callback?: () => void): boolean => {
    if (!globalEditMode) {
      if (!callback) return true;
      else callback();
    } else if (!discardChanges && !shouldSaveProject) {
      setUnsavedModalOpen(true);
      if (callback) setNavigationCallback(() => () => callback());
      return false;
    }
    setGlobalEditMode(false);
    if (!callback) return true;
    else callback();
    return false;
  };

  const getCurrentFunnelStageIndex = () => {
    if (projectData) {
      return funnelStages.indexOf(projectData.funnelStage);
    }
    return 0;
  };

  const getNextFunnelStage = (): FunnelStage => {
    return funnelStages[getCurrentFunnelStageIndex() + 1];
  };

  const overrideValidationRules = async () => {
    if (projectData?.funnelStage === "assess") {
      const selectedStartupName = getSelectedStartupName(projectData);
      if (!selectedStartupName) {
        return enqueueSnackbar("Please select the startup for Pilot", {
          variant: "error",
        });
      }
    }

    setSelectedStep(null);
    await setFunnelStage(getNextFunnelStage());
    setValidationDialogOpen(false);
  };

  // Check stage validation before moving to a new stage
  // Get confirmation before moving project to Buy stage
  const moveProjectToTheNewStage = async () => {
    if (projectData) {
      if (StageValidator.getInvalidProperties(projectData).length > 0) {
        setValidationDialogOpen(true);
      } else if (getNextFunnelStage() === "buy" && !proceedToBuyDialogOpen) {
        setProceedToBuyDialogOpen(true);
      } else {
        await setFunnelStage(getNextFunnelStage());
      }
    }
  };

  const setFunnelStage = async (funnelStage: FunnelStage) => {
    if (projectData) {
      await ProjectHttpService.updateProject({
        ...projectData,
        funnelStage: funnelStage,
      });
      handleSave();
    }
  };

  const handleSave = (withScroll = true) => {
    setRefresh((prevRefresh) => !prevRefresh);
    if (withScroll) {
      window.scrollTo({ behavior: "smooth", top: 0 });
    }
  };

  const handleSaveNoScroll = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };

  const handleBookmark = async () => {
    if (projectData) {
      try {
        await ProjectHttpService.updateProject({
          ...projectData,
          bookmarked: !projectData.bookmarked,
        });
        handleSave();
      } catch (error) {
        enqueueSnackbar("Something went wrong with bookmarking", {
          variant: "error",
        });
      }
    }
  };

  return (
    <Fragment>
      <GlobalProjectEditContext.Provider
        value={{
          globalEditMode,
          setGlobalEditMode,
          activeStep,
          shouldSaveProject,
          setShouldSaveProject,
        }}
      >
        <UnsavedChangesModal
          modalOpen={unsavedModalOpen}
          setModalOpen={setUnsavedModalOpen}
          setDiscardChanges={setDiscardChanges}
          setShouldSaveProject={setShouldSaveProject}
        />
        <Prompt
          when={true}
          message={(location) => {
            setNextLocation(location);
            return handleBlockedNavigation();
          }}
        />
        <Box className={classes.root}>
          <Box display="flex" flexGrow={1} justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={theme.spacing(2)}>
              <NavLink to={"/projects"} className={classes.back}>
                <ArrowBackIcon color={"primary"} />
                <Typography variant={"h5"} style={{ marginLeft: "20px" }}>
                  {projectData ? projectData.name : "Loading...."}
                </Typography>
              </NavLink>
              {projectData && (
                <Bookmark
                  className={classes.bookmarkIcon}
                  checked={projectData.bookmarked}
                  handleBookmark={handleBookmark}
                />
              )}
            </Box>
            {!globalEditMode && projectData && activeStep !== null && (
              <FunnelButton
                projectData={projectData}
                activeStep={activeStep}
                setFunnelStage={setFunnelStage}
                moveProjectToTheNewStage={moveProjectToTheNewStage}
                getCurrentFunnelStageIndex={getCurrentFunnelStageIndex}
                getNextFunnelStage={getNextFunnelStage}
              />
            )}
          </Box>
          {projectData && activeStep !== null && (
            <StageStepper
              activeStep={activeStep}
              setActiveStep={(step) =>
                handleBlockedNavigation(() => setActiveStep(step))
              }
              funnelStages={funnelStages}
              currentStageIndex={getCurrentFunnelStageIndex()}
              projectData={projectData}
            />
          )}

          {activeStep === -1 && projectData && (
            <DiscoverStage
              projectData={projectData}
              handleSave={handleSave}
              handleSaveNoScroll={handleSaveNoScroll}
              handleChangeTab={handleBlockedNavigation}
            />
          )}
          {activeStep === 0 && projectData && (
            <DiscoverStage
              projectData={projectData}
              handleSave={handleSave}
              handleSaveNoScroll={handleSaveNoScroll}
              handleChangeTab={handleBlockedNavigation}
            />
          )}
          {activeStep === 1 && projectData && (
            <AssessStage
              projectData={projectData}
              handleSave={handleSave}
              handleSaveNoScroll={handleSaveNoScroll}
              handleChangeTab={handleBlockedNavigation}
            />
          )}
          {activeStep === 2 && projectData && (
            <BuyStage
              projectData={projectData}
              handleSave={handleSave}
              handleChangeTab={handleBlockedNavigation}
            />
          )}
          {activeStep === 3 && projectData && (
            <PilotStage
              projectData={projectData}
              handleSave={handleSave}
              handleChangeTab={handleBlockedNavigation}
            />
          )}
          {activeStep === 4 && projectData && (
            <AdoptStage
              projectData={projectData}
              handleSave={handleSave}
              handleChangeTab={handleBlockedNavigation}
            />
          )}
        </Box>
        {projectData && validationDialogOpen && (
          <StageValidationModal
            projectData={projectData}
            setValidationDialogOpen={setValidationDialogOpen}
            modalOpen={validationDialogOpen}
            overrideValidationRules={overrideValidationRules}
          />
        )}
        {projectData && (
          <StageConfirmationModal
            setModalOpen={setProceedToBuyDialogOpen}
            modalOpen={proceedToBuyDialogOpen}
            moveProject={moveProjectToTheNewStage}
            projectData={projectData}
          />
        )}
      </GlobalProjectEditContext.Provider>
    </Fragment>
  );
}
