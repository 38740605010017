import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { ClientContactDTO, ProjectLeadMatch } from "../../Types/ClientContact";
import Phone from "@mui/icons-material/Phone";
import Email from "@mui/icons-material/Email";
import ClientContactHttpService from "../../Http/ClientContact/ClientContact.http.service";
import EditClientContactModal from "./EditClientContactModal";
import { useSnackbar } from "notistack";

interface ContactDetailsModalProps {
  contact: ClientContactDTO | ProjectLeadMatch;
  setModalOpen: (state: boolean) => void;
  handleSave: () => void;
  modalOpen: boolean;
  mustFetch?: boolean;
}
function DetailsClientContactModal(
  props: ContactDetailsModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [contact, setContact] = useState(props.contact);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.mustFetch) {
      ClientContactHttpService.getContactById(props.contact.id)
        .then((res) => setContact(res))
        .catch(() => {
          props.setModalOpen(false);
          enqueueSnackbar("Contact not found", {
            variant: "error",
          });
        })
        .finally(() => setLoading(false));
    }
  }, []);

  if (props.mustFetch && loading) {
    return (
      <Dialog fullScreen={fullScreen} open={loading}>
        <Box sx={{ p: 3 }}>
          <CircularProgress data-testid="client-contact-loader" />
        </Box>
      </Dialog>
    );
  } else {
    return (
      <>
        <Dialog
          fullScreen={fullScreen}
          open={props.modalOpen}
          onClose={() => props.setModalOpen(false)}
          data-testid="client-details-view"
        >
          <Box sx={{ px: 3, py: 1, minWidth: 600 }}>
            <DialogTitle mt={0.75}>
              {contact.name}{" "}
              {contact.gender && (
                <Typography variant="caption">({contact.gender})</Typography>
              )}
              {contact.title && (
                <Typography fontWeight={300}>{contact.title}</Typography>
              )}
            </DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
              <Box
                display="flex"
                alignItems="center"
                rowGap={0.5}
                columnGap={2}
                mb={4}
                flexWrap="wrap"
              >
                {contact.departmentCode && (
                  <Typography>{contact.departmentCode}</Typography>
                )}
                {contact.departmentCode && contact.businessUnit.name && (
                  <Typography fontWeight={300}>|</Typography>
                )}
                <Typography>{contact.businessUnit.name}</Typography>
                {contact.businessUnit.ventureClient.name && (
                  <Typography fontWeight={300}>|</Typography>
                )}
                <Typography>
                  {contact.businessUnit.ventureClient.name}
                </Typography>
              </Box>
              {contact.phone && (
                <Box
                  display="flex"
                  alignItems="center"
                  gap={3}
                  mb={1.5}
                  data-testid="phone-field"
                >
                  <Phone />
                  <Typography color={theme.palette.primary.main}>
                    {contact.phone}
                  </Typography>
                </Box>
              )}
              {contact.email && (
                <Box
                  display="flex"
                  alignItems="center"
                  gap={3}
                  mb={1}
                  data-testid="email-field"
                >
                  <Email />
                  <Typography color={theme.palette.primary.main}>
                    {contact.email}
                  </Typography>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => props.setModalOpen(false)}
                id="close-client-details-view"
                sx={{ color: "black" }}
              >
                Close
              </Button>
              <Button
                variant={"outlined"}
                onClick={() => setEditModalOpen(true)}
                color="primary"
                id="edit-client-details-view"
              >
                Edit
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        {editModalOpen && (
          <EditClientContactModal
            contact={contact}
            setModalOpen={setEditModalOpen}
            modalOpen={editModalOpen}
            handleSave={props.handleSave}
          />
        )}
      </>
    );
  }
}

export default DetailsClientContactModal;
