import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  TextField,
  styled,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useState, useReducer, Reducer, ReactElement, useContext } from "react";
import { useSnackbar } from "notistack";
import theme from "../../../../../../../theme";
import { MeetingHttpService } from "../../../../../../../Http/Meeting/Meeting.Http.service";
import { getErrorMessage } from "../../../../../../../utils";
import { GlobalProjectEditContext } from "../../../../../../../Context/ProjectDetailsContext";
import { ClientContactDTO } from "../../../../../../../Types/ClientContact";
import { StartupContactDTO } from "../../../../../../../Types/StartupContact";
import ClientContactSelect from "../../../../../../UI/InputFields/ClientContactSelect";
import StartupContactSelect from "../../../../../../UI/InputFields/StartupContactSelect";
import CustomTextField from "../../../../../../UI/InputFields/CustomTextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MeetingDatePicker from "../../../../../../UI/InputFields/MeetingDatePicker";
import { Meeting } from "../../../../../../../Types/Meeting";
import RichTextEditor from "../../../../../../UI/InputFields/RichTextEditor";
import { Project } from "../../../../../../../Types/Project";

const ActionButtons = styled("div")(() => ({
  marginLeft: "auto",
  height: "38px",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  margin: theme.spacing(3, 0, 2, 0),
  flexWrap: "wrap",
}));

const ContactWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  "& > div": {
    width: "100%",
    minWidth: "auto",
  },
}));

const DateLocationWrapper = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(2),
  width: "100%",
  "& .MuiFormControl-root": {
    minWidth: "auto",
    marginBlock: 0,
  },
  "& .MuiFormControl-root:first-of-type": {
    flex: "0.5",
  },
}));

const DeliverablesWrapper = styled(Box)(() => ({
  width: "100%",
  "& div.MuiFormControl-root": {
    margin: 0,
  },
  "& #deliverables": {
    fontSize: theme.customTypography.fontSize12,
  },
}));

const getInitialContacts = (project: Project) => {
  const initialContacts = [
    project.projectLeader,
    ...project.projectTeam,
    project.programManager,
    project.adoptionOwner,
    project.purchasingContact,
    project.projectLeadStartup,
  ];
  const contactsMap = new Map();
  initialContacts.forEach((contact) => {
    if (contact && !contactsMap.has(contact.id)) {
      contactsMap.set(contact.id, contact);
    }
  });
  const uniqueContacts = Array.from(contactsMap.values());
  return uniqueContacts;
};

interface Props {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  meeting?: Meeting;
  handleMeetingEdit?: (
    meeting: Meeting,
    isPushMeetingsEnabled: boolean
  ) => void;
  handleMeetingCreate?: (meeting: Meeting) => void;
  project?: Project;
  startupId: number;
  ventureClientId: number;
  maxDate: Date | null;
  minDate: Date | null;
}

const EditMeetingModal = (props: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isPushMeetingsEnabled, setIsPushMeetingsEnabled] = useState(false);
  const isBuyStage = activeStep === 2;
  const isPilotStage = activeStep === 3;
  const [newMeeting, setNewMeeting] = useReducer<
    Reducer<Meeting, Partial<Meeting>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    props.meeting ||
      ({
        projectId: props.project?.id,
        type: "Milestone",
        startupStakeholders: [],
        ventureClientStakeholders: props.project
          ? getInitialContacts(props.project)
          : [],
        isInitial: isBuyStage ? true : false,
      } as unknown as Meeting)
  );

  const isCreateMode = !props.meeting;
  const meetingType = props.meeting?.type;
  const meetingDisplayType =
    meetingType === "Kick-off" ? "Kickoff" : meetingType;

  const showError = () => {
    return enqueueSnackbar(`Please enter a date`, {
      variant: "error",
    });
  };

  const editMeeting = async () => {
    if (!newMeeting.dateStart) return showError();
    props.handleMeetingEdit &&
      props.handleMeetingEdit(newMeeting, isPushMeetingsEnabled);
    props.setModalOpen(false);
  };

  const createMeeting = async () => {
    if (!newMeeting.dateStart) return showError();

    setIsLoading(true);
    await MeetingHttpService.createMeeting(newMeeting)
      .then((createdMeeting: Meeting) => {
        props.handleMeetingCreate?.(createdMeeting);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not create meeting: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  return (
    <Dialog
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      maxWidth="sm"
      fullWidth
      data-testid="edit-meeting-modal"
    >
      <DialogTitle display="flex">
        {meetingDisplayType} Meeting
        <ActionButtons>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <Button onClick={() => props.setModalOpen(false)}>Close</Button>
              <Button onClick={isCreateMode ? createMeeting : editMeeting}>
                Save
              </Button>
            </>
          )}
        </ActionButtons>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Box padding={`${theme.spacing(2)} ${theme.spacing(3)}`}>
          <FormContainer>
            <TextField
              label="Title"
              value={newMeeting.title || ""}
              variant="outlined"
              onChange={(event) => setNewMeeting({ title: event.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              inputProps={{ "data-testid": "meeting-title-input" }}
            />
            <DateLocationWrapper>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en"
              >
                <MeetingDatePicker
                  value={newMeeting.dateStart || null}
                  onChange={(value, isPushMeetingsEnabled) => {
                    setNewMeeting({ dateStart: value });
                    setIsPushMeetingsEnabled(isPushMeetingsEnabled);
                  }}
                  minDate={props.minDate}
                  maxDate={props.maxDate}
                />
              </LocalizationProvider>
              <TextField
                label="Location"
                value={newMeeting.location || ""}
                variant="outlined"
                onChange={(event) =>
                  setNewMeeting({ location: event.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ "data-testid": "meeting-location-input" }}
                sx={{ flex: 1 }}
              />
            </DateLocationWrapper>
            <DeliverablesWrapper>
              <CustomTextField
                id="deliverables"
                dataTestId="deliverables"
                label="Deliverables"
                multiline
                value={newMeeting.deliverables || ""}
                onChange={(event) =>
                  setNewMeeting({ deliverables: event.target.value })
                }
                fullWidth
                minRows={4}
                editMode={true}
              />
            </DeliverablesWrapper>
          </FormContainer>

          <ContactWrapper>
            <ClientContactSelect
              labelText="Venture Client Stakeholders"
              ventureClientId={props.ventureClientId}
              onChange={(contacts: ClientContactDTO[]) =>
                setNewMeeting({
                  ...newMeeting,
                  ventureClientStakeholders: contacts,
                })
              }
              fieldId="ventureClientStakeholders"
              multiSelect={true}
              editMode={true}
              contactData={
                props.meeting?.ventureClientStakeholders ||
                newMeeting.ventureClientStakeholders
              }
            />

            <StartupContactSelect
              editMode={true}
              labelText="Startup Stakeholders"
              startupId={props.startupId}
              onChange={(contacts: StartupContactDTO[]) =>
                setNewMeeting({
                  ...newMeeting,
                  startupStakeholders: contacts,
                })
              }
              contactData={props.meeting?.startupStakeholders || []}
              fieldId="startupStakeholders"
              multiSelect={true}
            />
          </ContactWrapper>
        </Box>
        {isPilotStage && (
          <>
            <Divider />
            <Box padding={`${theme.spacing(2)} ${theme.spacing(3)}`}>
              <Typography
                variant="body2"
                color={theme.palette.grey[900]}
                fontWeight={600}
              >
                Meeting Notes
              </Typography>
              <RichTextEditor
                fieldId="general-meeting-notes-rte"
                labelText="General Meeting Notes"
                editMode={true}
                fieldValue={newMeeting.generalMeetingNotes || ""}
                onChange={(value: string) =>
                  setNewMeeting({ ...newMeeting, generalMeetingNotes: value })
                }
              />
              <RichTextEditor
                fieldId="next-steps-rte"
                labelText="Next Steps"
                editMode={true}
                fieldValue={newMeeting.nextSteps || ""}
                onChange={(value: string) =>
                  setNewMeeting({ ...newMeeting, nextSteps: value })
                }
              />
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EditMeetingModal;
