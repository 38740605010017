import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  Typography,
  TextField,
  styled,
  CircularProgress,
} from "@mui/material";
import theme from "../../../../../../../theme";
import { ReactElement, Reducer, useReducer, useState } from "react";
import { SolutionCluster } from "../../../../../../../Types/SolutionCluster";
import { SolutionClusterHttpService } from "../../../../../../../Http/SolutionCluster/SolutionCluster.http.service";
import { getErrorMessage } from "../../../../../../../utils";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

const ActionButtons = styled("div")(() => ({
  marginLeft: "auto",
  height: "38px",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
  marginBlock: theme.spacing(1, 4),
  flexWrap: "wrap",
  "& > div:first-of-type": {
    flexBasis: "100%",
  },
  "& > div:not(:first-of-type)": {
    flex: 1,
  },
}));

const Stats = styled(Box)(() => ({
  marginBottom: theme.spacing(2),
  "& > div": {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const isClusterNameEmpty = (cluster: SolutionCluster) => {
  return !cluster.name;
};

interface Props {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleSave: () => void;
  solutionCluster?: SolutionCluster;
  numberOfOpportunities?: number;
  totalFunding?: string;
  handleNewCluster?: (clusterId: number) => void;
}

const ManageSolutionClusterModal = (props: Props): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [newCluster, setNewCluster] = useReducer<
    Reducer<SolutionCluster, Partial<SolutionCluster>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    props.solutionCluster || ({ projectId: Number(id) } as SolutionCluster)
  );

  const isCreateMode = !props.solutionCluster;
  const totalStartups = isCreateMode ? 0 : props.numberOfOpportunities;
  const totalFunding = isCreateMode ? 0 : props.totalFunding;

  const handleSave = () => {
    if (isClusterNameEmpty(newCluster)) {
      return enqueueSnackbar("Please provide Cluster Name", {
        variant: "error",
      });
    }

    return isCreateMode ? createCluster() : editCluster();
  };

  const createCluster = async () => {
    setIsLoading(true);
    await SolutionClusterHttpService.createSolutionCluster(newCluster)
      .then((cluster) => {
        props.handleSave();
        props.handleNewCluster && props.handleNewCluster(cluster.id);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not create cluster: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const editCluster = async () => {
    setIsLoading(true);
    await SolutionClusterHttpService.updateSolutionCluster(newCluster)
      .then(() => {
        props.handleSave();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update Cluster: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  return (
    <>
      <Dialog
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        maxWidth="sm"
        fullWidth
        data-testid="manage-cluster-modal"
        PaperProps={{
          style: { borderRadius: theme.customBorderRadius.md },
        }}
      >
        <DialogTitle display="flex" data-testid="manage-cluster-header">
          {isCreateMode ? "New" : "Edit"} Solution Cluster
          <ActionButtons>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <Button onClick={() => props.setModalOpen(false)}>Close</Button>
                <Button onClick={handleSave}>Save</Button>
              </>
            )}
          </ActionButtons>
        </DialogTitle>
        <DialogContent dividers>
          <FormContainer data-testid="manage-cluster-form">
            <TextField
              required
              label="Cluster Name"
              value={newCluster.name || ""}
              variant="outlined"
              onChange={(event) => setNewCluster({ name: event.target.value })}
              inputProps={{ "data-testid": "cluster-name-input" }}
            />
            <TextField
              label="Cluster Description"
              value={newCluster.description || ""}
              variant="outlined"
              onChange={(event) =>
                setNewCluster({ description: event.target.value })
              }
              inputProps={{ "data-testid": "cluster-description-input" }}
              multiline
              minRows={3}
            />
          </FormContainer>

          <Stats data-testid="manage-cluster-modal-stats">
            <Box>
              <Typography variant="caption">Total Startups</Typography>
              <Typography variant="caption" fontWeight="bold">
                {totalStartups}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">Total Funding (in USD)</Typography>
              <Typography variant="caption" fontWeight="bold">
                ${totalFunding}M
              </Typography>
            </Box>
          </Stats>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ManageSolutionClusterModal;
