import {
  DashboardOutlined,
  ViewKanbanOutlined,
  Logout,
} from "@mui/icons-material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import DomainIcon from "@mui/icons-material/Domain";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Theme, useTheme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { ReactElement } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { Box } from "@mui/material";
import RocketOutlined from "@mui/icons-material/RocketOutlined";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    list_item__active: {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  })
);
const drawerWidth = 240;

interface SideNavProps {
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  setSignedIn: (x: boolean) => void;
}

export default function SideNav(props: SideNavProps): ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      props.setSignedIn(false);
      window.localStorage.clear();
      history.push("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={props.handleDrawerClose} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        sx={{ height: "100%" }}
      >
        <List>
          <NavLink
            to={"/"}
            exact
            activeClassName={classes.list_item__active}
            data-testid="navlink-home"
          >
            <ListItem>
              <ListItemIcon>
                <DashboardOutlined
                  htmlColor={
                    location.pathname === "/"
                      ? theme.palette.primary.main
                      : theme.palette.text.primary
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </NavLink>
          <NavLink
            to="/projects"
            exact
            activeClassName={classes.list_item__active}
            data-testid="navlink-projects"
          >
            <ListItem>
              <ListItemIcon>
                <ViewKanbanOutlined
                  htmlColor={
                    location.pathname.startsWith("/projects")
                      ? theme.palette.primary.main
                      : theme.palette.text.primary
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItem>
          </NavLink>
          <NavLink
            to="/startups"
            exact
            activeClassName={classes.list_item__active}
            data-testid="navlink-startups"
          >
            <ListItem>
              <ListItemIcon>
                <RocketOutlined
                  htmlColor={
                    location.pathname.startsWith("/startups")
                      ? theme.palette.primary.main
                      : theme.palette.text.primary
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Startups" />
            </ListItem>
          </NavLink>
          <NavLink
            to="/contacts"
            exact
            activeClassName={classes.list_item__active}
            data-testid="navlink-contacts"
          >
            <ListItem>
              <ListItemIcon>
                <PersonOutlineIcon
                  htmlColor={
                    location.pathname.startsWith("/contacts")
                      ? theme.palette.primary.main
                      : theme.palette.text.primary
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Contacts" />
            </ListItem>
          </NavLink>
          <NavLink
            to="/organizational-units"
            exact
            activeClassName={classes.list_item__active}
            data-testid="navlink-ventureClients"
          >
            <ListItem>
              <ListItemIcon>
                <DomainIcon
                  htmlColor={
                    location.pathname.startsWith("/venture-clients")
                      ? theme.palette.primary.main
                      : theme.palette.text.primary
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Venture Clients" />
            </ListItem>
          </NavLink>
        </List>
        <List>
          <ListItem onClick={handleSignOut} sx={{ cursor: "pointer" }}>
            <ListItemIcon>
              <Logout
                htmlColor={
                  location.pathname === "Logout"
                    ? theme.palette.primary.main
                    : theme.palette.text.primary
                }
              />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}
