import { Box, Button, IconButton, Typography, styled } from "@mui/material";
import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import AddIcon from "@mui/icons-material/Add";
import { ReactElement, useContext, useState } from "react";
import ManageObjectiveModal from "./ManageObjectiveModal";
import ManageKpiModal from "./Kpi/ManageKpiModal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import ObjectiveHttpService from "../../../../Http/Objective/Objective.Http.service";
import { Kpi } from "../../../../Types/Kpi";
import { Objective } from "../../../../Types/Objective";
import theme from "../../../../theme";
import DeleteModal from "../../../UI/Modals/DeleteModal/DeleteModal";
import KpiCard from "./Kpi/KpiCard";
import EditDeleteMenu from "../../../UI/EditDeleteMenu";

interface Props {
  objective: Objective;
  updateObjective: (objective: Objective) => void;
  deleteObjective: (id: number) => void;
  createObjective: (newObjective: Objective) => void;
  updateKpi: (objectiveId: number, updatedKpi: Kpi) => void;
  createKpi: (newKpi: Kpi) => void;
  deleteKpi: (id: number) => void;
}

const ObjectiveContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  outline: `1px solid ${theme.palette.other.surfaceSoft}`,
  borderRadius: theme.shape.borderRadius * 3,
  padding: theme.spacing(2),
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const EditButton = styled(Box)(() => ({
  marginLeft: "auto",
}));

const ObjectiveContent = styled(Box)(({ theme }) => ({
  whiteSpace: "pre-line",
  paddingInline: theme.spacing(4, 5),
  display: "flex",
  flexDirection: "column",
}));

const ObjectiveAchievementResult = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $fulfilled }: { $fulfilled: boolean | null }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  "& > p, & > svg": {
    color: $fulfilled ? theme.palette.success.main : theme.palette.error.main,
  },
}));

const MultiKpiWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
  gridTemplateColumns: "repeat(3, 1fr)",
  gridAutoFlow: "row",
  gridAutoRows: "auto",
}));

const NewKpiButton = styled(Button)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  justifyContent: "end",
  marginLeft: "auto",
  marginTop: theme.spacing(2),
}));

const ObjectiveCard = ({ objective, ...props }: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const [editObjectiveModalOpen, setEditObjectiveModalOpen] = useState(false);
  const [createKpiModalOpen, setCreateKpiModalOpen] = useState(false);
  const [deleteObjectiveModalOpen, setDeleteObjectiveModalOpen] =
    useState(false);

  const isBuyStageActive = activeStep === 2;
  const isPilotStageActive = activeStep === 3;
  const hasObjectiveAchievementResult =
    isPilotStageActive && objective.fulfilled !== null;

  // Update objective's initial fulfilled status
  const refreshObjective = async (
    kpiId: number,
    isKpiFulfilled: boolean | null
  ) => {
    if (objective.fulfilled !== null) return;

    const allKpisFulfilled = objective.kpis.every(
      (kpi) => (kpi.id === kpiId ? isKpiFulfilled : kpi.fulfilled) === true
    );

    if (allKpisFulfilled) {
      await ObjectiveHttpService.updateObjective({
        id: objective.id,
        fulfilled: true,
      } as Objective);
    }
  };

  return (
    <>
      <ObjectiveContainer data-testid="objective-card">
        <Box sx={{ flexWrap: "nowrap", display: "flex", gap: 5 }}>
          <Box sx={{ flex: 1 }}>
            <Header data-testid="objective-header">
              <ModeStandbyOutlinedIcon />
              <Typography variant="caption" color="primary">
                Objective
              </Typography>
              <EditButton>
                {isBuyStageActive ? (
                  <EditDeleteMenu
                    entity="objective"
                    setEditModalOpen={setEditObjectiveModalOpen}
                    setDeleteModalOpen={setDeleteObjectiveModalOpen}
                  />
                ) : (
                  <IconButton
                    sx={{ color: "black" }}
                    onClick={() => setEditObjectiveModalOpen(true)}
                    data-testid="objective-edit-button"
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                )}
              </EditButton>
            </Header>
            <ObjectiveContent data-testid="objective-content">
              {objective.name}
              {hasObjectiveAchievementResult && (
                <ObjectiveAchievementResult
                  $fulfilled={objective.fulfilled}
                  data-testid="objective-achievement-result"
                >
                  {objective.fulfilled ? <CheckCircleIcon /> : <CancelIcon />}
                  <Typography variant="body2">
                    {objective.fulfilled ? "Achieved" : "Not Achieved"}
                  </Typography>
                </ObjectiveAchievementResult>
              )}
            </ObjectiveContent>
          </Box>
          {objective.kpis.length === 1 && (
            <Box sx={{ flex: 1 }}>
              <KpiCard
                kpi={objective.kpis[0]}
                refreshObjective={refreshObjective}
                createKpi={props.createKpi}
                updateKpi={props.updateKpi}
                deleteKpi={props.deleteKpi}
              />
            </Box>
          )}
        </Box>
        {objective.kpis.length > 1 && (
          <MultiKpiWrapper>
            {objective.kpis.map((kpi: Kpi) => (
              <KpiCard
                key={kpi.id}
                kpi={kpi}
                refreshObjective={refreshObjective}
                updateKpi={props.updateKpi}
                createKpi={props.createKpi}
                deleteKpi={props.deleteKpi}
              />
            ))}
          </MultiKpiWrapper>
        )}
        {isBuyStageActive && (
          <NewKpiButton
            sx={{ color: theme.palette.other.secondaryAction }}
            onClick={() => setCreateKpiModalOpen(true)}
          >
            <AddIcon />
            New KPI
          </NewKpiButton>
        )}
      </ObjectiveContainer>
      {editObjectiveModalOpen && (
        <ManageObjectiveModal
          projectId={objective.projectId}
          modalOpen={editObjectiveModalOpen}
          setModalOpen={setEditObjectiveModalOpen}
          objective={objective}
          updateObjective={props.updateObjective}
          createObjective={props.createObjective}
        />
      )}
      {createKpiModalOpen && (
        <ManageKpiModal
          objectiveId={objective.id}
          modalOpen={createKpiModalOpen}
          setModalOpen={setCreateKpiModalOpen}
          updateKpi={props.updateKpi}
          createKpi={props.createKpi}
        />
      )}
      {deleteObjectiveModalOpen && (
        <DeleteModal
          id={objective.id}
          modalOpen={deleteObjectiveModalOpen}
          setModalOpen={setDeleteObjectiveModalOpen}
          entity="Objective"
          handleSave={props.deleteObjective}
        />
      )}
    </>
  );
};

export default ObjectiveCard;
