import axios from "axios";
import {
  CreateClientContactDTO,
  ClientContactDTO,
  GetClientContactParams,
} from "../../Types/ClientContact";
import { PaginationResult } from "../../Types/Common";

export default class ClientContactHttpService {
  public static async createContact(
    contact: CreateClientContactDTO
  ): Promise<void> {
    await axios.post<void>(`/api/client-contacts`, contact);
  }

  public static async deleteContact(contactId: number): Promise<void> {
    await axios.delete<void>(`/api/client-contacts/${contactId}`);
  }

  public static async updateContact(
    contact: ClientContactDTO,
    id: number
  ): Promise<void> {
    await axios.put<void>(`/api/client-contacts/${id}`, contact);
  }

  public static async getContacts(
    page: number,
    name: string
  ): Promise<PaginationResult<ClientContactDTO>> {
    const params: GetClientContactParams = { page: page };

    if (name) {
      params.name = name;
    }

    const response = await axios.get<PaginationResult<ClientContactDTO>>(
      "/api/client-contacts",
      { params: params }
    );

    return response.data;
  }

  public static async getContactById(id: number): Promise<ClientContactDTO> {
    const response = await axios.get(`/api/client-contacts/${id}`);
    return response.data;
  }

  public static async getContactsByName(
    ventureClientId: number,
    name: string
  ): Promise<ClientContactDTO[]> {
    return (
      await axios.get<ClientContactDTO[]>(`/api/client-contacts`, {
        params: { ventureClientId, name },
      })
    ).data;
  }
}
