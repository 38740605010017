import { Box, Button, Typography, styled } from "@mui/material";
import { ReactElement, useContext, useState } from "react";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import ManageKpiModal from "./ManageKpiModal";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import { Kpi } from "../../../../../Types/Kpi";
import theme from "../../../../../theme";
import DeleteModal from "../../../../UI/Modals/DeleteModal/DeleteModal";
import EditDeleteMenu from "../../../../UI/EditDeleteMenu";

interface Props {
  kpi: Kpi;
  refreshObjective: (kpiId: number, isKpiFulfilled: boolean | null) => void;
  createKpi: (newKpi: Kpi) => void;
  updateKpi: (objectiveId: number, updatedKpi: Kpi) => void;
  deleteKpi: (id: number) => void;
}

const KpiWrapper = styled(Box)(({ theme }) => ({
  outline: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius * 3,
}));

const KpiContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2, 2),
  height: "100%",
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  minHeight: "40px",
}));

const KpiContent = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-line",
  padding: theme.spacing(2, 2, 2, 0),
}));

const KpiTarget = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1.5),
  alignItems: "center",
  cursor: "pointer",
}));

const KpiTargetBuy = styled(KpiTarget)(({ theme }) => ({
  cursor: "default",
  "& > p": {
    color: theme.palette.primary.main,
  },
}));

const KpiTargetPilot = styled(KpiTarget)(({ theme }) => ({
  cursor: "default",
  marginTop: "auto",
  justifyContent: "end",
  "& > span": {
    color: theme.palette.text.secondary,
  },
}));

const KpiTargetNumericResult = styled(KpiTarget, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $fulfilled }: { $fulfilled: boolean | null }) => ({
  "& > p, & > svg": {
    color: $fulfilled ? theme.palette.success.main : theme.palette.error.main,
  },
}));

const KpiTargetBinaryResult = styled(KpiTarget, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $fulfilled }: { $fulfilled: boolean | null }) => ({
  display: "flex",
  alignItems: "center",
  "& > p, & > svg": {
    color: $fulfilled ? theme.palette.success.main : theme.palette.error.main,
  },
}));

const KpiTargetNotMeasuredResult = styled(KpiTarget)(({ theme }) => ({
  color: theme.palette.text.disabled,
  paddingBlock: theme.spacing(1),
}));

const AddAchievedValueButton = styled(Button)(({ theme }) => ({
  paddingLeft: "0",
  marginBottom: theme.spacing(2),
  color: theme.palette.other.secondaryAction,
  textTransform: "none",
  alignSelf: "start",
}));

const KpiCard = ({ kpi, ...props }: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const [editKpiModalOpen, setEditKpiModalOpen] = useState(false);
  const [deleteKpiModalOpen, setDeleteKpiModalOpen] = useState(false);

  const isBuyStageActive = activeStep === 2;
  const isPilotStageActive = activeStep === 3;
  const hasKpiNoAchievedValue =
    isPilotStageActive && kpi.fulfilled === null && kpi.isNotMeasured === false;
  const hasKpiNumericTarget =
    isPilotStageActive && kpi.fulfilled !== null && kpi.operator !== "binary";
  const hasKpiBinaryTarget =
    isPilotStageActive && kpi.fulfilled !== null && kpi.operator === "binary";
  const hasKpiNoMeasuredValue = isPilotStageActive && kpi.isNotMeasured;

  return (
    <>
      <KpiWrapper>
        <KpiContainer data-testid="kpi-card">
          <Header data-testid="kpi-header">
            <SpeedOutlinedIcon />
            <Typography variant="caption" color="primary">
              KPI
            </Typography>

            {isBuyStageActive && (
              <EditDeleteMenu
                entity="kpi"
                setEditModalOpen={setEditKpiModalOpen}
                setDeleteModalOpen={setDeleteKpiModalOpen}
              />
            )}
          </Header>
          <KpiContent variant="body2" data-testid="kpi-content">
            {kpi.description}
          </KpiContent>
          {isBuyStageActive && kpi.target ? (
            <KpiTargetBuy data-testid="kpi-target">
              <Typography>{kpi.operator}</Typography>
              <Typography fontSize="2rem">{kpi.target}</Typography>
              <Typography alignSelf="start">{kpi.unit?.name || ""}</Typography>
            </KpiTargetBuy>
          ) : hasKpiNoAchievedValue ? (
            <AddAchievedValueButton onClick={() => setEditKpiModalOpen(true)}>
              <AddIcon /> Add achieved value
            </AddAchievedValueButton>
          ) : hasKpiNumericTarget ? (
            <KpiTargetNumericResult
              data-testid="kpi-target-result"
              $fulfilled={kpi.fulfilled}
              onClick={() => setEditKpiModalOpen(true)}
            >
              {kpi.fulfilled === true ? <CheckCircleIcon /> : <CancelIcon />}
              <Typography fontSize="2rem">{kpi.measurement}</Typography>
              <Typography alignSelf="start">{kpi.unit?.name || ""}</Typography>
            </KpiTargetNumericResult>
          ) : hasKpiBinaryTarget ? (
            <KpiTargetBinaryResult
              data-testid="kpi-target-result"
              $fulfilled={kpi.fulfilled}
              onClick={() => setEditKpiModalOpen(true)}
            >
              {kpi.fulfilled ? (
                <>
                  <CheckCircleIcon />
                  <Typography variant="body2">Fulfilled</Typography>
                </>
              ) : (
                <>
                  <CancelIcon />
                  <Typography variant="body2">Not Fulfilled</Typography>
                </>
              )}
            </KpiTargetBinaryResult>
          ) : hasKpiNoMeasuredValue ? (
            <KpiTargetNotMeasuredResult
              onClick={() => setEditKpiModalOpen(true)}
            >
              <Typography variant="body2">No Value Available</Typography>
            </KpiTargetNotMeasuredResult>
          ) : (
            ""
          )}
          {isPilotStageActive && kpi.target && (
            <KpiTargetPilot data-testid="kpi-target">
              <Typography variant="caption">Target</Typography>
              <Typography
                p={theme.spacing(0.5, 1)}
                variant="caption"
                bgcolor="grey.100"
              >
                {kpi.operator} {kpi.target} {kpi.unit?.name}
              </Typography>
            </KpiTargetPilot>
          )}
        </KpiContainer>
      </KpiWrapper>
      {editKpiModalOpen && (
        <ManageKpiModal
          objectiveId={kpi.objectiveId}
          modalOpen={editKpiModalOpen}
          setModalOpen={setEditKpiModalOpen}
          kpi={kpi}
          refreshObjective={props.refreshObjective}
          updateKpi={props.updateKpi}
          createKpi={props.createKpi}
        />
      )}
      {deleteKpiModalOpen && (
        <DeleteModal
          id={kpi.id}
          modalOpen={deleteKpiModalOpen}
          setModalOpen={setDeleteKpiModalOpen}
          entity="KPI"
          handleSave={props.deleteKpi}
        />
      )}
    </>
  );
};

export default KpiCard;
