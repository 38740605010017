import { ReactElement, useEffect, useState } from "react";
import { LeadProject, LeadProjectUseCase } from "../../../Types/LeadProject";
import UseCaseItem from "./UseCaseItem";
import { Box, Button, styled } from "@mui/material";
import theme from "../../../theme";
import UseCase from "../../../Types/UseCase";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../utils";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";

const StyledListContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(4),
  justifyContent: "space-between",
}));

interface UseCasesListProps {
  leadProject: LeadProject;
  onRefresh: () => void;
}

export default function UseCasesList(props: UseCasesListProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [currentUseCaseId, setCurrentUseCaseId] = useState<number | null>(null);
  const [useCases, setUseCases] = useState<LeadProjectUseCase[]>(
    props.leadProject.useCases
  );

  useEffect(() => {
    setUseCases(props.leadProject.useCases);
  }, [props.leadProject]);

  const addUseCase = () => {
    setCurrentUseCaseId(-1);
    setUseCases([
      ...useCases,
      {
        id: -1,
        name: "",
        leadProjectId: props.leadProject.id,
      },
    ]);
  };

  const deleteUseCase = async (id: number) => {
    const isNew = id === -1;
    if (isNew) {
      setUseCases([...useCases.filter((useCase) => useCase.id !== id)]);
    } else {
      try {
        await LeadProjectsHttpService.deleteUseCase(props.leadProject.id, id);
        props.onRefresh();
        // eslint-disable-next-line
      } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not delete the use case: ${errorMessage}`, {
          variant: "error",
        });
      }
    }
    setCurrentUseCaseId(null);
  };

  const saveUseCase = async (newUseCase: UseCase) => {
    if (newUseCase?.name) {
      try {
        const useCase = await LeadProjectsHttpService.addUseCase({
          leadProjectId: props.leadProject.id,
          name: newUseCase.name,
        });

        setUseCases(
          useCases.map((_useCase) => {
            if (_useCase.id == -1) return useCase;
            return _useCase;
          })
        );
        // eslint-disable-next-line
      } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    } else {
      setUseCases(useCases.filter((useCase) => useCase.id !== -1));
    }
    props.onRefresh();
  };

  const updateUseCase = async (updatedUseCase: LeadProjectUseCase) => {
    const isNew = updatedUseCase.id === -1;

    if (!isNew) {
      try {
        await LeadProjectsHttpService.updateUseCase(updatedUseCase);
        // eslint-disable-next-line
      } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    }
    const orderedUseCases = useCases.map((useCase) =>
      useCase.id !== updatedUseCase.id ? useCase : updatedUseCase
    );
    setUseCases(orderedUseCases);
    props.onRefresh();
  };

  return (
    <StyledListContainer
      onBlur={() => setCurrentUseCaseId(null)}
      data-testid="lead-project-use-case-container"
    >
      <div>
        {useCases.map((useCase, index) => {
          return (
            <UseCaseItem
              key={index}
              index={index}
              currentEditUseCase={currentUseCaseId}
              onChangeEditUseCase={setCurrentUseCaseId}
              deleteUseCase={deleteUseCase}
              onUseCaseChange={updateUseCase}
              onCreateUseCase={saveUseCase}
              useCase={useCase}
            />
          );
        })}
      </div>
      <Button
        sx={{ textTransform: "none", alignSelf: "flex-end" }}
        onClick={addUseCase}
        data-testid="add-use-case-button"
      >
        Add Use Case
      </Button>
    </StyledListContainer>
  );
}
