import { Dispatch, ReactElement } from "react";
import { Project } from "../../../../Types/Project";
import Box from "@mui/material/Box";
import { SelectInput } from "../../../UI/InputFields/SelectInput";
import CustomTextField from "../../../UI/InputFields/CustomTextField";
import RichTextEditor from "../../../UI/InputFields/RichTextEditor";

interface Props {
  project: Project;
  editMode: boolean;
  onChange: Dispatch<Partial<Project>>;
}

const startupOfferValues = [
  { id: "Event", name: "Event" },
  { id: "Media", name: "Media" },
  { id: "Other", name: "Other" },
  { id: "Referral by 27pilots", name: "Referral by 27pilots" },
  { id: "Referral by Accelerator", name: "Referral by Accelerator" },
  { id: "Referral by VC", name: "Referral by VC" },
  { id: "Referral by VCL", name: "Referral by VCL" },
  { id: "Referral by VCL Unit", name: "Referral by VCL Unit" },
  { id: "Research by 27pilots", name: "Research by 27pilots" },
  { id: "Research by Startup", name: "Research by Startup" },
  { id: "Research by VCL Unit", name: "Research by VCL Unit" },
];

export default function PilotBackgroundSection(props: Props): ReactElement {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
        <SelectInput
          labelText="Source Of Startup"
          selectValues={startupOfferValues}
          value={props.project.sourceOfStartup}
          onChange={(e) =>
            props.onChange({
              sourceOfStartup: e.target.value as string,
            })
          }
          editMode={props.editMode}
        />

        <CustomTextField
          id="sourceOfStartupComment"
          label="Source Comment"
          value={props.project.sourceOfStartupComment}
          editMode={props.editMode}
          onChange={(e) =>
            props.onChange({ sourceOfStartupComment: e.target.value })
          }
        />
      </Box>
      <RichTextEditor
        fieldId="pilotBackground"
        labelText="Pilot Background"
        editMode={props.editMode}
        fieldValue={props.project.pilotBackground}
        onChange={(value: string) => props.onChange({ pilotBackground: value })}
        required
      />
    </Box>
  );
}
