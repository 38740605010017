import { Grid } from "@mui/material";
import { Dispatch, ReactElement } from "react";
import { Project } from "../../../../../../Types/Project";
import CustomTextField from "../../../../../UI/InputFields/CustomTextField";

interface Props {
  projectId: number;
  project: Project;
  editMode: boolean;
  onChange: Dispatch<Partial<Project>>;
}

export default function PilotResourcesSection(props: Props): ReactElement {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <CustomTextField
          id="costEstimation"
          label="Cost Estimation"
          value={props.project.costEstimation}
          editMode={props.editMode}
          type="currency"
          onChange={(e) => props.onChange({ costEstimation: e.target.value })}
        />
      </Grid>
      <Grid item>
        <CustomTextField
          type="currency"
          id="firstOffer"
          label="First Startup Offer"
          editMode={props.editMode}
          value={props.project.firstOffer}
          onChange={(e) => props.onChange({ firstOffer: e.target.value })}
          required
        />
      </Grid>
      <Grid item>
        <CustomTextField
          type="currency"
          id="purchaseOrderAmount"
          label="Purchase Order Amount"
          editMode={props.editMode}
          value={props.project.purchaseOrderAmount}
          onChange={(e) =>
            props.onChange({ purchaseOrderAmount: e.target.value })
          }
          required
        />
      </Grid>
      <Grid item>
        <CustomTextField
          id="additionalResources"
          label="Additional Resources"
          editMode={props.editMode}
          value={props.project.additionalResources}
          onChange={(e) =>
            props.onChange({ additionalResources: e.target.value })
          }
          maxCharacter={255}
        />
      </Grid>
    </Grid>
  );
}
