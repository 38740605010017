import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  styled,
  Typography,
  Box,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ReactElement, useContext, useState } from "react";
import EditMeetingModal from "./EditMeetingModal";
import theme from "../../../../../../../theme";
import { Meeting } from "../../../../../../../Types/Meeting";
import ClientContactSelect from "../../../../../../UI/InputFields/ClientContactSelect";
import StartupContactSelect from "../../../../../../UI/InputFields/StartupContactSelect";
import CustomTextField from "../../../../../../UI/InputFields/CustomTextField";
import { ExpandMore } from "@mui/icons-material";
import parse from "html-react-parser";
import { GlobalProjectEditContext } from "../../../../../../../Context/ProjectDetailsContext";

const dateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
} as const;

const ActionButtons = styled("div")(() => ({
  marginLeft: "auto",
  height: "38px",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

const DateDisplayWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  gap: theme.spacing(0.5),
}));

const DateDisplay = styled(Chip)(() => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "5px",
  backgroundColor: theme.palette.primary.main,
  height: "24px",
  color: "white",
  fontSize: theme.customTypography.fontSize12,
}));

const LocationWrapper = styled(Box)(() => ({
  display: "grid",
  alignItems: "start",
  gap: theme.spacing(0.5),
}));

const ContactWrapper = styled(Box)(() => ({
  display: "flex",
  "& > div": {
    width: "50%",
    minWidth: "auto",
    fontSize: theme.customTypography.fontSize12,
  },
  "& p": {
    fontSize: theme.customTypography.fontSize12,
  },
}));

const DeliverablesWrapper = styled(Box)(() => ({
  "& div.MuiFormControl-root": {
    margin: 0,
  },
  "& #deliverables": {
    fontSize: theme.customTypography.fontSize12,
  },
}));

const MeetingNotesAccordion = styled(Accordion)(() => ({
  padding: `${theme.spacing(3)} 0`,
  boxShadow: "none",
  "&::before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: 0,
  },
}));

const MeetingNotesAccordionSummary = styled(AccordionSummary)(() => ({
  "&.MuiAccordionSummary-root": {
    minHeight: 0,
    "&.Mui-expanded": {
      marginBottom: theme.spacing(1),
    },
  },
  ".MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
  padding: `0 ${theme.spacing(3)}`,
}));

const MeetingNotesAccordionDetails = styled(AccordionDetails)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  padding: `0 ${theme.spacing(3)}`,
}));

interface Props {
  index: number | null;
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  meeting: Meeting;
  handleMeetingEdit: (meeting: Meeting, isPushMeetingsEnabled: boolean) => void;
  startupId: number;
  ventureClientId: number;
  maxDate: Date | null;
  minDate: Date | null;
}

const ViewMeetingModal = (props: Props): ReactElement => {
  const [isEditMeetingModalOpen, setIsEditMeetingModalOpen] = useState(false);
  const { activeStep } = useContext(GlobalProjectEditContext);

  const dateStart = props.meeting.dateStart
    ? new Date(props.meeting.dateStart).toLocaleDateString("de-DE", dateOptions)
    : null;

  const title = props.meeting.title;

  const meetingType = props.meeting.type;
  const isMilestone = meetingType === "Milestone";
  const meetingDisplayType =
    meetingType === "Kick-off" ? "Kickoff" : meetingType;

  const isPilotStage = activeStep === 3;

  return (
    <>
      <Dialog
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        maxWidth="sm"
        fullWidth
        data-testid="view-meeting-modal"
      >
        <DialogTitle display="flex">
          {meetingDisplayType} Meeting {isMilestone ? props.index : ""}
          <ActionButtons>
            <Button onClick={() => setIsEditMeetingModalOpen(true)}>
              Edit
            </Button>
            <Button onClick={() => props.setModalOpen(false)}>Close</Button>
          </ActionButtons>
        </DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={2}>
            {title && (
              <Typography variant="h6" data-testid="title">
                {title}
              </Typography>
            )}
            <Box display="flex" gap={3}>
              <DateDisplayWrapper>
                <Typography
                  variant="caption"
                  color={theme.palette.grey[500]}
                  fontSize={theme.customTypography.fontSize12}
                >
                  Date & Time
                </Typography>
                {dateStart ? (
                  <DateDisplay label={dateStart} />
                ) : (
                  <Typography variant="caption">-</Typography>
                )}
              </DateDisplayWrapper>
              <LocationWrapper data-testid="location-wrapper">
                <Typography
                  variant="caption"
                  color={theme.palette.grey[500]}
                  fontSize={theme.customTypography.fontSize12}
                >
                  Location
                </Typography>
                <Typography
                  variant="body2"
                  fontSize={theme.customTypography.fontSize12}
                >
                  {props.meeting.location || "-"}
                </Typography>
              </LocationWrapper>
            </Box>
            <DeliverablesWrapper>
              <CustomTextField
                id="deliverables"
                label="Deliverables"
                dataTestId="deliverables"
                multiline
                value={props.meeting.deliverables || "-"}
                inputProps={{ "data-testid": "meeting-deliverables-input" }}
                fullWidth
                editMode={false}
              />
            </DeliverablesWrapper>
          </Box>
          <ContactWrapper>
            <ClientContactSelect
              labelText="Venture Client Stakeholders"
              ventureClientId={props.ventureClientId}
              onChange={() => null}
              fieldId="ventureClientStakeholders"
              multiSelect={true}
              editMode={false}
              contactData={props.meeting?.ventureClientStakeholders}
            />

            <StartupContactSelect
              editMode={false}
              labelText="Startup Stakeholders"
              onChange={() => null}
              startupId={props.startupId}
              contactData={props.meeting?.startupStakeholders}
              fieldId="startupStakeholders"
              multiSelect={true}
            />
          </ContactWrapper>
        </DialogContent>
        {isPilotStage && (
          <MeetingNotesAccordion data-testid="meeting-notes-accordion">
            <MeetingNotesAccordionSummary expandIcon={<ExpandMore />}>
              <Typography
                variant="body2"
                fontWeight={600}
                color={theme.palette.grey[900]}
              >
                Meeting Notes
              </Typography>
            </MeetingNotesAccordionSummary>
            <MeetingNotesAccordionDetails>
              <Box>
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  General Meeting Notes
                </Typography>
                <Typography variant="caption" color={theme.palette.grey[900]}>
                  {parse(props.meeting.generalMeetingNotes ?? "-")}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  Next Steps
                </Typography>
                <Typography variant="caption" color={theme.palette.grey[900]}>
                  {parse(props.meeting.nextSteps ?? "-")}
                </Typography>
              </Box>
            </MeetingNotesAccordionDetails>
          </MeetingNotesAccordion>
        )}
      </Dialog>
      {isEditMeetingModalOpen && (
        <EditMeetingModal
          modalOpen={isEditMeetingModalOpen}
          setModalOpen={(status) => {
            setIsEditMeetingModalOpen(status);
            props.setModalOpen(status);
          }}
          meeting={props.meeting}
          handleMeetingEdit={props.handleMeetingEdit}
          startupId={props.startupId}
          ventureClientId={props.ventureClientId}
          minDate={props.minDate}
          maxDate={props.maxDate}
        />
      )}
    </>
  );
};

export default ViewMeetingModal;
