import {
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, Reducer, useReducer, Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";

import { CreateProjectLeadDTO } from "../../Types/LeadProject";
import { Workspaces } from "@mui/icons-material";
import { UserContext } from "../../Context/UserContext";
import { LeadProjectsHttpService } from "../../Http/LeadProjects/LeadProjects.http.service";

interface CreateProjectLeadModalProps {
  handleModalClose: () => void;
  modalOpen: boolean;
}

interface newProjectRequiredFields {
  Name: string;
  "Short Description": string;
}

function CreateProjectLeadModal(
  props: CreateProjectLeadModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const user = useContext(UserContext);
  const ventureClientId = user?.roles[0].ventureClientId;
  const [newProject, setNewProject] = useReducer<
    Reducer<CreateProjectLeadDTO, Partial<CreateProjectLeadDTO>>
  >((state, newState) => ({ ...state, ...newState }), {
    ventureClientId: ventureClientId,
  } as CreateProjectLeadDTO);

  const newProjectRequiredFields: newProjectRequiredFields = {
    Name: newProject.name,
    "Short Description": newProject.shortDescription,
  };

  const checkRequiredFields = () => {
    const emptyRequiredFieldsArray: string[] = [];
    for (const [key, value] of Object.entries(newProjectRequiredFields)) {
      if (("" + value).trim().length === 0 || !value) {
        emptyRequiredFieldsArray.push(key);
      }
    }
    return emptyRequiredFieldsArray;
  };

  const showError = (value: string) => {
    enqueueSnackbar(`Please enter ${value}`, {
      variant: "error",
    });
  };

  const handleCreateLead = async () => {
    const emptyRequiredFields = checkRequiredFields();
    if (emptyRequiredFields.length > 0) {
      return showError(emptyRequiredFields[0]);
    }

    const createdProjectID = await LeadProjectsHttpService.createLeadProject(
      newProject as CreateProjectLeadDTO
    );
    props.handleModalClose();
    history.push(`/lead-project/${createdProjectID}`);
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        open={props.modalOpen}
        onClose={props.handleModalClose}
        data-testid="create-new-lead-project-modal"
        sx={{
          ".MuiPaper-root": {
            padding: theme.spacing(4),
            gap: theme.spacing(3),
          },
        }}
      >
        <DialogTitle
          display="flex"
          alignItems="center"
          gap={2}
          sx={{ padding: 0 }}
        >
          <Workspaces /> Create New Project Lead
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <form noValidate autoComplete="off">
            <Typography fontWeight={500} marginBottom={theme.spacing(1.5)}>
              Basic Information
            </Typography>
            <Grid container spacing={theme.spacing(2)}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  data-testid="lead-project-name"
                  variant="outlined"
                  fullWidth
                  required
                  value={newProject.name || ""}
                  onChange={(event) =>
                    setNewProject({ name: event.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Short Description"
                  data-testid="lead-project-short-description"
                  variant="outlined"
                  fullWidth
                  required
                  value={newProject.shortDescription || ""}
                  multiline
                  rows={3}
                  onChange={(event) =>
                    setNewProject({ shortDescription: event.target.value })
                  }
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <Button onClick={props.handleModalClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleCreateLead}
            variant="contained"
            color="secondary"
            data-testid="create-lead-button"
          >
            Create Lead
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
export default CreateProjectLeadModal;
