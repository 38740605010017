import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, useState } from "react";
import theme from "../../../../theme";
import CustomTextField from "../../InputFields/CustomTextField";
import { UnitHttpService } from "../../../../Http/Unit/Unit.http.service";

interface CreateEntityByNameProps {
  name: string;
  label: string;
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  handleAdd: (createdValue: CreatedValue) => void;
  maxCharacter?: number;
  ventureClientId?: number;
}

type ValueToBeCreated = Omit<CreatedValue, "id">;

type CreatedValue = {
  id: number;
  name: string;
  ventureClientId?: number;
};

async function create(label: string, newValue: ValueToBeCreated) {
  switch (label) {
    case "Unit":
      return UnitHttpService.createUnit(newValue);
    default:
      return {} as CreatedValue;
  }
}

const CreateEntityByName = (props: CreateEntityByNameProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [newValue, setNewValue] = useState<ValueToBeCreated>({
    name: "",
  });

  const handleCreate = async () => {
    if (!newValue.name) {
      return enqueueSnackbar("No input provided", { variant: "error" });
    }

    try {
      const createdValue: CreatedValue = await create(props.label, newValue);

      // displaying the newly created value on the field once its created
      if (createdValue) {
        props.handleAdd(createdValue);
      }
      props.setModalOpen(false);
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(`Something went wrong: ${error.response.data.message}`, {
        variant: "error",
      });
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      data-testid={`${props.name}-create-modal`}
    >
      <DialogTitle>Create {props.label}</DialogTitle>
      <DialogContent>
        <Box mt={theme.spacing(1)}>
          <CustomTextField
            id={props.name}
            label={props.label}
            fullWidth
            editMode={true}
            value={newValue.name}
            onChange={(event) => {
              setNewValue({ name: event.target.value });
            }}
            {...(props.maxCharacter && {
              maxCharacter: props.maxCharacter,
            })}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => props.setModalOpen(false)}
          color="primary"
          data-testid={`${props.name}-cancel-button`}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleCreate()}
          variant="contained"
          color="secondary"
          data-testid={`${props.name}-create-button`}
        >
          Create {props.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEntityByName;
