import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Fragment,
  ReactElement,
  useEffect,
  useState,
  Reducer,
  useReducer,
  useContext,
} from "react";
import { ProductDemoHttpService } from "../../../../../Http/ProductDemos/ProductDemo.http.service";
import { ProjectHttpService } from "../../../../../Http/Project/Project.http.service";
import { SolutionClusterHttpService } from "../../../../../Http/SolutionCluster/SolutionCluster.http.service";
import { Project, RichTextCharLimit } from "../../../../../Types/Project";
import { SolutionCluster } from "../../../../../Types/SolutionCluster";
import RichTextEditor from "../../../../UI/InputFields/RichTextEditor";
import { ProductDemo } from "../../../../../Types/ProductDemo.d";
import CustomTextField from "../../../../UI/InputFields/CustomTextField";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import {
  areObjectsShallowEqual,
  isCharLimitExceeded,
} from "../../../../../utils";
import { useSnackbar } from "notistack";
import OpportunityHttpService from "../../../../../Http/Opportunity/Opportunity.Http.service";
import { Opportunity } from "../../../../../Types/Opportunity";
import AssessmentConclusion from "./Form/AssessmentConclusion/AssessmentConclusion";
import ProductDemosSection from "./Form/ProductDemosSection/ProductDemosSection";

const CHAR_LIMIT_KEY_FINDINGS = 350;
const CHAR_LIMIT_RECOMMENDATION = 350;
const CHAR_LIMIT_COMMENTS = 255;

const richTextFields: RichTextCharLimit[] = [
  {
    id: "keyFindings",
    label: "Key Problem Findings",
    charLimit: CHAR_LIMIT_KEY_FINDINGS,
  },
  {
    id: "recommendations",
    label: "Recommendations",
    charLimit: CHAR_LIMIT_RECOMMENDATION,
  },
  {
    id: "demoComments",
    label: "Comments",
    charLimit: CHAR_LIMIT_COMMENTS,
  },
];

const sortProductDemos = (a: ProductDemo, b: ProductDemo) => {
  if (a.date === null) return 1;
  if (b.date === null) return -1;
  return (
    (a.date ? new Date(a.date).getTime() : 1) -
    (b.date ? new Date(b.date).getTime() : 1)
  );
};

interface Props {
  projectData: Project;
  handleSave: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Typography: {
      marginTop: theme.spacing(6),
    },
  })
);

export default function Form(props: Props): ReactElement {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.projectData
  );
  const [productDemos, setProductDemos] = useState<ProductDemo[]>([]);
  const [opportunities, setOpportunities] = useState<Opportunity[]>(
    props.projectData.opportunities
  );
  const [productDemoIdsToDelete, setProductDemoIdsToDelete] = useState<
    number[]
  >([]);

  const [opportunitiesInConclusion, setOpportunitiesInConclusion] = useState<
    Opportunity[]
  >(
    props.projectData.opportunities.filter(
      (opp) => opp.productDemos.length > 0 || opp.inAssessmentConclusion
    )
  );

  const [solutionClusters, setSolutionClusters] = useState<SolutionCluster[]>(
    []
  );
  const [solutionClusterIdsToDelete, setSolutionClusterIdsToDelete] = useState<
    number[]
  >([]);

  const {
    setGlobalEditMode,
    globalEditMode,
    setShouldSaveProject,
    shouldSaveProject,
  } = useContext(GlobalProjectEditContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setProject(props.projectData);
    setSolutionClusters(props.projectData.solutionClusters || []);
    resetProductDemos();
    setOpportunities(props.projectData.opportunities);
    setOpportunitiesInConclusion(
      props.projectData.opportunities.filter(
        (opp) => opp.productDemos.length > 0 || opp.inAssessmentConclusion
      )
    );
  }, [props.projectData, editMode]);

  useEffect(() => {
    setGlobalEditMode(editMode);
  }, [editMode]);

  useEffect(() => {
    const saveChanges = async () => {
      if (shouldSaveProject) {
        await saveProject();
        setShouldSaveProject(false);
      }
    };
    saveChanges();
  }, [shouldSaveProject]);

  useEffect(() => {
    const updatedOpportunities = [...opportunitiesInConclusion];
    opportunities.forEach((opp) => {
      const demo = productDemos.find((demo) => demo.opportunityId === opp.id);
      const isPresent =
        opportunitiesInConclusion.findIndex(
          (opportunity) => opportunity.id === opp.id
        ) >= 0;
      if (demo && !isPresent) {
        updatedOpportunities.push({ ...opp, productDemos: [demo] });
      }
      if (demo && isPresent) {
        const index = updatedOpportunities.findIndex(
          (opp) => opp.id === demo.opportunityId
        );
        updatedOpportunities[index] = {
          ...updatedOpportunities[index],
          productDemos: [demo],
        };
      }
    });
    opportunitiesInConclusion.forEach((opp, index) => {
      if (!productDemos.map((demo) => demo.opportunityId).includes(opp.id)) {
        updatedOpportunities[index] = {
          ...updatedOpportunities[index],
          productDemos: [],
        };
      }
    });
    setOpportunitiesInConclusion(updatedOpportunities);
  }, [productDemos]);

  const toggleEditMode = () => {
    resetProductDemos();
    setSolutionClusters(props.projectData.solutionClusters || []);
    setEditMode(!editMode);
  };

  const cancelEdit = () => {
    setProject(props.projectData);
    setSolutionClusters(props.projectData.solutionClusters || []);
    resetProductDemos();
    setProductDemoIdsToDelete([]);
    setSolutionClusterIdsToDelete([]);
    setOpportunities(props.projectData.opportunities);
    setOpportunitiesInConclusion(
      props.projectData.opportunities.filter(
        (opp) => opp.productDemos.length > 0 || opp.inAssessmentConclusion
      )
    );
    setEditMode(false);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const getOpportunitiesToUpdate = () => {
    const opportunitiesToUpdate: Opportunity[] = [];

    project.opportunities.forEach((opportunity) => {
      const localOpportunity = opportunities.find(
        (localOpportunity) => localOpportunity.id === opportunity.id
      );

      if (localOpportunity) {
        !areObjectsShallowEqual(opportunity, localOpportunity) &&
          opportunitiesToUpdate.push(localOpportunity);
      }
    });
    return opportunitiesToUpdate;
  };

  const saveProject = async () => {
    for (const field of richTextFields) {
      if (isCharLimitExceeded(project[field.id] as string, field.charLimit)) {
        enqueueSnackbar(`Maximum character limit exceeded for ${field.label}`, {
          variant: "error",
        });
        return;
      }
    }

    await ProjectHttpService.updateProject(project as Project);

    await Promise.all([
      ...solutionClusters.map(async (cluster) => {
        if (cluster.id) {
          await SolutionClusterHttpService.updateSolutionCluster(cluster);
        } else {
          await SolutionClusterHttpService.createSolutionCluster(cluster);
        }
      }),

      ...solutionClusterIdsToDelete.map(async (id) => {
        await SolutionClusterHttpService.deleteSolutionCluster(id);
      }),
    ]);

    await Promise.all([
      ...productDemos.map(async (demo) => {
        if (demo.id) {
          await ProductDemoHttpService.updateProductDemo(demo);
        } else {
          await ProductDemoHttpService.createProductDemo(demo);
        }
      }),

      ...productDemoIdsToDelete.map(async (id) => {
        await ProductDemoHttpService.deleteProductDemo(id);
      }),
    ]);

    const opportunitiesToUpdate = getOpportunitiesToUpdate();
    await Promise.all(
      opportunitiesToUpdate.map(async (opportunity) => {
        await OpportunityHttpService.updateOpportunity(
          opportunity,
          opportunity.id
        );
      })
    );

    await Promise.all(
      opportunitiesInConclusion.map(async (opportunity) => {
        await OpportunityHttpService.updateOpportunity(
          opportunity,
          opportunity.id
        );
      })
    );

    const deleted = props.projectData.opportunities.filter(
      (opp) =>
        (opp.productDemos.length > 0 || opp.inAssessmentConclusion) &&
        !opportunitiesInConclusion.find((_opp) => _opp.id === opp.id)
    );

    await Promise.all(
      deleted.map(async (opportunity) => {
        await OpportunityHttpService.updateOpportunity(
          { ...opportunity, inAssessmentConclusion: false },
          opportunity.id
        );
      })
    );

    props.handleSave();
    setProductDemoIdsToDelete([]);
    setSolutionClusterIdsToDelete([]);
    setEditMode(false);
  };

  const resetProductDemos = () => {
    const demos: ProductDemo[] = [];
    props.projectData.opportunities?.forEach((opp) => {
      if (opp.productDemos) {
        demos.push(...opp.productDemos);
      }
    });
    const sortedDemos = demos.sort(sortProductDemos);
    setProductDemos(sortedDemos);
  };

  return (
    <Fragment>
      {project && (
        <form noValidate autoComplete="off">
          <Box
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Typography variant="h6">Assessment Insights</Typography>
            {!editMode && !globalEditMode && (
              <Button onClick={toggleEditMode} id="edit-form-button">
                <EditOutlinedIcon />
              </Button>
            )}
          </Box>
          <Typography variant="h6" className={classes.Typography}>
            Status Quo
          </Typography>
          <Grid container spacing={2}>
            <Grid item sx={{ flexGrow: 1 }}>
              <RichTextEditor
                editMode={editMode}
                fieldValue={project.keyFindings}
                onChange={(value: string) => setProject({ keyFindings: value })}
                labelText="Key Problem Findings"
                fieldId="keyFindings"
                required
                maxCharacter={CHAR_LIMIT_KEY_FINDINGS}
                isToolbarDisabled
              />
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <RichTextEditor
                editMode={editMode}
                fieldValue={project.recommendations}
                onChange={(value: string) =>
                  setProject({ recommendations: value })
                }
                labelText="Recommendations"
                fieldId="recommendations"
                required
                maxCharacter={CHAR_LIMIT_RECOMMENDATION}
                isToolbarDisabled
              />
            </Grid>
          </Grid>
          <Typography variant="h6" className={classes.Typography}>
            Demo Questions
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                id="demoUseCases"
                label="Use Cases"
                editMode={editMode}
                value={project.demoUseCases}
                onChange={(e) => setProject({ demoUseCases: e.target.value })}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <RichTextEditor
                editMode={editMode}
                fieldValue={project.demoQuestions}
                onChange={(value: string) =>
                  setProject({ demoQuestions: value })
                }
                labelText="Questions"
                fieldId="demoQuestions"
                isToolbarDisabled
              />
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <RichTextEditor
                editMode={editMode}
                maxCharacter={CHAR_LIMIT_COMMENTS}
                fieldValue={project.demoComments}
                onChange={(value: string) =>
                  setProject({ demoComments: value })
                }
                labelText="Comments"
                fieldId="demoComments"
                isToolbarDisabled
              />
            </Grid>
          </Grid>
          <Typography variant="h6" className={classes.Typography}>
            Product Demos
          </Typography>
          <ProductDemosSection
            project={project}
            setProductDemos={setProductDemos}
            setProductDemoIdsToDelete={setProductDemoIdsToDelete}
            productDemos={productDemos}
            editMode={editMode}
          />
          <AssessmentConclusion
            opportunities={opportunities}
            setOpportunities={setOpportunities}
            editMode={editMode}
            opportunitiesInConclusion={opportunitiesInConclusion}
            setOpportunitiesInConclusion={setOpportunitiesInConclusion}
          />
        </form>
      )}
      <Box
        sx={{
          justifyContent: "flex-end",
          flexDirection: "row",
          display: editMode ? "flex" : "none",
          m: 2,
        }}
      >
        <Button
          autoFocus
          onClick={cancelEdit}
          color="primary"
          id="cancel-project-button"
        >
          Cancel
        </Button>
        <Button
          onClick={saveProject}
          variant="contained"
          color="secondary"
          id="save-project-button"
          sx={{ marginLeft: "15px" }}
        >
          Save Project
        </Button>
      </Box>
    </Fragment>
  );
}
