import React, { useState, ReactElement } from "react";
import clsx from "clsx";
import AliceCarousel from "react-alice-carousel";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from "@mui/material";
import "react-alice-carousel/lib/alice-carousel.css";

const useStyles = makeStyles(() =>
  createStyles({
    CarouselContainer: {
      margin: "0 50px",
      position: "relative",
      height: "130px",
    },
    CarouselHandle: {
      position: "absolute",
      height: "100%",
      minWidth: "unset",
      top: 0,
    },
    CarouselHandleLeft: {
      left: "-40px",
    },
    CarouselHandleRight: {
      right: "-40px",
    },
    carouselItemContainer: {
      width: "200px",
    },
  })
);

interface CarouselProps {
  children: ReactElement[];
}
const CustomCarousel = ({ children }: CarouselProps): ReactElement => {
  const classes = useStyles();
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);

  const carouselItems = () => {
    return children.map((item, i) => (
      <div key={i} className={classes.carouselItemContainer}>
        {item}
      </div>
    ));
  };

  const cItems = carouselItems();

  const slideNext = () => {
    if (!thumbAnimation && thumbIndex < cItems.length - 1) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex + 1);
    }
  };

  const slidePrev = () => {
    if (!thumbAnimation && thumbIndex > 0) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex - 1);
    }
  };

  const syncThumbs = (e: { item: React.SetStateAction<number> }) => {
    setThumbIndex(e.item);
    setThumbAnimation(false);
  };

  return (
    <div className={classes.CarouselContainer}>
      <AliceCarousel
        activeIndex={thumbIndex}
        autoWidth
        disableDotsControls
        disableButtonsControls
        items={cItems}
        mouseTracking={false}
        touchTracking={false}
        onSlideChanged={syncThumbs}
      ></AliceCarousel>
      <Button
        onClick={slidePrev}
        className={clsx(classes.CarouselHandleLeft, classes.CarouselHandle)}
      >
        <ArrowBackIosIcon data-cy="carousel-prev" color="primary" />
      </Button>
      <Button
        onClick={slideNext}
        className={clsx(classes.CarouselHandleRight, classes.CarouselHandle)}
      >
        <ArrowForwardIosIcon data-cy="carousel-next" color="primary" />
      </Button>
    </div>
  );
};

export default CustomCarousel;
