import { Box, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ReactElement, useEffect, useState } from "react";
import theme from "../../../../theme";
import { ProjectStartupOpportunityAssociation } from "../../../../Types/Project";

const margin = { top: 10, right: 160, bottom: 10, left: 5 };
const clusterColors = [
  "hsl(67, 84%, 64%)",
  "hsl(352, 100%, 64%)",
  "hsl(240, 76%, 48%)",
  "hsl(192, 70%, 56%)",
  "hsl(240, 67%, 89%)",
  "hsl(352, 100%, 64%)",
  "hsl(240, 76%, 48%)",
];

const useStyles = makeStyles(() =>
  createStyles({
    overlay: {
      position: "absolute",
      top: 0,
      right: margin.right,
      bottom: 0,
      left: margin.left,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      pointerEvents: "none",
    },
  })
);
interface ClusterChartSectionProps {
  project: ProjectStartupOpportunityAssociation;
}
const ClusterChartSection = (props: ClusterChartSectionProps): ReactElement => {
  const classes = useStyles();
  const [chartData, setChartData] = useState<
    {
      id: string;
      label: string;
      value: number;
      color: string;
    }[]
  >([]);

  useEffect(() => {
    const data = [] as {
      id: string;
      label: string;
      value: number;
      color: string;
    }[];
    props.project.solutionClusters.forEach((cluster, index) => {
      const opportunityCount = props.project.opportunities.filter(
        (opportunity) => opportunity.solutionClusterId === cluster.id
      );
      data.push({
        id: cluster.name,
        label: cluster.name,
        value: opportunityCount.length,
        color: clusterColors[index],
      });
    });

    const opportunitiesWithoutCluster = props.project.opportunities.filter(
      (opportunity) => opportunity.solutionClusterId === null
    );

    if (opportunitiesWithoutCluster.length) {
      data.push({
        id: "Other",
        label: "Other",
        value: opportunitiesWithoutCluster.length || 0,
        color: "hsl(233, 4%, 59%)",
      });
    }

    setChartData(data);
  }, [props.project]);

  return (
    <Box
      sx={{
        pb: theme.spacing(2),
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "clamp(270px, 100%, 300px)",
          height: "170px",
        }}
      >
        <ResponsivePie
          data={chartData}
          margin={margin}
          innerRadius={0.8}
          colors={{ datum: "data.color" }}
          borderWidth={1}
          padAngle={2}
          cornerRadius={2}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.5]],
          }}
          enableArcLinkLabels={false}
          enableArcLabels={false}
          activeOuterRadiusOffset={4}
          legends={[
            {
              anchor: "right",
              direction: "column",
              translateX: 120,
              itemWidth: 110,
              itemHeight: 20,
              itemsSpacing: 11,
              symbolSize: 12,
              itemDirection: "left-to-right",
              symbolShape: "circle",
            },
          ]}
        />
        <Box className={classes.overlay} data-cy="totalRelatedStartupsCount">
          <Typography variant="h5">
            {props.project?.opportunities?.length}
          </Typography>
          <Typography variant="body2">Startups</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default ClusterChartSection;
