import { ReactElement, Fragment, useEffect, useState } from "react";
import { Startup } from "../../../../Types/Startup";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../../../../theme";
import Geocode from "react-geocode";
import { StaticGoogleMap, Marker } from "react-static-google-map";
import { formatFunding, normalizeUrl } from "../../../../utils";

interface Props {
  startup: Startup;
  startupUSPData: { label: string; value: string | number | undefined }[];
}
interface DetailSectionProps {
  header: string;
  rows: { label: string; value: string | number | undefined }[];
}

const useStyles = makeStyles(() => ({
  paperContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

function DetailSection(props: DetailSectionProps): ReactElement {
  return (
    <Grid
      marginTop={2}
      container
      item
      flexDirection="column"
      xs={12}
      sm={8}
      data-testid={`startup-${props.header}`}
    >
      <Grid item>
        <Typography paragraph variant="h6">
          {props.header}
        </Typography>
      </Grid>
      {props.rows.map((row) => (
        <Grid key={row.label} container item alignItems="center" mb={1}>
          <Grid item xs={4}>
            <Typography paragraph variant="caption" mb={0}>
              {row.label}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              paragraph
              variant="caption"
              mb={0}
              data-testid={`value-${row.label
                .replace(/\(|\)/g, "")
                .split(" ")
                .join("-")}`}
            >
              {row.value}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default function StartupDetails(props: Props): ReactElement {
  const classes = useStyles();
  const [geoCode, setGeoCode] = useState<string>();

  const foundedYear = props.startup.dateFounded
    ? props.startup.dateFounded.getFullYear()
    : "Undisclosed";

  useEffect(() => {
    const startupAddress =
      props.startup.billingStreet +
      " " +
      props.startup.billingCity +
      ", " +
      props.startup.billingState +
      ", " +
      props.startup.billingCountry +
      ", " +
      props.startup.billingPostalCode;
    if (process.env.REACT_APP_GOOGLE_API_KEY) {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
      Geocode.setLanguage("en");
      Geocode.setRegion("de");
      Geocode.fromAddress(startupAddress).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const geoCodeString = lat.toString() + " " + lng.toString();
          setGeoCode(geoCodeString);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [props.startup]);

  const fundingSectionValues = [
    {
      label: "Total Funding",
      value: `${
        props.startup?.fundingIsUndisclosed
          ? "Funding Undisclosed"
          : props.startup.totalFunding
          ? formatFunding(props.startup.totalFunding)
          : "No Funding Information"
      } `,
    },
    { label: "Funding Stage", value: props.startup?.currentInvestmentStage },
    {
      label: "Investors",
      value: props.startup?.investors
        .flatMap((investor) => investor.name)
        .join(", "),
    },
    ...(props.startup.currentInvestmentStage === "Acquired"
      ? [
          {
            label: "Parent Company",
            value: props.startup.parentCompany?.name || "",
          },
        ]
      : []),
  ];
  const productReadinessSectionValues = [
    {
      label: "Technology Readiness",
      value: props.startup?.technologyReadiness,
    },
    {
      label: "Reference Clients",
      value: props.startup?.referenceClients
        .flatMap((client) => client.name)
        .join(", "),
    },
  ];

  const contactsSectionValues = [
    {
      label: "Main Contact",
      value: props.startup?.mainContact?.name,
    },
    {
      label: "Additional Contacts",
      value: props.startup?.additionalContacts
        .flatMap((contact) => contact.name)
        .join(", "),
    },
  ];

  const operationsSectionValues = [
    {
      label: "Use Cases",
      value: props.startup?.useCases
        .flatMap((useCase) => useCase.name)
        .join(", "),
    },
    {
      label: "Client Industries",
      value: props.startup?.industries
        .flatMap((industry) => industry.name)
        .join(", "),
    },
  ];

  const dateOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  } as const;

  const lastModifiedDate = props.startup.lastModifiedDate?.toLocaleDateString(
    "de-DE",
    dateOptions
  );

  return (
    <span className={classes.paperContainer}>
      <Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Typography paragraph variant="h6">
              Startup Details
            </Typography>
            <Box
              data-testid="startup-overview"
              sx={{
                display: "flex",
                mb: theme.spacing(1),
                "& hr": {
                  mx: 1,
                  borderColor: "rgba(0, 0, 0, 0.4)",
                },
                "& p": {
                  mb: 0,
                },
              }}
            >
              <Typography paragraph variant="caption">
                Founded {foundedYear}
              </Typography>
              {props.startup?.employees && (
                <Fragment>
                  <Divider orientation="vertical" flexItem />
                  <Typography paragraph variant="caption">
                    {props.startup?.employees} Employees
                  </Typography>
                </Fragment>
              )}
              <Fragment>
                <Divider orientation="vertical" flexItem />
                <Typography paragraph variant="caption">
                  {props.startup?.fundingIsUndisclosed
                    ? "Funding Undisclosed"
                    : formatFunding(props.startup.totalFunding)}
                </Typography>
              </Fragment>
              {props.startup?.currentInvestmentStage && (
                <Fragment>
                  <Divider orientation="vertical" flexItem />
                  <Typography paragraph variant="caption">
                    {props.startup?.currentInvestmentStage}
                  </Typography>
                </Fragment>
              )}
              {props.startup?.technologyReadiness && (
                <Fragment>
                  <Divider orientation="vertical" flexItem />
                  <Typography paragraph variant="caption">
                    {props.startup?.technologyReadiness}
                  </Typography>
                </Fragment>
              )}
              {props.startup.website && (
                <Fragment>
                  <Divider orientation="vertical" flexItem />
                  <Typography paragraph variant="caption">
                    <a
                      href={normalizeUrl(props.startup.website)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.startup.website}
                    </a>
                  </Typography>
                </Fragment>
              )}
            </Box>
            {props.startup.lastModifiedBy && (
              <Fragment>
                <Divider orientation="vertical" flexItem />
                <Typography variant="caption">
                  {`Last Updated${
                    lastModifiedDate ? ": " + lastModifiedDate : ""
                  } by ${props.startup.lastModifiedBy?.name}`}
                </Typography>
              </Fragment>
            )}
          </Grid>
        </Grid>
        <Grid
          marginTop={2}
          container
          item
          flexDirection="column"
          xs={12}
          sm={8}
        >
          <Grid item>
            <Typography paragraph variant="h6">
              Location
            </Typography>
          </Grid>
          <Grid container item flexDirection="row" mb={1}>
            <Grid item xs={4}>
              <Box
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  "& p": {
                    mb: 0,
                  },
                }}
              >
                <Typography paragraph variant="caption">
                  {props.startup?.billingStreet}
                </Typography>
                <Typography paragraph variant="caption">
                  {props.startup?.billingCity}
                  {props.startup?.billingState ? ", " : ""}
                  {props.startup?.billingState}
                </Typography>
                <Typography paragraph variant="caption">
                  {props.startup?.billingCountry}
                  {props.startup?.billingPostalCode ? " " : ""}
                  {props.startup?.billingPostalCode}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={8}>
              {geoCode && (
                <Box
                  sx={{
                    borderRadius: "4px",
                    overflow: "hidden",
                  }}
                  data-cy="startup-map-container"
                >
                  <StaticGoogleMap
                    size="300x120"
                    zoom="2"
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                  >
                    <Marker location={geoCode} color="red" label="P" />
                  </StaticGoogleMap>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>

        {DetailSection({
          header: "Funding",
          rows: fundingSectionValues,
        })}
        {DetailSection({
          header: "Product Readiness",
          rows: productReadinessSectionValues,
        })}
        {DetailSection({
          header: "Operations",
          rows: operationsSectionValues,
        })}
        {DetailSection({
          header: "Unique Selling Proposition",
          rows: props.startupUSPData,
        })}
        {DetailSection({
          header: "Startup Contacts",
          rows: contactsSectionValues,
        })}
      </Fragment>
    </span>
  );
}
