import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { ReactElement, Reducer, useContext, useReducer, useState } from "react";
import { useSnackbar } from "notistack";
import { GlobalProjectEditContext } from "../../../../../../../Context/ProjectDetailsContext";
import { getErrorMessage } from "../../../../../../../utils";
import PhaseHttpService from "../../../../../../../Http/Phase/Phase.Http.service";
import Phase from "../../../../../../../Types/Phase";
import theme from "../../../../../../../theme";

const ActionButtons = styled("div")(() => ({
  marginLeft: "auto",
  height: "38px",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  marginBlock: theme.spacing(3, 4),
  flexWrap: "wrap",
}));

interface Props {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  phase?: Phase;
  handlePhaseEdit: (phase: Phase) => void;
  projectId?: number;
}

const EditPhaseModal = (props: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const { enqueueSnackbar } = useSnackbar();
  const isBuyStage = activeStep === 2;
  const [isLoading, setIsLoading] = useState(false);
  const [newPhase, setNewPhase] = useReducer<Reducer<Phase, Partial<Phase>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.phase ||
      ({
        projectId: props.projectId,
        isInitial: isBuyStage ? true : false,
      } as Phase)
  );

  const editPhase = async () => {
    setIsLoading(true);
    await PhaseHttpService.updatePhase(newPhase)
      .then(() => {
        props.handlePhaseEdit(newPhase);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update phase: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  return (
    <Dialog
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      maxWidth="sm"
      fullWidth
      data-testid="edit-phase-modal"
    >
      <DialogTitle display="flex" data-testid="edit-phase-header">
        Phase {props.phase?.name}
        <ActionButtons>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <Button onClick={() => props.setModalOpen(false)}>Close</Button>
              <Button onClick={editPhase}>Save</Button>
            </>
          )}
        </ActionButtons>
      </DialogTitle>
      <DialogContent dividers>
        <FormContainer data-testid="edit-phase-form">
          <TextField
            label="Name"
            value={newPhase.name || ""}
            variant="outlined"
            onChange={(event) => setNewPhase({ name: event.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            inputProps={{ "data-testid": "phase-name-input" }}
          />
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color={theme.palette.grey[500]}>
              Duration
            </Typography>
            <Typography variant="caption" color={theme.palette.grey[600]}>
              {props.phase?.duration || "-"} days
            </Typography>
          </Box>
          <TextField
            label="Description"
            multiline
            value={newPhase.description || ""}
            variant="outlined"
            onChange={(event) =>
              setNewPhase({ description: event.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ "data-testid": "phase-description-input" }}
            fullWidth
            minRows={4}
          />
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
};

export default EditPhaseModal;
