import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactElement, useReducer, Reducer } from "react";
import { CreateBusinessUnitDTO } from "../../Types/BusinessUnit";
import { BusinessUnitHttpService } from "../../Http/BusinessUnit/BusinessUnit.http.service";
import { useSnackbar } from "notistack";

interface CreateBusinessUnitModalProps {
  handleModalClose: () => void;
  modalOpen: boolean;
  handleSave: () => void;
}

export default function CreateBusinessUnitModal(
  props: CreateBusinessUnitModalProps
): ReactElement {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [newBusinessUnit, setNewBusinessUnit] = useReducer<
    Reducer<CreateBusinessUnitDTO, Partial<CreateBusinessUnitDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    {} as CreateBusinessUnitDTO
  );

  const isBusinessUnitNameValid = () => {
    return newBusinessUnit.name && newBusinessUnit.name.trim().length !== 0;
  };

  const handleCreateBusinessUnit = async () => {
    if (isBusinessUnitNameValid()) {
      await BusinessUnitHttpService.createBusinessUnit(newBusinessUnit);
      props.handleSave();
      props.handleModalClose();
    } else {
      enqueueSnackbar("Please enter Name", {
        variant: "error",
      });
      return false;
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={props.modalOpen}
      onClose={props.handleModalClose}
      data-testid="organizational-unit-create-modal"
    >
      <DialogTitle>New Organizational Unit</DialogTitle>
      <DialogContent>
        <Box
          noValidate
          autoComplete="off"
          component="form"
          display="flex"
          flexDirection="column"
          gap={1}
          padding={1}
        >
          <Typography variant={"h6"}>Information</Typography>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              label="Name"
              id="name"
              variant="outlined"
              fullWidth
              defaultValue=""
              onChange={(event) =>
                setNewBusinessUnit({ name: event.target.value })
              }
            />
            <TextField
              label="Short Description"
              id="shortDescription"
              variant="outlined"
              fullWidth
              defaultValue=""
              onChange={(event) =>
                setNewBusinessUnit({ shortDescription: event.target.value })
              }
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={props.handleModalClose}
          color="primary"
          id="close-business-unit-create-view"
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreateBusinessUnit}
          variant="contained"
          color="secondary"
          id="save-business-unit-create-view"
        >
          Create Organizational Unit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
