import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Chip,
  Paper,
  Step,
  StepButton,
  Stepper,
  Grid,
  Card,
  CardContent,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { NavLink, useHistory } from "react-router-dom";
import theme from "../../../../theme";
import {
  Project,
  ProjectStartupOpportunityAssociation,
} from "../../../../Types/Project";
import CustomCarousel from "../../CarouselComponent/CustomCarousel";
import CustomTextField from "../../InputFields/CustomTextField";
import ClusterChartSection from "../../../Startup/DetailsPage/ProjectSection/ClusterChartSection";
import Bookmark from "../../InputFields/Bookmark";
import { ProjectHttpService } from "../../../../Http/Project/Project.http.service";
import { useSnackbar } from "notistack";
import { GlobalLoaderContext } from "../../../../Context/LoaderContext";
import { formatFunding } from "../../../../utils";

interface ProjectDetailsModalProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean, bookmark: boolean) => void;
  handleViewDetailsClick?: () => void;
  projectId: number;
}

const dateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
} as const;

const funnelStages = ["discover", "assess", "buy", "pilot", "adopt"];

const useStyles = makeStyles<Theme, ProjectStartupOpportunityAssociation>(
  (theme: Theme) =>
    createStyles({
      statusSectionWrapper: (project) => ({
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
        backgroundColor:
          project.status === "active" ? "#f8f8ff" : theme.palette.grey[50],
        border: `1px solid  ${
          project.status === "active"
            ? theme.palette.primary.main
            : theme.palette.divider
        }`,
      }),
      statusComment: {
        marginBottom: 0,
        minWidth: "auto",
      },
      heading: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: theme.spacing(2),
      },
      relevantStartupsWrapper: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(2),
        gap: theme.spacing(3),
      },
      carouselItem: {
        width: "180px",
        margin: "10px",
        height: "110px",
        boxShadow: theme.shadows[2],
      },
      carouselCardOverview: {
        display: "flex",
        alignItems: "center",
        marginBlock: theme.spacing(1),
        gap: theme.spacing(1),
      },
      bookmarkIcon: {
        marginInline: "6px",
        "& > span:nth-of-type(1)": {
          padding: "6px",
        },
        "& svg": {
          fontSize: "20px",
          fill: "black",
        },
      },
    })
);

export default function ProjectDetailsModal(
  props: ProjectDetailsModalProps
): ReactElement {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const [project, setProject] =
    useState<ProjectStartupOpportunityAssociation>();
  const classes = useStyles(project as ProjectStartupOpportunityAssociation);

  useEffect(() => {
    setGlobalLoader(true);
    ProjectHttpService.getProjectById(props.projectId.toString(), "slim")
      .then((project) => {
        setProject(project as ProjectStartupOpportunityAssociation);
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong with opening the project modal", {
          variant: "error",
        });
      })
      .finally(() => {
        setGlobalLoader(false);
      });
  }, []);

  if (!project) {
    return <></>;
  }

  const statusCommentLastModifiedDate =
    project.projectHistoryStatusChange?.[0] &&
    new Date(
      project.projectHistoryStatusChange[0].dateTriggered
    ).toLocaleDateString("de-DE", dateOptions);

  const handleBookmark = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      await ProjectHttpService.updateProject({
        ...project,
        bookmarked: event.target.checked,
      } as Project);
      setProject({
        ...project,
        bookmarked: !event.target.checked,
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong with updating the project", {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false, project.bookmarked)}
      data-testid="related-project-details-modal"
    >
      <DialogTitle>
        <Box className={classes.heading}>
          <Typography variant="h6">{project.name}</Typography>
          <Chip
            size="small"
            label={project.status}
            variant="filled"
            color="secondary"
          />
          {project.focusAreas.map((focusArea) => (
            <Chip
              key={focusArea.id}
              size="small"
              label={focusArea.name}
              variant="outlined"
              color="primary"
            />
          ))}
          <Bookmark
            className={classes.bookmarkIcon}
            checked={project.bookmarked}
            handleBookmark={handleBookmark}
          />
        </Box>
        <Typography
          color="text.secondary"
          variant="subtitle2"
          component="div"
          mb={2}
          mt={1}
        >
          {`
          ${project.businessUnit.ventureClient.name}
          |   
          ${project.businessUnit.name}
          |    
          ${project.projectOwner?.name}`}
        </Typography>
        <Stepper activeStep={funnelStages.indexOf(project.funnelStage)}>
          {funnelStages.map((label, index) => {
            return (
              <Step
                key={label}
                completed={index <= funnelStages.indexOf(project.funnelStage)}
                disabled={true}
              >
                <StepButton>{label}</StepButton>
              </Step>
            );
          })}
        </Stepper>
      </DialogTitle>
      <DialogContent>
        <Box mt={theme.spacing(2)} display="flex" gap={3}>
          <Box
            variant="outlined"
            component={Paper}
            p={theme.spacing(2)}
            flex={2}
          >
            <Typography variant="h6" mb={theme.spacing(4)}>
              Project Background
            </Typography>
            <Grid spacing={2} container>
              <Grid item xs={6}>
                <CustomTextField
                  id="projectLeader"
                  label="Project Leader"
                  value={`${
                    project.projectLeader ? project.projectLeader.name : ""
                  } ${
                    project.projectLeader?.departmentCode
                      ? `(${project.projectLeader.departmentCode})`
                      : ""
                  }`}
                  editMode={false}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  id="programManager"
                  label="Venture Client Program Manager"
                  value={`${
                    project.programManager ? project.programManager.name : ""
                  } ${
                    project.programManager?.departmentCode
                      ? `(${project.programManager.departmentCode})`
                      : ""
                  }`}
                  editMode={false}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  id="shortDescription"
                  label="Short Description"
                  editMode={false}
                  fullWidth
                  value={project.shortDescription}
                  multiline
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={theme.spacing(2)}
            flex={1}
          >
            {statusCommentLastModifiedDate && project.statusComment && (
              <Paper
                data-testid="project-status-update"
                variant="outlined"
                className={classes.statusSectionWrapper}
              >
                <Typography variant="h6" sx={{ lineHeight: 1.2 }}>
                  {project.status === "active"
                    ? "Status Update"
                    : `Project is ${project.status}!`}
                </Typography>
                <Typography color="text.secondary" variant="caption">
                  {`${statusCommentLastModifiedDate} by ${project.projectHistoryStatusChange[0].user.name}`}
                </Typography>
                <Typography whiteSpace="pre-wrap">
                  {project.statusComment}
                </Typography>
              </Paper>
            )}
            <Box
              variant="outlined"
              component={Paper}
              p={theme.spacing(2)}
              data-testid="cluster-chart-section"
            >
              <Typography variant="h6" mb={theme.spacing(4)}>
                Assigned Startups
              </Typography>
              <Grid container>
                <ClusterChartSection project={project} />
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box
          className={classes.relevantStartupsWrapper}
          data-testid="carousel-section"
        >
          <Typography variant="h6">
            Relevant Startups ({project.opportunities.length})
          </Typography>
          {project.opportunities.length > 0 ? (
            <CustomCarousel>
              {project.opportunities.map((opportunity, index) => {
                const {
                  startup: {
                    id,
                    name,
                    dateFounded,
                    totalFunding,
                    shortDescription,
                    currentInvestmentStage,
                    fundingIsUndisclosed,
                  },
                } = opportunity;
                return (
                  <Card
                    className={classes.carouselItem}
                    key={index}
                    data-testid={`carousel-card-${id}`}
                  >
                    <NavLink
                      to={`/startups/${id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <CardContent
                        sx={{
                          padding: theme.spacing(1),
                        }}
                      >
                        <Typography color="primary" variant="subtitle2">
                          {name}
                        </Typography>
                        <Box className={classes.carouselCardOverview}>
                          {dateFounded !== null && (
                            <Typography
                              color="text.secondary"
                              sx={{
                                fontSize: "0.625rem",
                              }}
                            >
                              {new Date(dateFounded).getFullYear()}
                            </Typography>
                          )}
                          {currentInvestmentStage !== null && (
                            <Typography
                              title={currentInvestmentStage}
                              color="text.secondary"
                              sx={{
                                fontSize: "0.625rem",
                              }}
                            >
                              {currentInvestmentStage}
                            </Typography>
                          )}
                          <Typography
                            color="text.secondary"
                            sx={{
                              fontSize: "0.625rem",
                            }}
                          >
                            {fundingIsUndisclosed
                              ? "Undisclosed"
                              : totalFunding !== null
                              ? formatFunding(totalFunding)
                              : ""}
                          </Typography>
                        </Box>
                        <Typography
                          color="text.secondary"
                          variant="caption"
                          sx={{
                            fontSize: "0.625rem",
                          }}
                        >
                          {shortDescription}
                        </Typography>
                      </CardContent>
                    </NavLink>
                  </Card>
                );
              })}
            </CustomCarousel>
          ) : (
            <Typography variant="h6">No Assigned Startups</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.setModalOpen(false, project.bookmarked)}
          color="primary"
          data-testid="close-project-details"
        >
          Close
        </Button>
        <Button
          color="secondary"
          variant="contained"
          data-testid="view-project-details"
          onClick={() => history.push(`/projects/${project.id}`)}
        >
          view details
        </Button>
      </DialogActions>
    </Dialog>
  );
}
