import { Warning } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { ReactElement } from "react";
import theme from "../../theme";

interface UnsavedChangesModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  setDiscardChanges: (state: boolean) => void;
  setShouldSaveProject: (state: boolean) => void;
}

const UnsavedChangesModal = (props: UnsavedChangesModalProps): ReactElement => {
  const handleDiscardButton = () => {
    props.setModalOpen(false);
    props.setDiscardChanges(true);
  };

  const handleSaveButton = () => {
    props.setModalOpen(false);
    props.setShouldSaveProject(true);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      data-testid="unsaved-changes-modal"
    >
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent sx={{ display: "flex", gap: theme.spacing(2) }}>
        <Warning color="error" />
        <Typography>
          You have unsaved changes.
          <br />
          Would you like to save before leaving this page?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="discard-button"
          color="inherit"
          onClick={handleDiscardButton}
        >
          Discard
        </Button>
        <Button
          data-testid="save-button"
          variant="contained"
          color="secondary"
          onClick={handleSaveButton}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesModal;
