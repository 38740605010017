import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import ProjectKanban from "./Components/ProjectKanban/ProjectKanban";
import SideNav from "./Components/Navigation/SideNav";
import ApplicationBar from "./Components/Navigation/ApplicationBar";
import { ReactElement } from "react";
import { Analytics, Auth } from "aws-amplify";
import { Amplify } from "@aws-amplify/core";
import ProjectDetailsPage from "./Components/ProjectDetails/ProjectDetailsPage";
import StartupList from "./Components/Startup/StartupList";
import StartupDetailsPage from "./Components/Startup/DetailsPage/StartupDetailsPage";
import ContactList from "./Components/Contacts/ContactList";
import Authentication from "./Components/Authentication/Authentication";
import BusinessUnitsList from "./Components/BusinessUnits/BusinessUnitsList";
import Dashboard from "./Components/Dashboard/Dashboard";
import UserHttpService from "./Http/User/User.Http.service";
import { getUserRole } from "./utils";
import { GlobalLoaderContext } from "./Context/LoaderContext";
import { UserContext } from "./Context/UserContext";
import { User } from "./Types/User";
import { KanbanFilterContextProvider } from "./Context/KanbanFilterContext";
import { Box, styled } from "@mui/material";
import LeadProjectPage from "./Components/ProjectLead/ProjectLead";

interface ExtendedAuthConfig {
  userPoolId: string | undefined;
  userPoolWebClientId: string | undefined;
  identityPoolId: string | undefined;
  region: string;
  cookieStorage: {
    sameSite: string;
    secure: boolean;
  };
  oauth?: {
    domain: string | undefined;
    scope: string[];
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
  };
}

const isHolcim = window.location.origin.includes("holcim-vclms");

const baseConfig = {
  Auth: {
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: "eu-central-1",
    cookieStorage: {
      sameSite: "strict",
      secure: true,
    },
  } as ExtendedAuthConfig,
  Analytics: {
    disabled: process.env.REACT_APP_ENVIRONMENT === "production" ? false : true,
    AWSPinpoint: {
      appId: process.env.REACT_APP_AWS_PINPOINTID,
      region: "eu-central-1",
    },
  },
};

if (isHolcim) {
  baseConfig.Auth.oauth = {
    domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
    scope: ["email", "openid"],
    redirectSignIn: "https://holcim-vclms.27pilots.com/",
    redirectSignOut: "https://holcim-vclms.27pilots.com/",
    responseType: "token",
  };
}

Amplify.configure(baseConfig);

const Toolbar = styled("div")(({ theme }) => ({ ...theme.mixins.toolbar }));

const Content = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  margin: theme.spacing(2),
}));

function getFaviconEl() {
  return document.getElementById("favicon") as HTMLLinkElement;
}

export default function App(): ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const [activeLogo, setActiveLogo] = useState<string>("");
  const [activeBackground, setActiveBackground] = useState<string>("");
  const [globalLoader, setGlobalLoader] = useState<boolean>(false);
  const [activeUser, setActiveUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async () => {
        setSignedIn(true);
        if (signedIn) {
          UserHttpService.getUser(-1)
            .then((user) => {
              setActiveUser(user);
              const endpoint = {
                Demographic: {
                  Platform: "VCLMS",
                },
                userId: user.email,
                userAttributes: {
                  ventureClients: [
                    ...user.roles.map((role) => {
                      if (role.ventureClient) {
                        return role.ventureClient.name;
                      } else {
                        return "Admin";
                      }
                    }),
                  ],
                  role: [getUserRole(user)],
                  name: [user.name],
                },
              };
              Analytics.updateEndpoint(endpoint);

              setSignedIn(true);
            })
            .catch((error) => console.log(error));
        }
      })
      .catch(() => {
        setSignedIn(false);
      });
  }, [signedIn]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const setLogoAndBackground = (ventureClient: string) => {
    const environment =
      process.env.NODE_ENV === "development" ? "development" : "production";
    let baseS3Url = "";
    if (environment === "development") {
      baseS3Url = "https://vclms-frontend.s3.eu-central-1.amazonaws.com";
    } else {
      baseS3Url = "https://vclms-frontend-prod.s3.eu-central-1.amazonaws.com";
    }

    if (ventureClient.includes("siemensenergy-vclms")) {
      setActiveLogo(`${baseS3Url}/siemensEnergy-logo.png`);
      setActiveBackground(`${baseS3Url}/siemensEnergy-bg.png`);
    } else if (ventureClient.includes("holcim-vclms")) {
      setActiveLogo(`${baseS3Url}/holcim-logo.png`);
      setActiveBackground(`${baseS3Url}/holcim-bg.webp`);
    } else if (ventureClient.includes("cariad-vclms")) {
      setActiveLogo(`${baseS3Url}/cariad-logo.jpeg`);
      setActiveBackground(`${baseS3Url}/cariad-bg.JPEG`);
    } else if (ventureClient.includes("otto-vclms")) {
      setActiveLogo(`${baseS3Url}/otto-logo.png`);
      setActiveBackground(`${baseS3Url}/otto-bg.jpeg`);
    } else if (ventureClient.includes("bmwstartupgarage-vclms")) {
      setActiveLogo(`${baseS3Url}/bmwstartupgarage-logo.png`);
      setActiveBackground(`${baseS3Url}/bmwstartupgarage-bg.jpg`);
    } else if (ventureClient.includes("obi-vclms")) {
      setActiveLogo(`${baseS3Url}/obi-logo.png`);
      setActiveBackground(`${baseS3Url}/obi-bg.jpg`);
    } else if (ventureClient.includes("airbus-vclms")) {
      setActiveLogo(`${baseS3Url}/airbus-logo.png`);
      setActiveBackground(`${baseS3Url}/airbus-bg.jpg`);
    } else if (ventureClient.includes("openbosch-vclms")) {
      setActiveLogo(`${baseS3Url}/openbosch-logo.png`);
      setActiveBackground(`${baseS3Url}/openbosch-bg.jpg`);
    } else if (ventureClient.includes("stationx-vclms")) {
      setActiveLogo(`${baseS3Url}/stationx-logo.png`);
      setActiveBackground(`${baseS3Url}/stationx-bg.jpg`);
    } else if (ventureClient.includes("ksb-vclms")) {
      setActiveLogo(`${baseS3Url}/ksb-logo.png`);
      setActiveBackground(`${baseS3Url}/ksb-bg.jpg`);
    } else if (ventureClient.includes("copace-vclms")) {
      setActiveLogo(`${baseS3Url}/copace-logo.png`);
      setActiveBackground(`${baseS3Url}/copace-bg.png`);
    } else if (ventureClient.includes("mtu-vclms")) {
      setActiveLogo(`${baseS3Url}/mtu-logo.png`);
      setActiveBackground(`${baseS3Url}/mtu-bg.jpg`);
    } else if (ventureClient.includes("knauf-vclms")) {
      setActiveLogo(`${baseS3Url}/knauf-logo.png`);
      setActiveBackground(`${baseS3Url}/knauf-bg.png`);
    } else if (ventureClient.includes("vodafone-vclms")) {
      setActiveLogo(`${baseS3Url}/vodafone-logo.png`);
      setActiveBackground(`${baseS3Url}/vodafone-bg.jpeg`);
    } else if (ventureClient.includes("obr-vclms")) {
      setActiveLogo(`${baseS3Url}/obr-logo.png`);
      setActiveBackground(`${baseS3Url}/obr-bg.jpeg`);
    } else if (ventureClient.includes("siemens-di-vclms")) {
      setActiveLogo(`${baseS3Url}/siemens-di-logo.png`);
      setActiveBackground(`${baseS3Url}/siemens-di-bg.jpg`);
    } else if (ventureClient.includes("prosiebensat1-vclms")) {
      setActiveLogo(`${baseS3Url}/prosiebensat1-logo.png`);
      setActiveBackground(`${baseS3Url}/prosiebensat1-bg.jpg`);
    } else if (ventureClient.includes("forvia-vclms")) {
      setActiveLogo(`${baseS3Url}/forvia-logo.png`);
      setActiveBackground(`${baseS3Url}/forvia-bg.jpg`);
    } else if (ventureClient.includes("equinor-vclms")) {
      setActiveLogo(`${baseS3Url}/equinor-logo.png`);
      setActiveBackground(`${baseS3Url}/equinor-bg.webp`);
    } else if (ventureClient.includes("axa-vclms")) {
      setActiveLogo(`${baseS3Url}/axa-logo.png`);
      setActiveBackground(`${baseS3Url}/axa-bg.jpg`);
    } else if (ventureClient.includes("seatcode-vclms")) {
      setActiveLogo(`${baseS3Url}/seatCode-logo.png`);
      setActiveBackground(`${baseS3Url}/seatCode-bg.jpg`);
    } else if (ventureClient.includes("zurichinsurance-vclms")) {
      setActiveLogo(`${baseS3Url}/zurichinsurance-logo.png`);
      setActiveBackground(`${baseS3Url}/zurichinsurance-bg.jpg`);
    } else if (ventureClient.includes("govtechh-vclms")) {
      setActiveLogo(`${baseS3Url}/govtechh-logo.png`);
      setActiveBackground(`${baseS3Url}/govtechh-bg.jpg`);
    } else if (ventureClient.includes("lbbw-vclms")) {
      setActiveLogo(`${baseS3Url}/lbbw-logo.svg`);
      setActiveBackground(`${baseS3Url}/lbbw-bg.jpg`);
    } else if (ventureClient.includes("beyondgravity-vclms")) {
      setActiveLogo(`${baseS3Url}/beyondgravity-logo.png`);
      setActiveBackground(`${baseS3Url}/beyondgravity-bg.jpg`);
    } else if (ventureClient.includes("redbull-vclms")) {
      setActiveLogo(`${baseS3Url}/redbull-logo.png`);
      setActiveBackground(`${baseS3Url}/redbull-bg.png`);
    } else if (ventureClient.includes("kaufland-vclms")) {
      setActiveLogo(`${baseS3Url}/kaufland-logo.jpg`);
      setActiveBackground(`${baseS3Url}/kaufland-bg.jpg`);
    } else if (ventureClient.includes("vinci-vclms")) {
      setActiveLogo(`${baseS3Url}/vinci-logo.png`);
      setActiveBackground(`${baseS3Url}/vinci-bg.jpg`);
    } else if (ventureClient.includes("helm-vclms")) {
      setActiveLogo(`${baseS3Url}/helm-logo.svg`);
      setActiveBackground(`${baseS3Url}/helm-bg.jpg`);
    } else if (ventureClient.includes("siemenssi-vclms")) {
      setActiveLogo(`${baseS3Url}/siemenssi-logo.png`);
      setActiveBackground(`${baseS3Url}/siemenssi-bg.jpg`);
    } else if (ventureClient.includes("miele-vclms")) {
      setActiveLogo(`${baseS3Url}/miele-logo.png`);
      setActiveBackground(`${baseS3Url}/miele-bg.png`);
    } else if (ventureClient.includes("sportradar-vclms")) {
      setActiveLogo(`${baseS3Url}/sportradar-logo.png`);
      setActiveBackground(`${baseS3Url}/sportradar-bg.jpeg`);
    } else {
      setActiveLogo(`${baseS3Url}/27pilots-logo_white.png`);
      setActiveBackground(`${baseS3Url}/27pilots-bg.png`);
    }
  };

  const favicon = getFaviconEl();
  favicon.href = `${activeLogo?.slice(
    0,
    activeLogo.lastIndexOf("-")
  )}-favIcon.png` as string;

  useEffect(() => {
    setLogoAndBackground(window.location.origin);
  }, []);

  switch (signedIn) {
    case false:
      return (
        <Authentication
          activeBackground={activeBackground}
          setSignedIn={setSignedIn}
        />
      );
    case true:
      return (
        <UserContext.Provider value={activeUser}>
          <GlobalLoaderContext.Provider
            value={{ globalLoader, setGlobalLoader }}
          >
            <Router>
              <Box display="flex">
                <CssBaseline />
                <ApplicationBar
                  handleDrawerOpen={handleDrawerOpen}
                  open={open}
                  activeLogo={activeLogo}
                />
                <SideNav
                  handleDrawerOpen={handleDrawerOpen}
                  handleDrawerClose={handleDrawerClose}
                  open={open}
                  setOpen={setOpen}
                  setSignedIn={setSignedIn}
                />
                <KanbanFilterContextProvider>
                  <Content>
                    <Toolbar />
                    <Switch>
                      <Route exact path="/" component={Dashboard} />
                      <Route exact path="/projects" component={ProjectKanban} />
                      <Route
                        path="/projects/:id"
                        component={ProjectDetailsPage}
                      />
                      <Route
                        path="/lead-project/:id"
                        component={LeadProjectPage}
                      />
                      <Route exact path="/startups" component={StartupList} />
                      <Route
                        path="/startups/:id"
                        component={StartupDetailsPage}
                      />
                      <Route
                        exact
                        path="/organizational-units"
                        component={BusinessUnitsList}
                      />
                      <Route path="/contacts" component={ContactList} />
                    </Switch>
                  </Content>
                </KanbanFilterContextProvider>
              </Box>
            </Router>
          </GlobalLoaderContext.Provider>
        </UserContext.Provider>
      );
  }
}
