import { Box, TableCell, Typography, styled, Button } from "@mui/material";
import { ReactElement } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Requirement } from "../../../../../../Types/Requirement";
import theme from "../../../../../../theme";
import SolutionRequirementItem from "../../../../FunnelStages/Discover/DataView/SolutionRequirements/SolutionRequirementItem";
import useRequirements from "../../../../../../Hooks/useRequirements";

const RequirementAddWrapper = styled(TableCell)(() => ({
  "&.MuiTableCell-root": {
    padding: 0,
    height: theme.spacing(3),
    background: theme.palette.secondary.main,
    overflow: "hidden",
  },
  "& button": {
    color: theme.palette.common.black,
    borderRadius: 0,
    overflow: "hidden",
    fontSize: theme.typography.htmlFontSize,
    fontweight: theme.typography.fontWeightBold,
  },
}));

interface RequirementsRowLabelsProps {
  requirements: Requirement[];
  projectId: number;
  handleSaveNoScroll: () => void;
}

export default function RequirementsRowLabels(
  props: RequirementsRowLabelsProps
): ReactElement {
  const {
    requirements,
    currentEditRequirement,
    setCurrentEditRequirement,
    addRequirement,
    deleteRequirement,
    changeRequirement,
    saveRequirement,
    handleDragEnd,
  } = useRequirements({
    initialRequirements: props.requirements,
    projectId: props.projectId,
    handleSaveNoScroll: props.handleSaveNoScroll,
  });

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="requirementList">
          {(provided, snapshot) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: snapshot.isDraggingOver
                  ? theme.palette.grey[200]
                  : "none",
              }}
              sx={{
                "& > div": {
                  height: theme.spacing(13),
                  borderBottom: `1px solid ${theme.palette.secondary.light}`,
                  borderRight: `solid 1px ${theme.palette.secondary.light}`,
                  paddingRight: theme.spacing(2),
                },
                "& textarea": {
                  fontSize: theme.typography.fontSize,
                },
                "& p": {
                  fontSize: theme.customTypography.fontSize12,
                },
              }}
              onBlur={() => setCurrentEditRequirement(null)}
            >
              {requirements?.length ? (
                requirements.map((requirement, index) => (
                  <SolutionRequirementItem
                    key={requirement.id}
                    index={index}
                    currentEditRequirement={currentEditRequirement}
                    onChangeEditRequirement={setCurrentEditRequirement}
                    deleteRequirement={deleteRequirement}
                    onRequirementChange={changeRequirement}
                    onCreateRequirement={saveRequirement}
                    requirement={requirement}
                  />
                ))
              ) : (
                <TableCell className="no-requirement-cell">
                  <Typography variant="subtitle2">
                    There are no solution requirements yet.
                  </Typography>
                  <Typography variant="caption">
                    Go ahead and add the first one
                  </Typography>
                </TableCell>
              )}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      <RequirementAddWrapper>
        <Button
          onClick={addRequirement}
          disabled={!!currentEditRequirement}
          data-testid="new-requirement-button"
        >
          +
        </Button>
      </RequirementAddWrapper>
    </>
  );
}
