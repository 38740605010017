import { ReactElement } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import { CalendarMonth } from "@mui/icons-material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import theme from "../../theme";
import { LeadProject } from "../../Types/LeadProject";

interface Props {
  leadProject: LeadProject;
}

const StyledProjectCard = styled(Card, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  position: "relative",
  gap: theme.spacing(3),
}));

const StyledSection = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  padding: 0,
  "&:last-child": {
    paddingBottom: theme.spacing(0.5),
  },
}));

const StyledName = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2 /* number of lines to show */,
  WebkitBoxOrient: "vertical",
});

const StyledProjectCardDetails = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
}));

function ProjectLeadKanbanCard(props: Props): ReactElement {
  function calculateDaysBetweenDates(date1: Date, date2: Date): number {
    const _date2 = new Date(date2);
    const differenceInMs: number = Math.abs(_date2.getTime() - date1.getTime());
    const daysDifference: number = Math.ceil(
      differenceInMs / (1000 * 60 * 60 * 24)
    );
    return daysDifference;
  }
  const numberOfStartups = props.leadProject.opportunities.length;

  return (
    <NavLink to={`/lead-project/${props.leadProject.id}`}>
      <StyledProjectCard
        data-testid={`project.lead-card-${props.leadProject.id}`}
      >
        <StyledSection>
          <StyledName variant="subtitle1">{props.leadProject.name}</StyledName>
          <StyledName color="text.secondary" variant="caption">
            {props.leadProject.ventureClient.name}
          </StyledName>
        </StyledSection>
        <StyledSection sx={{ marginTop: theme.spacing(1) }}>
          <Box display="flex" alignItems="center">
            <CalendarMonth fontSize="small" />
            <StyledProjectCardDetails marginLeft={theme.spacing(1)}>
              Day{" "}
              {calculateDaysBetweenDates(
                new Date(),
                props.leadProject.dateCreated
              )}
            </StyledProjectCardDetails>
          </Box>
          <Box display="flex" alignItems="center">
            <RocketLaunchIcon fontSize="small" />
            <StyledProjectCardDetails marginLeft={theme.spacing(1)}>
              {numberOfStartups}{" "}
              {numberOfStartups === 1 ? "Startup" : "Startups"}
            </StyledProjectCardDetails>
          </Box>
        </StyledSection>
      </StyledProjectCard>
    </NavLink>
  );
}

export default ProjectLeadKanbanCard;
