import axios from "axios";
import { RequirementStartupFit } from "../../Types/RequirementStartupFit";

export default class RequirementStartupFitHttpService {
  public static async update(
    requirementStartupFits: RequirementStartupFit[]
  ): Promise<void> {
    await axios.put<void>(
      "/api/requirement-startup-fit",
      requirementStartupFits
    );
  }
  public static async updateById(
    updatedReq: RequirementStartupFit
  ): Promise<void> {
    await axios.put<void>(
      "/api/requirement-startup-fit/" + updatedReq.id,
      updatedReq
    );
  }
}
