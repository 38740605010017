import axios from "axios";
import { Unit } from "../../Types/Kpi";

export class UnitHttpService {
  public static async getUnitsByName(name: string): Promise<Unit[]> {
    const response = await axios.get<Unit[]>(`/api/units`, {
      params: { name },
    });
    return response.data;
  }

  public static async createUnit(unit: Omit<Unit, "id">): Promise<Unit> {
    const response = await axios.post<Unit>(`/api/units`, unit);
    return response.data;
  }
}
