import { AppBar, Box, Tab, Tabs, styled } from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import { Project } from "../../../../Types/Project";
import DataView from "./DataView/DataView";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface PilotStageProps {
  projectData: Project;
  handleSave: () => void;
  handleChangeTab: (fn: () => void) => void;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: theme.palette.background.default,
  marginTop: theme.spacing(2),
}));

export default function PilotStage(props: PilotStageProps): ReactElement {
  const [value, setValue] = useState(0);
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    props.handleChangeTab(() => setValue(newValue));
  };

  return (
    <Fragment>
      <StyledAppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="DATA VIEW" {...a11yProps(0)} />
        </Tabs>
      </StyledAppBar>
      <TabPanel value={value} index={0}>
        {props.projectData && (
          <DataView handleSave={props.handleSave} project={props.projectData} />
        )}
      </TabPanel>
    </Fragment>
  );
}
