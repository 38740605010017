import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactElement, useState, useEffect, useReducer, Reducer } from "react";
import ClientContactHttpService from "../../Http/ClientContact/ClientContact.http.service";
import { ClientContactDTO } from "../../Types/ClientContact";
import { SelectInput } from "../UI/InputFields/SelectInput";
import { Gender, SelectOption } from "../../Types/Common";
import { BusinessUnitHttpService } from "../../Http/BusinessUnit/BusinessUnit.http.service";
interface CreateContactModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleSave: () => void;
  contact: ClientContactDTO;
}

const genders = ["male", "female", "non-binary"];

export default function EditClientContactModal(
  props: CreateContactModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [contact, setContact] = useReducer<
    Reducer<ClientContactDTO, Partial<ClientContactDTO>>
  >((state, newState) => ({ ...state, ...newState }), {} as ClientContactDTO);
  const [businessUnitsSelectOptions, setBusinessUnitsSelectOptions] = useState<
    SelectOption[]
  >([]);

  useEffect(() => {
    setContact({ ...props.contact });
    BusinessUnitHttpService.getBusinessUnits().then((results) => {
      const businessUnitSelectOptions = results.map((businessUnit) => ({
        id: businessUnit.id as number,
        name: businessUnit.name,
      }));
      setBusinessUnitsSelectOptions(businessUnitSelectOptions);
    });
  }, [props.contact]);

  const handleUpdateContact = async () => {
    if (contact) {
      await ClientContactHttpService.updateContact(contact, props.contact.id);
      props.handleSave();
      props.setModalOpen(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      id="client-edit-view"
      data-testid="client-edit-view"
    >
      <DialogTitle>Edit Client Contact</DialogTitle>
      <DialogContent>
        {contact && (
          <Box
            noValidate
            autoComplete="off"
            component="form"
            display="flex"
            flexDirection="column"
            gap={1}
            padding={1}
          >
            <Typography variant="h6">Personal Information</Typography>
            <Box display="flex" flexDirection="row" gap={3}>
              <TextField
                label="Name"
                id="name"
                variant="outlined"
                required
                fullWidth
                value={contact.name}
                onChange={(event) => setContact({ name: event.target.value })}
              />
              <TextField
                label="Gender"
                id="gender"
                variant="outlined"
                fullWidth
                select
                value={contact.gender}
                onChange={(event) =>
                  setContact({ gender: event.target.value as Gender })
                }
              >
                {genders.map((gender) => (
                  <MenuItem key={gender} value={gender}>
                    {gender}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Typography variant="h6" mt={4}>
              Company
            </Typography>
            <Box display="flex" flexDirection="row" gap={3}>
              <TextField
                label="Title"
                id="title"
                variant="outlined"
                fullWidth
                required
                value={contact.title}
                onChange={(event) => setContact({ title: event.target.value })}
              />
              <TextField
                label="Department Code"
                id="departmentCode"
                variant="outlined"
                fullWidth
                value={contact.departmentCode}
                onChange={(event) =>
                  setContact({ departmentCode: event.target.value })
                }
              />
            </Box>
            <Box display="flex" flexDirection="row" gap={3} mt={2}>
              <SelectInput
                labelText="Organizational Unit"
                selectValues={businessUnitsSelectOptions}
                value={
                  businessUnitsSelectOptions.length
                    ? contact.businessUnitId
                    : ""
                }
                onChange={(event) =>
                  setContact({ businessUnitId: parseInt(event.target.value) })
                }
                editMode={true}
                required
                fullWidth
                ignoreValidation
              />
            </Box>
            <Typography variant="h6" mt={4}>
              Contact
            </Typography>
            <Box display="flex" flexDirection="row" gap={3}>
              <TextField
                label="Phone Number"
                id="phone"
                variant="outlined"
                fullWidth
                value={contact.phone}
                onChange={(event) => setContact({ phone: event.target.value })}
              />
              <TextField
                label="Email"
                id="email"
                variant="outlined"
                required
                fullWidth
                value={contact.email}
                onChange={(event) => setContact({ email: event.target.value })}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => props.setModalOpen(false)}
          color="primary"
          id="close-client-edit-view"
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpdateContact}
          variant="contained"
          color="secondary"
          id="save-client-edit-view"
        >
          Save Contact
        </Button>
      </DialogActions>
    </Dialog>
  );
}
