import {
  ReactElement,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
} from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Typography,
  Box,
} from "@mui/material";
import {
  compareOpportunitiesByRating,
  delay,
  findLogo,
} from "../../../../../utils";
import { Requirement } from "../../../../../Types/Requirement";
import { FunnelStage, Project } from "../../../../../Types/Project";
import { SolutionCluster } from "../../../../../Types/SolutionCluster";
import {
  CreateOpportunityDTO,
  Opportunity,
  OpportunityRating as OpportunityRatingType,
  RejectionReason,
} from "../../../../../Types/Opportunity";
import OpportunityHttpService from "../../../../../Http/Opportunity/Opportunity.Http.service";
import { useSnackbar } from "notistack";
import { DetailsViewRowLabels } from "./DetailsView/DetailsView";
import RequirementStartupFitHttpService from "../../../../../Http/RequirementStartupFit/RequirementStartupFit.http.service";
import { ProjectHttpService } from "../../../../../Http/Project/Project.http.service";
import { RequirementStartupFit } from "../../../../../Types/RequirementStartupFit";
import SolutionFitMatrixOpportunity from "./SolutionFitMatrixOpportunity/SolutionFitMatrixOpportunity";
import { GlobalLoaderContext } from "../../../../../Context/LoaderContext";
import { AddStartup } from "./AddStartup/AddStartup";
import AddIcon from "@mui/icons-material/Add";
import { Flipper } from "react-flip-toolkit";
import RequirementsViewRowLabels from "./RequirementsView/RequirementsRowLabels";

const StyledTableContainer = styled(TableContainer)(() => ({
  boxShadow: "none",
  overflowY: "scroll",
  borderRadius: "12px",
  maxWidth: "calc(100vw - 242px)",
}));

const NoStartupButton = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(1.5)} auto`,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.spacing(4),
  width: theme.spacing(4),
  height: theme.spacing(4),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  border: "none",
  "& .highlighted-selection td:first-of-type": {
    backgroundColor: theme.palette.success.light,
  },
  "& .highlighted-selection.selected-for-pilot": {
    border: `1px solid ${theme.palette.success.light}`,
    borderTop: "none",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    "& td:first-of-type": {
      borderRight: "none",
    },
  },
  "& .highlighted-selection.selected-for-pilot:has(+ tr.comment-column)": {
    borderRight: "none",
    boxShadow: "none",
  },

  "& td.startup-name, & td:first-of-type": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    position: "sticky",
    padding: `${theme.spacing(2)} ${theme.spacing(1.75)}`,
    fontSize: theme.typography.htmlFontSize,
    fontWeight: "500",
    "& a": {
      color: theme.palette.common.white,
    },
  },
  "& tr.empty-row": {
    width: "100%",
    "& td": {
      width: "100%",
    },
    "& .text-container": {
      position: "relative",
      margin: "auto",
      top: "40%",
      maxWidth: "260px",
      textAlign: "center",
      border: "none",
    },
  },
  "& tr.add-startup-button": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0 12px 12px 0 ",
    width: theme.spacing(3),
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    right: "-1px",
    cursor: "pointer",
    td: {
      backgroundColor: theme.palette.secondary.main,
      padding: 0,
    },
  },
  "& tr.opportunity-column:last-of-type td:first-of-type, tr.requirements:nth-last-of-type(2) td:first-of-type,  tr.requirements:nth-last-of-type(2).selected-for-pilot,  tr:last-of-type.selected-for-pilot":
    {
      borderRadius: "0 12px 0 0",
    },
  "& tr.requirements:hover ": {
    position: "relative",
  },
  "& tr.requirements:hover:not(.opened-comments)::after": {
    content: "''",
    right: "-1px",
    width: "12px",
    height: "100%",
    zIndex: "2",
    position: "absolute",
    borderRight: `2px solid ${theme.palette.primary.main}`,
  },
  "& tr.requirements:hover:not(.opened-comments):last-of-type::after": {
    borderRadius: "0 12px 0 0",
  },
  "& tr.opportunity-column.requirements:nth-last-of-type(2)::after": {
    content: "''",
    borderRadius: " 0 12px 0 0",
  },
  "& tr.requirements:hover + .requirement-comment-indicator": {
    zIndex: "2",
    display: "block",
    "& td": { borderRight: "none" },
    "& svg": {
      display: "block",
    },
  },
  "& .opportunity-column:hover + .requirement-comment": {
    display: "inline",
  },
  "& tr:first-of-type": {
    width: "330px",
    boxShadow: " 4px 0px 8px rgba(0, 0, 0, 0.08)",
    backgroundColor: theme.palette.common.white,
    position: "sticky",
    left: "0",
    zIndex: "3",
    "& td": {
      fontSize: theme.typography.fontSize,
      width: "330px",
    },
  },
  "& tr": {
    width: "230px",
  },
  "& tr.comment-column": {
    width: "460px",
    "& .no-requirement-cell": {
      background: theme.palette.other.backgroundGrey,
    },
  },
  "& tr.add-startup-column": {
    width: "370px",
  },
  "& tr.highlighted-selection+ tr.comment-column": {
    "& td:first-of-type": {
      backgroundColor: theme.palette.success.light,
    },
  },
  "tr.highlighted-selection.selected-for-pilot + tr.comment-column": {
    border: `1px solid ${theme.palette.success.light}`,
    borderLeft: "none",
    borderTop: "none",
  },

  "& tr.requirement-comment-indicator": {
    display: "none",
    width: 0,
    "& td": {
      padding: 0,
      height: "100%",
    },
  },

  "& td.highlighted-border": {
    borderBottomColor: theme.palette.primary.main,
  },

  "& td.selection-reason-divider": {
    height: theme.spacing(3),
    padding: 0,
    background: theme.palette.other.backgroundGrey,
  },
  "& td.selection-reason-comment": {
    height: theme.spacing(14),
    background: theme.palette.other.backgroundGrey,
  },
  "& td.assign-cluster,  .rating-cell": {
    padding: 0,
  },
  "& td.selection-rationale-Title": {
    padding: `0 ${theme.spacing(2)}`,
  },
  "& td.extra-high-cell": {
    height: theme.spacing(11),
  },
  "& td.no-requirement-cell": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}`,
    background: "none",
    color: "black",
    height: theme.spacing(13),
  },
  "& td.requirement-cell": {
    height: theme.spacing(13),
    padding: `0 ${theme.spacing(2)}`,
    lineBreak: "anywhere",
    alignItems: "center",
    "& svg": {
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
    },
    "& svg.semi-filled-icon": {
      height: 0,
      width: 0,
    },
  },

  "& td": {
    borderColor: theme.palette.secondary.light,
    borderRight: `solid 1px ${theme.palette.secondary.light}`,
    fontSize: theme.typography.caption.fontSize,
    height: theme.spacing(7),
    alignItems: "center",
    display: "grid",
  },
  "& td.startup-description-short": {
    height: theme.spacing(14),
    alignItems: "start",
  },
  "& td.startup-description-long": {
    height: theme.spacing(21),
    alignItems: "start",
  },
  "& .opened-comments td:not(.startup-name)": {
    backgroundColor: theme.palette.other.backgroundGrey,
  },
  "& .startup-link-logo": {
    display: "flex",
    alignItems: "center",
    height: theme.spacing(8),
    "& img, .MuiSvgIcon-root": {
      width: "auto",
      maxWidth: theme.spacing(10),
      maxHeight: theme.spacing(5),
    },
  },
  "& tr:last-of-type > td": {
    borderRight: "none",
  },
}));

interface SolutionFitMatrixTableProps {
  projectId: number;
  opportunities: Opportunity[];
  requirements: Requirement[];
  clusters: SolutionCluster[];
  detailsView: boolean;
  handleSave: () => void;
  isAddingStartup: boolean;
  setIsAddingStartup: (state: boolean) => void;
  projectFunnelStage: FunnelStage;
  currentProjectStage: FunnelStage;
}

export default function SolutionFitMatrixTable(
  props: SolutionFitMatrixTableProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [expandedCommentsOpportunity, setExpandedCommentsOpportunity] =
    useState<number | null>(null);
  const [editingOpportunity, setEditingOpportunity] = useState<string | null>(
    null
  );
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const ref = useRef<null | HTMLTableSectionElement>(null);

  const isAssessStage = props.projectFunnelStage === "assess";

  const refreshHighlightOpportunities = (): number[] => {
    const highlight = props.opportunities.filter(
      (opportunity) =>
        (opportunity.isQualified && props.projectFunnelStage === "discover") ||
        (opportunity.isSelectedForPilot && isAssessStage)
    );
    return highlight.map((opportunity) => opportunity.id);
  };

  const [highlightedOpportunities, setHighlightedOpportunities] = useState<
    number[]
  >(refreshHighlightOpportunities());

  const updateOpportunities = async (
    updatedOpportunities: Opportunity[],
    selectedOpportunityIds: number[]
  ) => {
    setOpportunities(updatedOpportunities);

    try {
      setGlobalLoader(true);
      await Promise.all(
        selectedOpportunityIds.map(async (opportunityId) => {
          const updatedOpportunity = updatedOpportunities.find(
            (opp) => opp.id === opportunityId
          );
          if (updatedOpportunity) {
            await OpportunityHttpService.updateOpportunity(
              updatedOpportunity,
              opportunityId
            );
          }
        })
      );
      await delay(500);
      props.handleSave();
    } catch (error) {
      enqueueSnackbar("Something went wrong while updating opportunity", {
        variant: "error",
      });
    } finally {
      setGlobalLoader(false);
    }
  };

  const handleCreateOpportunity = async (
    createdOpportunity: CreateOpportunityDTO
  ) => {
    try {
      setGlobalLoader(true);
      props.handleSave();
      delete createdOpportunity.id;
      setOpportunities([...opportunities, createdOpportunity as Opportunity]);
      await OpportunityHttpService.createOpportunity(createdOpportunity);
    } catch (error) {
      enqueueSnackbar("Something went wrong while creating opportunity", {
        variant: "error",
      });
    } finally {
      setGlobalLoader(false);
    }
  };

  const handleRequirementChange = async (
    selectedOpportunity: Opportunity,
    updatedRequirementId: number,
    updatedStatus: string
  ) => {
    const reqFitToBeUpdated = selectedOpportunity.requirementStartupFits.find(
      (requirement) => requirement.requirementId === updatedRequirementId
    ) as RequirementStartupFit;

    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? {
            ...opportunity,
            requirementStartupFits:
              selectedOpportunity.requirementStartupFits.map((fit) =>
                fit.id === updatedRequirementId
                  ? { ...fit, status: updatedStatus }
                  : fit
              ),
          }
        : opportunity
    );
    const reqFitUpdated = {
      ...reqFitToBeUpdated,
      status: updatedStatus,
    } as RequirementStartupFit;

    setOpportunities(updatedOpportunities);
    try {
      setGlobalLoader(true);
      await RequirementStartupFitHttpService.updateById(reqFitUpdated);
      await ProjectHttpService.updateProject({
        id: props.projectId,
      } as Project);
      await delay(500);
      props.handleSave();
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong while updating requirement startup fits",
        {
          variant: "error",
        }
      );
    } finally {
      setGlobalLoader(false);
    }
  };

  const handleRequirementCommentChange = async (
    selectedOpportunity: Opportunity,
    updatedRequirementId: number,
    updatedComment: string
  ) => {
    const reqFitToBeUpdated = selectedOpportunity.requirementStartupFits.find(
      (requirement) => requirement.requirementId === updatedRequirementId
    ) as RequirementStartupFit;

    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? {
            ...opportunity,
            requirementStartupFits:
              selectedOpportunity.requirementStartupFits.map((fit) =>
                fit.id === updatedRequirementId
                  ? { ...fit, fit: updatedComment }
                  : fit
              ),
          }
        : opportunity
    );
    const reqFitUpdated = {
      ...reqFitToBeUpdated,
      fit: updatedComment,
    } as RequirementStartupFit;

    setOpportunities(updatedOpportunities);

    try {
      setGlobalLoader(true);
      await RequirementStartupFitHttpService.updateById(reqFitUpdated);
      await ProjectHttpService.updateProject({
        id: props.projectId,
      } as Project);
      await delay(500);
      props.handleSave();
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong while updating requirement startup fits",
        {
          variant: "error",
        }
      );
    } finally {
      setGlobalLoader(false);
    }
  };

  const handleRatingChange = async (
    selectedOpportunity: Opportunity,
    selectedRating: OpportunityRatingType
  ) => {
    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? { ...opportunity, rating: selectedRating }
        : opportunity
    );
    updateOpportunities(updatedOpportunities, [selectedOpportunity.id]);
  };

  const handleChangeCluster = async (
    selectedOpportunity: Opportunity,
    selectedClusterId: number
  ) => {
    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? { ...opportunity, solutionClusterId: selectedClusterId }
        : opportunity
    );
    updateOpportunities(updatedOpportunities, [selectedOpportunity.id]);
  };

  const handleRejectionReasonChange = async (
    selectedOpportunity: Opportunity,
    updatedRejectionReasons: RejectionReason[],
    updatedDescription: string
  ) => {
    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? {
            ...opportunity,
            rejectionReasons: updatedRejectionReasons,
            rejectionDescription: updatedDescription,
          }
        : opportunity
    );
    updateOpportunities(updatedOpportunities, [selectedOpportunity.id]);
  };

  const handleChangeRecommended = async (
    selectedOpportunity: Opportunity,
    recommended: boolean
  ) => {
    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? { ...opportunity, recommendedForDemo: recommended }
        : opportunity
    );
    updateOpportunities(updatedOpportunities, [selectedOpportunity.id]);
  };

  const handleInsightsUpdate = async (
    selectedOpportunity: Opportunity,
    updatedInsights: string
  ) => {
    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? { ...opportunity, insights: updatedInsights }
        : opportunity
    );
    updateOpportunities(updatedOpportunities, [selectedOpportunity.id]);
  };

  const handleOpportunityStatusChange = async (
    selectedOpportunity: Opportunity
  ) => {
    const opportunitiesToUpdate: number[] = [];
    const statusField =
      props.projectFunnelStage === "discover"
        ? "isQualified"
        : "isSelectedForPilot";

    let unselectedOpportunityId: number | undefined = undefined;

    if (isAssessStage) {
      unselectedOpportunityId = opportunities.find(
        (opportunity) => opportunity.isSelectedForPilot
      )?.id;
    }

    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? { ...opportunity, [statusField]: !opportunity[statusField] }
        : opportunity.id === unselectedOpportunityId
        ? {
            ...opportunity,
            isSelectedForPilot: false,
          }
        : opportunity
    );

    opportunitiesToUpdate.push(selectedOpportunity.id);

    if (unselectedOpportunityId) {
      opportunitiesToUpdate.push(unselectedOpportunityId);
    }
    updateOpportunities(updatedOpportunities, opportunitiesToUpdate);
  };

  const toggleComment = (opportunityId: number) => {
    setExpandedCommentsOpportunity(
      expandedCommentsOpportunity === opportunityId ? null : opportunityId
    );
  };
  const scrollToAddStartup = () => {
    if (ref.current) {
      const lastChildElement = ref.current?.lastElementChild;
      lastChildElement?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  useEffect(() => {
    if (props.isAddingStartup) {
      scrollToAddStartup();
    }
  }, [props.isAddingStartup]);

  useEffect(() => {
    setHighlightedOpportunities(refreshHighlightOpportunities());
    setOpportunities(props.opportunities.sort(compareOpportunitiesByRating));
  }, [props.opportunities]);

  // Calculate a unique flipKey based on opportunity IDs to trigger animations when opportunities are reordered.
  const flipKey = useMemo(() => {
    return opportunities.reduce((acc, opportunity) => {
      return acc + opportunity.id;
    }, "");
  }, [opportunities]);

  const isSelectionReasonVisible: boolean =
    props.opportunities.find((opportunity) => opportunity.isQualified) !==
      undefined && props.projectFunnelStage == "discover";

  return (
    <Flipper className="flipper" flipKey={flipKey}>
      <StyledTableContainer>
        <StyledTable>
          <TableBody ref={ref} sx={{ display: "flex" }}>
            <TableRow
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TableCell
                data-testid="startup-name"
                className="startup-name"
              ></TableCell>
              <TableCell
                data-testid="startup-link"
                className="startup-link-logo"
              ></TableCell>
              <TableCell>Rating</TableCell>
              <TableCell className={isAssessStage ? "" : "highlighted-border"}>
                Cluster
              </TableCell>
              {isAssessStage && (
                <TableCell className="highlighted-border">
                  Recommend for Demo
                </TableCell>
              )}
              {props.detailsView ? (
                <DetailsViewRowLabels />
              ) : (
                <RequirementsViewRowLabels
                  requirements={props.requirements}
                  projectId={props.projectId}
                  handleSaveNoScroll={props.handleSave}
                />
              )}
              {isSelectionReasonVisible && (
                <TableCell className="extra-high-cell">
                  Selection Rationale
                </TableCell>
              )}
            </TableRow>

            {opportunities.length
              ? opportunities.map((opportunity) => {
                  return (
                    <SolutionFitMatrixOpportunity
                      key={`opportunity-column-${opportunity.id}`}
                      opportunity={opportunity}
                      opportunityLogo={
                        opportunity.startup.files
                          ? findLogo(opportunity.startup.files)
                          : null
                      }
                      isSelectionReasonVisible={isSelectionReasonVisible}
                      projectFunnelStage={props.projectFunnelStage}
                      currentProjectStage={props.currentProjectStage}
                      detailsView={props.detailsView}
                      clusters={props.clusters}
                      requirements={props.requirements}
                      expandedCommentsOpportunity={expandedCommentsOpportunity}
                      highlightedOpportunities={highlightedOpportunities}
                      toggleComment={() => {
                        toggleComment(opportunity.id);
                      }}
                      isAddingStartup={props.isAddingStartup}
                      editingOpportunity={editingOpportunity}
                      setEditingOpportunity={(id) => setEditingOpportunity(id)}
                      handleRequirementCommentChange={(
                        selectedOpportunity: Opportunity,
                        updatedRequirementId: number,
                        updatedComment: string
                      ) =>
                        handleRequirementCommentChange(
                          selectedOpportunity,
                          updatedRequirementId,
                          updatedComment
                        )
                      }
                      handleRejectionReasonChange={(
                        updatedRejectionReasons: RejectionReason[],
                        updatedDescription: string
                      ) =>
                        handleRejectionReasonChange(
                          opportunity,
                          updatedRejectionReasons,
                          updatedDescription
                        )
                      }
                      handleSave={props.handleSave}
                      handleRatingChange={(option: OpportunityRatingType) =>
                        handleRatingChange(opportunity, option)
                      }
                      handleOpportunityStatusChange={
                        handleOpportunityStatusChange
                      }
                      handleInsightsUpdate={(updatedInsights: string) =>
                        handleInsightsUpdate(opportunity, updatedInsights)
                      }
                      handleChangeCluster={(cluster: number) =>
                        handleChangeCluster(opportunity, cluster)
                      }
                      handleChangeRecommended={(recommended: boolean) =>
                        handleChangeRecommended(opportunity, recommended)
                      }
                      handleRequirementChange={(
                        updatedRequirementId: number,
                        updatedStatus: string
                      ) =>
                        handleRequirementChange(
                          opportunity,
                          updatedRequirementId,
                          updatedStatus
                        )
                      }
                    />
                  );
                })
              : !props.isAddingStartup && (
                  <TableRow key="empty-row" className="empty-row">
                    <TableCell></TableCell>
                    <TableCell className="text-container">
                      <Typography variant="subtitle2">
                        There are no startups yet.
                        <br /> Go ahead and add the first one.
                      </Typography>
                      {props.detailsView && (
                        <NoStartupButton
                          onClick={() => props.setIsAddingStartup(true)}
                        >
                          <AddIcon>+</AddIcon>
                        </NoStartupButton>
                      )}
                    </TableCell>
                  </TableRow>
                )}
            {props.isAddingStartup ||
            (opportunities.length && props.detailsView) ? (
              <AddStartup
                isAddingStartup={props.isAddingStartup}
                setIsAddingStartup={props.setIsAddingStartup}
                assignedStartupsIds={props.opportunities?.flatMap(
                  (opportunity) => opportunity.startupId
                )}
                createOpportunity={handleCreateOpportunity}
                projectId={props.projectId}
                projectFunnelStage={props.projectFunnelStage}
              />
            ) : null}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </Flipper>
  );
}
