import { Box, Paper, Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ReactElement } from "react";
import { Project } from "../../../../../Types/Project";
import { ProjectFileSection } from "../../../../UI/FilesComponent/ProjectFilesSection";
import ProjectSummary from "../../../SharedComponents/ProjectSummary";
import Form from "./Form";
interface Props {
  project: Project;
  handleSave: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(4),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
      },
    },
  })
);

export default function DataView(props: Props): ReactElement {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid
        component={Paper}
        variant="outlined"
        className={classes.grid}
        item
        xs={8}
      >
        <Form project={props.project} handleSave={props.handleSave} />
      </Grid>
      <Grid
        item
        container
        xs={4}
        style={{ paddingTop: "0" }}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        alignContent="flex-start"
        gap={2}
      >
        <Grid item xs={12}>
          <Box>
            <ProjectSummary
              handleSave={props.handleSave}
              projectDetails={props.project}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ProjectFileSection
            handleSave={props.handleSave}
            project={props.project}
            opportunityFileTypes={[
              "Other",
              "Kickoff Deck",
              "Kickoff Protocol",
              "MS I Protocol",
              "MS I Deck",
              "MS II Protocol",
              "MS II Deck",
              "MS III Protocol",
              "MS III Deck",
              "Project Report",
              "Conclusion Protocol",
              "Project Success Report",
              "Conclusion Deck",
              "Pilot Synthesis",
              "Business Case - Pilot",
            ]}
            stage="pilot"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
