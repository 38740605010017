import {
  Typography,
  Button,
  Box,
  Badge,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Chip,
  styled,
} from "@mui/material";
import {
  ReactElement,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CSVLink } from "react-csv";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import { PresentToAll, RocketLaunchOutlined } from "@mui/icons-material";
import { AccountBalanceWalletOutlined } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RecommendedStartupsOverviewModal from "./RecommendedStartupsOverviewModal/RecommendedStartupsOverviewModal";
import EditKeyFindingsModal from "./EditKeyFindingsModal/EditKeyFindingsModal";
import SolutionFitExportService from "../../../../../../Services/SolutionFitExportService";
import { Project } from "../../../../../../Types/Project";
import theme from "../../../../../../theme";
import {
  compareOpportunitiesByRating,
  findLogo,
} from "../../../../../../utils";
import { GlobalProjectEditContext } from "../../../../../../Context/ProjectDetailsContext";
import parse from "html-react-parser";
import { Opportunity } from "../../../../../../Types/Opportunity";
import SolutionClusterOverviewModal from "./SolutionCluster/SolutionClusterOverviewModal/SolutionClusterOverviewModal";

const OverviewHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginBottom: theme.spacing(3),
}));

const OverviewContent = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: theme.spacing(2),
}));

const OverviewCard = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flex: 1,
  borderRadius: theme.customBorderRadius.md,
  outline: `1px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(3, 3, 1.5),
  cursor: "pointer",
}));

const OverviewCardDivided = styled(OverviewCard)(({ theme }) => ({
  flexDirection: "column",
  outline: "none",
  flex: 1,
  padding: 0,
  gap: theme.spacing(2),
  cursor: "default",
}));

const OverviewSubCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  outline: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.customBorderRadius.md,
  padding: theme.spacing(3),
  gap: theme.spacing(1),
}));

const SubCardBody = styled(Typography)(({ theme }) => ({
  fontWeight: "300",
  fontSize: "28px",
  color: theme.palette.primary.main,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
}));

const RocketFilledIcon = styled(RocketLaunchIcon)(() => ({
  transform: "translate(0, -5px)",
  stroke: "black",
  strokeWidth: 1.5,
}));

const RocketOutlinedIcon = styled(RocketLaunchOutlined)(() => ({
  position: "absolute",
  translate: "-17px -2px",
}));

const RocketIconBorderMaker = styled(RocketOutlinedIcon)(() => ({
  stroke: "white",
  strokeWidth: 5,
}));

const FlexColumnBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: theme.spacing(1),
  gap: theme.spacing(4),
}));

const KeyFinding = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isOverflowing }: { $isOverflowing: boolean }) => ({
  height: "202px",
  overflow: "hidden",
  position: "relative",
  ...($isOverflowing && {
    borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "48px",
      background:
        "linear-gradient(to top, rgba(255, 255, 255, 1) 30%, transparent)",
    },
  }),

  "& > *": {
    ...theme.typography.body2,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontSize: "11px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
}));

const TitleBadge = styled(Badge)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  "& span": {
    width: "25px",
  },
}));

const StyledListItemText = styled(ListItemText)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
}));

const IconWrapper = styled(Box)(() => ({
  transform: "translateY(-4px)",
}));

interface Props {
  project: Project;
  handleSave: () => void;
}

const OverviewSection = (props: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const [exportData, setExportData] = useState<{
    headers: { label: string; key: string }[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any[];
  }>({ headers: [], body: [] });

  const [openSolutionClusterModal, setOpenSolutionClusterModal] =
    useState(false);
  const [openRecommendedStartupsModal, setOpenRecommendedStartupsModal] =
    useState(false);
  const [openRecommendedForDemoModal, setOpenRecommendedForDemoModal] =
    useState(false);
  const [openEditKeyFindings, setOpenEditKeyFindings] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const isAssessStageActive = activeStep === 1;
  const keyFindings = isAssessStageActive
    ? "keyFindings"
    : "curatedKeyFindings";
  const containerRef = useRef<HTMLDivElement>(null);

  const checkOverFlow = () => {
    const element = containerRef.current;
    if (element) {
      setIsOverflowing(element.scrollHeight > element.clientHeight);
    }
  };

  const opportunities = isAssessStageActive
    ? props.project.opportunities.filter((opp) => opp.isQualified === true)
    : props.project.opportunities;

  const cumulatedFunding = opportunities
    .reduce(
      (totalFunding, currentOpportunity) =>
        totalFunding + +currentOpportunity.startup.totalFunding,
      0.0
    )
    .toFixed(2);

  const allARatedOpportunities = opportunities
    .filter((opp) => opp.rating === "A")
    .sort(compareOpportunitiesByRating);

  const allRecommendedForDemoOpportunities = opportunities.filter(
    (opp) => opp.recommendedForDemo
  );

  const downloadCSV = async () => {
    const response = SolutionFitExportService.prepareAsCSV(
      props.project,
      isAssessStageActive ? false : true
    );
    setExportData(response);
  };

  useLayoutEffect(() => {
    checkOverFlow();
  }, [props.project[keyFindings]]);

  return (
    <>
      <OverviewHeader>
        <Typography variant="h6">Overview</Typography>
        <Box sx={{ ml: "auto" }}>
          <CSVLink
            data={exportData["body"]}
            headers={exportData["headers"]}
            className="hidden"
            filename={
              props.project.name +
              (isAssessStageActive
                ? "_Qualified-List.csv"
                : "_Curated-List.csv")
            }
            target="_blank"
            style={{ marginLeft: "auto" }}
          >
            <Button onClick={downloadCSV} id="downloadCSV">
              <SystemUpdateAltOutlinedIcon />
            </Button>
          </CSVLink>
        </Box>
      </OverviewHeader>
      <OverviewContent>
        <OverviewCardDivided>
          <OverviewSubCard data-testid="total-startups-card">
            <IconWrapper>
              <RocketLaunchOutlined />
            </IconWrapper>
            <Box display="flex" flexDirection="column" gap={2}>
              <Title>Total Startups</Title>
              {opportunities.length ? (
                <SubCardBody>{opportunities.length}</SubCardBody>
              ) : (
                <EmptyPlaceHolder text="No startups assigned" />
              )}
            </Box>
          </OverviewSubCard>
          <OverviewSubCard data-testid="total-funding-card">
            <IconWrapper>
              <AccountBalanceWalletOutlined />
            </IconWrapper>
            <Box display="flex" flexDirection="column" gap={2}>
              <Title>Cumulated Funding (in USD)</Title>
              {opportunities.length ? (
                <SubCardBody data-testid="cumulated-funding-value">
                  $ {cumulatedFunding} M
                </SubCardBody>
              ) : (
                <EmptyPlaceHolder text="No startups assigned" />
              )}
            </Box>
          </OverviewSubCard>
        </OverviewCardDivided>
        <OverviewCard
          onClick={() => setOpenSolutionClusterModal(true)}
          data-testid="solution-clusters-card"
        >
          <Box position="relative">
            <RocketFilledIcon fontSize="small" />
            <RocketIconBorderMaker />
            <RocketOutlinedIcon />
          </Box>
          <FlexColumnBox ml={1}>
            <Box display="flex" alignItems="center">
              <Title>Solution Clusters</Title>
              <TitleBadge
                badgeContent={props.project.solutionClusters.length}
                color="primary"
              />
            </Box>
            {props.project.solutionClusters.length ? (
              <List
                disablePadding
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <FlexColumnBox sx={{ gap: "10px" }}>
                  {props.project.solutionClusters
                    .slice(0, 4)
                    .map((cluster, index) => {
                      const lastItem =
                        index === props.project.solutionClusters.length - 1;
                      return (
                        <ListItem
                          key={cluster.id}
                          disablePadding
                          divider={!lastItem}
                          sx={{
                            pb: "10px",
                            borderBottomColor: theme.palette.other.surfaceSoft,
                          }}
                        >
                          <StyledListItemText
                            primary={cluster.name}
                            primaryTypographyProps={{
                              variant: "body2",
                            }}
                          />
                        </ListItem>
                      );
                    })}
                </FlexColumnBox>
                {props.project.solutionClusters.length > 4 && (
                  <MoreItemsIndicator
                    indicator={props.project.solutionClusters.length}
                  />
                )}
              </List>
            ) : (
              <EmptyPlaceHolder text="No solution clusters assigned" />
            )}
          </FlexColumnBox>
          <ArrowForwardIcon fontSize="small" color="primary" />
        </OverviewCard>
        {isAssessStageActive ? (
          <RecommendedSection
            opportunities={allRecommendedForDemoOpportunities}
            onCardClick={() => setOpenRecommendedForDemoModal(true)}
            title="Recommended for Demo"
            icon={<PresentToAll color="disabled" />}
            cardTestId="recommended-startups-card"
          />
        ) : (
          <RecommendedSection
            opportunities={allARatedOpportunities}
            onCardClick={() => setOpenRecommendedStartupsModal(true)}
            title="Recommended Startups"
            icon={<FavoriteBorderOutlinedIcon color="disabled" />}
            cardTestId="recommended-startups-card"
          />
        )}
        <OverviewCard
          onClick={() => setOpenEditKeyFindings(true)}
          sx={{ outlineColor: theme.palette.grey[300] }}
          data-testid="key-findings-card"
        >
          <IconWrapper>
            <InfoOutlinedIcon />
          </IconWrapper>
          <FlexColumnBox sx={{ gap: 2 }}>
            <Title>Key {isAssessStageActive && "Problem "}Findings</Title>
            {props.project[keyFindings] ? (
              <Box display="flex" flexDirection="column">
                <KeyFinding ref={containerRef} $isOverflowing={isOverflowing}>
                  {parse(props.project[keyFindings])}
                </KeyFinding>
                {isOverflowing && <MoreItemsIndicator indicator="More" />}
              </Box>
            ) : (
              <EmptyPlaceHolder text="Key findings to be determined" />
            )}
          </FlexColumnBox>
          <ArrowForwardIcon fontSize="small" color="primary" />
        </OverviewCard>
      </OverviewContent>
      {openSolutionClusterModal && (
        <SolutionClusterOverviewModal
          modalOpen={openSolutionClusterModal}
          setModalOpen={() => setOpenSolutionClusterModal(false)}
          solutionClusters={props.project.solutionClusters}
          opportunities={opportunities}
          handleSave={props.handleSave}
        />
      )}
      {openRecommendedStartupsModal && (
        <RecommendedStartupsOverviewModal
          modalOpen={openRecommendedStartupsModal}
          setModalOpen={() => setOpenRecommendedStartupsModal(false)}
          opportunities={allARatedOpportunities}
          recommendationType="rating"
        />
      )}
      {openRecommendedForDemoModal && (
        <RecommendedStartupsOverviewModal
          modalOpen={openRecommendedForDemoModal}
          setModalOpen={() => setOpenRecommendedForDemoModal(false)}
          opportunities={allRecommendedForDemoOpportunities}
          recommendationType="demo"
        />
      )}
      {openEditKeyFindings && (
        <EditKeyFindingsModal
          modalOpen={openEditKeyFindings}
          setModalOpen={() => setOpenEditKeyFindings(false)}
          keyFindings={props.project[keyFindings]}
          handleSave={props.handleSave}
        />
      )}
    </>
  );
};

export default OverviewSection;

export const EmptyPlaceHolder = (props: { text: string }): ReactElement => {
  return (
    <Typography
      variant="caption"
      component="span"
      sx={{
        color: theme.palette.grey[500],
        fontStyle: "italic",
      }}
    >
      {props.text}
    </Typography>
  );
};

export const MoreItemsIndicator = (props: {
  indicator: number | string;
}): ReactElement => {
  const label =
    typeof props.indicator === "number"
      ? `+${props.indicator - 4}`
      : props.indicator;
  return (
    <Chip
      data-testid="more-items-indicator"
      label={label}
      sx={{
        pointerEvents: "none",
        padding: theme.spacing(0.5),
        height: "25px",
        backgroundColor: theme.palette.secondary.light,
        borderRadius: theme.spacing(0, 0, 1.5, 1.5),
        color: theme.palette.primary.main,
        fontWeight: "bold",
        alignSelf: "center",
      }}
    />
  );
};

interface RecommendedSectionProps {
  opportunities: Opportunity[];
  onCardClick: () => void;
  title: string;
  icon: ReactElement;
  cardTestId: string;
}

export const RecommendedSection = (
  props: RecommendedSectionProps
): ReactElement => {
  return (
    <OverviewCard onClick={props.onCardClick} data-testid={props.cardTestId}>
      <IconWrapper>{props.icon}</IconWrapper>
      <FlexColumnBox>
        <Box display="flex" alignItems="center">
          <Title> {props.title}</Title>
          <TitleBadge
            color="primary"
            badgeContent={props.opportunities.length}
          />
        </Box>
        {props.opportunities.length ? (
          <List
            disablePadding
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <FlexColumnBox sx={{ gap: "10px" }}>
              {props.opportunities.slice(0, 4).map((opportunity, index) => {
                const lastItem = index === props.opportunities.length - 1;
                const logo = findLogo(opportunity.startup.files);
                return (
                  <ListItem
                    key={opportunity.id}
                    disablePadding
                    divider={!lastItem}
                    sx={{
                      pb: "10px",
                      gap: 5,
                    }}
                    data-testid={`recommended-startup-${opportunity.id}`}
                  >
                    <ListItemIcon sx={{ justifyContent: "center" }}>
                      {logo ? (
                        <img
                          src={logo}
                          alt="startup logo"
                          width="50px"
                          style={{
                            objectFit: "contain",
                            aspectRatio: "2/1",
                          }}
                        />
                      ) : (
                        <RocketLaunchOutlined color="disabled" />
                      )}
                    </ListItemIcon>
                    <StyledListItemText
                      primary={opportunity.startup.name}
                      primaryTypographyProps={{
                        variant: "caption",
                      }}
                    />
                  </ListItem>
                );
              })}
            </FlexColumnBox>
            {props.opportunities.length > 4 && (
              <MoreItemsIndicator indicator={props.opportunities.length} />
            )}
          </List>
        ) : (
          <EmptyPlaceHolder text="No recommended startups selected" />
        )}
      </FlexColumnBox>
      <ArrowForwardIcon fontSize="small" color="primary" />
    </OverviewCard>
  );
};
