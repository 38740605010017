import {
  ReactElement,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Project, RichTextCharLimit } from "../../../../../Types/Project";
import Box from "@mui/material/Box";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Button from "@mui/material/Button";
import { ProjectHttpService } from "../../../../../Http/Project/Project.http.service";
import StrategicBenefitsSection from "../../../SharedComponents/StrategicBenefitsSection/StrategicBenefitsSection";
import ClientContactSelect from "../../../../UI/InputFields/ClientContactSelect";
import { ClientContactDTO } from "../../../../../Types/ClientContact";
import { Grid } from "@mui/material";
import { SelectInput } from "../../../../UI/InputFields/SelectInput";
import CustomTextField from "../../../../UI/InputFields/CustomTextField";
import { SolutionCluster } from "../../../../../Types/SolutionCluster";
import { SolutionClusterHttpService } from "../../../../../Http/SolutionCluster/SolutionCluster.http.service";
import RichTextEditor from "../../../../UI/InputFields/RichTextEditor";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import { SelectOption } from "../../../../../Types/Common";
import { useSnackbar } from "notistack";
import { isCharLimitExceeded } from "../../../../../utils";
import SolutionRequirements from "./SolutionRequirements/SolutionRequirements";

const CHAR_LIMIT_KEY_FINDINGS = 350;
const CHAR_LIMIT_RECOMMENDATION = 350;

const richTextFields: RichTextCharLimit[] = [
  {
    id: "description",
    label: "Problem Description",
  },
  {
    id: "deepDiveKeyFindings",
    label: "Key Findings (PDD)",
    charLimit: CHAR_LIMIT_KEY_FINDINGS,
  },
  {
    id: "deepDiveRecommendation",
    label: "Recommendation (PDD)",
    charLimit: CHAR_LIMIT_RECOMMENDATION,
  },
];
interface Props {
  projectData: Project;
  handleSave: (withScroll?: boolean) => void;
}

const ventureCapitalSelectValues = [
  { id: "High", name: "High (>$500 Mio)" },
  { id: "Medium", name: "Medium (>$100 Mio)" },
  { id: "Low", name: "Low (<$100 Mio)" },
];
const availabilityOfStartupsSelectValues = [
  { id: "High", name: "High (>20)" },
  { id: "Medium", name: "Medium (6-20)" },
  { id: "Low", name: "Low (<6)" },
];
const startupOpportunitySelectValues = [
  {
    id: "High",
    name: "High",
    description:
      "Startup technologies constitute a crucial factor in this market. The high number of Venture Capital backed companies indicates the maturity of the domain. With a high probability, we will identify suitable startup solutions.",
  },
  {
    id: "Medium",
    name: "Medium",
    description:
      "Startups are one of the primary players in this market. The number of Venture Capital backed companies indicates a promising discovery.",
  },
  {
    id: "Low",
    name: "Low",
    description:
      "Startup technologies have recently started to emerge. The number of Venture Capital backed companies is low. Hence, it could be challenging to find a suitable solution.",
  },
];

const adoptionStrategySelectValues: SelectOption[] = [
  { id: "Acquisition", name: "Acquisition" },
  { id: "Exploration", name: "Exploration" },
  { id: "Partnership", name: "Partnership" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Typography: {
      marginTop: theme.spacing(6),
    },
  })
);

export default function Form(props: Props): ReactElement {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.projectData
  );
  const [solutionClusters, setSolutionClusters] = useState<SolutionCluster[]>(
    []
  );
  const [solutionClusterIdsToDelete, setSolutionClusterIdsToDelete] = useState<
    number[]
  >([]);

  const {
    setGlobalEditMode,
    globalEditMode,
    setShouldSaveProject,
    shouldSaveProject,
  } = useContext(GlobalProjectEditContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setProject(props.projectData);
    setSolutionClusters(props.projectData.solutionClusters || []);
  }, [props.projectData]);

  useEffect(() => {
    setGlobalEditMode(editMode);
  }, [editMode]);

  useEffect(() => {
    const saveChanges = async () => {
      if (shouldSaveProject) {
        await saveProject();
        setShouldSaveProject(false);
      }
    };

    saveChanges();
  }, [shouldSaveProject]);

  const toggleEditMode = () => {
    setSolutionClusters(props.projectData.solutionClusters || []);
    setEditMode(!editMode);
  };

  const cancelEdit = () => {
    setProject(props.projectData);
    setSolutionClusters(props.projectData.solutionClusters || []);
    setSolutionClusterIdsToDelete([]);
    setEditMode(false);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const saveProject = async () => {
    for (const field of richTextFields) {
      if (isCharLimitExceeded(project[field.id] as string, field.charLimit)) {
        enqueueSnackbar(`Maximum character limit exceeded for ${field.label}`, {
          variant: "error",
        });
        return;
      }
    }

    await ProjectHttpService.updateProject(project as Project);

    await Promise.all([
      ...solutionClusters.map(async (cluster) => {
        if (cluster.id) {
          await SolutionClusterHttpService.updateSolutionCluster(cluster);
        } else {
          await SolutionClusterHttpService.createSolutionCluster(cluster);
        }
      }),

      ...solutionClusterIdsToDelete.map(async (id) => {
        await SolutionClusterHttpService.deleteSolutionCluster(id);
      }),
    ]);
    props.handleSave();
    setSolutionClusterIdsToDelete([]);
    setEditMode(false);
  };

  return (
    <div id="discover-data-view-form">
      {project && (
        <form noValidate autoComplete="off">
          <Box
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Typography variant="h6">Problem Deep Dive</Typography>
            {!editMode && !globalEditMode && (
              <Button onClick={toggleEditMode} id="edit-form-button">
                <EditOutlinedIcon />
              </Button>
            )}
          </Box>
          <Typography
            variant="h6"
            className={classes.Typography}
            sx={{ mb: 2 }}
          >
            Status Quo
          </Typography>
          <Grid container>
            <Grid item sx={{ mb: 2 }} xs={12}>
              <RichTextEditor
                fieldId="description"
                labelText="Problem Description"
                editMode={editMode}
                fieldValue={project.description}
                required
                onChange={(value) => setProject({ description: value })}
                isToolbarDisabled
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item>
              <CustomTextField
                id="previousProjects"
                label="Previous Projects"
                editMode={editMode}
                value={project.previousProjects}
                onChange={(e) =>
                  setProject({ previousProjects: e.target.value })
                }
                multiline
              />
            </Grid>
            <Grid item>
              <CustomTextField
                id="knownSolutions"
                label="Known Solutions"
                editMode={editMode}
                value={project.knownSolutions}
                onChange={(e) => setProject({ knownSolutions: e.target.value })}
                multiline
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item>
              <RichTextEditor
                fieldId="deepDiveKeyFindings"
                labelText="Key Findings (PDD)"
                editMode={editMode}
                fieldValue={project.deepDiveKeyFindings}
                onChange={(value) => setProject({ deepDiveKeyFindings: value })}
                maxCharacter={CHAR_LIMIT_KEY_FINDINGS}
                isToolbarDisabled
              />
            </Grid>
            <Grid item>
              <RichTextEditor
                fieldId="deepDiveRecommendation"
                labelText="Recommendation (PDD)"
                editMode={editMode}
                fieldValue={project.deepDiveRecommendation}
                onChange={(value) =>
                  setProject({
                    deepDiveRecommendation: value,
                  })
                }
                maxCharacter={CHAR_LIMIT_RECOMMENDATION}
                isToolbarDisabled
              />
            </Grid>
          </Grid>
          <SolutionRequirements
            requirements={props.projectData.requirements || []}
            projectId={props.projectData.id}
            handleSaveNoScroll={() =>
              editMode ? null : props.handleSave(false)
            }
          />
          <Typography variant="h6" className={classes.Typography}>
            Stakeholders and Timeline
          </Typography>
          <Grid spacing={2} container>
            <Grid item>
              <ClientContactSelect
                editMode={editMode}
                labelText="Project Leader"
                fieldId="projectLeaderId"
                ventureClientId={project.businessUnit?.ventureClient.id}
                onChange={setProject}
                contactData={project.projectLeader}
                required
              />
            </Grid>
            <Grid item>
              <ClientContactSelect
                editMode={editMode}
                labelText="Project Sponsor"
                fieldId="projectSponsorId"
                ventureClientId={project.businessUnit?.ventureClient.id}
                onChange={setProject}
                contactData={project.projectSponsor}
              />
            </Grid>
            <Grid item>
              <ClientContactSelect
                editMode={editMode}
                labelText="Project Team"
                fieldId="projectTeam"
                ventureClientId={project.businessUnit?.ventureClient.id}
                onChange={(projectTeam: ClientContactDTO[]) => {
                  setProject({ projectTeam });
                }}
                contactData={project.projectTeam}
                multiSelect={true}
              />
            </Grid>
            <Grid item>
              <ClientContactSelect
                editMode={editMode}
                labelText="Venture Client Program Manager"
                fieldId="programManagerId"
                ventureClientId={project.businessUnit?.ventureClient.id}
                onChange={setProject}
                required
                contactData={project.programManager}
              />
            </Grid>
            <Grid item>
              <ClientContactSelect
                editMode={editMode}
                labelText="Other Stakeholders"
                fieldId="otherStakeholders"
                ventureClientId={project.businessUnit?.ventureClient.id}
                onChange={(otherStakeholders: ClientContactDTO[]) => {
                  setProject({ otherStakeholders });
                }}
                contactData={project.otherStakeholders}
                multiSelect={true}
              />
            </Grid>
            <Grid spacing={2} container pl="16px" pt="16px">
              <Grid item>
                <CustomTextField
                  id="budget"
                  label="Amount for Pilot Project"
                  type="currency"
                  editMode={editMode}
                  value={project.budget}
                  onChange={(e) =>
                    setProject({ budget: parseInt(e.target.value) })
                  }
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  id="startupAdoptionAmount"
                  label="Amount for Startup Solution Adoption"
                  type="currency"
                  editMode={editMode}
                  value={project.startupAdoptionAmount}
                  onChange={(e) =>
                    setProject({
                      startupAdoptionAmount: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid spacing={2} container pl="16px">
              <Grid item>
                <CustomTextField
                  id="deadline"
                  label="Deadline"
                  type="date"
                  editMode={editMode}
                  value={project.deadline}
                  onChange={(e) => {
                    setProject({ deadline: e.target.value || null });
                  }}
                  required
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  id="pilotStartDate"
                  label="Starting Date of Pilot Project"
                  type="date"
                  editMode={editMode}
                  value={project.pilotStartDate}
                  onChange={(e) =>
                    setProject({ pilotStartDate: e.target.value || null })
                  }
                  required
                />
              </Grid>
            </Grid>
            <Grid container pl="16px">
              <Grid item>
                <SelectInput
                  id="adoptionStrategy"
                  labelText="Adoption Strategy"
                  selectValues={adoptionStrategySelectValues}
                  value={project.adoptionStrategy}
                  onChange={(e) =>
                    setProject({ adoptionStrategy: e.target.value })
                  }
                  editMode={editMode}
                />
              </Grid>
            </Grid>
          </Grid>
          <Typography variant="h6" className={classes.Typography}>
            Strategic Benefits
          </Typography>
          <StrategicBenefitsSection
            project={project}
            editMode={editMode}
            onChange={setProject}
          />
          <Typography variant="h6" className={classes.Typography}>
            Startup Supply
          </Typography>
          <Grid spacing={2} container>
            <Grid item>
              <SelectInput
                required={true}
                labelText="Availability of Startups"
                selectValues={availabilityOfStartupsSelectValues}
                value={project.availabilityOfStartups}
                onChange={(e) =>
                  setProject({
                    availabilityOfStartups: e.target.value,
                  })
                }
                editMode={editMode}
              />
            </Grid>
            <Grid item>
              <SelectInput
                required={true}
                labelText="Invested Amount of Venture Capital"
                selectValues={ventureCapitalSelectValues}
                value={project.ventureCapital}
                onChange={(e) => setProject({ ventureCapital: e.target.value })}
                editMode={editMode}
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item>
              <SelectInput
                required={true}
                labelText="Startup Opportunity"
                selectValues={startupOpportunitySelectValues}
                value={project.startupOpportunity}
                onChange={(e) =>
                  setProject({
                    startupOpportunity: e.target.value,
                  })
                }
                editMode={editMode}
              />
            </Grid>
            <Grid item>
              {project.startupOpportunity && (
                <CustomTextField
                  id="startupOpportunityComment"
                  label="Startup Opportunity Comment"
                  editMode={editMode}
                  value={
                    startupOpportunitySelectValues.filter(
                      (option) => option.id === project.startupOpportunity
                    )[0]?.description
                  }
                  multiline
                />
              )}
            </Grid>
          </Grid>
        </form>
      )}
      <Box
        sx={{
          justifyContent: "flex-end",
          flexDirection: "row",
          display: editMode ? "flex" : "none",
          m: 2,
        }}
      >
        <Button
          autoFocus
          onClick={cancelEdit}
          color="primary"
          id="cancel-project-button"
        >
          Cancel
        </Button>
        <Button
          onClick={saveProject}
          variant="contained"
          color="secondary"
          id="save-project-button"
          sx={{ marginLeft: "15px" }}
        >
          Save Project
        </Button>
      </Box>
    </div>
  );
}
