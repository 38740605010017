import axios from "axios";
import {
  CreateProjectDTO,
  Project,
  ProjectDTO,
  ProjectFilterCriteria,
} from "../../Types/Project";
import ProjectMapper from "./Project.mapper";

export class ProjectHttpService {
  public static async getProjects(
    filterCriteria: ProjectFilterCriteria
  ): Promise<Project[]> {
    const response = await axios.get<ProjectDTO[]>(`/api/projects`, {
      params: {
        filterCriteria: filterCriteria,
      },
    });
    return response.data.map((project) => ProjectMapper.map(project));
  }

  public static async getProjectById(
    id: string,
    representation?: string
  ): Promise<Project> {
    const response = await axios.get<ProjectDTO>(`/api/projects/${id}`, {
      params: {
        representation,
      },
    });
    return ProjectMapper.map(response.data);
  }

  public static async createProject(
    project: CreateProjectDTO
  ): Promise<number> {
    const response = await axios.post<number>("/api/projects", project);
    return response.data;
  }

  public static async updateProject(project: Project): Promise<void> {
    await axios.put<void>(`/api/projects/${project.id}`, project);
  }
}
