import { Box, InputBaseProps, TextField, styled } from "@mui/material";
import { ReactElement, ReactNode } from "react";
import { NumberFormatCustom } from "./NumberFormat";
import CustomToolTip from "../CustomToolTip";

interface CustomTextFieldProps {
  id: string;
  type?: "text" | "currency" | "date" | "number";
  disabled?: boolean;
  label?: string;
  toolTipText?: string | null;
  editMode: boolean;
  value?: string | null | number;
  required?: boolean;
  fullWidth?: boolean;
  inputProps?: InputBaseProps["inputProps"];
  multiline?: boolean;
  minRows?: number; // When Multiline is true
  maxRows?: number; // When Multiline is true
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxCharacter?: number;
  className?: string;
  placeHolder?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLImageElement>) => void;
  dataTestId?: string;
  children?: ReactNode;
  autoFocus?: boolean;
  minDate?: string;
}

const Container = styled(Box)(() => ({
  width: "100%",
  position: "relative",
  "& .auto-generate-text-button": {
    position: "absolute",
    right: "1rem",
    top: "0rem",
  },
}));

const StyledCustomTextField = styled(TextField)(({ theme }) => ({
  marginBlock: theme.spacing(2),
  minWidth: "35ch",

  "& .MuiInput-underline:before, .MuiInputBase-root.MuiInput-underline:hover:before":
    {
      borderBottom: "none",
    },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  "& .MuiInputLabel-root.Mui-error, .MuiInputLabel-asterisk.Mui-error": {
    color: theme.palette.warning.main,
  },
  "&.helperError .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.warning.main,
  },
  "&.helperError .MuiFormHelperText-root.Mui-error": {
    color: theme.palette.warning.main,
  },
}));

const CustomTextField = (props: CustomTextFieldProps): ReactElement => {
  const {
    id,
    label,
    editMode,
    value,
    type = "text",
    disabled = false,
    required = false,
    fullWidth = false,
    multiline = false,
    toolTipText = null,
    onChange,
    maxRows,
    minRows,
    maxCharacter,
    placeHolder,
    onKeyDown,
    dataTestId,
    autoFocus,
    minDate,
  } = props;

  const isError = editMode && required && !value;

  return (
    <Container>
      <StyledCustomTextField
        id={id}
        autoFocus={autoFocus}
        data-testid={dataTestId}
        placeholder={placeHolder || ""}
        onKeyDown={onKeyDown}
        type={type}
        className={`${props.className} ${isError ? "helperError" : ""}`}
        disabled={disabled}
        label={
          !!!toolTipText ? (
            label
          ) : (
            <>
              <span>{label}</span>
              {editMode && (
                <CustomToolTip
                  id={`${id}Tooltip`}
                  key={`${id}Tooltip`}
                  toolTipText={toolTipText}
                  warning={isError}
                />
              )}
            </>
          )
        }
        value={value || ""}
        fullWidth={fullWidth}
        InputLabelProps={{
          shrink: true,
          sx: {
            marginTop: editMode && !!toolTipText ? "-7px" : "0px",
            paddingRight: "8px",
          },
        }}
        variant={editMode ? "outlined" : "standard"}
        focused={false}
        multiline={multiline}
        InputProps={{
          readOnly: !editMode,
          ...(type === "currency" && {
            name: id,
            // eslint-disable-next-line
            inputComponent: NumberFormatCustom as any,
          }),
        }}
        inputProps={{
          ...(maxCharacter && {
            maxLength: maxCharacter,
          }),
          ...(minDate && {
            min: minDate,
          }),
          "data-testid": `input-${dataTestId}`,
        }}
        helperText={
          maxCharacter && editMode
            ? (value && typeof value === "string" ? value.length : 0) +
              "/" +
              maxCharacter
            : ""
        }
        onChange={onChange}
        required={required && editMode}
        {...(multiline && {
          minRows,
          maxRows,
        })}
        error={isError}
      />
      {props.children}
    </Container>
  );
};

export default CustomTextField;
