import { ReactElement, useContext, useEffect, useState } from "react";
import { AnalyticsHttpService } from "../../../Http/Analytics/Analytics.http.service";
import { ProjectEvent } from "../../../Types/Analytics";
import { Box, Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ProjectDetailsModal from "../../UI/Modals/ProjectDetailsModal/ProjectDetailsModal";
import { useSnackbar } from "notistack";
import { GlobalLoaderContext } from "../../../Context/LoaderContext";
import React from "react";
import { MemoizedEventCard } from "./EventCard";
import { DashboardFilterOptions } from "../../../Types/Dashboard";

type ScrollPosition = {
  start: boolean;
  end: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gradient: {
      background: `-webkit-linear-gradient(180deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      lineHeight: 1,
    },
    title: {
      ...theme.typography.h5,
      color: theme.palette.grey[600],
      fontWeight: "500",
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    eventsContainer: {
      width: "100%",
      height: "100%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
    },
    eventWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      overflowX: "auto",
      gap: theme.spacing(1),
      paddingBlock: theme.spacing(1),
      margin: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(1)}`,
    },
    scrollShadow: {
      pointerEvents: "none",
      position: "absolute",
      width: "150px",
      height: "70%",
      transition: "opacity .3s ease-in-out",
    },
    scrollShadowLeft: (scrollPosition: ScrollPosition) => ({
      opacity: scrollPosition.start ? 0 : 1,
      left: 0,
      backgroundImage:
        "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
    }),
    scrollShadowRight: (scrollPosition: ScrollPosition) => ({
      opacity: scrollPosition.end ? 0 : 1,
      right: 0,
      backgroundImage:
        "linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
    }),
  })
);

interface Props {
  dashboardFilters: DashboardFilterOptions;
  refetch: boolean;
}

function UpcomingEvents({ dashboardFilters, refetch }: Props): ReactElement {
  const [upcomingEvents, setUpcomingEvents] = useState<ProjectEvent[]>([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [projectId, setProjectId] = useState<number>();
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({
    start: true,
    end: false,
  });
  const classes = useStyles(scrollPosition);
  const { enqueueSnackbar } = useSnackbar();
  const { globalLoader, setGlobalLoader } = useContext(GlobalLoaderContext);

  useEffect(() => {
    const fetchData = async () => {
      setGlobalLoader(true);
      try {
        const events = await AnalyticsHttpService.getUpcomingEvents({
          businessUnitIds: dashboardFilters.businessUnitsIds,
          focusAreasIds: dashboardFilters.focusAreasIds,
        });
        setUpcomingEvents(events);
        // eslint-disable-next-line
      } catch (error: any) {
        enqueueSnackbar(
          `Something went wrong with fetching upcoming events: ${error.message}`,
          {
            variant: "error",
          }
        );
      } finally {
        setGlobalLoader(false);
      }
    };
    fetchData();
  }, [refetch]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollWidth = e.currentTarget.scrollWidth;
    const width = e.currentTarget.clientWidth;
    const scrollLeft = e.currentTarget.scrollLeft;
    const isEnd = scrollWidth - width - scrollLeft <= 1;
    const isStart = scrollLeft === 0;

    if (isEnd) {
      setScrollPosition({ start: false, end: true });
    } else if (isStart) {
      setScrollPosition({ start: true, end: false });
    } else {
      setScrollPosition({ start: false, end: false });
    }
  };

  const openProjectDetails = async (projectId: number) => {
    setProjectId(projectId);
    setOpenDetails(true);
  };

  return (
    <Box
      height="300px"
      sx={{ position: "relative" }}
      data-testid="upcoming-events"
    >
      <Grid display="flex" flexDirection="column" gap={3} container>
        <Typography className={classes.title}>Upcoming Events</Typography>

        <Box className={classes.eventsContainer}>
          <Box
            className={`${classes.scrollShadow} ${classes.scrollShadowLeft}`}
          />
          <Box
            className={`${classes.scrollShadow} ${classes.scrollShadowRight}`}
          />
          <Box
            data-testid="upcomingEventsWrapper"
            onScroll={handleScroll}
            className={classes.eventWrapper}
          >
            {!upcomingEvents.length && !globalLoader ? (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginInline: "auto" }}
              >
                There is no upcoming events
              </Typography>
            ) : (
              upcomingEvents.map((event) => (
                <MemoizedEventCard
                  event={event}
                  openProjectDetails={openProjectDetails}
                  key={event.id}
                />
              ))
            )}
          </Box>
        </Box>
      </Grid>
      {openDetails && projectId && (
        <ProjectDetailsModal
          setModalOpen={setOpenDetails}
          modalOpen={openDetails}
          projectId={projectId}
        />
      )}
    </Box>
  );
}

export default UpcomingEvents;
