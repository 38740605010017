import { ReactElement, useContext, useEffect, useState } from "react";
import { OrganizationalReach } from "../../Types/Analytics";
import { AnalyticsHttpService } from "../../Http/Analytics/Analytics.http.service";
import { Box, Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import BusinessIcon from "@mui/icons-material/Business";
import theme from "../../theme";
import { getRamp } from "../../utils";
import { useSnackbar } from "notistack";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { DashboardFilterOptions } from "../../Types/Dashboard";

const gradientColors = getRamp(
  theme.palette.primary.main,
  theme.palette.secondary.main,
  6
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      ...theme.typography.h5,
      color: theme.palette.grey[600],
      fontWeight: "500",
      position: "absolute",
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    contentWrapper: {
      width: "100%",
      height: "100%",
      position: "absolute",
      display: "flex",
      gap: theme.spacing(6),
      justifyContent: "center",
      alignItems: "center",
    },
    ventureClientName: {
      position: "absolute",
      top: "100%",
      color: theme.palette.grey[600],
      fontWeight: "500",
      textTransform: "uppercase",
      textAlign: "center",
      lineHeight: "1.2",
    },
    right: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      isolation: "isolate",
    },
    circle: {
      width: "56px",
      aspectRatio: "1/1",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: `-webkit-linear-gradient(180deg, ${gradientColors[0]}, ${gradientColors[1]})`,
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        width: "40%",
        height: "3px",
        background: `-webkit-linear-gradient(0deg, ${gradientColors[1]}, ${gradientColors[2]})`,
        left: "100%",
      },
      "&::after": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "180%",
        background: `transparent`,
        left: "140%",
        borderWidth: "3px 0 3px 3px",
        borderStyle: "solid",
        borderImage: `-webkit-linear-gradient(0deg, ${gradientColors[2]}, ${gradientColors[3]}) 15`,
      },
    },
    reach_numbers: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      color: "white",
      fontWeight: 500,
      background: `-webkit-linear-gradient(0deg, ${gradientColors[3]}, ${gradientColors[7]})`,
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
      borderRadius: "100px",
      width: "fit-content",
      fontSize: "14px",
    },
  })
);

interface Props {
  dashboardFilters: DashboardFilterOptions;
  refetch: boolean;
}

function OrganizationalReachSection({
  dashboardFilters,
  refetch,
}: Props): ReactElement {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const [organizationalReach, setOrganizationalReach] =
    useState<OrganizationalReach>({
      numberOfBusinessUnits: 0,
      numberOfContacts: 0,
      ventureClientUnitName: "",
    });

  useEffect(() => {
    const fetchData = async () => {
      setGlobalLoader(true);
      try {
        const data = await AnalyticsHttpService.getOrganizationalReach({
          businessUnitIds: dashboardFilters.businessUnitsIds,
          focusAreasIds: dashboardFilters.focusAreasIds,
        });
        setOrganizationalReach(data);
        // eslint-disable-next-line
      } catch (error: any) {
        enqueueSnackbar(
          `Something went wrong with fetching organizational reach: ${error.message}`,
          {
            variant: "error",
          }
        );
      } finally {
        setGlobalLoader(false);
      }
    };
    fetchData();
  }, [refetch]);

  return (
    <Box data-testid="organizational-reach" height="300px">
      <Grid display="flex" flexDirection="column" gap={3} container>
        <Typography className={classes.title}>Organizational Reach</Typography>

        <Grid className={classes.contentWrapper}>
          <Box className={classes.circle} color="#fff">
            <BusinessIcon color="inherit" fontSize="medium" />
            <Typography className={classes.ventureClientName}>
              {organizationalReach.ventureClientUnitName}
            </Typography>
          </Box>

          <Box className={classes.right}>
            <Typography className={classes.reach_numbers}>
              <span style={{ fontSize: "40px" }}>
                {organizationalReach?.numberOfBusinessUnits}
              </span>{" "}
              Organizational Units
            </Typography>
            <Typography className={classes.reach_numbers}>
              <span style={{ fontSize: "40px" }}>
                {organizationalReach?.numberOfContacts}
              </span>{" "}
              Contacts
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
export default OrganizationalReachSection;
