import { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  styled,
} from "@mui/material";
import ProjectTimelineSection from "./ProjectTimeline";
import OrganizationalReachSection from "./OrganizationalReach";
import ActiveProjectsSection from "./ActiveProjects";
import UpcomingEvents from "./Events/UpcomingEvents";
import ConversionRate from "./ConversionRate";
import BusinessValue from "./BusinessValue";
import RecentEvents from "./Events/RecentEvents";
import { Close, FilterList } from "@mui/icons-material";
import { BusinessUnitHttpService } from "../../Http/BusinessUnit/BusinessUnit.http.service";
import { FocusArea } from "../../Types/VentureClient";
import { BusinessUnit } from "../../Types/BusinessUnit";
import { useSnackbar } from "notistack";
import FocusAreaHttpService from "../../Http/FocusArea/FocusArea.http.service";
import { DashboardFilterOptions } from "../../Types/Dashboard";
import useLocalStorage from "../../Hooks/useLocalStorage";

const CardOutlined = styled(Card)(({ theme }) => ({
  position: "relative",
  borderRadius: "5px",
  border: `1px solid ${theme.palette.divider}`,
}));

const SelectFilterWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const FilterButton = styled(Button)(({ theme }) => ({
  width: "109px",
  height: "55px",
  background: "linear-gradient(92.72deg, #FFFFFF 5.64%, #EEEEEE 155.94%)",
  boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
  color: theme.palette.grey[600],
}));

const FilterDrawer = styled(Box)(({ theme }) => ({
  position: "absolute",
  background: "white",
  zIndex: 100,
  transform: "translateY(70px)",
  padding: theme.spacing(3),
  borderRadius: "10px",
  boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.25)",
}));

const FilterChipsWrapper = styled(Box)(() => ({
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 1 /* number of lines to show */,
  WebkitBoxOrient: "vertical",
  width: "fit-content",
}));

const defaultFilters = {
  businessUnitsIds: [],
  focusAreasIds: [],
};

function Dashboard(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [filterOpen, setFilterOpen] = useState(false);
  const [organizationalUnits, setOrganizationalUnits] = useState<
    BusinessUnit[]
  >([]);
  const [focusAreas, setFocusAreas] = useState<FocusArea[]>([]);
  const [refetch, setRefetch] = useState(false);
  const [dashboardFilters, setDashboardFilters] =
    useLocalStorage<DashboardFilterOptions>("dashboardFilters", defaultFilters);
  const [activeFilters, setActiveFilters] = useState(dashboardFilters);
  const [filterChips, setFilterChips] = useState({
    businessUnitsIds: dashboardFilters.businessUnitsIds,
    focusAreasIds: dashboardFilters.focusAreasIds,
  });

  useEffect(() => {
    try {
      BusinessUnitHttpService.getBusinessUnits().then(
        (orgnanizationalUnits) => {
          setOrganizationalUnits(orgnanizationalUnits);
        }
      );
    } catch (error) {
      enqueueSnackbar("Failed to fetch organizational units", {
        variant: "error",
      });
    }
    try {
      FocusAreaHttpService.getAll().then((focusAreas) => {
        setFocusAreas(focusAreas);
      });
    } catch (error) {
      enqueueSnackbar("Failed to fetch focus areas", {
        variant: "error",
      });
    }
  }, []);

  const handleFilterChange = (
    key: "businessUnitsIds" | "focusAreasIds",
    value: number[] | string
  ) => {
    setActiveFilters({
      ...activeFilters,
      [key]: value,
    });
  };

  const handleChipClick = (
    unit: number,
    chipType: "businessUnitsIds" | "focusAreasIds"
  ) => {
    setActiveFilters((prev) => ({
      ...prev,
      [chipType]: prev[chipType].filter((item) => item !== unit),
    }));
    setFilterChips((prev) => ({
      ...prev,
      [chipType]: prev[chipType].filter((item) => item !== unit),
    }));
    setDashboardFilters({
      ...activeFilters,
      [chipType]: activeFilters[chipType].filter((item) => item !== unit),
    });

    setRefetch(!refetch);
  };

  const handleAcceptFilter = () => {
    setFilterOpen(false);
    setRefetch(!refetch);
    setFilterChips(activeFilters);
    setDashboardFilters(activeFilters);
  };

  const handleResetFilter = () => {
    setFilterOpen(false);
    setRefetch(!refetch);
    setActiveFilters(defaultFilters);
    setFilterChips(defaultFilters);
    setDashboardFilters(defaultFilters);
  };

  return (
    <Grid container display="flex" spacing={4} width="100%">
      <Grid container item justifyContent="end">
        <Box display="flex" alignItems="center">
          <FilterChipsWrapper data-testid="filter-chips-wrapper">
            {filterChips.businessUnitsIds?.map((selectedFilterId) => {
              const value = organizationalUnits.find(
                (area) => area.id === selectedFilterId
              )?.name;

              return (
                <Chip
                  key={`business-unit-chip-${selectedFilterId}`}
                  label={value}
                  variant="filled"
                  onDelete={() =>
                    handleChipClick(selectedFilterId, "businessUnitsIds")
                  }
                  sx={{ ml: 1 }}
                />
              );
            })}
            {filterChips.focusAreasIds?.map((selectedFilterId) => {
              const value = focusAreas.find(
                (area) => area.id === selectedFilterId
              )?.name;

              return (
                <Chip
                  key={`focus-area-chip-${selectedFilterId}`}
                  label={value}
                  variant="filled"
                  onDelete={() =>
                    handleChipClick(selectedFilterId, "focusAreasIds")
                  }
                  sx={{ ml: 1 }}
                />
              );
            })}
          </FilterChipsWrapper>

          <FilterButton
            data-testid="filter-button"
            onClick={() => setFilterOpen(true)}
            sx={{ ml: 4 }}
          >
            <FilterList sx={{ fontSize: "1.2rem", mr: 1 }} /> Filter
          </FilterButton>
        </Box>

        {filterOpen && (
          <FilterDrawer data-testid="filter-drawer">
            <Box display="flex" alignItems="center">
              <Typography variant="h6">Filters</Typography>
              <Button
                data-testid="filter-drawer-close-button"
                sx={{ ml: "auto" }}
                color="primary"
                onClick={() => setFilterOpen(false)}
              >
                <Close />
              </Button>
            </Box>
            <Divider />
            <SelectFilterWrapper>
              {/* Organizational Unit Filter */}
              <FormControl>
                <InputLabel id="organizational-unit">
                  Organizational Unit
                </InputLabel>
                <Select
                  labelId="organizational-unit"
                  data-testid="business-unit-select"
                  multiple
                  value={activeFilters.businessUnitsIds}
                  input={<OutlinedInput label="Organizational Unit" />}
                  onChange={(event) =>
                    handleFilterChange("businessUnitsIds", event.target.value)
                  }
                  sx={{ width: "35ch" }}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (value) =>
                          organizationalUnits.find((unit) => unit.id === value)
                            ?.name || ""
                      )
                      .join(", ")
                  }
                >
                  {organizationalUnits
                    .sort(function (option1, option2) {
                      return option1.name.localeCompare(option2.name, "en", {
                        numeric: true,
                      });
                    })
                    .map((unit) => {
                      return (
                        <MenuItem
                          key={unit.id}
                          value={unit.id}
                          data-testid={`business-unit-${unit.id}`}
                        >
                          <Checkbox
                            checked={
                              activeFilters.businessUnitsIds.indexOf(unit.id) >
                              -1
                            }
                          />
                          <ListItemText primary={unit.name} />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              {/* Focus Area Filter */}
              <FormControl>
                <InputLabel id="focus-area">Focus Area</InputLabel>
                <Select
                  labelId="focus-area"
                  data-testid="focus-area-select"
                  multiple
                  value={activeFilters.focusAreasIds}
                  input={<OutlinedInput label="Focus Area" />}
                  onChange={(event) =>
                    handleFilterChange("focusAreasIds", event.target.value)
                  }
                  sx={{ width: "35ch" }}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (value) =>
                          focusAreas.find((unit) => unit.id === value)?.name ||
                          ""
                      )
                      .join(", ")
                  }
                >
                  {focusAreas
                    .sort(function (option1, option2) {
                      return option1.name.localeCompare(option2.name, "en", {
                        numeric: true,
                      });
                    })
                    .map((area) => {
                      return (
                        <MenuItem
                          key={area.id}
                          value={area.id}
                          data-testid={`focus-area-${area.id}`}
                        >
                          <Checkbox
                            checked={
                              activeFilters.focusAreasIds.indexOf(area.id) > -1
                            }
                          />
                          <ListItemText primary={area.name} />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </SelectFilterWrapper>
            <Grid item display="flex" gap={2} justifyContent="end" mt={3}>
              <Button
                onClick={handleResetFilter}
                variant="outlined"
                data-testid="reset-button"
              >
                Reset
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleAcceptFilter}
                data-testid="accept-button"
              >
                Accept
              </Button>
            </Grid>
          </FilterDrawer>
        )}
      </Grid>

      <Grid container item>
        <Grid xs={12} item>
          <CardOutlined>
            <ActiveProjectsSection
              dashboardFilters={activeFilters}
              refetch={refetch}
            />
          </CardOutlined>
        </Grid>
      </Grid>
      <Grid container item display="flex" flexWrap="nowrap" gap={4}>
        <Grid item xs={4}>
          <CardOutlined>
            <ConversionRate
              dashboardFilters={activeFilters}
              refetch={refetch}
            />
          </CardOutlined>
        </Grid>
        <Grid item xs={4}>
          <CardOutlined>
            <ProjectTimelineSection
              dashboardFilters={activeFilters}
              refetch={refetch}
            />
          </CardOutlined>
        </Grid>
        <Grid item xs={4}>
          <CardOutlined>
            <BusinessValue dashboardFilters={activeFilters} refetch={refetch} />
          </CardOutlined>
        </Grid>
      </Grid>
      <Grid container item display="flex" flexWrap="nowrap" gap={4}>
        <Grid item xs={4}>
          <CardOutlined>
            <RecentEvents dashboardFilters={activeFilters} refetch={refetch} />
          </CardOutlined>
        </Grid>
        <Grid item xs={4}>
          <CardOutlined>
            <UpcomingEvents
              dashboardFilters={activeFilters}
              refetch={refetch}
            />
          </CardOutlined>
        </Grid>
        <Grid item xs={4}>
          <CardOutlined>
            <OrganizationalReachSection
              dashboardFilters={activeFilters}
              refetch={refetch}
            />
          </CardOutlined>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Dashboard;
