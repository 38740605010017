import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  styled,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useContext, useReducer, Reducer, ReactElement } from "react";
import { useSnackbar } from "notistack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import { UserContext } from "../../../../Context/UserContext";
import ObjectiveHttpService from "../../../../Http/Objective/Objective.Http.service";
import { Objective } from "../../../../Types/Objective";
import theme from "../../../../theme";
import { getErrorMessage } from "../../../../utils";

interface Props {
  projectId: number;
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  objective?: Objective;
  updateObjective?: (objective: Objective) => void;
  createObjective: (newObjective: Objective) => void;
}

const ActionButtons = styled("div")(() => ({
  marginLeft: "auto",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

const InfoSection = styled(Box)(() => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(6),
  backgroundColor: theme.palette.other.surfaceBright,
  borderRadius: theme.shape.borderRadius * 3,
  boxShadow:
    "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
}));

const FormContainer = styled(Box)(({ theme }) => ({
  marginBlock: theme.spacing(3, 4),
  "& > div": {
    width: "100%",
  },
}));

const ManageObjectiveModal = (props: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const user = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newObjective, setNewObjective] = useReducer<
    Reducer<Objective, Partial<Objective>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    props.objective || ({ projectId: props.projectId } as Objective)
  );
  const [isInfoSectionOpen, setIsInfoSectionOpen] = useState(true);

  const isBuyStageActive = activeStep === 2;
  const isCreateMode = !props.objective;

  const createObjective = async () => {
    setIsLoading(true);
    await ObjectiveHttpService.createObjective(newObjective)
      .then((objective: Objective) => {
        props.createObjective(objective);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not create Objective: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const editObjective = async () => {
    setIsLoading(true);
    await ObjectiveHttpService.updateObjective(newObjective)
      .then(() => {
        props.updateObjective?.(newObjective);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update Objective: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  return (
    <>
      <Dialog
        open={props.modalOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => props.setModalOpen(false)}
        data-testid="manage-objective-modal"
      >
        <DialogTitle display="flex" data-testid="manage-objective-header">
          {isCreateMode ? "New Objective" : "Edit Objective"}
          <ActionButtons>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <Button onClick={() => props.setModalOpen(false)}>Close</Button>
                <Button
                  onClick={isCreateMode ? createObjective : editObjective}
                >
                  Save
                </Button>
              </>
            )}
          </ActionButtons>
        </DialogTitle>
        <DialogContent dividers>
          {isCreateMode && isInfoSectionOpen && (
            <InfoSection data-testid="info-section">
              <Box display="flex" alignItems="center" gap={1}>
                <InfoIcon color="primary" sx={{ fontSize: "2rem" }} />
                <Typography>Hello {user?.name}</Typography>
                <IconButton
                  sx={{ ml: "auto" }}
                  onClick={() => setIsInfoSectionOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box p={theme.spacing(2, 2, 0)}>
                <Typography variant="body2">
                  You are about to create a new objective for your project
                  <br />
                  <br />
                  Project <b>objectives</b> should clearly describe the pilot
                  project&apos;s goals and define which aspects of the solution
                  are to be evaluated.
                  <br />
                  <br />
                  Here are a few tips to make things easier:
                </Typography>
                <List
                  dense
                  sx={{
                    listStyleType: "disc",
                    pl: 3,
                  }}
                >
                  <ListItem disablePadding sx={{ display: "list-item" }}>
                    <ListItemText primary="Clearly articulate what the involved parties are looking to achieve or evaluate in this pilot project" />
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "list-item" }}>
                    <ListItemText primary="Ensure that your objective can be measured or quantified in some way." />
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "list-item" }}>
                    <ListItemText primary="Set relevant objectives that are meaningful to the project and avoid ambiguous, vague or contradictory objectives." />
                  </ListItem>
                </List>
              </Box>
            </InfoSection>
          )}

          <FormContainer>
            {isBuyStageActive ? (
              <TextField
                label="Objective"
                multiline
                value={newObjective.name}
                variant="outlined"
                onChange={(event) =>
                  setNewObjective({ name: event.target.value })
                }
                placeholder="e.g. Demonstrate the capacity of the startup solution to substantially reduce energy consumption within the plant"
                helperText={
                  isCreateMode &&
                  "Enter a clear and concise description for the project objective."
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ "data-testid": "objective-name-input" }}
              />
            ) : (
              <>
                <Typography mb={3}>{props.objective?.name}</Typography>
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Objective Achieved"
                        disableRipple
                        checked={newObjective.fulfilled === true}
                        checkedIcon={
                          <CheckCircleIcon
                            sx={{ color: theme.palette.success.main }}
                          />
                        }
                        icon={<CheckCircleIcon sx={{ color: "grey.300" }} />}
                        onChange={() => {
                          setNewObjective({ fulfilled: true });
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        color={
                          newObjective.fulfilled === true
                            ? theme.palette.success.main
                            : "inherit"
                        }
                      >
                        Objective Achieved
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Objective Not Achieved"
                        disableRipple
                        checked={newObjective.fulfilled === false}
                        checkedIcon={
                          <CancelIcon
                            sx={{ color: theme.palette.error.main }}
                          />
                        }
                        icon={<CancelIcon sx={{ color: "grey.300" }} />}
                        onChange={() => {
                          setNewObjective({ fulfilled: false });
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        color={
                          newObjective.fulfilled === false
                            ? theme.palette.error.main
                            : "inherit"
                        }
                      >
                        Objective Not Achieved
                      </Typography>
                    }
                  />
                </FormGroup>
              </>
            )}
          </FormContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ManageObjectiveModal;
