import { Grid, Paper } from "@mui/material";
import { ReactElement, useState } from "react";
import { Project } from "../../../../../Types/Project";
import OverviewSection from "./OverviewSection/OverviewSection";
import SolutionFitMatrixTable from "../../../SharedComponents/SolutionFitMatrixComponents/SolutionFitMatrixTable/SolutionFitMatrixTable";
import SolutionFitMatrixHeader from "../../../SharedComponents/SolutionFitMatrixComponents/SolutionFitMatrixHeader/SolutionFitMatrixHeader";

interface CuratedListProps {
  project: Project;
  handleSave: () => void;
}

export default function CuratedList(props: CuratedListProps): ReactElement {
  const [selectedDetailsView, setSelectedDetailsView] = useState<boolean>(true);
  const [isAddingStartup, setIsAddingStartup] = useState(false);

  return (
    <>
      <Grid
        container
        spacing={3}
        component={Paper}
        variant="outlined"
        sx={{ p: 4, isolation: "isolate" }}
      >
        <OverviewSection
          project={props.project}
          handleSave={props.handleSave}
        />
      </Grid>

      <Grid
        container
        spacing={3}
        component={Paper}
        variant="outlined"
        sx={{
          mt: 3,
          p: 4,
          isolation: "isolate",
          "& .flipper": {
            width: "100%",
          },
        }}
      >
        <SolutionFitMatrixHeader
          setSelectedDetailsView={(view: boolean) =>
            setSelectedDetailsView(view)
          }
          selectedDetailsView={selectedDetailsView}
          projectFunnelStage="discover"
          opportunities={props.project.opportunities}
        />

        <SolutionFitMatrixTable
          projectId={props.project.id}
          clusters={props.project.solutionClusters}
          opportunities={props.project.opportunities}
          handleSave={props.handleSave}
          detailsView={selectedDetailsView}
          requirements={
            props.project.requirements ? props.project.requirements : []
          }
          projectFunnelStage="discover"
          currentProjectStage={props.project.funnelStage}
          isAddingStartup={isAddingStartup}
          setIsAddingStartup={setIsAddingStartup}
        />
      </Grid>
    </>
  );
}
