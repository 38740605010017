import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  ReactElement,
  Reducer,
  useEffect,
  useReducer,
  useState,
  Fragment,
  useContext,
} from "react";
import { ProjectHttpService } from "../../../../../Http/Project/Project.http.service";
import { Project } from "../../../../../Types/Project";
import StrategicBenefitsSection from "../../../SharedComponents/StrategicBenefitsSection/StrategicBenefitsSection";
import ObjectivesSection from "../../../SharedComponents/ObjectiveSection/ObjectivesSection";
import PilotTeamSection from "./Form/PilotTeamSection";
import PilotResourcesSection from "./Form/PilotResourcesSection";
import PilotBackgroundSection from "../../../SharedComponents/PilotBackgroundSection/PilotBackgroundSection";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import TimeplanAndMilestones from "./Form/TimeplanAndMilestones/TimeplanAndMilestones";

interface Props {
  project: Project;
  handleSave: (withScroll?: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Typography: {
      marginTop: theme.spacing(6),
    },
  })
);
export default function Form(props: Props): ReactElement {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.project
  );
  const {
    setGlobalEditMode,
    globalEditMode,
    setShouldSaveProject,
    shouldSaveProject,
  } = useContext(GlobalProjectEditContext);

  useEffect(() => {
    setProject(props.project);
  }, [props.project]);

  useEffect(() => {
    setGlobalEditMode(editMode);
  }, [editMode]);

  useEffect(() => {
    const saveChanges = async () => {
      if (shouldSaveProject) {
        await saveProject();
        setShouldSaveProject(false);
      }
    };

    saveChanges();
  }, [shouldSaveProject]);

  const cancelEdit = () => {
    setProject(props.project);
    setEditMode(false);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const saveProject = async () => {
    await ProjectHttpService.updateProject(project);
    props.handleSave();
    setEditMode(false);
  };

  return (
    <div id="buy-data-view-form">
      <form noValidate autoComplete="off">
        <Box
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Typography variant="h6">Buy Insights</Typography>
          {!editMode && !globalEditMode && (
            <Button onClick={() => setEditMode(true)} id="buy-edit-form-button">
              <EditOutlinedIcon />
            </Button>
          )}
        </Box>
        {project && (
          <Fragment>
            <Typography variant="h6" className={classes.Typography}>
              Pilot Team
            </Typography>
            <PilotTeamSection
              project={project}
              editMode={editMode}
              onChange={setProject}
            />

            <Typography variant="h6" className={classes.Typography}>
              Pilot Resources
            </Typography>
            <PilotResourcesSection
              projectId={project.id}
              project={project}
              editMode={editMode}
              onChange={setProject}
            />
            <Typography variant="h6" className={classes.Typography}>
              Pilot Background
            </Typography>
            <PilotBackgroundSection
              project={project}
              editMode={editMode}
              onChange={setProject}
            />

            <ObjectivesSection
              projectId={props.project.id}
              objectives={props.project.objectives || []}
              handleSave={() => {
                editMode ? null : props.handleSave(false);
              }}
            />

            <TimeplanAndMilestones
              project={{
                ...props.project,
                meetings: props.project.meetings.filter(
                  (meeting) => meeting.isInitial === true
                ),
                phases: props.project.phases.filter(
                  (phase) => phase.isInitial === true
                ),
              }}
              handleSave={() => {
                editMode ? null : props.handleSave(false);
              }}
            />

            <Typography variant="h6" className={classes.Typography}>
              Strategic Benefits
            </Typography>
            <StrategicBenefitsSection
              project={project}
              editMode={editMode}
              onChange={setProject}
            />
          </Fragment>
        )}
      </form>
      {editMode && (
        <Box
          sx={{
            justifyContent: "flex-end",
            flexDirection: "row",
            display: "flex",
            m: 2,
          }}
        >
          <Button onClick={cancelEdit} color="primary">
            Cancel
          </Button>
          <Button
            onClick={saveProject}
            variant="contained"
            color="secondary"
            sx={{ marginLeft: "15px" }}
          >
            Save Project
          </Button>
        </Box>
      )}
    </div>
  );
}
