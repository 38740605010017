import { DeleteOutline } from "@mui/icons-material";
import { Box, styled } from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../theme";
import DeleteModal from "../../UI/Modals/DeleteModal/DeleteModal";

const DeleteContainer = styled(Box)(() => ({
  cursor: "pointer",
  color: theme.palette.grey[600],
}));

const HoverableDeleteIcon = styled(DeleteOutline)(() => ({
  opacity: 0,
  transition: "opacity 0.2s",
}));

interface FadingDeleteButtonProps {
  id: number;
  entity: "Project Lead Startup" | "Project Lead Leader";
  onDelete: () => void;
}

export default function FadingDeleteButton(
  props: FadingDeleteButtonProps
): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);

  const testId =
    "delete-" +
    props.entity.toLowerCase().replaceAll(" ", "-") +
    "-" +
    props.id.toString();

  return (
    <>
      <DeleteContainer data-testid={testId} onClick={() => setModalOpen(true)}>
        <HoverableDeleteIcon
          color="inherit"
          className="deleteIcon"
          fontSize="small"
        />
      </DeleteContainer>
      {modalOpen && (
        <DeleteModal
          entity={props.entity}
          id={props.id}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleSave={props.onDelete}
        />
      )}
    </>
  );
}
