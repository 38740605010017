import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  Typography,
} from "@mui/material";
import { ReactElement, Fragment, useReducer, Reducer, useEffect } from "react";
import { LeadProject } from "../../../Types/LeadProject";
import CustomTextField from "../../UI/InputFields/CustomTextField";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import UserSelectInput from "../../UI/InputFields/UserSelect";
import { useSnackbar } from "notistack";
import UserHttpService from "../../../Http/User/User.Http.service";
import { Stack } from "@mui/system";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";

const ActionButtons = styled(DialogActions)(({ theme }) => ({
  margin: theme.spacing(2),
}));

interface editProjectLeadRequiredFields {
  Name?: string;
  "Short Description"?: string;
}

interface ProjectLeadSummaryEditModalProps {
  handleModalClose: () => void;
  modalOpen: boolean;
  leadProject: LeadProject;
  onRefresh: () => void;
}

function ProjectLeadSummaryEditModal(
  props: ProjectLeadSummaryEditModalProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [newProjectLead, setNewProjectLead] = useReducer<
    Reducer<LeadProject, Partial<LeadProject>>
  >((state, newState) => ({ ...state, ...newState }), props.leadProject);

  const showError = (value: string) => {
    enqueueSnackbar(`Please Enter ${value}`, {
      variant: "error",
    });
  };

  const editProjectLeadRequiredFields: editProjectLeadRequiredFields = {
    Name: newProjectLead.name,
    "Short Description": newProjectLead.shortDescription,
  };

  const isArchived = () => newProjectLead.status === "archived";
  const isOnHold = () => newProjectLead.status === "on hold";
  const isActive = () => newProjectLead.status === "active";
  const hasComment = () => !!newProjectLead.statusComment;

  const checkRequiredFields = () => {
    const emptyRequiredFieldsArray: string[] = [];
    for (const [key, value] of Object.entries(editProjectLeadRequiredFields)) {
      if (("" + value).trim().length === 0 || !value) {
        emptyRequiredFieldsArray.push(key);
      }
    }
    return emptyRequiredFieldsArray;
  };

  const saveProject = async () => {
    if (!isActive() && !hasComment()) {
      return showError("Status Comment");
    }

    const emptyRequiredFields = checkRequiredFields();
    if (emptyRequiredFields.length > 0) {
      return showError(emptyRequiredFields[0]);
    }

    await LeadProjectsHttpService.updateLeadProject(newProjectLead);
    props.handleModalClose();
    props.onRefresh();
  };

  useEffect(() => {
    UserHttpService.getUser(newProjectLead.projectOwnerId).then((user) => {
      setNewProjectLead({
        projectOwnerId: user.id,
        projectOwner: user,
      });
    });
  }, []);

  return (
    <Fragment>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={props.modalOpen}
        onClose={props.handleModalClose}
        data-testid="edit-lead-project-modal"
      >
        <DialogTitle>Edit Lead</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <Stack gap={3}>
              <Stack>
                <Typography>Basic Information</Typography>
                <CustomTextField
                  id="name"
                  label="Name"
                  editMode={true}
                  value={newProjectLead.name}
                  onChange={(e) => {
                    setNewProjectLead({
                      name: e.target.value,
                    });
                  }}
                  fullWidth
                  required
                />
                <CustomTextField
                  id="shortDescription"
                  label="Short Description"
                  editMode={true}
                  value={newProjectLead.shortDescription}
                  onChange={(e) => {
                    setNewProjectLead({
                      shortDescription: e.target.value,
                    });
                  }}
                  fullWidth
                  required
                  multiline
                  minRows={3}
                />
              </Stack>
              <Stack gap={2}>
                <Typography>Project Administrators</Typography>
                <UserSelectInput
                  onChange={(user) => {
                    setNewProjectLead({
                      projectOwnerId: user?.id,
                    });
                  }}
                  user={newProjectLead.projectOwner}
                />
              </Stack>
              <Stack>
                <Typography>Project Status</Typography>
                <SelectInput
                  id="status"
                  labelText="Status"
                  value={newProjectLead.status}
                  onChange={(e) => {
                    setNewProjectLead({
                      status: e.target.value as LeadProject["status"],
                    });
                  }}
                  selectValues={[
                    { id: "active", name: "active" },
                    { id: "archived", name: "archived" },
                    { id: "on hold", name: "on hold" },
                  ]}
                  editMode
                  required
                  fullWidth
                />
                <CustomTextField
                  id="statusComment"
                  label="Comment"
                  editMode={true}
                  value={newProjectLead.statusComment}
                  onChange={(e) =>
                    setNewProjectLead({
                      statusComment: e.target.value,
                    })
                  }
                  fullWidth
                  required={isArchived() || isOnHold()}
                  multiline
                  minRows={1}
                />
              </Stack>
            </Stack>
          </form>
        </DialogContent>
        <ActionButtons>
          <Button onClick={props.handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveProject} variant="contained" color="secondary">
            Save Lead
          </Button>
        </ActionButtons>
      </Dialog>
    </Fragment>
  );
}

export default ProjectLeadSummaryEditModal;
