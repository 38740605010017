import React, { Fragment, ReactElement, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  debounce,
  InputLabel,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { ClientContactDTO } from "../../../Types/ClientContact";
import ClientContactHttpService from "../../../Http/ClientContact/ClientContact.http.service";
import CustomToolTip from "../CustomToolTip";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import DetailsClientContactModal from "../../Contacts/DetailsClientContactModal";

interface Props {
  editMode: boolean;
  labelText: string;
  ventureClientId: number;
  // eslint-disable-next-line
  onChange: any;
  contactData: ClientContactDTO | ClientContactDTO[] | undefined;
  fieldId: string;
  multiSelect?: boolean;
  required?: boolean;
  toolTipText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      minWidth: "35ch",
      "& .MuiInputLabel-root.Mui-error, .MuiInputLabel-asterisk.Mui-error": {
        color: theme.palette.warning.main,
      },
      "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.warning.main,
      },
      "& .MuiInput-underline:before, .MuiInput-underline:hover:before": {
        borderBottom: "none",
      },
    },
    contactRoot: {
      marginTop: theme.spacing(1.8),
      marginBottom: theme.spacing(2),
      minWidth: "35ch",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    contactLabel: {
      fontSize: "0.8rem",
    },
    contactValue: {
      fontSize: "1rem",
      color: theme.palette.primary.main,
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

export default function ClientContactSelect(props: Props): ReactElement {
  const classes = useStyles();
  const [contacts, setContacts] = useState<ClientContactDTO[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<ClientContactDTO[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedClientContact, setSelectedClientContact] =
    useState<ClientContactDTO>();
  const [openClientContact, setOpenClientContact] = useState<boolean>(false);

  const searchForContacts = async (searchValue: string): Promise<void> => {
    setIsLoading(true);
    if (searchValue.trim().length > 0)
      setContacts(
        await ClientContactHttpService.getContactsByName(
          props.ventureClientId,
          searchValue
        )
      );
    setIsLoading(false);
  };

  const handleEditSave = () => {
    window.location.reload();
  };

  //Used to initialize the state when component is rendered
  useEffect(() => {
    if (props.contactData && props.multiSelect) {
      setSelectedContacts(props.contactData as ClientContactDTO[]);
    } else if (!props.multiSelect) {
      setSelectedContacts([{ ...(props.contactData as ClientContactDTO) }]);
    }
  }, []);

  //used to keep state and parent props in sync
  useEffect(() => {
    if (props.multiSelect) {
      props.onChange(selectedContacts as ClientContactDTO[]);
    } else if (!props.multiSelect) {
      props.onChange({
        [props.fieldId]: selectedContacts[0] ? selectedContacts[0].id : null,
      });
    }
  }, [selectedContacts]);

  const debouncedSearchForContacts = debounce(searchForContacts, 500);

  return (
    <Fragment>
      {props.editMode ? (
        props.multiSelect ? (
          <Autocomplete
            id={props.fieldId}
            multiple
            forcePopupIcon={false}
            options={contacts as ClientContactDTO[]}
            isOptionEqualToValue={(
              option: ClientContactDTO,
              value: ClientContactDTO
            ) => option.id === value.id}
            getOptionLabel={(option: ClientContactDTO) =>
              option.departmentCode
                ? `${option.name} (${option.departmentCode})`
                : option.name
            }
            filterOptions={(options) => options}
            value={selectedContacts as ClientContactDTO[]}
            filterSelectedOptions
            onInputChange={(_, newValue: string) =>
              debouncedSearchForContacts(newValue)
            }
            onChange={(_, selectedContactsList) =>
              setSelectedContacts(selectedContactsList as ClientContactDTO[])
            }
            className={classes.root}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  !!!props.toolTipText ? (
                    props.labelText
                  ) : (
                    <>
                      <span>{props.labelText}</span>
                      {props.editMode && (
                        <CustomToolTip
                          id={`${props.fieldId}Tooltip`}
                          key={`${props.fieldId}Tooltip`}
                          toolTipText={props.toolTipText}
                        />
                      )}
                    </>
                  )
                }
                id={props.fieldId}
                placeholder="search by name"
                required={!!props.required}
                focused={false}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    marginTop: props.toolTipText ? "-7px" : "-2px",
                  },
                }}
                error={
                  props.required &&
                  !!!Object.keys(selectedContacts[0] || {}).length
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {params.InputProps.endAdornment}
                      {isLoading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <Autocomplete
            id={props.fieldId}
            options={contacts}
            forcePopupIcon={false}
            getOptionLabel={(option) =>
              option.departmentCode
                ? `${option.name} (${option.departmentCode})`
                : option.name || ""
            }
            filterOptions={(options) => options}
            value={
              !!Object.keys(selectedContacts[0] || {}).length
                ? (selectedContacts[0] as ClientContactDTO)
                : null
            }
            filterSelectedOptions
            isOptionEqualToValue={(
              option: ClientContactDTO,
              value: ClientContactDTO
            ) => option.id === value.id}
            onInputChange={(_, newValue: string) =>
              debouncedSearchForContacts(newValue)
            }
            onChange={(_, selectedContact) =>
              setSelectedContacts([(selectedContact as ClientContactDTO) || ""])
            }
            className={classes.root}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  !!!props.toolTipText ? (
                    props.labelText
                  ) : (
                    <>
                      <span>{props.labelText}</span>
                      {props.editMode && (
                        <CustomToolTip
                          id={`${props.fieldId}Tooltip`}
                          key={`${props.fieldId}Tooltip`}
                          toolTipText={props.toolTipText}
                        />
                      )}
                    </>
                  )
                }
                id={props.fieldId}
                placeholder="search by name"
                focused={false}
                required={!!props.required}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    marginTop: props.toolTipText ? "-7px" : "-2px",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {params.InputProps.endAdornment}
                      {isLoading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Fragment>
                  ),
                }}
                error={
                  props.required &&
                  !!!Object.keys(selectedContacts[0] || {}).length
                }
              />
            )}
          />
        )
      ) : (
        <Box className={classes.contactRoot} data-testid="client-contact">
          <InputLabel className={classes.contactLabel}>
            {props.labelText}
          </InputLabel>
          {selectedContacts.length
            ? selectedContacts.flatMap((contact, idx) => (
                <Typography
                  key={idx}
                  data-testid={`contact-select-value-${contact.id}`}
                  className={classes.contactValue}
                  onClick={() => {
                    setOpenClientContact(true);
                    setSelectedClientContact(contact);
                  }}
                >
                  {contact.name}
                </Typography>
              ))
            : "-"}
        </Box>
      )}
      {openClientContact && selectedClientContact && (
        <DetailsClientContactModal
          setModalOpen={setOpenClientContact}
          modalOpen={openClientContact}
          contact={selectedClientContact}
          handleSave={handleEditSave}
          mustFetch
        />
      )}
    </Fragment>
  );
}
