import axios from "axios";
import Phase, { CreatePhaseDTO } from "../../Types/Phase";

export default class PhaseHttpService {
  public static async createPhase(phase: CreatePhaseDTO): Promise<Phase> {
    const response = await axios.post<Phase>(`/api/phases`, phase);
    return response.data;
  }

  public static async deletePhase(phaseId: number): Promise<void> {
    await axios.delete<void>(`/api/phases/${phaseId}`);
  }

  public static async updatePhase(phase: Phase): Promise<void> {
    await axios.put<void>(`/api/phases/${phase.id}`, phase);
  }
}
