import axios from "axios";
import {
  CreateStartupDTO,
  GetStartupsParams,
  Startup,
  StartupDTO,
  StartupFilterCriteria,
} from "../../Types/Startup";
import StartupMapper from "./Startup.mapper";
import { PaginationResult } from "../../Types/Common";

export class StartupHttpService {
  public static async getStartups(
    filterCriteria: StartupFilterCriteria,
    page: number,
    name: string,
    ignoreClient?: boolean
  ): Promise<PaginationResult<Startup>> {
    const params: GetStartupsParams = {
      page: page,
    };
    if (Object.keys(filterCriteria).length != 0) {
      params.filterCriteria = filterCriteria;
    }
    if (name) {
      params.name = name;
    }
    if (ignoreClient) {
      params.ignoreClient = ignoreClient;
    }

    const response = await axios.get<PaginationResult<StartupDTO>>(
      "/api/startups",
      { params: params }
    );

    const startups = response.data.data.map((startup: StartupDTO) =>
      StartupMapper.map(startup)
    );
    return { ...response.data, data: startups };
  }

  public static async getStartupsByName(
    name: string,
    ignoreClient?: boolean
  ): Promise<Startup[]> {
    const params: GetStartupsParams = {
      name: name,
    };
    if (ignoreClient) {
      params.ignoreClient = ignoreClient;
    }
    const response = await axios.get<StartupDTO[]>(`/api/startups`, {
      params: params,
    });

    return response.data.map((startup) => StartupMapper.map(startup));
  }

  public static async getStartupById(
    id: string | number,
    ignoreClient?: boolean
  ): Promise<Startup> {
    const response = ignoreClient
      ? await axios.get<StartupDTO>(`/api/startups/${id}`, {
          params: { ignoreClient: ignoreClient },
        })
      : await axios.get<StartupDTO>(`/api/startups/${id}`);

    return StartupMapper.map(response.data);
  }

  public static async getStartupsByProjectId(
    projectId: number
  ): Promise<Startup[]> {
    const response = await axios.get<StartupDTO[]>(
      `/api/startups/project?id=${projectId}`
    );
    return response.data.map((startup) => StartupMapper.map(startup));
  }

  public static async createStartup(
    startup: CreateStartupDTO
  ): Promise<number> {
    const { data } = await axios.post<{ id: number }>("/api/startups", {
      ...startup,
    });
    return data.id;
  }
}
