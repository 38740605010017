import axios from "axios";
import { StartupContactDTO } from "../../Types/StartupContact";

export default class StartupContactHttpService {
  public static async getContactsByName(
    startupId: number,
    name: string
  ): Promise<StartupContactDTO[]> {
    return (
      await axios.get<StartupContactDTO[]>(`/api/startup-contacts`, {
        params: { startupId, name },
      })
    ).data;
  }
}
