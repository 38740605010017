import {
  Accordion,
  AccordionSummary,
  IconButton,
  Typography,
  Box,
  AccordionDetails,
  styled,
} from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import theme from "../../../../theme";
import { getErrorMessage, thousandSeparator } from "../../../../utils";
import ManageVariableModal from "./ManageVariableModal";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Variable from "./Variable";
import { CalculationType, VariableType } from "../../../../Types/ImpactValue";
import { VariableHttpService } from "../../../../Http/Variable/Variable.http.service";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSnackbar } from "notistack";
import DeleteModal from "../../../UI/Modals/DeleteModal/DeleteModal";

const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: "none",
  background: "transparent",
  marginBlock: 1,
  "&:before": {
    backgroundColor: "transparent",
  },
  "&:not(:first-of-type)": {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  paddingInline: 0,
  "& .MuiAccordionSummary-content": {
    display: "flex",
    alignItems: "center",
  },
  "&:hover button": {
    opacity: "100%",
  },
}));

const CalculationResultWrapper = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(1.5),
  marginInline: "auto 2rem",
}));

const CalculationValue = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  fontSize: "24px",
  fontWeight: "300",
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: theme.spacing(1, 0.2),
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  overflow: "scroll",
  backgroundImage: `
  linear-gradient(to right, white, white),
  linear-gradient(to right, white, white),
  linear-gradient(to right, rgba(0,0,0,.15), rgba(255,255,255,0)),
  linear-gradient(to left, rgba(0,0,0,.15), rgba(255,255,255,0))
`,
  backgroundPosition: "left center, right center, left center, right center",
  backgroundRepeat: "no-repeat",
  backgroundColor: "white",
  backgroundSize: "20px 100%, 20px 100%, 10px 90%, 10px 90%",
  backgroundAttachment: "local, local, scroll, scroll",
}));

const AddVariableButton = styled(IconButton)(() => ({
  display: "flex",
  alignItems: "center",
  color: "#4977C2",
  fontSize: theme.customTypography.fontSize12,
  cursor: "pointer",
  minWidth: "fit-content",
  borderRadius: 0,
  marginLeft: theme.spacing(1),
}));

interface Props {
  calculation: CalculationType;
  index: number;
  variables: VariableType[];
  result: number;
  handleImpactValue: () => void;
  expanded: boolean;
  handleToggleExpand: () => void;
}

const Calculation = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [editVariableModalOpen, setEditVariableModalOpen] = useState(false);
  const [deleteCalculationModalOpen, setDeleteCalculationModalOpen] =
    useState(false);
  const { calculation, index, variables, result } = props;

  const deleteOperatorFromSecondVarible = async () => {
    const secondVariable = variables[1];
    if (secondVariable?.operator) {
      await VariableHttpService.updateVariable({
        ...secondVariable,
        operator: null,
      }).catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not update calculation: ${errorMessage}`, {
          variant: "error",
        });
      });
    }
  };

  const handleImpactValue = async (id?: number) => {
    const isFirstVariable = variables[0].id === id;
    if (isFirstVariable) {
      await deleteOperatorFromSecondVarible();
    }

    props.handleImpactValue();
  };

  const deleteCalculation = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setDeleteCalculationModalOpen(true);
  };

  return (
    <Fragment key={calculation.id}>
      <StyledAccordion
        square
        expanded={props.expanded}
        onChange={props.handleToggleExpand}
      >
        <StyledAccordionSummary
          data-testid={`calculation-${index + 1}`}
          expandIcon={
            <IconButton>
              <ArrowDropDownIcon />
            </IconButton>
          }
        >
          <Typography variant="body1" color="text.disabled">
            {calculation.name} {index + 1}
          </Typography>
          <IconButton
            sx={{ opacity: 0 }}
            onClick={deleteCalculation}
            data-testid={`delete-calculation-${index + 1}`}
          >
            <DeleteOutlineIcon />
          </IconButton>
          <CalculationResultWrapper>
            <CalculationValue data-testid={`calculation-${index + 1}-result`}>
              {thousandSeparator(result)}
            </CalculationValue>
            <Typography color="primary">€</Typography>
          </CalculationResultWrapper>
        </StyledAccordionSummary>
        <StyledAccordionDetails
          data-testid={`calculation-${index + 1}-details`}
        >
          {variables.map((variable) => (
            <Variable
              key={variable.id}
              variable={variable}
              handleImpactValue={handleImpactValue}
            />
          ))}
          <AddVariableButton
            onClick={() => setEditVariableModalOpen(true)}
            data-testid="add-variable"
          >
            <AddIcon fontSize="small" /> Add Variable
          </AddVariableButton>
        </StyledAccordionDetails>
      </StyledAccordion>
      {editVariableModalOpen && (
        <ManageVariableModal
          calculationId={calculation.id}
          setModalOpen={setEditVariableModalOpen}
          modalOpen={editVariableModalOpen}
          handleImpactValue={props.handleImpactValue}
          hasSingleVariable={variables.length === 0}
        />
      )}
      {deleteCalculationModalOpen && (
        <DeleteModal
          id={calculation.id}
          entity="Calculation"
          modalOpen={deleteCalculationModalOpen}
          setModalOpen={setDeleteCalculationModalOpen}
          handleSave={props.handleImpactValue}
        />
      )}
    </Fragment>
  );
};

export default Calculation;
