import { File as DocumentFile } from "../../../Types/File";
import { Fragment, ReactElement } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FileItem from "./FileItem";
import { otherFileTypes } from "../../../Constants/Files";
interface FilesListSectionProps {
  files: DocumentFile[];
  mapId: number | string;
  deleteFile: (fileId: number, objectKey: number | string) => void;
  editMode: boolean;
  stage?: string;
  handleFileRename: (id: number, name: string, mapId: number | string) => void;
  projectId?: number;
  handleSave?: () => void;
}

const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: "none",
  ":before": {
    display: "none",
  },

  "& .MuiAccordionSummary-root": {
    padding: "0",
  },
  "& .MuiAccordionDetails-root": {
    padding: "0",
  },
}));

export function FilesListSection(props: FilesListSectionProps): ReactElement {
  const files = props.files.filter(
    (file) => !otherFileTypes.includes(file.type)
  );
  const otherFiles = props.files.filter((file) =>
    otherFileTypes.includes(file.type)
  );

  return (
    <Fragment>
      {files.map((file, index) => (
        <FileItem
          key={file.id}
          file={file}
          index={index}
          mapId={props.mapId}
          deleteFile={props.deleteFile}
          editMode={props.editMode}
          handleFileRename={props.handleFileRename}
          handleSave={props.handleSave}
          projectId={props.projectId}
          stage={props.stage}
        />
      ))}
      {otherFiles.length ? (
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="other-files-panel"
            id="other-files-panel"
            data-testid="other-files-panel"
          >
            <Typography variant="body1" fontWeight={"bold"} fontSize={"1rem"}>
              Other Files
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {otherFiles.map((file, index) => (
              <FileItem
                key={file.id}
                file={file}
                index={index}
                mapId={props.mapId}
                deleteFile={props.deleteFile}
                editMode={props.editMode}
                handleFileRename={props.handleFileRename}
                handleSave={props.handleSave}
                projectId={props.projectId}
                stage={props.stage}
              />
            ))}
          </AccordionDetails>
        </StyledAccordion>
      ) : null}
    </Fragment>
  );
}
