import { TextField, MenuItem, Divider, Theme } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { BusinessUnitHttpService } from "../../../Http/BusinessUnit/BusinessUnit.http.service";
import { BusinessUnit } from "../../../Types/BusinessUnit";
import { Add } from "@mui/icons-material";
import CreateBusinessUnitModal from "../../BusinessUnits/CreateBusinessUnitModal";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

interface BusinessUnitSelectProps {
  defaultBusinessUnitId?: number;
  handleSelectBusinessUnit: (unitId: number) => void;
  className?: string;
  required?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      minWidth: "35ch",
    },
  })
);

const BusinessUnitSelect = (props: BusinessUnitSelectProps): ReactElement => {
  const classes = useStyles();
  const [businessUnitSelectOptions, setBusinessUnitSelectOptions] = useState<
    BusinessUnit[]
  >([]);
  const [businessUnitModalOpen, setBusinessUnitModal] = useState(false);

  const { required = true } = props;

  useEffect(() => {
    BusinessUnitHttpService.getBusinessUnits().then((businessUnits) => {
      setBusinessUnitSelectOptions(businessUnits);
    });
  }, []);

  const handleBusinessUnitModalClose = () => {
    setBusinessUnitModal(false);
  };

  const handleBusinessUnitModalOpen = () => {
    setBusinessUnitModal(true);
  };

  const handleCreateNewBusinessUnit = async () => {
    BusinessUnitHttpService.getBusinessUnits().then((businessUnits) => {
      setBusinessUnitSelectOptions(businessUnits);
    });
  };

  return (
    <>
      <TextField
        select
        label="Organizational Unit"
        id="organizational-unit"
        className={`${classes.root} ${props.className}`}
        variant="outlined"
        fullWidth
        value={
          businessUnitSelectOptions.length
            ? props.defaultBusinessUnitId
              ? props.defaultBusinessUnitId
              : ""
            : ""
        }
        onChange={(event) =>
          props.handleSelectBusinessUnit(parseInt(event.target.value))
        }
        required={required}
      >
        {businessUnitSelectOptions
          ?.sort((option1, option2) => option1.name.localeCompare(option2.name))
          .map((unit: BusinessUnit) => (
            <MenuItem key={unit.id} value={unit.id}>
              {unit.name}
            </MenuItem>
          ))}
        <Divider />
        <MenuItem onClick={handleBusinessUnitModalOpen}>
          <Add />
          Add New Organizational Unit
        </MenuItem>
      </TextField>
      {businessUnitModalOpen && (
        <CreateBusinessUnitModal
          modalOpen={businessUnitModalOpen}
          handleModalClose={handleBusinessUnitModalClose}
          handleSave={handleCreateNewBusinessUnit}
        />
      )}
    </>
  );
};

export default BusinessUnitSelect;
