import { ArrowBack, Workspaces } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { LeadProject } from "../../Types/LeadProject";
import ProjectLeadGridItem from "./ProjectLeadGridItem";
import theme from "../../theme";
import ProjectLeadSummary from "./ProjectLeadSummary/ProjectLeadSummary";
import UseCasesList from "./ProjectLeadUseCases/UseCasesList";
import ProjectLeadStartups from "./ProjectLeadStartups/ProjectLeadStartups";
import LaunchProjectModal from "./LaunchProjectModal";
import InformationModal from "./InformationModal";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../utils";
import ProjectLeadLeaders from "./ProjectLeadLeaders/ProjectLeadLeaders";
import { LeadProjectsHttpService } from "../../Http/LeadProjects/LeadProjects.http.service";

export default function LeadProjectPage(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [leadProject, setProjectLead] = useState<LeadProject>();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getProjectLead();
  }, []);

  const getProjectLead = async () => {
    LeadProjectsHttpService.getLeadProjectById(id)
      .then((data) => {
        setProjectLead(data);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, { variant: "error" });

        history.push(`/projects`);
      });
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const informationMissing =
    !leadProject?.leaders?.length || !leadProject.useCases?.length;

  const shouldOpenLaunchProjectModal =
    leadProject &&
    leadProject.useCases.length > 0 &&
    leadProject.leaders.length > 0;

  return (
    <Fragment>
      <Box>
        <Box display="flex">
          <NavLink
            to="/projects"
            style={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(2),
            }}
          >
            <ArrowBack color="primary" />
            <Box display="flex" alignItems="center" gap={1}>
              <Workspaces />
              <Typography data-testid="lead-project-title" variant="h5">
                {leadProject ? leadProject.name : "Loading...."}
              </Typography>
            </Box>
          </NavLink>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleModalOpen}
            data-testid="open-launch-project-button"
            sx={{ marginLeft: "auto" }}
            disabled={!shouldOpenLaunchProjectModal}
          >
            Launch Project
          </Button>
        </Box>
      </Box>
      {leadProject && (
        <Grid container spacing={theme.spacing(4)} sx={{ mt: 0 }}>
          <Grid item container xs={8} spacing={theme.spacing(2)}>
            <Grid item xs={6}>
              <ProjectLeadGridItem title="Use Cases" fullHeight>
                <UseCasesList
                  leadProject={leadProject}
                  onRefresh={getProjectLead}
                />
              </ProjectLeadGridItem>
            </Grid>
            <Grid item xs={6}>
              <ProjectLeadGridItem title="Project Leaders" fullHeight>
                <ProjectLeadLeaders
                  leadProject={leadProject}
                  onRefresh={getProjectLead}
                />
              </ProjectLeadGridItem>
            </Grid>
            <Grid item xs={12}>
              <ProjectLeadGridItem title="Startups">
                <ProjectLeadStartups
                  leadProject={leadProject}
                  onRefresh={getProjectLead}
                />
              </ProjectLeadGridItem>
            </Grid>
          </Grid>
          <Grid item container xs={4} spacing={theme.spacing(2)}>
            <Grid item xs={12}>
              <ProjectLeadSummary
                leadProject={leadProject}
                onRefresh={getProjectLead}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {modalOpen &&
        (informationMissing ? (
          <InformationModal
            title="Information is needed"
            content="To launch a project make sure you have the Use Cases and Project Lead Leader fields filled in with some information."
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          />
        ) : (
          <LaunchProjectModal
            leadProject={leadProject}
            modalOpen={modalOpen}
            handleModalClose={handleModalClose}
          />
        ))}
    </Fragment>
  );
}
