import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  styled,
} from "@mui/material";
import { ReactElement, useContext, useState } from "react";
import theme from "../../../../../../../theme";
import { useSnackbar } from "notistack";
import {
  getErrorMessage,
  isCharLimitExceeded,
} from "../../../../../../../utils";
import { GlobalProjectEditContext } from "../../../../../../../Context/ProjectDetailsContext";
import RichTextEditor from "../../../../../../UI/InputFields/RichTextEditor";
import { ProjectHttpService } from "../../../../../../../Http/Project/Project.http.service";
import { useParams } from "react-router-dom";
import { Project } from "../../../../../../../Types/Project";

const KEY_FINDING_CHAR_LIMIT = 350;

const ActionButtons = styled("div")(() => ({
  marginLeft: "auto",
  height: "38px",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

interface EditKeyFindingsProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  keyFindings: string;
  handleSave: () => void;
}

const EditKeyFindingsModal = (props: EditKeyFindingsProps): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { activeStep } = useContext(GlobalProjectEditContext);
  const { enqueueSnackbar } = useSnackbar();
  const [keyFindingsLocal, setKeyFindingsLocal] = useState(props.keyFindings);
  const [editMode, setEditMode] = useState(false);
  const isAssessStageActive = activeStep === 1;
  const keyFindingsProperty = isAssessStageActive
    ? "keyFindings"
    : "curatedKeyFindings";

  const saveKeyFinding = async () => {
    if (isCharLimitExceeded(keyFindingsLocal, KEY_FINDING_CHAR_LIMIT)) {
      enqueueSnackbar("Key Findings cannot be more than 350 characters", {
        variant: "error",
      });
      return;
    }

    await ProjectHttpService.updateProject({
      id: parseInt(id),
      [keyFindingsProperty]: keyFindingsLocal,
    } as unknown as Project)
      .then(() => {
        props.handleSave();
        props.setModalOpen(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update project: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      data-testid="edit-key-findings-modal"
      PaperProps={{
        style: { borderRadius: theme.customBorderRadius.md },
      }}
    >
      <DialogTitle display="flex">
        Key Findings
        <ActionButtons>
          <Button onClick={() => props.setModalOpen(false)}>Close</Button>
          {editMode ? (
            <Button onClick={saveKeyFinding}>Save</Button>
          ) : (
            <Button onClick={() => setEditMode(true)}>Edit</Button>
          )}
        </ActionButtons>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          "& .ck-editor__main p, & #edit-key-findings p": {
            ...theme.typography.body2,
          },
          pb: 4.5,
        }}
      >
        <RichTextEditor
          fieldId="edit-key-findings"
          editMode={editMode}
          fieldValue={keyFindingsLocal}
          maxCharacter={KEY_FINDING_CHAR_LIMIT}
          onChange={(value) => setKeyFindingsLocal(value)}
          labelText=""
          isToolbarDisabled
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditKeyFindingsModal;
