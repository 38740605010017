import { Box, Button, List, Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { FileHttpService } from "../../../Http/File/File.http.service";
import { File as DocumentFile } from "../../../Types/File";
import { Startup } from "../../../Types/Startup";
import { FilesListSection } from "./FilesListSection";

interface FileSectionProps {
  startup: Startup;
  fileTypes: string[];
  handleSave?: () => void;
  readOnly: boolean;
}

export function StartupFileSection(props: FileSectionProps): ReactElement {
  const [editMode, setEditMode] = useState(false);
  const [filesToDelete, setFilesToDelete] = useState<number[]>([]);
  const [localFiles, setLocalFiles] = useState<DocumentFile[]>([]);

  useEffect(() => {
    setLocalFiles(props.startup.files);
  }, [props.startup.files]);

  const cancelChanges = () => {
    setLocalFiles(props.startup.files);
    setFilesToDelete([]);
    setEditMode(false);
  };

  const saveChanges = async (): Promise<void> => {
    for (const fileId of filesToDelete) {
      await FileHttpService.delete(fileId);
    }
    for (const file of localFiles) {
      await FileHttpService.update(file);
    }
    setFilesToDelete([]);
    setEditMode(false);
    if (props.handleSave) {
      props.handleSave();
    }
  };

  const deleteFile = (fileId: number): void => {
    setFilesToDelete((prevState) => [...prevState, fileId]);
    setLocalFiles((prevState) =>
      prevState.filter((file) => file.id !== fileId)
    );
  };

  const handleFileRename = (id: number, name: string) => {
    const files = JSON.parse(JSON.stringify(localFiles));
    const changedFile = files.find((file: DocumentFile) => file.id === id);
    changedFile.name = name;
    setLocalFiles(files);
  };

  return (
    <Box p={4}>
      <Box
        justifyContent="space-between"
        flexDirection="row"
        display="flex"
        mb={2}
      >
        <Typography variant={"h6"}>Files</Typography>
      </Box>
      <List dense>
        <FilesListSection
          files={localFiles}
          mapId={props.startup.id}
          deleteFile={deleteFile}
          editMode={editMode}
          handleFileRename={handleFileRename}
          handleSave={props.handleSave}
        />
      </List>

      {editMode && (
        <Box justifyContent="flex-end" flexDirection="row" display="flex" m={2}>
          <Button
            autoFocus
            onClick={cancelChanges}
            color="primary"
            id="cancel-files-button"
          >
            Cancel
          </Button>
          <Button
            onClick={saveChanges}
            variant="contained"
            color="secondary"
            id="save-files-button"
            sx={{ ml: 2 }}
          >
            Save Changes
          </Button>
        </Box>
      )}
    </Box>
  );
}
