import {
  Box,
  Card,
  CardContent,
  Chip,
  Typography,
  Divider,
  styled,
  Link,
} from "@mui/material";
import { ReactElement, useState } from "react";
import { StartupOpportunity } from "../../../../Types/Opportunity";
import { getStartupStatus } from "../../../../utils";
import ProjectDetailsModal from "../../../UI/Modals/ProjectDetailsModal/ProjectDetailsModal";
import ProjectCardStepper from "./ProjectCardStepper";
import { ReportGmailerrorred } from "@mui/icons-material";
import { stageScores } from "../../../../Constants/FunnelStages";
import theme from "../../../../theme";

interface ProjectCardProps {
  opportunity: StartupOpportunity;
  startupName: string;
}

const CardContentWrapper = styled(CardContent)(({ theme }) => ({
  padding: 0,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

const ProjectHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  justifyContent: "space-between",
}));

const ProjectName = styled(Typography, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $status?: string }>(({ theme, $status }) => ({
  cursor: "pointer",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitLineClamp: "2",
  textOverflow: "ellipsis",
  WebkitBoxOrient: "vertical",
  lineHeight: theme.spacing(2.5),
  color: theme.palette.primary.main,
  opacity: $status && ["on hold", "archived"].includes($status) ? 0.3 : 1,
}));

const StatusChip = styled(Chip, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $statusColor?: string }>(({ theme, $statusColor }) => ({
  color: $statusColor,
  borderColor: $statusColor,
  fontWeight: 500,
  fontSize: theme.customTypography.fontSize12,
  height: "fit-content",
  width: "fit-content",
  lineHeight: theme.spacing(2),
  padding: "2px 0px",
}));

const RuledOutDetails = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  padding: theme.spacing(1),
  flexDirection: "column",
  gap: theme.spacing(1),
  borderRadius: theme.customBorderRadius.xs,
}));

const RuledOutReason = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(0.5),
}));

export default function ProjectCard(props: ProjectCardProps): ReactElement {
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const startupStatus = getStartupStatus(props.opportunity);
  const selectedStartup = props.opportunity.project.opportunities.find(
    (o) => o.isSelectedForPilot
  )?.startup;

  const projectStageScore = stageScores[props.opportunity.project.funnelStage];

  const getRuledOutStage = (opportunity: StartupOpportunity) => {
    const isBeyondDiscover = projectStageScore > stageScores["discover"];
    const isBeyondAssess = projectStageScore > stageScores["assess"];

    if (isBeyondDiscover && !opportunity.isQualified) {
      return `Discover`;
    } else if (isBeyondAssess && !opportunity.isSelectedForPilot) {
      return `Assess`;
    } else return "";
  };

  const getStatusColor = (status: string) => {
    if (status === "Adopted") {
      return theme.palette.success.light;
    } else if (status === "Evaluation") {
      return theme.palette.text.secondary;
    } else if (status === "Ruled Out") {
      return theme.palette.error.main;
    } else {
      return theme.palette.primary.main;
    }
  };

  return (
    <>
      <Card
        elevation={0}
        data-testid={"project-card-" + props.opportunity.project.id}
        key={props.opportunity.id}
      >
        <CardContentWrapper>
          <ProjectHeader>
            <Box minWidth={0}>
              <ProjectName
                variant="subtitle1"
                onClick={() => setOpenDetails(true)}
                $status={props.opportunity.project.status}
              >
                {props.opportunity.project.name}
              </ProjectName>
              <Box display="flex" gap={2}>
                <Typography
                  variant="caption"
                  color="text.primary"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  noWrap
                >
                  {props.opportunity.project.businessUnit.name}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <StatusChip
                label={startupStatus}
                variant="outlined"
                $statusColor={getStatusColor(startupStatus)}
              />
              {startupStatus === "Ruled Out" && (
                <Typography
                  fontWeight="500"
                  fontSize={theme.customTypography.fontSize10}
                  color={theme.palette.text.secondary}
                >
                  {getRuledOutStage(props.opportunity)}
                </Typography>
              )}
            </Box>
          </ProjectHeader>
          <ProjectCardStepper
            activeStep={projectStageScore - 1}
            isProjectActive={props.opportunity.project.status === "active"}
            ruledOutStage={getRuledOutStage(props.opportunity)}
            badgeColor={getStatusColor(startupStatus)}
          />
          {projectStageScore > 0 && startupStatus === "Ruled Out" && (
            <RuledOutDetails>
              <RuledOutReason>
                <ReportGmailerrorred style={{ fontSize: "16px" }} />
                <Typography variant="caption" lineHeight={theme.spacing(2)}>
                  This startup was not selected
                </Typography>
              </RuledOutReason>
              {projectStageScore > 2 && selectedStartup && (
                <>
                  <Divider
                    sx={{ borderColor: theme.palette.secondary.light }}
                    flexItem
                  />
                  <Box
                    display="flex"
                    color={theme.palette.text.secondary}
                    alignItems="center"
                  >
                    <Typography
                      fontSize={theme.customTypography.fontSize12}
                      marginRight={theme.spacing(0.5)}
                    >
                      Chosen Startup
                    </Typography>
                    <Link
                      href={`/startups/${selectedStartup.id}`}
                      target="_blank"
                      rel="noreferrer"
                      sx={{ textDecoration: "none" }}
                    >
                      <Typography fontSize={theme.customTypography.fontSize12}>
                        {selectedStartup.name}
                      </Typography>
                    </Link>
                  </Box>
                </>
              )}
            </RuledOutDetails>
          )}
        </CardContentWrapper>
      </Card>
      {openDetails && (
        <ProjectDetailsModal
          projectId={props.opportunity.project.id}
          setModalOpen={setOpenDetails}
          modalOpen={openDetails}
        />
      )}
    </>
  );
}
