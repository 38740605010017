import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Dispatch, Fragment, ReactElement, useState } from "react";
import {
  PilotOutcome,
  PilotOutcomeReason,
  Project,
} from "../../../../../../Types/Project";
import RichTextEditor from "../../../../../UI/InputFields/RichTextEditor";
import theme from "../../../../../../theme";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { SelectInput } from "../../../../../UI/InputFields/SelectInput";

interface Props {
  project?: Project;
  editMode: boolean;
  setProject: Dispatch<Partial<Project>>;
}

const reasonValues = [
  {
    id: "Failure to meet project objectives",
    name: "Failure to meet project objectives",
  },
  {
    id: "Insufficient data or inconclusive results",
    name: "Insufficient data or inconclusive results",
  },
  {
    id: "Budget overruns",
    name: "Budget overruns",
  },
  {
    id: "Technical or logistical issues",
    name: "Technical or logistical issues",
  },
  {
    id: "Changes of external factors",
    name: "Changes of external factors",
  },
  {
    id: "Other",
    name: "Other",
  },
];

const PilotProjectEvaluation = ({
  children,
}: {
  children: PilotOutcome | null;
}) => {
  const isPositive = children === "Positive";
  return (
    <Box display="flex" gap={theme.spacing(1.25)} height={theme.spacing(5)}>
      {children && (
        <>
          {isPositive ? (
            <CheckCircle color="success" />
          ) : (
            <Cancel color="error" />
          )}
          <Typography
            color={
              isPositive ? theme.palette.success.main : theme.palette.error.main
            }
          >
            {children}
          </Typography>
        </>
      )}
    </Box>
  );
};

const RadioGroupLabel = ({
  value,
  label,
  required = false,
  editMode,
}: {
  value: string | null;
  label: string;
  required: boolean;
  editMode: boolean;
}) => {
  const showWarning = value !== null || !required || !editMode;
  return (
    <Typography
      variant="caption"
      color={
        showWarning ? theme.palette.text.secondary : theme.palette.warning.main
      }
    >
      {showWarning ? label : label + " *"}
    </Typography>
  );
};

export default function PilotConclusionSection(props: Props): ReactElement {
  const [outcome, setOutcome] = useState<string | null>(
    props.project?.pilotOutcome ?? null
  );

  const handleClick = (e: React.BaseSyntheticEvent) => {
    const newOutCome = outcome === e.target.value ? null : e.target.value;

    if (newOutCome === "Positive" && outcome === "Negative") {
      props.setProject({ pilotOutcomeReason: null });
    }

    setOutcome(newOutCome);
    props.setProject({ pilotOutcome: newOutCome });
  };

  return (
    <Fragment>
      {props.project && (
        <Grid
          container
          spacing={2}
          marginTop={theme.spacing(0)}
          sx={{
            ".MuiFormControl-root > div": {
              marginTop: props.editMode ? 0 : theme.spacing(2),
            },
          }}
        >
          <Grid item xs={6}>
            <RadioGroupLabel
              value={outcome}
              label="Pilot Project Evaluation"
              required
              editMode={props.editMode}
            />
            {props.editMode ? (
              <RadioGroup
                aria-labelledby="pilot-project-evaluation-label"
                row
                value={outcome}
                sx={{
                  display: "flex",
                  gap: theme.spacing(7.5),
                }}
              >
                <FormControlLabel
                  value="Positive"
                  control={<Radio onClick={handleClick} />}
                  label="Positive"
                />
                <FormControlLabel
                  value="Negative"
                  control={<Radio onClick={handleClick} />}
                  label="Negative"
                />
              </RadioGroup>
            ) : (
              <PilotProjectEvaluation>
                {props.project.pilotOutcome}
              </PilotProjectEvaluation>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            height={theme.spacing(11)}
            marginBottom={theme.spacing(1)}
            sx={{
              ".MuiFormControl-root": {
                marginBlock: props.editMode
                  ? theme.spacing(1.5)
                  : theme.spacing(1),
              },
            }}
          >
            {outcome === "Negative" && (
              <SelectInput
                id="reason-input"
                labelText="Reason"
                selectValues={reasonValues}
                value={props.project.pilotOutcomeReason}
                onChange={(e) =>
                  props.setProject({
                    pilotOutcomeReason: e.target.value as PilotOutcomeReason,
                  })
                }
                editMode={props.editMode}
                disableSorting
                required
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <RichTextEditor
              editMode={props.editMode}
              fieldValue={props.project.learnings}
              onChange={(value) => props.setProject({ learnings: value })}
              labelText={"Learnings"}
              fieldId={"learnings"}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <RichTextEditor
              editMode={props.editMode}
              fieldValue={props.project.achievements}
              onChange={(value) => props.setProject({ achievements: value })}
              labelText={"Achievements"}
              fieldId={"achievements"}
              required
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
