import { Box, Button } from "@mui/material";
import { ReactElement, useState } from "react";
import { LeadProject } from "../../../Types/LeadProject";
import theme from "../../../theme";
import ProjectLeadLeadersTable from "./ProjectLeadLeadersTable";
import ManageProjectLeadLeaderModal from "./ManageProjectLeadLeaderModal";

interface ProjectLeadLeaderProps {
  leadProject: LeadProject;
  onRefresh: () => void;
}
export default function ProjectLeadLeaders(
  props: ProjectLeadLeaderProps
): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        gap={theme.spacing(4)}
      >
        <ProjectLeadLeadersTable
          leaders={props.leadProject.leaders}
          onRefresh={props.onRefresh}
          leadProject={props.leadProject}
        />
        <Button
          color="primary"
          sx={{ textTransform: "none", alignSelf: "flex-end" }}
          onClick={handleModalOpen}
          data-testid="add-lead-project-leader-button"
        >
          Add Project Leader
        </Button>
      </Box>
      {modalOpen && (
        <ManageProjectLeadLeaderModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          leadProject={props.leadProject}
          onRefresh={props.onRefresh}
        />
      )}
    </>
  );
}
