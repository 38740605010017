import axios from "axios";
import {
  ProjectCount,
  ProjectsCountDto,
  OrganizationalReach,
  ProjectTimeline,
  ProjectEvent,
  TBusinessValue,
  ProjectEventDTO,
  DashboardFilterCriteria,
} from "../../Types/Analytics";
import AnalyticsMapper from "./Analytics.mapper";

export class AnalyticsHttpService {
  public static async getOrganizationalReach(
    filterCriteria: DashboardFilterCriteria
  ): Promise<OrganizationalReach> {
    const response = await axios.get<OrganizationalReach>(
      `/api/analytics/organizational-reach`,
      { params: { filterCriteria } }
    );
    return response.data;
  }

  public static async getProjectTimeline(
    filterCriteria: DashboardFilterCriteria
  ): Promise<ProjectTimeline> {
    const response = await axios.get<ProjectTimeline>(
      `/api/analytics/project-timeline`,
      { params: { filterCriteria } }
    );
    return response.data;
  }

  public static async getUpcomingEvents(
    filterCriteria: DashboardFilterCriteria
  ): Promise<ProjectEvent[]> {
    const response = await axios.get<ProjectEventDTO[]>(
      `/api/analytics/upcoming-events`,
      { params: { filterCriteria } }
    );

    return AnalyticsMapper.mapEvents(response.data);
  }

  public static async getRecentEvents(
    filterCriteria: DashboardFilterCriteria
  ): Promise<ProjectEvent[]> {
    const response = await axios.get<ProjectEventDTO[]>(
      `/api/analytics/recent-events`,
      { params: { filterCriteria } }
    );

    const allRecentEvents = AnalyticsMapper.mapEvents(response.data);
    const currentDate = new Date();
    const oneYearAgo = new Date();

    oneYearAgo.setDate(currentDate.getDate() + 1);
    currentDate.setDate(currentDate.getDate() - 1);
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    return allRecentEvents.filter(
      (event) => event.date >= oneYearAgo && event.date <= currentDate
    );
  }

  public static async getProjectCount(
    filterCriteria: DashboardFilterCriteria,
    status?: "active"
  ): Promise<ProjectCount[]> {
    const response = await axios.get<ProjectsCountDto>(
      `/api/analytics/project-count`,
      { params: { filterCriteria } }
    );

    return AnalyticsMapper.mapProjectCount(response.data, status);
  }

  public static async getBusinessValue(
    filterCriteria: DashboardFilterCriteria
  ): Promise<TBusinessValue> {
    const response = await axios.get<TBusinessValue>(
      `/api/analytics/business-value`,
      { params: { filterCriteria } }
    );
    return response.data;
  }
}
