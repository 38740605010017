import { FunnelStage } from "../Types/Project";

export const funnelStages: FunnelStage[] = [
  "discover",
  "assess",
  "buy",
  "pilot",
  "adopt",
];

export const stageScores = {
  discover: 1,
  assess: 2,
  buy: 3,
  pilot: 4,
  adopt: 5,
};
