import { ReactElement } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FormControl, InputLabel, Theme, debounce } from "@mui/material";
import parse from "html-react-parser";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import CustomToolTip from "../CustomToolTip";
import EventInfo from "@ckeditor/ckeditor5-utils/src/eventinfo";
import { removeFormatting } from "../../../utils";
import FormHelperText from "@mui/material/FormHelperText";

interface Props {
  onChange?: (value: string) => void;
  editMode: boolean;
  fieldValue: string;
  labelText: string;
  fieldId: string;
  required?: boolean;
  toolTipText?: string;
  maxCharacter?: number;
  isToolbarDisabled?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    root: {
      width: "35ch",
      "& .MuiInputLabel-root.Mui-error, .MuiInputLabel-asterisk.Mui-error": {
        color: theme.palette.warning.main,
      },
      minWidth: "100%",
    },
    error: {
      borderColor: `${theme.palette.warning.main} !important`,
    },
    readModeField: {
      marginBlock: theme.spacing(2),
      "& > span": {
        wordBreak: "break-word",
      },
    },
    ckEditor: (props) => ({
      position: "relative",
      marginTop: theme.spacing(3),
      fontFamily: theme.typography.fontFamily,
      border: "1px solid",
      borderRadius: theme.shape.borderRadius,
      borderColor: "rgba(0, 0, 0, 0.23)",

      "&:hover": {
        borderColor: "rgba(0, 0, 0, 1)",
      },

      "& .ck.ck-editor": {
        cursor: "text",
      },

      // content
      "& .ck.ck-editor__main": {
        transform: `translateY(${props.isToolbarDisabled ? "0" : "-.6rem"})`,
        paddingBlock: props.isToolbarDisabled ? "0.2rem" : "0",
      },

      "& .ck.ck-editor__top": {
        transform: "translateY(-15px)",
      },

      // toolbar (label and buttons)
      "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar": {
        border: "none",
        backgroundColor: "transparent",
      },

      // buttons container
      "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar .ck-toolbar__items": {
        flexGrow: "initial",
        marginInline: "auto .8rem",
        backgroundColor: "#fff",
        outline: "5px solid #fff",
      },

      // each button
      "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar .ck-toolbar__items .ck-button":
        {
          marginBlock: "0",
        },

      // tooltip for button
      "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar .ck-toolbar__items .ck-button .ck-tooltip":
        {
          display: "none",
        },

      // when button is clicked
      "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar .ck-toolbar__items .ck-button .ck-on":
        {
          backgroundColor: "#000",
        },

      // the element who creates border for content section
      "& .ck.ck-editor>.ck.ck-editor__main>.ck-content.ck-editor__editable.ck-rounded-corners":
        {
          border: "none",
          boxShadow: "none",
        },

      // label of the field when its in edit mode
      "& > label": {
        position: "absolute",
        color: theme.palette.text.secondary,
        top: props.toolTipText ? "-7px" : "-4px",
        left: "-5px",
        padding: "0 0.5em",
        backgroundColor: "#fff",
      },

      // character count display
      "& .MuiFormHelperText-root": {
        position: "absolute",
        right: 0,
        bottom: 0,
      },
    }),
  })
);

function RichTextEditor(props: Props): ReactElement {
  const classes = useStyles(props);
  const error = props.editMode && props.required && !props.fieldValue;

  const handleChange = (event: EventInfo, editor: ClassicEditor) => {
    props.onChange && props.onChange(editor.getData());
  };

  const characterCount = props.fieldValue
    ? removeFormatting(props.fieldValue).length
    : 0;

  const toolbar = props.isToolbarDisabled
    ? []
    : ["bold", "italic", "bulletedList", "numberedList"];
  const removePlugins = props.isToolbarDisabled
    ? ["Bold", "Italic", "List"]
    : [];

  return (
    <FormControl
      error={error}
      className={classes.root}
      fullWidth
      variant="outlined"
      focused={false}
      data-testid={props.fieldId}
    >
      {props.editMode ? (
        <div className={`${classes.ckEditor} ${error ? classes.error : ""}`}>
          <CKEditor
            editor={ClassicEditor}
            data={props.fieldValue || ""}
            onChange={debounce(handleChange, 100)}
            config={{
              toolbar: toolbar,
              removePlugins: removePlugins,
            }}
          />
          {props.maxCharacter && (
            <FormHelperText
              error={characterCount > props.maxCharacter}
              data-testid="character-count"
            >
              {characterCount}/{props.maxCharacter}
            </FormHelperText>
          )}
          <InputLabel
            shrink
            required={!!props.required}
            htmlFor={props.fieldId}
          >
            {props.labelText}
            {props.toolTipText && (
              <CustomToolTip
                id={`${props.fieldId}Tooltip`}
                key={`${props.fieldId}Tooltip`}
                toolTipText={props.toolTipText}
              />
            )}
          </InputLabel>
        </div>
      ) : (
        <div className={classes.readModeField}>
          <InputLabel variant="standard" shrink htmlFor={props.fieldId}>
            {props.labelText}
          </InputLabel>
          <span id={props.fieldId}>
            {props.fieldValue ? parse(props.fieldValue) : ""}
          </span>
        </div>
      )}
    </FormControl>
  );
}

export default RichTextEditor;
