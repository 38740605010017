import { createContext } from "react";
export type ProjectDetailsContextType = {
  globalEditMode: boolean;
  setGlobalEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  activeStep: number | null;
  shouldSaveProject: boolean;
  setShouldSaveProject: React.Dispatch<React.SetStateAction<boolean>>;
};
export const GlobalProjectEditContext =
  createContext<ProjectDetailsContextType>({
    globalEditMode: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setGlobalEditMode: () => {},
    activeStep: null,
    shouldSaveProject: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setShouldSaveProject: () => {},
  });
