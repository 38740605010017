import axios from "axios";
import { CreateObjectiveData, Objective } from "../../Types/Objective";

export default class ObjectiveHttpService {
  public static async createObjective(
    objective: CreateObjectiveData
  ): Promise<Objective> {
    const response = await axios.post<Objective>(`/api/objectives`, objective);
    return response.data;
  }

  public static async updateObjective(objective: Objective): Promise<void> {
    await axios.put<Objective>(`/api/objectives`, objective);
  }

  public static async deleteObjective(objectiveId: number): Promise<void> {
    await axios.delete<Objective>(`/api/objectives`, {
      params: { objectiveId },
    });
  }
}
