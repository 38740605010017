import React, { ReactElement, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import theme from "../../theme";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: "flex",
      width: "500px",
      flexDirection: "column",
      justifyContent: "center",
      padding: "20px",
      zIndex: 1,
    },
    passwordRequirements: {
      margin: 0,
      columns: 2,
    },
  })
);

interface Props {
  setFormType: (x: string) => void;
}

export default function ResetPassword(props: Props): ReactElement {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [token, setToken] = useState<string>("");

  const textFieldForPasswordRef = React.useRef<HTMLInputElement>(null);
  const textFieldForTokenRef = React.useRef<HTMLInputElement>(null);

  const [showToken, setShowToken] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleResetPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(email, token, password);
      props.setFormType("signIn");
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <Box className={classes.form}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="h4" align="center" sx={{ fontWeight: 500 }}>
          Reset your password
        </Typography>
        <TextField
          id="email"
          label="Email"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value as string)}
          inputProps={{
            onKeyPress: (event) => {
              if (event.key === "Enter" && textFieldForPasswordRef.current) {
                textFieldForPasswordRef.current.focus();
              }
            },
          }}
        />
        <TextField
          id="token"
          label="Token"
          value={token}
          type={showToken ? "text" : "password"}
          inputRef={textFieldForTokenRef}
          onChange={(e) => setToken(e.target.value as string)}
          inputProps={{
            onKeyPress: (event) => {
              if (event.key === "Enter" && textFieldForPasswordRef.current) {
                textFieldForPasswordRef.current.focus();
              }
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="token-visibility-toggle"
                  aria-label="toggle token visibility"
                  onClick={() => setShowToken(!showToken)}
                  sx={{
                    "& .MuiSvgIcon-root": { color: "white" },
                  }}
                >
                  {showToken ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="password"
          label="New Password"
          autoComplete="new-password"
          value={password}
          type={showNewPassword ? "text" : "password"}
          inputRef={textFieldForPasswordRef}
          onChange={(e) => setPassword(e.target.value as string)}
          inputProps={{
            onKeyPress: (event) => {
              if (event.key === "Enter") {
                handleResetPassword();
              }
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="new-password-visibility-toggle"
                  aria-label="toggle new password visibility"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  sx={{
                    "& .MuiSvgIcon-root": { color: "white" },
                  }}
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText sx={{ color: "white" }}>
          <ul className={classes.passwordRequirements}>
            <li>At least 12 characters</li>
            <li>Contains number</li>
            <li>Contains uppercase letter</li>
            <li>Contains lowercase letter</li>
          </ul>
        </FormHelperText>
        <Box display="flex" justifyContent="start" gap={1}>
          <Typography variant="caption">didnt receive a code?</Typography>
          <Typography
            variant="caption"
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.secondary.main,
              },
            }}
            onClick={() => props.setFormType("resetPasswordToken")}
          >
            click here
          </Typography>
        </Box>
        <Button
          type="submit"
          onClick={handleResetPassword}
          variant="contained"
          color="secondary"
          id="reset-password-button"
          sx={{
            width: "200px",
            margin: "auto",
          }}
        >
          Reset Password
        </Button>
      </Box>
    </Box>
  );
}
