import axios from "axios";
import { CreateKpiData, Kpi } from "../../Types/Kpi";

export class KpiHttpService {
  public static async createKpi(kpi: CreateKpiData): Promise<Kpi> {
    const response = await axios.post<Kpi>(`/api/kpis`, kpi);
    return response.data;
  }

  public static async updateKpi(kpi: Kpi): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { unit, ...kpiPayload } = kpi;
    await axios.put<Kpi>(`/api/kpis`, kpiPayload);
  }

  public static async deleteKpi(kpiId: number): Promise<void> {
    await axios.delete<Kpi>(`/api/kpis`, {
      params: { kpiId },
    });
  }
}
