import {
  Autocomplete,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Theme,
} from "@mui/material";
import { ReactElement } from "react";
import AddIcon from "@mui/icons-material/Add";
import { SelectOption } from "../../../Types/Common";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export interface SelectInputProps {
  selectValues: SelectOption[];
  labelText: string;
  fieldId: string;
  value: SelectOption[];
  onChange: (event: SelectOption[]) => void;
  isLoading?: boolean;
  addIcon: boolean;
  setModalOpen?: () => void;
  onInputChange?: (event: string) => void;
  required?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      minWidth: "35ch",
      "& .MuiInputLabel-root.Mui-error, .MuiInputLabel-asterisk.Mui-error": {
        color: theme.palette.warning.main,
      },
      "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.warning.main,
      },
    },
    useCaseEndAdornmentWrapper: {
      position: "absolute",
      right: "9px",
      display: "flex",
      alignItems: "center",

      "& .MuiAutocomplete-endAdornment": {
        position: "relative",
      },
    },
  })
);

export function MultiSelectInput(props: SelectInputProps): ReactElement {
  const classes = useStyles();

  return (
    <>
      <Autocomplete
        data-cy={props.fieldId}
        fullWidth={props.fullWidth}
        id={props.fieldId}
        multiple
        disabled={props.disabled}
        forcePopupIcon={props.addIcon ? false : true}
        options={props.selectValues.sort((option1, option2) =>
          option1.name.localeCompare(option2.name)
        )}
        isOptionEqualToValue={(option: SelectOption, value: SelectOption) =>
          option.id === value.id
        }
        getOptionLabel={(option: SelectOption) => option.name}
        value={props.value}
        filterSelectedOptions
        onChange={(_, selectedOptionsList) =>
          props.onChange(selectedOptionsList as SelectOption[])
        }
        onInputChange={(_, newValue: string) =>
          props.onInputChange && props.onInputChange(newValue)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.root}
            label={props.labelText}
            error={props.required && !props.value}
            InputProps={{
              ...params.InputProps,
              readOnly: true,
              endAdornment: props.addIcon ? (
                <div className={classes.useCaseEndAdornmentWrapper}>
                  {props.isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <>
                      <Tooltip
                        title={`Create New ${props.labelText}`}
                        placement="right-end"
                        sx={{ mr: "8px" }}
                      >
                        <span>
                          <IconButton
                            data-testid={"addNew-" + props.fieldId}
                            color="primary"
                            onClick={props.setModalOpen}
                            disabled={props.disabled}
                          >
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                      {params.InputProps.endAdornment}
                    </>
                  )}
                </div>
              ) : (
                params.InputProps.endAdornment
              ),
            }}
          />
        )}
      />
    </>
  );
}
