import { Box, Typography, styled } from "@mui/material";
import { SolutionCluster } from "../../../../../../../../Types/SolutionCluster";
import { ReactElement, useState } from "react";
import ManageSolutionClusterModal from "../ManageSolutionClusterModal";
import DeleteModal from "../../../../../../../UI/Modals/DeleteModal/DeleteModal";
import EditDeleteMenu from "../../../../../../../UI/EditDeleteMenu";

const ClusterWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const Header = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Stats = styled(Box)(() => ({
  "& > div": {
    display: "flex",
    justifyContent: "space-between",
  },
}));

interface Props {
  solutionCluster: SolutionCluster;
  numberOfOpportunities: number;
  totalFunding: string;
  handleSave: () => void;
}

const ClusterCard = (props: Props): ReactElement => {
  const [editClusterModalOpen, setEditClusterModalOpen] = useState(false);
  const [deleteClusterModalOpen, setDeleteClusterModalOpen] = useState(false);

  return (
    <>
      <ClusterWrapper
        key={props.solutionCluster.id}
        data-testid={`solution-cluster-${props.solutionCluster.id}`}
      >
        <Header>
          <Typography variant="body2" fontWeight="bold">
            {props.solutionCluster.name}
          </Typography>
          <>
            <EditDeleteMenu
              entity="cluster"
              setEditModalOpen={setEditClusterModalOpen}
              setDeleteModalOpen={setDeleteClusterModalOpen}
            />
          </>
        </Header>
        {props.solutionCluster.description && (
          <Typography variant="caption" sx={{ whiteSpace: "break-spaces" }}>
            {props.solutionCluster.description}
          </Typography>
        )}
        <Stats>
          <Box>
            <Typography variant="caption">Total Startups</Typography>
            <Typography variant="caption" fontWeight="bold">
              {props.numberOfOpportunities}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption">Total Funding (in USD)</Typography>
            <Typography variant="caption" fontWeight="bold">
              ${props.totalFunding}M
            </Typography>
          </Box>
        </Stats>
      </ClusterWrapper>

      {editClusterModalOpen && (
        <ManageSolutionClusterModal
          modalOpen={editClusterModalOpen}
          setModalOpen={setEditClusterModalOpen}
          solutionCluster={props.solutionCluster}
          handleSave={props.handleSave}
          numberOfOpportunities={props.numberOfOpportunities}
          totalFunding={props.totalFunding}
        />
      )}
      {deleteClusterModalOpen && (
        <DeleteModal
          id={props.solutionCluster.id}
          modalOpen={deleteClusterModalOpen}
          setModalOpen={setDeleteClusterModalOpen}
          entity="Solution Cluster"
          handleSave={props.handleSave}
        />
      )}
    </>
  );
};

export default ClusterCard;
