import {
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  styled,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, Reducer, useReducer, Fragment } from "react";
import BusinessUnitSelect from "../../UI/InputFields/BusinessUnitSelect";
import { getErrorMessage } from "../../../utils";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import ClientContactSelect from "../../UI/InputFields/ClientContactSelect";
import { LeadProject, LeadProjectLeader } from "../../../Types/LeadProject";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";

const ActionButtons = styled(DialogActions)(({ theme }) => ({
  margin: theme.spacing(2),
}));

const FormInput = styled(Grid)({
  width: "100%",
  "& .MuiFormControl-root": {
    margin: 0,
  },
});

interface ManageProjectLeadLeaderModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  leadProject: LeadProject;
  onRefresh: () => void;
  projectLeadLeader?: LeadProjectLeader;
}

function ManageProjectLeadLeaderModal(
  props: ManageProjectLeadLeaderModalProps
): ReactElement {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [newProjectLeadLeader, setNewProjectLeadLeader] = useReducer<
    Reducer<LeadProjectLeader, Partial<LeadProjectLeader>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    props.projectLeadLeader ||
      ({
        leadProjectId: props.leadProject.id,
      } as LeadProjectLeader)
  );

  const isCreateMode = !props.projectLeadLeader;

  const addLeadProjectLeader = () => {
    const canAddProjectLeadLeader =
      newProjectLeadLeader.projectLeaderId ||
      newProjectLeadLeader.useCaseId ||
      newProjectLeadLeader.businessUnitId;

    if (!canAddProjectLeadLeader) {
      return enqueueSnackbar("Please provide Project Leader information", {
        variant: "error",
      });
    }

    LeadProjectsHttpService.addLeadProjectLeader(newProjectLeadLeader)
      .then(() => {
        props.onRefresh();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not add lead project leader: ${errorMessage}`,
          { variant: "error" }
        );
      })
      .finally(() => {
        props.setModalOpen(false);
      });
  };

  const updateLeadProjectLeader = () => {
    LeadProjectsHttpService.updateLeadProjectLeader(newProjectLeadLeader)
      .then(() => {
        props.onRefresh();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not update lead project leader: ${errorMessage}`,
          { variant: "error" }
        );
      })
      .finally(() => {
        props.setModalOpen(false);
      });
  };

  const handleBusinessUnitChange = (selectedUnitId: number) => {
    setNewProjectLeadLeader({ businessUnitId: selectedUnitId });
  };

  const handleUseCaseChange = (selectedUseCaseId: number) => {
    setNewProjectLeadLeader({ useCaseId: selectedUseCaseId });
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        data-testid="manage-lead-project-leader-modal"
      >
        <DialogTitle data-testid="title">Project Leader</DialogTitle>
        <DialogContent>
          <form
            noValidate
            autoComplete="off"
            style={{ paddingTop: theme.spacing(1) }}
          >
            <Stack direction="column" gap={3}>
              <FormInput item>
                <BusinessUnitSelect
                  handleSelectBusinessUnit={handleBusinessUnitChange}
                  defaultBusinessUnitId={newProjectLeadLeader.businessUnitId}
                  required={false}
                />
              </FormInput>
              <FormInput item sx={{ "& > div": { mt: 0 } }}>
                <ClientContactSelect
                  editMode
                  labelText="Project Leader"
                  ventureClientId={props.leadProject.ventureClient.id}
                  onChange={setNewProjectLeadLeader}
                  contactData={props.projectLeadLeader?.projectLeader}
                  fieldId="projectLeaderId"
                />
              </FormInput>
              <FormInput item>
                <SelectInput
                  id="use-cases"
                  fullWidth
                  labelText="Use Cases"
                  selectValues={props.leadProject.useCases}
                  onChange={(event) =>
                    handleUseCaseChange(parseInt(event.target.value))
                  }
                  editMode
                  value={newProjectLeadLeader.useCaseId}
                />
              </FormInput>
            </Stack>
          </form>
        </DialogContent>
        <ActionButtons>
          <Button onClick={() => props.setModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={
              isCreateMode ? addLeadProjectLeader : updateLeadProjectLeader
            }
            variant="contained"
            color="secondary"
          >
            {isCreateMode ? "Add" : "Update"} Project Leader
          </Button>
        </ActionButtons>
      </Dialog>
    </Fragment>
  );
}

export default ManageProjectLeadLeaderModal;
