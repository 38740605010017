import {
  Autocomplete,
  CircularProgress,
  IconButton,
  ListItem,
  TextField,
  Tooltip,
  debounce,
  styled,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ReactElement, SyntheticEvent, useState } from "react";
import { Startup } from "../../../Types/Startup";
import { StartupHttpService } from "../../../Http/Startup/Startup.http.service";
import AddNewStartupModal from "../Modals/AddNewStartupModal";

interface StartupSelectProps {
  assignedStartupIds: number[] | undefined;
  handleSelectStartup: (selectedStartup: Startup | null) => void;
  selectedStartup: Startup | null | undefined;
  label?: string;
}

const StyledTextField = styled(TextField)((props) => ({
  "& div.MuiOutlinedInput-root": {
    padding: props.label ? "8px" : "0px 8px 0px 0px",
  },
}));

const SimilarResultsLabel = styled(ListItem)(({ theme }) => ({
  "&.MuiAutocomplete-option[aria-disabled='true']": {
    fontStyle: "italic",
    fontSize: theme.typography.caption.fontSize,
    opacity: 1,
    color: theme.palette.grey[700],
  },
}));

const StartupSelect = (props: StartupSelectProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [startupOptions, setStartupOptions] = useState<Startup[]>([]);
  const [addStartupModalOpen, setAddStartupModalOpen] = useState(false);
  const [startupInput, setStartupInput] = useState("");

  const searchForStartups = async (
    event: SyntheticEvent<Element, Event> | null,
    searchValue: string
  ): Promise<void> => {
    if (event?.type === "click") return;

    const trimmedSearchValue = searchValue.trim();
    // avoid fetching when a new startup created
    if (event === null) return;

    if (trimmedSearchValue.length > 0) {
      setIsLoading(true);
      const startups = await StartupHttpService.getStartupsByName(
        trimmedSearchValue,
        true
      );
      let filteredStartups = startups.filter(
        (startup) => !props.assignedStartupIds?.includes(startup.id)
      );
      const filteredSimilarStartupNames = filteredStartups.filter((startup) =>
        startup.name.toLowerCase().includes(trimmedSearchValue.toLowerCase())
      );

      if (filteredSimilarStartupNames.length) {
        filteredStartups = filteredSimilarStartupNames;
        setStartupOptions([...filteredStartups]);
      } else {
        if (filteredStartups.length > 0) {
          setStartupOptions([
            { id: -1, name: "Similar Results" } as Startup,
            ...filteredStartups,
          ]);
        } else setStartupOptions([]);
      }

      setIsLoading(false);
    } else {
      // when clear the field, there should be no startups
      setStartupOptions([]);
    }
  };

  const debouncedSearchForStartups = debounce(searchForStartups, 500);

  const handleChange = (selectedStartup: Startup | null) => {
    if (selectedStartup?.id === -1) {
      setAddStartupModalOpen(true);
    } else {
      props.handleSelectStartup(selectedStartup);
    }
  };

  const handleStartupImportAndCreate = async (id: number) => {
    const startup = await StartupHttpService.getStartupById(id, true);
    props.handleSelectStartup(startup);
  };

  const handleModalClose = () => {
    setAddStartupModalOpen(false);
  };

  return (
    <>
      <Autocomplete
        id="startup-autocomplete"
        sx={{ width: "100%" }}
        forcePopupIcon={false}
        onInputChange={(event, newValue) =>
          debouncedSearchForStartups(event, newValue)
        }
        onChange={(_, selectedStartup) => {
          handleChange(selectedStartup);
        }}
        getOptionDisabled={(startup) => startup.id === -1}
        getOptionLabel={(startup) => startup.name}
        options={startupOptions}
        value={props.selectedStartup || null}
        noOptionsText="No Startup Found"
        loading={isLoading}
        // assign startup's id as a key, otherwise startup's name is being assigned as a key which react complains about.
        renderOption={(props, startup) => {
          if (startup.id === -1) {
            return (
              <SimilarResultsLabel {...props} key={startup.id}>
                {startup.name}
              </SimilarResultsLabel>
            );
          } else {
            return (
              <li {...props} key={startup.id}>
                {startup.name}
              </li>
            );
          }
        }}
        // deactivate filterOptions prop in order to making "Add" option to work
        filterOptions={(startups) => startups}
        renderInput={(params) => {
          return (
            <StyledTextField
              {...params}
              label={props.label}
              placeholder="Type startup name"
              data-testid="add-new-startup-search-input"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <>
                        {params.InputProps.endAdornment}
                        <Tooltip
                          title="Create New Startup"
                          placement="right-end"
                        >
                          <IconButton
                            id="addNewStartup"
                            data-testid="add-new-startup"
                            color="primary"
                            onClick={() => setAddStartupModalOpen(true)}
                          >
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              onChange={(e) => setStartupInput(e.target.value)}
            />
          );
        }}
      />
      {addStartupModalOpen && (
        <AddNewStartupModal
          handleModalClose={handleModalClose}
          modalOpen={addStartupModalOpen}
          startupName={startupInput}
          handleSelectStartup={props.handleSelectStartup}
          handleImport={handleStartupImportAndCreate}
        />
      )}
    </>
  );
};

export default StartupSelect;
