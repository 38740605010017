import { Box, Button, Chip, Typography, styled } from "@mui/material";
import theme from "../../../../../../../theme";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Project } from "../../../../../../../Types/Project";
import { Meeting, Status } from "../../../../../../../Types/Meeting";
import Phase from "../../../../../../../Types/Phase";
import PhaseHttpService from "../../../../../../../Http/Phase/Phase.Http.service";
import AddIcon from "@mui/icons-material/Add";
import RocketIcon from "@mui/icons-material/Rocket";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditMeetingModal from "./EditMeetingModal";
import { getErrorMessage } from "../../../../../../../utils";
import { useSnackbar } from "notistack";
import { GlobalProjectEditContext } from "../../../../../../../Context/ProjectDetailsContext";
import MeetingRow from "./MeetingRow";
import PhaseRow from "./PhaseRow";
import { MeetingHttpService } from "../../../../../../../Http/Meeting/Meeting.Http.service";
import { GlobalLoaderContext } from "../../../../../../../Context/LoaderContext";

type MilestoneOrPhase = Meeting | Phase;

const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;

const TimePlanContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

const TimePlanWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  outline: `1px solid ${theme.palette.other.surfaceSoft}`,
  borderRadius: theme.customBorderRadius.md,
  pb: 0,
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(4),
}));

const Footer = styled(Button)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1.5),
  padding: theme.spacing(1.5),
  fontWeight: 400,
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  color: theme.palette.other.secondaryAction,
  textTransform: "capitalize",
  borderRadius: 0,
}));

const ProgressBarContainer = styled(Box)(() => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  overflow: "hidden",
  position: "relative",
}));

const ProgressBarWrapper = styled(Box)(() => ({
  position: "absolute",
  left: "50%",
  width: "1px",
  transform: "translate(-50%, 44px)",
  top: 0,
  zIndex: 8,
  // extract paddings and row height
  height: "calc(100% - 32px - 56px)",
}));

const IconWrapper = styled("span")(() => ({
  position: "absolute",
  left: "50%",
  width: "32px",
  aspectRatio: "1/1",
  borderRadius: "50%",
  display: "grid",
  placeItems: "center",
}));

const RocketIconWrapper = styled(IconWrapper, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(
  ({
    $rocketPosition,
    $color,
  }: {
    $rocketPosition: number;
    $color: string;
  }) => ({
    transform: "translate(-50%, -50%)",
    fill: $color,
    backgroundColor: $color,
    top: `${$rocketPosition}%`,
  })
);

const RocketIndicator = styled(RocketIcon, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $color }: { $color: string }) => ({
  fontSize: "18px",
  stroke: "white",
  strokeWidth: "1.5px",
  fill: $color,
}));

const ConclusionIconWrapper = styled(IconWrapper, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isConcluded }: { $isConcluded: boolean }) => ({
  // padding of the parent + half of the row height
  bottom: `calc(${theme.spacing(2)} + 28px)`,
  transform: "translate(-50%, 50%)",
  fill: theme.palette.other.surfaceSoft,
  backgroundColor: $isConcluded
    ? theme.palette.primary.main
    : theme.palette.grey[300],
  zIndex: 10,
}));

const ConclusionIcon = styled(CheckCircleOutlineIcon)(() => ({
  fontSize: "18px",
  fill: "white",
}));

const TotalDurationWrapper = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
  padding: theme.spacing(4, 3),
  justifyContent: "end",
}));

const TotalDurationDisplay = styled(Chip)(() => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.customBorderRadius.xs,
  backgroundColor: "transparent",
  height: "24px",
  color: theme.palette.primary.main,
  fontSize: theme.customTypography.fontSize12,
}));

const compareByDateThenId = (a: Meeting, b: Meeting) => {
  if (a.type === "Kick-off" || b.type === "Conclusion") return -1;
  if (b.type === "Kick-off" || a.type === "Conclusion") return 1;

  if (a.dateStart === b.dateStart) return a.id - b.id;
  if (a.dateStart === null) return 1;
  if (b.dateStart === null) return -1;

  return +new Date(a.dateStart) - +new Date(b.dateStart);
};

const compareById = (a: Meeting | Phase, b: Meeting | Phase) => {
  return a.id - b.id;
};

const mergeMeetingAndPhase = (meetings: Meeting[], phases: Phase[]) => {
  const sortedMeetings = meetings.sort(compareByDateThenId);
  const sortedPhases = phases.sort(compareById);

  const merged = [];
  for (
    let i = 0;
    i < Math.max(sortedMeetings.length, sortedPhases.length);
    i++
  ) {
    if (sortedMeetings[i]) merged.push(meetings[i]);
    if (sortedPhases[i]) merged.push(phases[i]);
  }
  return merged;
};

const calculateTotalDuration = (sortedMeetings: Meeting[]): number => {
  return sortedMeetings.reduce((accumulatedDuration, currentMeeting, index) => {
    if (index === 0) return accumulatedDuration;

    const previousMeeting = sortedMeetings[index - 1];

    if (!previousMeeting.dateStart || !currentMeeting.dateStart)
      return accumulatedDuration;

    const previousDate = new Date(previousMeeting.dateStart ?? "");
    const currentDate = new Date(currentMeeting.dateStart ?? "");

    let duration = (+currentDate - +previousDate) / MILLISECONDS_IN_A_DAY;
    duration = Math.max(0, Math.ceil(duration));

    return accumulatedDuration + duration;
  }, 0);
};

const computePhaseDurations = (
  meetings: Meeting[],
  phases: Phase[]
): Phase[] => {
  return phases.map((phase, index) => {
    if (index < meetings.length - 1) {
      const startDate = new Date(meetings[index].dateStart ?? "");
      const endDate = new Date(meetings[index + 1].dateStart ?? "");
      const duration = (+endDate - +startDate) / MILLISECONDS_IN_A_DAY;
      phase.duration = Math.max(0, Math.ceil(duration));
    }
    return phase;
  });
};

const isMeeting = (obj: Meeting | Phase): obj is Meeting => {
  return "type" in obj;
};

export const adjustWeekendDate = (date: Date): Date => {
  if (date.getDay() === 6) {
    date.setDate(date.getDate() + 2);
  } else if (date.getDay() === 0) {
    date.setDate(date.getDate() + 1);
  }
  return date;
};

export const getSubsequentMeetingsToUpdate = (
  meetings: Meeting[],
  startIndex: number,
  dateDifference: number
): Meeting[] => {
  return meetings
    .slice(startIndex + 1)
    .filter((meeting) => meeting.dateStart)
    .map((meeting) => {
      if (meeting.dateStart) {
        const newDate = meeting.dateStart.getTime() + dateDifference;
        meeting.dateStart = new Date(newDate);
        meeting.dateStart = adjustWeekendDate(meeting.dateStart);
      }

      return {
        ...meeting,
        dateStart: meeting.dateStart,
      };
    });
};

export const getApproximateDuration = (
  duration: number | undefined
): string => {
  if (!duration) return "- days";

  if (duration <= 10) return `${duration} days`;
  else if (duration <= 17) return "2 weeks";
  else if (duration <= 24) return "3 weeks";
  else if (duration <= 37) return "1 month";
  else if (duration <= 49) return "1.5 months";
  else if (duration <= 75) return "2 months";
  else {
    return `${Math.ceil((duration - 75) / 31) + 2} months`;
  }
};

const getMeetingDate = (meetings: Meeting[], type: string): Date | null => {
  const meeting = meetings.find((meeting) => meeting.type === type);
  return meeting?.dateStart || null;
};

interface Props {
  project: Project;
  handleSave: (withScroll?: boolean) => void;
}

const TimeplanAndMilestones = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const { activeStep } = useContext(GlobalProjectEditContext);
  const meetingsInitial = props.project.meetings.sort(compareByDateThenId);
  const phasesInitial = props.project.phases.sort(compareById);
  const [meetings, setMeetings] = useState(meetingsInitial);
  const [phases, setPhases] = useState(phasesInitial);
  const phasesWithDuration = computePhaseDurations(meetings, phases);
  const totalDuration = calculateTotalDuration(meetings);
  const [mergedData, setMergedData] = useState<MilestoneOrPhase[]>(
    mergeMeetingAndPhase(meetings, phasesWithDuration)
  );
  const [isEditMeetingModalOpen, setIsEditMeetingModalOpen] = useState(false);
  const [meetingStatuses, setMeetingStatuses] = useState<Status[]>([]);
  const [rocketPosition, setRocketPosition] = useState(
    Number(`calc(${theme.spacing(2)} + 28px)`)
  );

  const isBuyStage = activeStep === 2;
  const isPilotStage = activeStep === 3;

  let meetingIndex = 0;
  let phaseIndex = 0;
  const shouldShowIcon = mergedData.length > 0;
  const isConcluded = rocketPosition === 100;

  const rocketColor = isPilotStage
    ? theme.palette.primary.main
    : theme.palette.other.surfaceSoft;

  useEffect(() => {
    setMergedData(mergeMeetingAndPhase(meetings, phasesWithDuration));
  }, [meetings, phases]);

  useEffect(() => {
    if (isPilotStage) {
      const getMeetingStatus = (meetingDate: Date, today: Date) => {
        if (meetingDate < today) return "completed";
        if (meetingDate > today || isNaN(meetingDate.getTime())) {
          return "upcoming";
        }
        return "today";
      };

      const updateMeetingStatuses = (meetings: Meeting[], today: Date) => {
        const statuses: Status[] = meetings.map((meeting) => {
          const meetingDate = new Date(meeting.dateStart || "");
          meetingDate.setHours(0, 0, 0, 0);
          return getMeetingStatus(meetingDate, today);
        });

        for (let i = 0; i < statuses.length - 1; i++) {
          if (
            statuses[i] === "completed" &&
            (statuses[i + 1] === "completed" || statuses[i + 1] === "today")
          ) {
            statuses[i] = "done";
          }
        }

        setMeetingStatuses(statuses);
      };

      const updateRocketPosition = (meetings: Meeting[], today: Date) => {
        if (today < new Date(meetings[0].dateStart || "")) {
          setRocketPosition(0);
          return;
        }

        if (today > new Date(meetings[meetings.length - 1].dateStart || "")) {
          setRocketPosition(100);
          return;
        }

        for (let i = 1; i < meetings.length; i++) {
          const prevMeetingDate = new Date(meetings[i - 1].dateStart || "");
          const currentMeetingDate = new Date(meetings[i].dateStart || "");
          prevMeetingDate.setHours(0, 0, 0, 0);
          currentMeetingDate.setHours(0, 0, 0, 0);

          if (today >= prevMeetingDate && today <= currentMeetingDate) {
            const totalDays =
              (+currentMeetingDate - +prevMeetingDate) / MILLISECONDS_IN_A_DAY;
            const passedDays =
              (+today - +prevMeetingDate) / MILLISECONDS_IN_A_DAY;
            const percentagePassed = (passedDays / totalDays) * 100;
            const totalMeetings = meetings.length;
            const percentagePerMeeting = 100 / (totalMeetings - 1);
            const startPosition = (i - 1) * percentagePerMeeting;
            setRocketPosition(
              startPosition + percentagePerMeeting * (percentagePassed / 100)
            );
            break;
          }
        }
      };

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (meetings.length) {
        updateMeetingStatuses(meetings, today);
        updateRocketPosition(meetings, today);
      }
    }
  }, [meetings, mergedData]);

  const getBoundaryDate = (
    meetingIndex: number,
    direction: "min" | "max"
  ): Date | null => {
    let filteredMeetings: Meeting[];
    if (direction === "min") {
      filteredMeetings = mergedData
        .slice(0, meetingIndex)
        .filter(
          (meeting) => isMeeting(meeting) && meeting.dateStart
        ) as Meeting[];
    } else {
      filteredMeetings = mergedData
        .slice(meetingIndex + 1)
        .filter(
          (meeting) => isMeeting(meeting) && meeting.dateStart
        ) as Meeting[];
    }
    if (filteredMeetings.length === 0) return null;
    const boundaryMeeting = filteredMeetings.reduce((prev, current) => {
      const prevDate = prev.dateStart ? new Date(prev.dateStart) : null;
      const currentDate = current.dateStart
        ? new Date(current.dateStart)
        : null;
      if (!prevDate) return current;
      if (!currentDate) return prev;
      return (direction === "min" && prevDate > currentDate) ||
        (direction === "max" && prevDate < currentDate)
        ? prev
        : current;
    });
    return boundaryMeeting.dateStart
      ? new Date(boundaryMeeting.dateStart)
      : null;
  };

  const handleMeetingCreate = async (createdMeeting: Meeting) => {
    const initialPhase = {
      projectId: props.project.id,
      name: "",
      description: "",
      isInitial: isBuyStage ? true : false,
    };
    await PhaseHttpService.createPhase(initialPhase)
      .then((createdPhase: Phase) => {
        props.handleSave(false);
        setPhases((prevPhases) => [...prevPhases, createdPhase]);
        setMeetings((prevMeetings) => {
          return [...prevMeetings, createdMeeting];
        });
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not create phase: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleMeetingEdit = async (
    updatedMeeting: Meeting,
    isPushMeetingsEnabled: boolean
  ) => {
    const meetingIndex = meetings.findIndex(
      (meeting) => meeting.id === updatedMeeting.id
    );
    if (meetingIndex === -1) return;

    const originalMeeting: Meeting = meetings[meetingIndex];
    const meetingsToUpdate: Meeting[] = [updatedMeeting];

    if (
      originalMeeting.dateStart !== updatedMeeting.dateStart &&
      isPushMeetingsEnabled
    ) {
      const dateDifference =
        +(updatedMeeting.dateStart || "") - +(originalMeeting.dateStart || "");

      if (dateDifference) {
        const subsequentMeetingsToUpdate = getSubsequentMeetingsToUpdate(
          meetings,
          meetingIndex,
          dateDifference
        );
        meetingsToUpdate.push(...subsequentMeetingsToUpdate);
      }
    }

    try {
      setGlobalLoader(true);
      await Promise.all(
        meetingsToUpdate.map((meeting) =>
          MeetingHttpService.updateMeeting(meeting)
        )
      );
      setMeetings((prevMeetings) =>
        prevMeetings.map((meeting) =>
          meeting.id === updatedMeeting.id ? updatedMeeting : meeting
        )
      );
      // eslint-disable-next-line
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      enqueueSnackbar(`Could not update meetings: ${errorMessage}`, {
        variant: "error",
      });
    } finally {
      setGlobalLoader(false);
    }
  };

  const getPhaseToDelete = (milestoneId: number) => {
    const milestoneIndex = mergedData.findIndex(
      (item) => item.id === milestoneId && isMeeting(item)
    );
    let phaseToDelete: Phase | null = null;
    if (!isMeeting(mergedData[milestoneIndex + 1])) {
      phaseToDelete = mergedData[milestoneIndex + 1] as Phase;
    }
    return phaseToDelete?.id;
  };
  const handleDeleteMilestone = async (milestoneId: number): Promise<void> => {
    const phaseIdToDelete = getPhaseToDelete(milestoneId);
    setMeetings((prevMeetings) =>
      prevMeetings.filter(
        (meeting) =>
          meeting.id !== milestoneId && meeting.id !== phaseIdToDelete
      )
    );
    setPhases((prevPhases) =>
      prevPhases.filter((phase) => phase.id !== phaseIdToDelete)
    );
    if (phaseIdToDelete) {
      await PhaseHttpService.deletePhase(phaseIdToDelete);
    }
    props.handleSave(false);
  };
  const handlePhaseEdit = (updatedPhase: Phase) => {
    setPhases((prevPhases) =>
      prevPhases.map((phase) =>
        phase.id === updatedPhase.id ? updatedPhase : phase
      )
    );
    props.handleSave();
  };
  return (
    <>
      <TimePlanContainer>
        <Header>
          <Typography variant="h6">Project Timeplan & Milestones</Typography>
        </Header>
        <TimePlanWrapper>
          <ProgressBarContainer>
            <ProgressBarWrapper
              sx={{
                background: `linear-gradient(to bottom, ${theme.palette.primary.main} ${rocketPosition}%, transparent ${rocketPosition}%)`,
              }}
            />
            {shouldShowIcon && !isConcluded && (
              <ProgressBarWrapper
                sx={{
                  zIndex: 11,
                }}
              >
                <RocketIconWrapper
                  $rocketPosition={rocketPosition}
                  $color={rocketColor}
                >
                  <RocketIndicator $color={rocketColor} />
                </RocketIconWrapper>
              </ProgressBarWrapper>
            )}
            {mergedData.map((item, index) => {
              const isMilestone = isMeeting(item) && item.type === "Milestone";
              if (isMilestone) {
                meetingIndex++;
              }

              if (isMeeting(item)) {
                const minDate = getBoundaryDate(index, "min");
                const maxDate = getBoundaryDate(index, "max");

                return (
                  <MeetingRow
                    key={item.id}
                    index={meetingIndex}
                    isFirst={index === 0}
                    isLast={index === mergedData.length - 1}
                    meeting={item}
                    status={meetingStatuses[index / 2]}
                    handleMeetingEdit={handleMeetingEdit}
                    handleDelete={() => {
                      handleDeleteMilestone(item.id);
                      props.handleSave(false);
                    }}
                    startupId={
                      props.project.opportunities?.filter(
                        (opp) => opp.isSelectedForPilot
                      )[0]?.startupId
                    }
                    ventureClientId={
                      props.project.businessUnit.ventureClient.id
                    }
                    maxDate={maxDate}
                    minDate={minDate}
                  />
                );
              } else {
                return (
                  <PhaseRow
                    index={++phaseIndex}
                    key={item.id}
                    phase={item}
                    handlePhaseEdit={handlePhaseEdit}
                  />
                );
              }
            })}
            {shouldShowIcon && (
              <ConclusionIconWrapper $isConcluded={isConcluded}>
                <ConclusionIcon />
              </ConclusionIconWrapper>
            )}
          </ProgressBarContainer>
          <TotalDurationWrapper>
            <Typography variant="caption" color={theme.palette.grey[600]}>
              Total duration of the project
            </Typography>
            <TotalDurationDisplay
              label={
                totalDuration ? getApproximateDuration(totalDuration) : "N/A"
              }
            />
          </TotalDurationWrapper>
          <Footer onClick={() => setIsEditMeetingModalOpen(true)}>
            <AddIcon sx={{ fontSize: 25 }} />
            New Milestone
          </Footer>
        </TimePlanWrapper>
      </TimePlanContainer>
      {isEditMeetingModalOpen && (
        <EditMeetingModal
          modalOpen={isEditMeetingModalOpen}
          setModalOpen={setIsEditMeetingModalOpen}
          handleMeetingCreate={handleMeetingCreate}
          project={props.project}
          startupId={
            props.project.opportunities?.filter(
              (opp) => opp.isSelectedForPilot
            )[0]?.startupId
          }
          ventureClientId={props.project.businessUnit.ventureClient.id}
          minDate={getMeetingDate(meetings, "Kick-off")}
          maxDate={getMeetingDate(meetings, "Conclusion")}
        />
      )}
    </>
  );
};

export default TimeplanAndMilestones;
