import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  debounce,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
  Fragment,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ClientContactDTO } from "../../Types/ClientContact";
import ClientContactHttpService from "../../Http/ClientContact/ClientContact.http.service";
import CreateClientContactModal from "./CreateClientContactModal";
import DetailsClientContactModal from "./DetailsClientContactModal";
import { useSnackbar } from "notistack";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { thousandSeparator } from "../../utils";
import { unstable_batchedUpdates } from "react-dom";

const StyledSearchBar = styled(TextField)(({ theme }) => ({
  width: "40%",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "4px",
  "& .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0px 4px 10px #E0E1E0",
    border: "1px solid #E0E1E0",
  },
}));

export default function ContactList(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [clientContacts, setClientContacts] = useState<ClientContactDTO[]>([]);
  const [totalNumberOfContacts, setTotalNumberOfContacts] = useState(0);
  const [totalPage, setTotalPage] = useState<number>();
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);

  const [clientContactDetailsModalOpen, setClientContactDetailsModalOpen] =
    useState<boolean>(false);
  const [createClientContactModalOpen, setCreateContactClientModalOpen] =
    useState(false);
  const [selectedClientContact, setSelectedClientContact] =
    useState<ClientContactDTO>();

  const handleClientDetailsModalOpen = (clientContact: ClientContactDTO) => {
    setClientContactDetailsModalOpen(true);
    setSelectedClientContact(clientContact);
  };

  const handleClientContactRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    try {
      setGlobalLoader(true);
      ClientContactHttpService.getContacts(page, searchValue).then((res) => {
        setClientContacts(res.data);
        setTotalPage(res.totalPages);
        setTotalNumberOfContacts(res.totalData);
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong with fetching contacts", {
        variant: "error",
      });
    } finally {
      setGlobalLoader(false);
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    }
  }, [page, debouncedSearchValue, refresh]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debouncedStateChange(value);
  };

  const debouncedStateChange = useCallback(
    debounce((searchVal: string) => {
      unstable_batchedUpdates(() => {
        setDebouncedSearchValue(searchVal);
        setPage(1);
      });
    }, 500),
    []
  );

  const formattedSearchResult = thousandSeparator(totalNumberOfContacts);

  return (
    <Fragment>
      <Box display="flex" alignItems="center" flexGrow={1} mb={4} gap={3}>
        <StyledSearchBar
          id="search-contacts"
          inputProps={{
            "data-testid": "search-contacts",
          }}
          variant="outlined"
          placeholder="Type a name or unit"
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton size="small" sx={{ pointerEvents: "none" }}>
                  <SearchIcon color={searchValue ? "primary" : "action"} />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {debouncedSearchValue && (
                  <IconButton
                    size="small"
                    onClick={() => handleSearch("")}
                    id="clear-search"
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="body2" color="text.secondary" marginRight="auto">
          {clientContacts.length > 0 &&
            `${formattedSearchResult} ${
              formattedSearchResult === "1" ? "result" : "results"
            }`}
        </Typography>
        <Button
          onClick={() => setCreateContactClientModalOpen(true)}
          variant="outlined"
          id="add-contact"
        >
          + Add Contact
        </Button>
      </Box>
      <TableContainer component={Paper} variant="outlined" id="contacts-list">
        <Table sx={{ minWidth: 200 }} aria-label="contacts table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Organizational Unit</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ cursor: "pointer" }}>
            {clientContacts.map((contact) => (
              <TableRow
                hover
                key={contact.id}
                onClick={() => handleClientDetailsModalOpen(contact)}
              >
                <TableCell>{contact.name}</TableCell>
                <TableCell>{contact.title}</TableCell>
                <TableCell>{contact.businessUnit.name}</TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell>{contact.phone}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedClientContact && clientContactDetailsModalOpen && (
        <DetailsClientContactModal
          setModalOpen={setClientContactDetailsModalOpen}
          modalOpen={clientContactDetailsModalOpen}
          contact={selectedClientContact}
          handleSave={handleClientContactRefresh}
        />
      )}
      {createClientContactModalOpen && (
        <CreateClientContactModal
          setModalOpen={setCreateContactClientModalOpen}
          modalOpen={createClientContactModalOpen}
          handleSave={handleClientContactRefresh}
        />
      )}

      <Pagination
        page={page}
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "center",
        }}
        size="medium"
        count={totalPage}
        color="primary"
        onChange={(event, page) => setPage(page)}
        siblingCount={2}
      />
    </Fragment>
  );
}
