import { VariableType } from "../../../../../Types/ImpactValue";

export const getDescAndTitle = (
  impactType: string
): { description: string; title: string } => {
  let description = "";
  let title = "";
  switch (impactType) {
    case "Cost":
      description =
        "Calculation of potential cost savings in case of successful adoption of the startup technology.";
      title = "Cost Saving Potential";
      break;
    case "Revenue":
      description =
        "Calculation of potential revenue increase in case of successful adoption of the startup technology.";
      title = "Revenue Potential";
      break;
    case "Cost & Revenue":
      description =
        "Calculation of aggregated potential value in case of successful adoption of the startup technology.";
      title = "Aggregated Impact Value";
      break;
  }
  return { description, title };
};

export function calculate(variables: VariableType[]): number {
  if (variables.length === 0) return 0;

  const numbers: number[] = [];
  const operators: string[] = [];

  const precedence: { [key: string]: number } = {
    "+": 1,
    "-": 1,
    x: 2,
    ":": 2,
  };

  const applyOperator = (operator: string, a: number, b: number): number => {
    switch (operator) {
      case "+":
        return +a + +b;
      case "-":
        return a - b;
      case "x":
        return a * b;
      case ":":
        return a / b;
      default:
        throw new Error("Invalid operator");
    }
  };

  for (const variable of variables) {
    if (variable.operator) {
      while (
        operators.length &&
        precedence[operators[operators.length - 1]] >=
          precedence[variable.operator]
      ) {
        const operator = operators.pop();
        const b = numbers.pop() as number;
        const a = numbers.pop() as number;
        numbers.push(applyOperator(operator as string, a, b));
      }
      operators.push(variable.operator);
    }
    numbers.push(+variable.value);
  }

  while (operators.length) {
    const operator = operators.pop();
    const b = numbers.pop() as number;
    const a = numbers.pop() as number;
    numbers.push(applyOperator(operator as string, a, b));
  }

  return numbers.pop() as number;
}

export function hasMonetaryValue(variables: VariableType[]): boolean {
  return variables.some((variable) => variable.unit === "Euro");
}
