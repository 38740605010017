import { FilterList } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  TextField,
  Divider,
  styled,
  Typography,
} from "@mui/material";

import {
  Fragment,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { NavLink } from "react-router-dom";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { StartupHttpService } from "../../Http/Startup/Startup.http.service";
import { Startup, StartupFilterCriteria } from "../../Types/Startup";
import { FilterDrawer } from "./FilterDrawer";
import { formatFunding, thousandSeparator } from "../../utils";
import { unstable_batchedUpdates } from "react-dom";

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
  gap: theme.spacing(4),
}));

const StyledSearchBar = styled(TextField)(({ theme }) => ({
  width: "40%",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "4px",
  "& .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0px 4px 10px #E0E1E0",
    border: "1px solid #E0E1E0",
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBlock: `${theme.spacing(6)} ${theme.spacing(5)}`,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  alignItems: "center",
  height: "100%",
  gap: theme.spacing(2),
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  "& .MuiTypography-h5": {
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const ShortDescription = styled(CardContent)(({ theme }) => ({
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxHeight: "3rem",
  paddingTop: "0",
  marginBottom: theme.spacing(2.5),
}));

export default function StartupList(): ReactElement {
  const [startups, setStartups] = useState<Startup[]>([]);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [searchInName, setSearchInName] = useState<string>("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [filters, setFilters] = useState<StartupFilterCriteria>({});
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState<number>();
  const [totalNumberOfStartups, setTotalNumberOfStartups] = useState(0);
  const { globalLoader, setGlobalLoader } = useContext(GlobalLoaderContext);

  const handleSearch = (value: string) => {
    setSearchInName(value);
    debouncedStateChange(value);
  };

  const handleFilters = (filters: StartupFilterCriteria) => {
    setFilters(filters);
    setPage(1);
  };

  useEffect(() => {
    setGlobalLoader(true);
    StartupHttpService.getStartups(filters, page, searchInName).then((res) => {
      setStartups(res.data);
      setTotalPage(res.totalPages);
      setTotalNumberOfStartups(res.totalData);
      setGlobalLoader(false);
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    });
  }, [filters, page, debouncedSearchValue]);

  const formattedSearchResult = thousandSeparator(totalNumberOfStartups);

  const debouncedStateChange = useCallback(
    debounce((searchVal: string) => {
      unstable_batchedUpdates(() => {
        setDebouncedSearchValue(searchVal);
        setPage(1);
      });
    }, 500),
    []
  );
  return (
    <Fragment>
      <StyledHeader>
        <StyledSearchBar
          inputProps={{ "data-testid": "startup-search" }}
          variant="outlined"
          id="search-contacts"
          placeholder="Search startups"
          value={searchInName}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton size="small" sx={{ pointerEvents: "none" }}>
                  <SearchIcon color={searchInName ? "primary" : "action"} />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position={"start"}>
                {debouncedSearchValue && (
                  <IconButton
                    size="small"
                    onClick={() => handleSearch("")}
                    id="clear-search"
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ mr: "auto" }}
        >
          {!globalLoader &&
            totalPage != undefined &&
            `${formattedSearchResult} ${
              formattedSearchResult === "1" ? "result" : "results"
            }`}
        </Typography>

        <Button
          id="startup-list-filter-button"
          onClick={() => setFilterDrawerOpen(true)}
        >
          <FilterList sx={{ mr: 1 }} /> Filter
        </Button>
      </StyledHeader>
      <StyledDivider />
      <Grid id="startup-list" container spacing={4}>
        {startups.map((startup) => {
          return (
            <Grid item xs={4} key={startup.id} data-testid="startupTile">
              <NavLink to={`/startups/${startup.id}`}>
                <StyledCard>
                  <StyledCardHeader
                    title={startup.name}
                    subheader={`Founded: ${
                      startup.dateFounded &&
                      new Date(startup.dateFounded).getFullYear()
                    }
                    ${
                      startup.employees
                        ? " | " + startup.employees + " Employees"
                        : ""
                    }  
                    ${
                      startup.fundingIsUndisclosed
                        ? " | Funding Undisclosed"
                        : " | " + formatFunding(startup.totalFunding)
                    } 
                     ${
                       startup.currentInvestmentStage
                         ? " | " + startup.currentInvestmentStage
                         : ""
                     }`}
                  />
                  <ShortDescription>
                    {startup.shortDescription}
                  </ShortDescription>
                </StyledCard>
              </NavLink>
            </Grid>
          );
        })}
      </Grid>
      {debouncedSearchValue && startups.length > 0 && (
        <Pagination
          data-testid="pagination-startuplist"
          page={page}
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "center",
          }}
          size="medium"
          count={totalPage}
          color="primary"
          onChange={(event, page) => setPage(page)}
          siblingCount={2}
        />
      )}

      <FilterDrawer
        open={filterDrawerOpen}
        setFilterDrawerOpen={(state: boolean) => setFilterDrawerOpen(state)}
        handleFilters={handleFilters}
        activeFilters={filters}
      />
    </Fragment>
  );
}
