import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import theme from "../../theme";
import {
  LeadProject,
  LeadOpportunity,
  LeadProjectLeader,
} from "../../Types/LeadProject";
import { useHistory } from "react-router-dom";
import { getErrorMessage } from "../../utils";
import { useSnackbar } from "notistack";
import { LeadProjectsHttpService } from "../../Http/LeadProjects/LeadProjects.http.service";
const SingleRadio = styled(FormControlLabel)(() => ({
  width: "100%",
  "& span:nth-of-type(2)": { width: "100%" },
}));
interface LaunchProjectModalProps {
  handleModalClose: () => void;
  modalOpen: boolean;
  leadProject: LeadProject;
}
export default function LaunchProjectModal(
  props: LaunchProjectModalProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const leaders = props.leadProject.leaders;
  const startups = props.leadProject.opportunities;
  const hasSingleProjectLeadLeader = leaders.length === 1;
  const shouldSelectProjectLeadLeader =
    hasSingleProjectLeadLeader &&
    !!props.leadProject.leaders[0].useCaseId &&
    !!props.leadProject.leaders[0].projectLeaderId;
  const [selectedValue, setSelectedValue] = useState<number | undefined>(
    shouldSelectProjectLeadLeader ? leaders[0].id : undefined
  );
  const [selectedStartups, setSelectedStartups] = useState<number[]>(
    startups.length === 1 ? [startups[0].startup.id] : []
  );
  const [keepLead, setKeepLead] = useState<boolean>(false);
  const selectedLeader = props.leadProject.leaders.find(
    (leader) => leader.id === selectedValue
  );
  const handleLaunchProject = async () => {
    await LeadProjectsHttpService.launchProject({
      leadProjectId: props.leadProject.id,
      businessUnitId: selectedLeader?.businessUnitId as number,
      startupIds: selectedStartups,
      name: selectedLeader?.useCase?.name as string,
      projectLeaderId: selectedLeader?.projectLeaderId as number,
      keepLead,
    })
      .then((projectId) => {
        history.push(`/projects/${projectId}`);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not launch project: ${errorMessage}`, {
          variant: "error",
        });
      });
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setSelectedValue(value);
  };
  return (
    <Fragment>
      <Dialog
        maxWidth="sm"
        open={props.modalOpen}
        onClose={props.handleModalClose}
        data-testid="launch-project-modal"
        sx={{
          ".MuiPaper-root": {
            gap: theme.spacing(2),
            padding: 2,
          },
        }}
      >
        <DialogTitle>Launch Project</DialogTitle>
        <Typography variant="body1" px={3}>
          Select the Use Case, Project Leaders and/or startups to launch a
          project.
        </Typography>
        <DialogContent sx={{ overflow: "visible" }}>
          <Stack gap={4}>
            <Stack gap={2}>
              <Typography variant="subtitle1">
                Use Case & Project Leader *
              </Typography>
              <FormControl
                fullWidth
                sx={{
                  p: theme.spacing(0.5, 2),
                }}
              >
                <RadioGroup
                  value={selectedValue ?? null}
                  onChange={handleRadioChange}
                >
                  {props.leadProject.leaders.map((leader) => {
                    return (
                      <SingleProjectLeader
                        key={leader.id}
                        leader={leader}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        shouldSelectProjectLeadLeader={
                          shouldSelectProjectLeadLeader
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack gap={1}>
              <ProjectLeadOpportunities
                opportunities={props.leadProject.opportunities}
                selectedValues={selectedStartups}
                setSelectedValues={setSelectedStartups}
              />
              {!props.leadProject.opportunities.length && (
                <Typography
                  paddingTop={theme.spacing(2)}
                  variant="body1"
                  color="text.disabled"
                >
                  No Startups added
                </Typography>
              )}
            </Stack>
            <Box
              display="flex"
              alignItems="start"
              p={theme.spacing(2, 0.5)}
              gap={1}
            >
              <Checkbox
                sx={{ mt: -1.5 }}
                checked={keepLead}
                onChange={() => setKeepLead(!keepLead)}
                data-testid="keep-lead-project"
              />
              <Stack gap={1}>
                <Typography variant="subtitle2">
                  I may launch multiple projects from this lead.
                </Typography>
                <Typography variant="body1">
                  After launching a project, this lead will remain in the
                  project leads section allowing you to create additional
                  projects from the same lead.
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleModalClose}>Close</Button>
          <Button
            onClick={handleLaunchProject}
            variant="contained"
            disabled={!selectedValue}
            data-testid="launch-project-button"
          >
            Launch Project
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
function SingleProjectLeader(props: {
  leader: LeadProjectLeader;
  selectedValue: number | undefined;
  setSelectedValue: (value: number) => void;
  shouldSelectProjectLeadLeader: boolean;
}): ReactElement {
  const {
    leader,
    selectedValue,
    setSelectedValue,
    shouldSelectProjectLeadLeader,
  } = props;
  const handleRadioChange = () => {
    setSelectedValue(leader.id);
  };
  const leaderDisabled =
    !leader.projectLeaderId || !leader.businessUnitId || !leader.useCaseId;
  return (
    <SingleRadio
      value={leader.id}
      control={<Radio disabled={leaderDisabled} />}
      label={
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle2">
            {leader.useCase?.name || ""}
          </Typography>{" "}
          <Typography variant="body1" textAlign="right">
            {`${leader.projectLeader?.name || ""} ${
              leader.businessUnit ? `(${leader.businessUnit.name})` : ""
            }`}
          </Typography>
        </Box>
      }
      onChange={handleRadioChange}
      checked={shouldSelectProjectLeadLeader || selectedValue === leader.id}
    />
  );
}
interface ProjectLeadOpportunities {
  opportunities: LeadOpportunity[];
  selectedValues: number[];
  setSelectedValues: (values: number[]) => void;
}
function ProjectLeadOpportunities(
  props: ProjectLeadOpportunities
): ReactElement {
  const { selectedValues, setSelectedValues } = props;
  const handleCheckboxChange = (value: number) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((v) => v !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };
  const handleSelectAll = () => {
    if (selectedValues.length === props.opportunities.length) {
      setSelectedValues([]);
    } else {
      setSelectedValues(
        props.opportunities.map((leadOpportunity) => leadOpportunity.startup.id)
      );
    }
  };
  return (
    <FormControl fullWidth>
      <Stack gap={2}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="subtitle1">Startups</Typography>
          <Button
            data-testid="select-all-startups-button"
            onClick={handleSelectAll}
            disabled={props.opportunities.length === 0}
          >
            {props.opportunities.length === 0 ||
            selectedValues.length !== props.opportunities.length
              ? "Select All"
              : "Deselect All"}
          </Button>
        </Box>
        {props.opportunities.length > 0 && (
          <Stack
            gap={2}
            p={2}
            // boxShadow={theme.boxShadows[0]}
            // borderRadius={theme.shape.radius.minimal}
          >
            {props.opportunities.map((leadOpportunity) => {
              const startup = leadOpportunity.startup;
              return (
                <Box
                  key={startup.id}
                  data-testid={`select-startup-${startup.id}`}
                >
                  <FormControlLabel
                    sx={{ alignItems: "start", gap: 1 }}
                    control={
                      <Checkbox
                        sx={{ mt: -1.5 }}
                        checked={selectedValues.includes(startup.id)}
                        onChange={() => handleCheckboxChange(startup.id)}
                      />
                    }
                    label={
                      <Box display="grid" gridTemplateColumns="64px 1fr">
                        <Typography
                          variant="subtitle2"
                          gridColumn="span 2"
                          mb={0.5}
                        >
                          {startup.name}
                        </Typography>
                        <Typography variant="caption">Mio. $</Typography>
                        <Typography
                          color="brand.accessibility"
                          variant="caption"
                        >
                          {startup.fundingIsUndisclosed
                            ? "Undisclosed"
                            : startup.totalFunding
                            ? startup.totalFunding
                            : "--"}
                        </Typography>
                        <Typography variant="caption">Stage</Typography>
                        <Typography
                          color="brand.accessibility"
                          variant="caption"
                        >
                          {startup.currentInvestmentStage || "--"}
                        </Typography>
                      </Box>
                    }
                    value={startup.id}
                    data-testid={`select-${startup.id}`}
                  />
                </Box>
              );
            })}
          </Stack>
        )}
      </Stack>
    </FormControl>
  );
}
