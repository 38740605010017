import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  Typography,
  DialogActions,
} from "@mui/material";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { ReactElement } from "react";

interface Props {
  modalOpen: boolean;
  title: string;
  content: string;
  setModalOpen: (state: boolean) => void;
  onDelete?: () => void;
}

const InformationModal = (props: Props): ReactElement => {
  const handleDeleteEntity = () => {
    props.onDelete?.();
    props.setModalOpen(false);
  };

  return (
    <Dialog open={props.modalOpen} maxWidth="sm">
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers sx={{ borderBottom: "none" }}>
        <Box display="flex" alignItems="center" gap={1}>
          <WarningOutlinedIcon color="error" sx={{ fontSize: "2rem" }} />
          <Typography>{props.content}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={() => props.setModalOpen(false)}
        >
          Cancel
        </Button>

        {props.onDelete && (
          <Button variant="outlined" color="error" onClick={handleDeleteEntity}>
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InformationModal;
