import { ReactElement, useState } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import SignIn from "./SignIn";
import ResetPasswordToken from "./ResetPasswordToken";
import ResetPassword from "./ResetPassword";
import ActiveAccount from "./ActiveAccount";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flexDirection: "row",
      width: "100%",
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      "& .MuiInputBase-root": {
        color: "white",
      },
      "& label.Mui-focused": {
        color: "white",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "white",
        },
        "&:hover fieldset": {
          borderColor: "white",
        },
        "&.Mui-focused fieldset": {
          borderColor: "white",
        },
      },
      "& .MuiInputLabel-root": {
        color: "white",
      },
      color: "white",
    },
    ImageContainer: {
      position: "fixed",
      height: "100%",
      minWidth: "100%",
    },
    overlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  })
);

interface Props {
  setSignedIn: (x: boolean) => void;
  activeBackground: string;
}

export default function Authentication(props: Props): ReactElement {
  const classes = useStyles();
  const [formType, setFormType] = useState<string>("signIn");
  const [user, setUser] = useState();

  return (
    <div className={classes.container}>
      <Box
        className={classes.ImageContainer}
        component="img"
        src={props.activeBackground}
        sx={{ objectFit: "cover" }}
      />
      <div className={classes.overlay}></div>
      {formType === "signIn" ? (
        <SignIn
          setSignedIn={props.setSignedIn}
          setFormType={setFormType}
          setUser={setUser}
        />
      ) : formType === "resetPasswordToken" ? (
        <ResetPasswordToken setFormType={setFormType} />
      ) : formType === "resetPassword" ? (
        <ResetPassword setFormType={setFormType} />
      ) : formType === "activateAccount" ? (
        <ActiveAccount
          setFormType={setFormType}
          user={user}
          setSignedIn={props.setSignedIn}
        />
      ) : (
        <SignIn
          setSignedIn={props.setSignedIn}
          setFormType={setFormType}
          setUser={setUser}
        />
      )}
    </div>
  );
}
