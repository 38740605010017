import { ReactElement } from "react";
import { Project } from "../../../../../Types/Project";
import Grid from "@mui/material/Grid";
import Form from "./Form";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import ProjectSummary from "../../../SharedComponents/ProjectSummary";
import { Paper, Theme, Box } from "@mui/material";
import { ProjectFileSection } from "../../../../UI/FilesComponent/ProjectFilesSection";
interface Props {
  projectData: Project;
  handleSave: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(4),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
      },
    },
  })
);

export default function DataView(props: Props): ReactElement {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid
        component={Paper}
        variant="outlined"
        className={classes.grid}
        item
        xs={8}
      >
        <Form projectData={props.projectData} handleSave={props.handleSave} />
      </Grid>
      <Grid
        item
        container
        xs={4}
        style={{ paddingTop: "0" }}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        alignContent="flex-start"
        gap={2}
      >
        <Grid item xs={12}>
          <Box>
            <ProjectSummary
              handleSave={props.handleSave}
              projectDetails={props.projectData}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ProjectFileSection
            handleSave={props.handleSave}
            project={props.projectData}
            projectFileTypes={[
              "Other",
              "Problem Deep Dive",
              "Curated List Presentation",
              "Curated List",
              "Business Case - Discover",
            ]}
            opportunityFileTypes={[
              "Partnership Confirmation",
              "Startup Rejection",
            ]}
            stage="discover"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
