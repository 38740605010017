import { ReactElement } from "react";
import { Typography } from "@mui/material";
import { Startup } from "../../../../Types/Startup";
import { makeStyles } from "@mui/styles";

interface Props {
  startup: Startup;
}

const useStyles = makeStyles(() => ({
  paperContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

export default function StartupOverview(props: Props): ReactElement {
  const classes = useStyles();

  return (
    <span className={classes.paperContainer}>
      <Typography paragraph variant="subtitle1">
        {props.startup?.shortDescription}
      </Typography>

      <Typography paragraph variant="body2">
        {props.startup?.description}
      </Typography>
    </span>
  );
}
