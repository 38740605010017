import { red } from "@mui/material/colors";
import { PaletteColorOptions, createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    customTypography: {
      fontSize8: string;
      fontSize10: string;
      fontSize12: string;
    };

    customBorderRadius: {
      xs: string;
      sm: string;
      md: string;
    };
  }
  interface ThemeOptions {
    customTypography: {
      fontSize8: string;
      fontSize10: string;
      fontSize12: string;
    };

    customBorderRadius: {
      xs: string;
      sm: string;
      md: string;
    };
  }
  interface Palette {
    other: PaletteColorOptions & {
      secondaryAction: string;
      surfaceSoft: string;
      surfaceBright: string;
      lightGrey: string;
      darkGrey: string;
      backgroundGrey: string;
    };
  }
  interface PaletteOptions {
    other: PaletteColorOptions & {
      secondaryAction: string;
      surfaceSoft: string;
      surfaceBright: string;
      lightGrey: string;
      darkGrey: string;
      backgroundGrey: string;
    };
  }
}

// A custom theme for this app
const defaultTheme = {
  palette: {
    primary: {
      main: "#1E1ED7",
    },
    secondary: {
      main: "#DEF054",
      light: "#D7E2FF",
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: "#E09740",
    },
    background: {
      default: "#f5f5f5",
    },
    text: {
      primary: "#000",
    },
    divider: "#D8D8D8",
    other: {
      secondaryAction: "#2C5DA7",
      surfaceSoft: "#D7E2FF",
      surfaceBright: "FAF9FD",
      lightGrey: "#C4C6D0",
      darkGrey: "#1A2B48",
      backgroundGrey: "#F4F3F7",
    },
  },
  customBorderRadius: {
    xs: "5px",
    sm: "8px",
    md: "12px",
  },
  customTypography: {
    fontSize8: "8px",
    fontSize10: "10px",
    fontSize12: "12px",
  },
  components: {
    MuiCardHeader: {
      styleOverrides: {
        subheader: {
          fontSize: "0.875rem",
        },
      },
    },
  },
};

const siemensEnergyTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#4d217a",
    },
    secondary: {
      main: "#009999",
    },
  },
};

const holcimTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#17325D",
    },
    secondary: {
      main: "#17ACED",
    },
  },
};

const cariadTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#1D0638",
    },
    secondary: {
      main: "#442EE0",
    },
  },
};

const ottoTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#e9001a",
    },
    secondary: {
      main: "#fffb9f",
    },
  },
};

const bmwstartupgarageTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#1c69d3",
    },
  },
};

const obiTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#f04f09",
    },
    secondary: {
      main: "#FFA976",
    },
  },
};

const airbusTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#373a36",
    },
    secondary: {
      main: "#4ec3ed",
    },
  },
};

const boschTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#a80062",
    },
    secondary: {
      main: "#967cb0",
    },
  },
};

const siemensMobilityTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#1f2232",
    },
    secondary: {
      main: "#4effd2",
    },
  },
};

const ksbTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#336699",
    },
    secondary: {
      main: "#b3c9de",
    },
  },
};

const contiTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#FD9407",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#434750",
      contrastText: "#FD9407",
    },
  },
};

const mtuTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#083963",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#1F93E2",
      contrastText: "#FFFFFF",
    },
  },
};

const knaufTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#1F9FE3",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#E6E2DC",
      contrastText: "#1F9FE3",
    },
  },
};

const vodafoneTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#E60002",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#353535",
      contrastText: "#FFFFFF",
    },
  },
};

const obrTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#913291",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#967cb0",
      contrastText: "#FFFFFF",
    },
  },
};

const siemensDiTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#000028",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#00CCCC",
      contrastText: "#FFFFFF",
    },
  },
};

const prosiebensat1Theme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#FF0230",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#501325",
      contrastText: "#FFFFFF",
    },
  },
};

const forviaTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#7b43f2",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#33f6b2",
      contrastText: "#FFFFFF",
    },
  },
};

const equinorTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#00727A",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#A8CFD1",
      contrastText: "#FFFFFF",
    },
  },
};

const axaTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#000089",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#5375B5",
      contrastText: "#FFFFFF",
    },
  },
};

const seatCodeTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#2E2F33",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#EF9B72",
      contrastText: "#FFFFFF",
    },
  },
};

const zurichinsuranceTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#005CA1",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#1C2F62",
      contrastText: "#FFFFFF",
    },
  },
};

const govTechhTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#EC0B19",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#427BBB",
      contrastText: "#FFFFFF",
    },
  },
};

const lbbwTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#123250",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#37c391",
      contrastText: "#FFFFFF",
    },
  },
};

const beyondGravityTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#020202",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#4B7FEF",
      contrastText: "#FFFFFF",
    },
  },
};

const redbullTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#db0a40",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#00162b",
      contrastText: "#FFFFFF",
    },
  },
};

const kauflandTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#ce2f27",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#f0f0f0",
      contrastText: "#000000",
    },
  },
};

const vinciTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#004489",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ff005a",
      contrastText: "#FFFFFF",
    },
  },
};

const helmTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#cf261c",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#5f7997",
      contrastText: "#FFFFFF",
    },
  },
};

const siemensSiTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#000028",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#00CCCC",
      contrastText: "#FFFFFF",
    },
  },
};

const mieleTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#8C0014",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#191919",
      contrastText: "#FFFFFF",
    },
  },
};

const sportRadarTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#020381",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FE2D2D",
      contrastText: "#FFFFFF",
    },
  },
};

const getActiveTheme = () => {
  const baseUrl = window.location.origin;

  if (baseUrl.includes("siemensenergy-vclms")) {
    return siemensEnergyTheme;
  } else if (baseUrl.includes("holcim-vclms")) {
    return holcimTheme;
  } else if (baseUrl.includes("cariad-vclms")) {
    return cariadTheme;
  } else if (baseUrl.includes("otto-vclms")) {
    return ottoTheme;
  } else if (baseUrl.includes("bmwstartupgarage-vclms")) {
    return bmwstartupgarageTheme;
  } else if (baseUrl.includes("obi-vclms")) {
    return obiTheme;
  } else if (baseUrl.includes("airbus-vclms")) {
    return airbusTheme;
  } else if (baseUrl.includes("openbosch-vclms")) {
    return boschTheme;
  } else if (baseUrl.includes("stationx-vclms")) {
    return siemensMobilityTheme;
  } else if (baseUrl.includes("ksb-vclms")) {
    return ksbTheme;
  } else if (baseUrl.includes("copace-vclms")) {
    return contiTheme;
  } else if (baseUrl.includes("mtu-vclms")) {
    return mtuTheme;
  } else if (baseUrl.includes("knauf-vclms")) {
    return knaufTheme;
  } else if (baseUrl.includes("vodafone-vclms")) {
    return vodafoneTheme;
  } else if (baseUrl.includes("obr-vclms")) {
    return obrTheme;
  } else if (baseUrl.includes("siemens-di-vclms")) {
    return siemensDiTheme;
  } else if (baseUrl.includes("prosiebensat1-vclms")) {
    return prosiebensat1Theme;
  } else if (baseUrl.includes("forvia-vclms")) {
    return forviaTheme;
  } else if (baseUrl.includes("equinor-vclms")) {
    return equinorTheme;
  } else if (baseUrl.includes("axa-vclms")) {
    return axaTheme;
  } else if (baseUrl.includes("seatcode-vclms")) {
    return seatCodeTheme;
  } else if (baseUrl.includes("zurichinsurance-vclms")) {
    return zurichinsuranceTheme;
  } else if (baseUrl.includes("govtechh-vclms")) {
    return govTechhTheme;
  } else if (baseUrl.includes("lbbw-vclms")) {
    return lbbwTheme;
  } else if (baseUrl.includes("beyondgravity-vclms")) {
    return beyondGravityTheme;
  } else if (baseUrl.includes("redbull-vclms")) {
    return redbullTheme;
  } else if (baseUrl.includes("kaufland-vclms")) {
    return kauflandTheme;
  } else if (baseUrl.includes("vinci-vclms")) {
    return vinciTheme;
  } else if (baseUrl.includes("helm-vclms")) {
    return helmTheme;
  } else if (baseUrl.includes("siemenssi-vclms")) {
    return siemensSiTheme;
  } else if (baseUrl.includes("miele-vclms")) {
    return mieleTheme;
  } else if (baseUrl.includes("sportradar-vclms")) {
    return sportRadarTheme;
  } else {
    // render 27pilots theme for default cases
    return defaultTheme;
  }
};

export default createTheme(getActiveTheme());
