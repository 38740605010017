import { AppBar, Tab, Tabs, styled } from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import { Project } from "../../../../Types/Project";
import { TabPanel, a11yProps } from "../../../UI/TabPanel";
import DataView from "./DataView";

interface AdoptStageProps {
  projectData: Project;
  handleSave: () => void;
  handleChangeTab: (fn: () => void) => void;
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: theme.palette.background.default,
  marginTop: theme.spacing(2),
}));

export default function AdoptStage(props: AdoptStageProps): ReactElement {
  const [value, setValue] = useState(0);
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    props.handleChangeTab(() => setValue(newValue));
  };

  return (
    <Fragment>
      <StyledAppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="DATA VIEW" {...a11yProps(0)} />
        </Tabs>
      </StyledAppBar>
      <TabPanel value={value} index={0}>
        {props.projectData && (
          <DataView
            handleSave={props.handleSave}
            projectData={props.projectData}
          />
        )}
      </TabPanel>
    </Fragment>
  );
}
