import { DeleteOutline, DragIndicator } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography, styled } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import theme from "../../theme";

interface EditableStyledItemProps {
  id: number;
  index: number;
  value: string;
  placeHolder?: string;
  item: "requirement" | "use-case";
  maxLength?: number;
  currentEditId: number | null;
  onChange: (newValue: string) => void;
  onBlur: (newValue: string) => void;
  onChangeEditId: (value: number | null) => void;
  onDelete: (value: number | string) => void;
  allowDrag: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

const StyledBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  "& fieldset": { border: "none" },
  border: 0,
  borderBottom: 1,
  borderColor: theme.palette.grey[300],
  borderStyle: "solid",
}));

const StyledTextField = styled(TextField)(() => ({
  flexGrow: 1,
  border: "none",
  transition: "max-width 0.3s ease, padding 0.3s ease",
  textarea: {
    ":focus": {
      color: "#000000A3",
    },
  },
}));

interface StyledDeleteSectionProps {
  isediting?: string;
}

const StyledDeleteSection = styled(Box)<StyledDeleteSectionProps>(
  ({ theme, isediting }) => ({
    alignItems: "center",
    transition: "opacity 0.3s ease",
    opacity: isediting === "true" ? 100 : 0,
    display: isediting === "true" ? "flex" : "none",
    color: theme.palette.grey[500],
  })
);

export default function EditableStyledItem(
  props: EditableStyledItemProps
): ReactElement {
  const [currentValue, setCurrentValue] = useState<string>(props.value || "");
  const [blur, setBlur] = useState(false);
  const buttonRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  const handleEnterKeyDown = () => {
    props.onChangeEditId(null);
    setBlur(true);
  };

  const handleEscapeKeyDown = () => {
    setCurrentValue(props.value);
    props.onChangeEditId(null);
    setBlur(true);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget === buttonRef.current) return e.stopPropagation();
    props.onBlur(currentValue);
  };

  useEffect(() => {
    if (blur) {
      inputRef.current?.blur();
      setBlur(false);
    }
  }, [blur]);

  const isEditing = props.id === props.currentEditId;

  return (
    <StyledBox>
      {props.allowDrag && (
        <IconButton
          className="drag-handle-button"
          {...props.dragHandleProps}
          disabled={!!props.currentEditId}
          color="inherit"
          sx={{
            transition: "all 0.3s ease-in-out",
            width: !!props.currentEditId ? 0 : 40,
            opacity: !!props.currentEditId ? 0 : 100,
          }}
        >
          <DragIndicator
            fontSize="small"
            sx={{
              transition: "all 0.3s ease",
              width: !!props.currentEditId ? 0 : 24,
            }}
          />
        </IconButton>
      )}
      <StyledTextField
        id={`use-case-${props.id}`}
        maxRows={3}
        inputRef={inputRef}
        value={currentValue}
        sx={{ input: { cursor: isEditing ? "text" : "pointer" } }}
        onChange={(e) => {
          setCurrentValue(e.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleEnterKeyDown();
          }
          if (event.key === "Escape") {
            handleEscapeKeyDown();
          }
        }}
        onBlur={handleOnBlur}
        onFocus={() => {
          props.onChangeEditId(props.id);
        }}
        data-testid={`${props.item}-${props.id}`}
        placeholder={props.placeHolder}
        inputProps={props.maxLength ? { maxLength: props.maxLength } : {}}
        autoFocus={props.currentEditId === -1}
        multiline
      />
      <StyledDeleteSection className="delete-button" isediting={`${isEditing}`}>
        {props.maxLength && (
          <Typography>{`${currentValue?.length}/${props.maxLength}`}</Typography>
        )}
        <IconButton
          onClick={() => {
            props.onDelete(props.id);
          }}
          data-testid={`delete-${props.item}-${props.id}`}
          ref={buttonRef}
        >
          <DeleteOutline />
        </IconButton>
      </StyledDeleteSection>
    </StyledBox>
  );
}
