import { ReactElement, Reducer, useContext, useReducer, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Chip, styled } from "@mui/material";
import { Project } from "../../Types/Project";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import ProjectDetailsModal from "../UI/Modals/ProjectDetailsModal/ProjectDetailsModal";
import { ProjectHttpService } from "../../Http/Project/Project.http.service";
import { useSnackbar } from "notistack";
import Bookmark from "../UI/InputFields/Bookmark";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import theme from "../../theme";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

interface Props {
  project: Project;
  refreshProjectData: () => void;
}

interface StyledProjectCardProps {
  $isArchivedOrOnhold?: boolean;
}

const StyledProjectCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "$isArchivedOrOnhold",
})<StyledProjectCardProps>(({ theme, $isArchivedOrOnhold }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  position: "relative",
  height: "175px",

  ...($isArchivedOrOnhold && {
    backgroundColor: theme.palette.grey[300],
  }),
}));

const StyledSection1 = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(0.5),
  padding: 0,
}));

const StyledFocusAreaChip = styled(Chip)(({ theme }) => ({
  fontSize: ".75rem",
  height: "1.5em",
  paddingInline: "1em",
  pointerEvents: "none",
  marginRight: theme.spacing(0.5),
}));

const StyledAdoptedChip = styled(Chip)(({ theme }) => ({
  fontSize: ".75rem",
  height: "1.5em",
  pointerEvents: "none",
  marginRight: theme.spacing(0.5),
  color: theme.palette.success.light,
  borderColor: theme.palette.success.light,
}));

const StyledProjectName = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2 /* number of lines to show */,
  WebkitBoxOrient: "vertical",
});

const StyledBusinessUnitName = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
});

const StyledFocusAreaWrapper = styled(Box)({
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  width: "100%",
});

const StyledSection2 = styled(CardContent)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 12,
  padding: 0,
  "&:last-child": {
    paddingBottom: theme.spacing(0.5),
  },
}));

const StyledSection2Info = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));

const StyledProjectCardDetails = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  fontSize: "0.625rem",
}));

const StyledBookmardIcon = styled(Bookmark)(() => ({
  marginInline: `10px 0px`,
  "& > span:nth-of-type(1)": {
    padding: "6px",
  },
  "& svg": {
    fontSize: "20px",
    fill: "black",
  },
}));

function ProjectKanbanCard(props: Props): ReactElement {
  const [openDetails, setOpenDetails] = useState(false);
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.project
  );
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);

  const isArchivedOrOnhold =
    props.project.status === "archived" || props.project.status === "on hold";

  const numberOfDemos = props.project.opportunities?.reduce(
    (previousOpportunity, currentOpportunity) => {
      if (currentOpportunity.productDemos) {
        return (previousOpportunity += currentOpportunity.productDemos.length);
      }
      return previousOpportunity;
    },
    0
  );

  const numberOfOpportunities =
    props.project.opportunities?.filter(
      (opportunity) =>
        opportunity.isQualified === true ||
        props.project.funnelStage === "discover"
    ).length || 0;

  const updateProject = async () => {
    try {
      setGlobalLoader(true);
      await ProjectHttpService.updateProject({
        ...project,
        bookmarked: !project.bookmarked,
      }).then(() => {
        props.refreshProjectData();
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong with updating project", {
        variant: "error",
      });
      setProject({ bookmarked: project.bookmarked });
    } finally {
      setGlobalLoader(false);
    }
  };

  const updateProjectBookmark = () => {
    setProject({ bookmarked: !project.bookmarked });
    updateProject();
  };

  return (
    <>
      <StyledProjectCard
        $isArchivedOrOnhold={isArchivedOrOnhold}
        data-testid={"project-card-" + props.project.id}
        onClick={() => setOpenDetails(true)}
      >
        <StyledSection1>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
          >
            <StyledProjectName variant="subtitle1">
              {props.project.name}
            </StyledProjectName>
            <StyledBookmardIcon
              checked={project.bookmarked}
              handleBookmark={(event) => {
                event.stopPropagation();
                updateProjectBookmark();
              }}
            />
          </Box>

          <StyledBusinessUnitName color="text.secondary" variant="caption">
            {props.project.businessUnit.name}
          </StyledBusinessUnitName>
          <StyledFocusAreaWrapper>
            {props.project.focusAreas &&
              props.project.focusAreas.map((focusArea) => (
                <StyledFocusAreaChip
                  key={focusArea.id}
                  label={focusArea.name}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              ))}
          </StyledFocusAreaWrapper>
          {props.project.status === "adopted" && (
            <StyledAdoptedChip
              data-testid="adopted-chip"
              label="Adopted"
              variant="outlined"
              size="small"
            />
          )}
        </StyledSection1>
        <StyledSection2>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <StyledSection2Info>
              <DateRangeIcon fontSize="small" />
              <StyledProjectCardDetails
                data-testid={"projectAge-" + props.project.id}
              >
                Day {props.project.funnelStageAge || "N.A."}
              </StyledProjectCardDetails>
            </StyledSection2Info>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Box display="flex" alignItems="center">
              <RocketLaunchIcon fontSize="small" />
              <StyledProjectCardDetails marginLeft={theme.spacing(1)}>
                {numberOfOpportunities}{" "}
                {numberOfOpportunities === 1 ? "Startup" : "Startups"}
              </StyledProjectCardDetails>
            </Box>
            {props.project.funnelStage === "assess" && (
              <StyledSection2Info>
                <CoPresentIcon fontSize="small" />
                <StyledProjectCardDetails
                  data-testid={"nrOfDemos-" + props.project.id}
                >
                  {numberOfDemos}
                  {numberOfDemos === 1 ? " Demo" : " Demos"}
                </StyledProjectCardDetails>
              </StyledSection2Info>
            )}
          </Box>
        </StyledSection2>
      </StyledProjectCard>
      {openDetails && (
        <ProjectDetailsModal
          setModalOpen={setOpenDetails}
          modalOpen={openDetails}
          projectId={project.id}
        />
      )}
    </>
  );
}

export default ProjectKanbanCard;
