import { ReactElement, Reducer, useContext, useReducer, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Project } from "../../Types/Project";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { Opportunity } from "../../Types/Opportunity";
import ProjectDetailsModal from "../UI/Modals/ProjectDetailsModal/ProjectDetailsModal";
import { ProjectHttpService } from "../../Http/Project/Project.http.service";
import { useSnackbar } from "notistack";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    startup_card: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 85,
      padding: theme.spacing(1),
    },
    startup_card__archivedOrOnhold: {
      backgroundColor: theme.palette.grey[300],
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(0.5),
      padding: 0,
    },
    startupName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1 /* number of lines to show */,
      WebkitBoxOrient: "vertical",
    },
    ventureClient: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      color: theme.palette.text.secondary,
    },
  })
);
interface Props {
  project: Project;
  opportunity: Opportunity;
  refreshProjectData: () => void;
}
const StartupKanbanCard = (props: Props): ReactElement => {
  const classes = useStyles();
  const [openDetails, setOpenDetails] = useState(false);
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.project
  );
  const isArchivedOrOnhold =
    props.project.status === "archived" || props.project.status === "on hold";
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);

  const getProjects = () => {
    props.refreshProjectData();
  };

  const updateProject = async () => {
    try {
      setGlobalLoader(true);
      await ProjectHttpService.updateProject({
        ...project,
        bookmarked: !project.bookmarked,
      }).then(() => {
        getProjects();
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong with updating project", {
        variant: "error",
      });
      setProject({ bookmarked: project.bookmarked });
    } finally {
      setGlobalLoader(false);
    }
  };

  const updateProjectBookmark = () => {
    setProject({ bookmarked: !project.bookmarked });
    updateProject();
  };

  const handleProjectDetailsModalOpen = async (
    isOpen: boolean,
    bookmarkFromModal: boolean
  ) => {
    setOpenDetails(isOpen);
    if (bookmarkFromModal != project.bookmarked) {
      getProjects();
      updateProjectBookmark();
    }
  };
  return (
    <>
      <Card
        onClick={() => setOpenDetails(!openDetails)}
        className={clsx(classes.startup_card, {
          [classes.startup_card__archivedOrOnhold]: isArchivedOrOnhold,
        })}
        data-testid={"startup-card-" + props.opportunity.id}
      >
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.startupName}
            variant="subtitle1"
            component="div"
          >
            {props.opportunity.startup.name}
          </Typography>
          <Typography className={classes.ventureClient} variant="caption">
            {props.project.businessUnit.ventureClient.name +
              " - " +
              props.project.businessUnit.name}
          </Typography>
        </CardContent>
        <RocketLaunchIcon fontSize="small" />
      </Card>
      {openDetails && (
        <ProjectDetailsModal
          setModalOpen={handleProjectDetailsModalOpen}
          modalOpen={openDetails}
          projectId={project.id}
        />
      )}
    </>
  );
};
export default StartupKanbanCard;
