import { ReactElement, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: "flex",
      width: "500px",
      flexDirection: "column",
      justifyContent: "center",
      padding: "20px",
      zIndex: 1,
    },
    cancelButton: {
      width: "200px",
      margin: "auto",
      color: "white",
      outline: "1px solid white",
      border: "none",
      transition: "all 0.1s ease-in-out",
      "&:hover": {
        border: "none",
        outline: "1.5px solid white",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      },
    },
  })
);

interface Props {
  setFormType: (x: string) => void;
}

export default function ResetPasswordToken(props: Props): ReactElement {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>("");

  const handleEmailVerification = async () => {
    try {
      await Auth.forgotPassword(email);
      props.setFormType("resetPassword");
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <Box className={classes.form}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="h4" align="center" sx={{ fontWeight: 500 }}>
          Forgot your password?
        </Typography>
        <Typography variant="caption" align="center">
          Enter your registered email and well send you a code to reset your
          password
        </Typography>
        <TextField
          id="verification-email"
          label="Email"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value as string)}
        />
        <Box gap={1} justifyContent="center" display="flex">
          <Button
            type="submit"
            onClick={() => props.setFormType("signIn")}
            variant="outlined"
            id="redirect-signIn-button"
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleEmailVerification}
            variant="contained"
            color="secondary"
            id="verify-email-button"
            sx={{
              width: "200px",
              margin: "auto",
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
