import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../theme";
import CustomTextField from "../../UI/InputFields/CustomTextField";
import { useSnackbar } from "notistack";
import { FocusArea } from "../../../Types/VentureClient";
import FocusAreaHttpService from "../../../Http/FocusArea/FocusArea.http.service";

interface CreateFocusAreaModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  handleAddFocusArea: (focusArea: FocusArea) => void;
}

export default function CreateFocusAreaModal(
  props: CreateFocusAreaModalProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [newFocusArea, setNewFocusArea] = useState<FocusArea>({
    name: "",
  } as FocusArea);

  const handleCreateFocusArea = async () => {
    if (newFocusArea.name) {
      try {
        const focusAreaCreated = await FocusAreaHttpService.createFocusArea(
          newFocusArea
        );

        // displaying the newly created focusArea on the field once its created
        if (focusAreaCreated) {
          props.handleAddFocusArea(focusAreaCreated);
        }
        props.setModalOpen(false);
        // eslint-disable-next-line
      } catch (error: any) {
        enqueueSnackbar(
          error.response?.data.message || "Something went wrong",
          {
            variant: "error",
          }
        );
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      id="focusArea-create-modal"
    >
      <DialogTitle>Create Focus Area</DialogTitle>
      <DialogContent>
        <Box mt={theme.spacing(1)}>
          <CustomTextField
            id="focusArea"
            label="FocusArea"
            fullWidth
            editMode={true}
            value={newFocusArea.name}
            onChange={(event) => {
              setNewFocusArea({
                ...newFocusArea,
                name: event.target.value,
              });
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => props.setModalOpen(false)}
          color="primary"
          id="cancel-focusArea-create-view"
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleCreateFocusArea()}
          variant="contained"
          color="secondary"
          id="save-focusArea-create-view"
        >
          Create Focus Area
        </Button>
      </DialogActions>
    </Dialog>
  );
}
