import { ReactElement, useState, useContext } from "react";
import { GlobalLoaderContext } from "../../../../../../Context/LoaderContext";
import { Opportunity } from "../../../../../../Types/Opportunity";
import { Box, styled } from "@mui/material";
import theme from "../../../../../../theme";
import CancelIcon from "@mui/icons-material/Cancel";
import MoreHorizOutlined from "@mui/icons-material/MoreHorizOutlined";
import HelpIcon from "@mui/icons-material/Help";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CurrentRequirementStatus = styled(Box)(() => ({
  display: "grid",
  justifyContent: "center",
  cursor: "pointer",
}));

const SelectedIndicator = styled("span")(() => ({
  height: theme.spacing(0.75),
  width: theme.spacing(0.75),
  backgroundColor: theme.palette.grey[300],
  borderRadius: "100px",
  marginTop: theme.spacing(0.5),
  position: "absolute",
  justifySelf: "center",
  bottom: theme.spacing(-1),
}));

const RequirementStatusContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
}));

const RequirementStatus = styled(Box)(() => ({
  width: "100%",
  display: "grid",
  justifyContent: "center",
  cursor: "pointer",
  position: "relative",
}));

const SemiFilledCheckIcon = () => (
  <>
    <svg
      className="semi-filled-icon"
      data-testid="requirement-icon-partially-fulfilled"
    >
      <linearGradient id="linearColors" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="50%" stopColor={theme.palette.other.darkGrey} />
        <stop offset="50%" stopColor={theme.palette.other.lightGrey} />
      </linearGradient>
    </svg>
    <CheckCircleIcon sx={{ fill: "url(#linearColors)" }} />
  </>
);

const getIcon = (status: string | null): ReactElement => {
  switch (status) {
    case "Undisclosed": {
      return (
        <HelpIcon
          sx={{ color: theme.palette.other.lightGrey }}
          data-testid="requirement-icon-undisclosed"
        />
      );
    }
    case "Fulfilled": {
      return (
        <CheckCircleIcon
          sx={{ color: theme.palette.other.darkGrey }}
          data-testid="requirement-icon-fulfilled"
        />
      );
    }
    case "Unfulfilled": {
      return (
        <CancelIcon
          sx={{ color: theme.palette.other.lightGrey }}
          data-testid="requirement-icon-unfulfilled"
        />
      );
    }
    case "Partially Fulfilled": {
      return <SemiFilledCheckIcon />;
    }
    default: {
      return (
        <MoreHorizOutlined
          sx={{ color: "#A5A5EF" }}
          data-testid="requirement-icon-missing-status"
        />
      );
    }
  }
};

interface RequirementsFullfilmentProps {
  opportunity: Opportunity;
  status: string | null;
  requirementId: number;
  requirementChange: (
    updatedRequirementId: number,
    updatedStatus: string
  ) => void;
  editingFullfilmentStatus: number | null;
  setEditingFullfilmentStatus: (id: number | null) => void;
  setEditingOpportunity: (id: string | null) => void;
  handleChangeStatusCell: () => void;
  editingOpportunity: string | null;
}

export function RequirementsFullfilment(
  props: RequirementsFullfilmentProps
): ReactElement {
  const [editingRequirements, setEditingRequirements] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(props.status);
  const { globalLoader } = useContext(GlobalLoaderContext);

  const updateRequirementStatus = (
    updatedStatus: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    props.requirementChange(props.requirementId, updatedStatus);
    setCurrentStatus(updatedStatus);
    setEditingRequirements(false);
    props.setEditingFullfilmentStatus(null);
    props.setEditingOpportunity(null);
  };

  return (
    <>
      {editingRequirements &&
      props.editingFullfilmentStatus === props.requirementId &&
      props.editingOpportunity ===
        `requirement-status-${props.opportunity.id}` ? (
        <RequirementStatusContainer>
          <RequirementStatus
            onClick={(event) => {
              updateRequirementStatus("Fulfilled", event);
            }}
          >
            {getIcon("Fulfilled")}
            {props.status === "Fulfilled" && (
              <SelectedIndicator data-testid="select-indicator-fulfilled" />
            )}
          </RequirementStatus>
          <RequirementStatus
            onClick={(event) => {
              updateRequirementStatus("Partially Fulfilled", event);
            }}
          >
            {getIcon("Partially Fulfilled")}
            {props.status === "Partially Fulfilled" && (
              <SelectedIndicator data-testid="select-indicator" />
            )}
          </RequirementStatus>
          <RequirementStatus
            onClick={(event) => {
              updateRequirementStatus("Unfulfilled", event);
            }}
          >
            {getIcon("Unfulfilled")}
            {props.status === "Unfulfilled" && (
              <SelectedIndicator data-testid="select-indicator-unfulfilled" />
            )}
          </RequirementStatus>
          {/* Undisclosed */}
          <RequirementStatus
            onClick={(event) => {
              updateRequirementStatus("Undisclosed", event);
            }}
          >
            {getIcon("Undisclosed")}
            {props.status === "Undisclosed" && (
              <SelectedIndicator data-testid="select-indicator" />
            )}
          </RequirementStatus>
        </RequirementStatusContainer>
      ) : (
        <CurrentRequirementStatus
          onClick={(event) => {
            if (!globalLoader) {
              props.handleChangeStatusCell();
              event.stopPropagation();
              setEditingRequirements(true);
            }
          }}
        >
          {getIcon(currentStatus)}
        </CurrentRequirementStatus>
      )}
    </>
  );
}
