import { Grid, Paper, Typography, styled } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { StartupHttpService } from "../../../Http/Startup/Startup.http.service";
import { Startup } from "../../../Types/Startup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StartupOverview from "./Overview/StartupOverview";
import StartupDetails from "./Details/StartupDetails";
import { StartupFileSection } from "../../UI/FilesComponent/StartupFileSection";
import { useHistory } from "react-router-dom";
import ProjectSection from "./ProjectSection/ProjectSection";
import { InfoOutlined } from "@mui/icons-material";

const requiredFields = [
  "name",
  "website",
  "legalEntity",
  "shortDescription",
  "currentInvestmentStage",
  "totalFunding",
  "employees",
];

const LimitedInformationBanner = styled(Paper)(({ theme }) => ({
  backgroundColor: "#f8f8ff",
  borderColor: theme.palette.primary.main,
  display: "flex",
  gap: theme.spacing(1.25),
}));

export default function StartupDetailsPage(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [startup, setStartup] = useState<Startup>();
  const [startupUSPData, setStartupUSPData] = useState<
    { label: string; value: string | number | undefined }[]
  >([]);

  const hasLimitedInformation = !requiredFields.every(
    (field) => startup?.[field as keyof Startup] !== null
  );

  useEffect(() => {
    StartupHttpService.getStartupById(id)
      .then((data) => {
        setStartup(data);
        setStartupUSPData(
          data.usps.map((usp, index) => ({
            label: `USP ${index + 1}`,
            value: usp.description,
          }))
        );
      })
      .catch(() => {
        history.push("/startups");
      });
  }, [id]);

  return (
    <Grid container spacing={4}>
      <Grid item container ml={0} mt={0} xs={12} alignItems="center">
        <NavLink
          to={"/startups"}
          style={{ display: "flex", alignItems: "center" }}
        >
          <ArrowBackIcon color={"primary"} />
        </NavLink>
        <Typography variant="h5">
          {startup ? startup.name : "Loading ..."} | {startup?.legalEntity}
        </Typography>
      </Grid>
      <Grid item container>
        {hasLimitedInformation ? (
          <Grid
            item
            component={LimitedInformationBanner}
            variant="outlined"
            xs={12}
            p={4}
          >
            <InfoOutlined color="primary" />
            This startup profile currently shows limited information. Details
            will be added by the 27pilots team soon.
          </Grid>
        ) : (
          <Grid item component={Paper} variant="outlined" xs={12} p={4}>
            {startup && <StartupOverview startup={startup} />}
          </Grid>
        )}
      </Grid>
      <Grid container item spacing={3} flexWrap="nowrap">
        <Grid item container xs={6}>
          <Grid xs={12} item component={Paper} variant="outlined" p={4}>
            {startup && (
              <StartupDetails
                startup={startup}
                startupUSPData={startupUSPData}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={3}
          minWidth="min-content"
          alignContent="flex-start"
        >
          <Grid item xs={12} component={Paper} variant="outlined">
            {startup?.opportunities && (
              <ProjectSection
                opportunities={startup.opportunities}
                startupName={startup.name}
              />
            )}
          </Grid>
        </Grid>
        <Grid item container xs={3} alignContent="flex-start">
          <Grid item xs={12} component={Paper} variant="outlined">
            {startup && (
              <StartupFileSection
                startup={startup}
                fileTypes={["Strategic Image", "Product Deck", "Logo", "Other"]}
                readOnly={true}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
