import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { Dispatch, ReactElement, SetStateAction, useEffect } from "react";
import { Opportunity } from "../../../../../../../Types/Opportunity";
import { useSnackbar } from "notistack";
import OpportunityRow from "./OpportunityRow";
import AddIcon from "@mui/icons-material/Add";

const StyledTableContainer = styled(TableContainer)(() => ({
  paddingTop: "16px",
  "& .MuiTableCell-root": {
    verticalAlign: "top",
    padding: "16px 16px 16px 0",
  },
  "& thead .MuiTableCell-root": {
    paddingTop: "0",
  },
}));

interface AssessmentConclusionProps {
  opportunities: Opportunity[];
  editMode: boolean;
  setOpportunities: Dispatch<SetStateAction<Opportunity[]>>;
  opportunitiesInConclusion: Opportunity[];
  setOpportunitiesInConclusion: Dispatch<SetStateAction<Opportunity[]>>;
}

const AssessmentConclusion = (
  props: AssessmentConclusionProps
): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const startupsAvailableForConclusion =
    props.opportunitiesInConclusion.filter(
      (opp) => opp.inAssessmentConclusion || opp.productDemos.length > 0
    ).length !== props.opportunities.filter((opp) => opp.isQualified).length;

  const handleOpportunityUpdate = (
    updatedOpportunity: Opportunity,
    index: number,
    updateType?: string
  ) => {
    const selectedStartup = props.opportunitiesInConclusion.find(
      (opportunity) => opportunity.isSelectedForPilot
    );

    if (
      selectedStartup &&
      selectedStartup.id !== updatedOpportunity.id &&
      updateType === "statusUpdate" &&
      updatedOpportunity.isSelectedForPilot
    ) {
      enqueueSnackbar("Only one startup can be selected for pilot", {
        variant: "error",
      });
    } else {
      const newOpportunities = [...props.opportunitiesInConclusion];
      newOpportunities[index] = updatedOpportunity;
      props.setOpportunitiesInConclusion(newOpportunities);
    }
  };

  const handleAdd = () => {
    const assessmentIds = props.opportunitiesInConclusion
      .filter((opp) => opp.inAssessmentConclusion)
      .map((opp) => opp.id);
    const withProductDemoIds = props.opportunitiesInConclusion
      .filter((opp) => opp.productDemos.length > 0)
      .map((opp) => opp.id);
    const opportunitiesToAdd = props.opportunities.filter(
      (opp) =>
        opp.isQualified &&
        !assessmentIds.includes(opp.id) &&
        !withProductDemoIds.includes(opp.id)
    );
    opportunitiesToAdd.every((toAdd) => {
      if (
        props.opportunitiesInConclusion
          .filter((opp) => opp.inAssessmentConclusion)
          .map((opp) => opp.id)
          .includes(toAdd.id)
      ) {
        const updatedOpportunities = [...props.opportunitiesInConclusion];
        const index = props.opportunitiesInConclusion.findIndex(
          (opp) => opp.id === toAdd.id
        );
        updatedOpportunities[index] = {
          ...toAdd,
          inAssessmentConclusion: true,
        };
        props.setOpportunitiesInConclusion(updatedOpportunities);
        return true;
      } else {
        const updatedOpportunities = [...props.opportunitiesInConclusion];
        const index = props.opportunitiesInConclusion.findIndex(
          (opp) => opp.id === toAdd.id
        );
        if (index >= 0) {
          updatedOpportunities[index] = {
            ...toAdd,
            inAssessmentConclusion: true,
          };
        } else {
          updatedOpportunities.push({
            ...toAdd,
            inAssessmentConclusion: true,
          });
        }
        props.setOpportunitiesInConclusion(updatedOpportunities);
        return false;
      }
    });
  };

  useEffect(() => {
    props.setOpportunitiesInConclusion((prev) =>
      prev.sort((a, b) => b.productDemos.length - a.productDemos.length)
    );
  }, [props.opportunitiesInConclusion]);

  return (
    <Box mt={6}>
      <Typography variant="h6">Assessment Conclusion</Typography>
      <StyledTableContainer data-testid="assessmentConclusion">
        <Table aria-label="decision rationale">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell width="15%">Startup</TableCell>
              <TableCell width="21%">Selected for Pilot</TableCell>
              <TableCell width={props.editMode ? "52%" : "62%"}>
                Decision Rationale
              </TableCell>
              {props.editMode && <TableCell width="10%" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.opportunitiesInConclusion
              .filter(
                (opportunity) =>
                  opportunity.inAssessmentConclusion ||
                  opportunity.productDemos.length > 0
              )
              .map((opportunity, index) => (
                <OpportunityRow
                  allOpportunities={props.opportunities}
                  opportunitiesInConclusion={props.opportunitiesInConclusion}
                  setOpportunitiesInConclusion={
                    props.setOpportunitiesInConclusion
                  }
                  key={index}
                  index={index}
                  opportunity={opportunity}
                  editMode={props.editMode}
                  handleOpportunityUpdate={handleOpportunityUpdate}
                  setOpportunities={props.setOpportunities}
                />
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {props.editMode && startupsAvailableForConclusion && (
        <Button onClick={handleAdd} data-testid="addStartupForPilot">
          <AddIcon sx={{ fontSize: 25 }} /> Startup
        </Button>
      )}
    </Box>
  );
};

export default AssessmentConclusion;
