import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button,
  styled,
  Badge,
  Link,
} from "@mui/material";
import { RocketLaunchOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link as RouterLink } from "react-router-dom";
import theme from "../../../../../../../theme";
import { Opportunity } from "../../../../../../../Types/Opportunity";
import { findLogo, normalizeUrl } from "../../../../../../../utils";
import { ReactElement } from "react";

const ActionButton = styled("div")(() => ({
  marginLeft: "auto",
  "& > button": {
    color: theme.palette.other.secondaryAction,
    textTransform: "none",
  },
}));

const TitleBadge = styled(Badge)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  "& span": {
    width: "25px",
  },
}));

const StartupWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
  display: "flex",
  flexDirection: "column",
}));

interface RecommendedStartupsOverviewModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  opportunities: Opportunity[];
  recommendationType: "rating" | "demo";
}

const RecommendedStartupsOverviewModal = (
  props: RecommendedStartupsOverviewModalProps
): ReactElement => {
  const modalTitle =
    props.recommendationType === "rating"
      ? "Recommended Startups"
      : "Recommended for Demo";
  const modalDescription =
    props.recommendationType === "rating"
      ? "It is recommended to assess A-rated startups based on the specific criteria and requirements of the use case in order to find the best fit."
      : "It is recommended to conduct a product demo with these promising startups.";

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      data-testid="recommended-startups-overview-modal"
      PaperProps={{
        style: { borderRadius: theme.customBorderRadius.md },
      }}
    >
      <DialogTitle display="flex" pt="2rem">
        <Box>
          {modalTitle}
          <TitleBadge
            badgeContent={props.opportunities.length}
            color="primary"
          />
        </Box>
        <ActionButton>
          <Button onClick={() => props.setModalOpen(false)}>Close</Button>
        </ActionButton>
      </DialogTitle>
      <Box px={3} display="flex" gap={1}>
        <InfoOutlinedIcon />
        <Typography variant="body2">{modalDescription}</Typography>
      </Box>

      <DialogContent dividers sx={{ p: 0, mt: 2 }}>
        {props.opportunities.map((opportunity) => {
          const logo = findLogo(opportunity.startup.files);
          const startup = opportunity.startup;
          const foundedYear = startup.dateFounded?.getFullYear();
          return (
            <StartupWrapper
              key={startup.id}
              data-testid={`startup-${startup.id}`}
            >
              <Box display="flex" justifyContent="space-between">
                <RouterLink target="_blank" to={"/startups/" + startup.id}>
                  {logo ? (
                    <img
                      src={logo}
                      alt="startup logo"
                      style={{
                        maxWidth: "80px",
                        maxHeight: "40px",
                      }}
                    />
                  ) : (
                    <RocketLaunchOutlined color="disabled" />
                  )}
                </RouterLink>
                <Link target="_blank" href={normalizeUrl(startup.website)}>
                  <LaunchIcon data-testid="startup-link-icon" />
                </Link>
              </Box>
              <RouterLink target="_blank" to={"/startups/" + startup.id}>
                <Typography mt={0.5} color="other.secondaryAction">
                  {startup.name}
                </Typography>
              </RouterLink>
              <Box display="flex" mt={1}>
                <Typography variant="caption">
                  {startup.billingCountry || "N/A"}
                </Typography>
                <Typography variant="caption" ml={2}>
                  {foundedYear || "N/A"}
                </Typography>
                <Typography ml="auto" variant="caption">
                  {startup.currentInvestmentStage || "N/A"}
                </Typography>
                <Typography variant="caption" ml={2}>
                  {startup.totalFunding ? `$${startup.totalFunding}M` : "N/A"}
                </Typography>
              </Box>
            </StartupWrapper>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export default RecommendedStartupsOverviewModal;
