import { ReactElement } from "react";
import {
  styled,
  Typography,
  Link,
  Box,
  Button,
  ButtonGroup,
  Chip,
} from "@mui/material";
import { Opportunity } from "../../../../../Types/Opportunity";
import { findLogo } from "../../../../../utils";

const SwitchViewButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  borderWidth: "1px",
  width: theme.spacing(20),
}));

const DetailsButton = styled(SwitchViewButton)(() => ({
  borderRadius: "12px 0 0 12px",
}));

const RequirementsButton = styled(SwitchViewButton)(() => ({
  borderRadius: "0 12px 12px 0",
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginBottom: theme.spacing(4),
}));

const NumberOfStartups = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.common.white,
  height: theme.spacing(3),
}));

const SelectedStartupsInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  columnGap: theme.spacing(1),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  display: "flex",
  columnGap: theme.spacing(1),
  alignItems: "center",
  textDecoration: "none",
  "& img": {
    height: theme.spacing(3.5),
    borderRight: "1px solid rgba(0, 0, 0, 0.38)",
    paddingRight: theme.spacing(1),
  },
}));

const getNumberOfQualifiedStartups = (opportunities: Opportunity[]): number => {
  return opportunities.filter((opportunity) => opportunity.isQualified).length;
};

interface SolutionFitMatrixHeaderProps {
  setSelectedDetailsView: (view: boolean) => void;
  selectedDetailsView: boolean;
  projectFunnelStage: string;
  opportunities: Opportunity[];
}

export default function SolutionFitMatrixHeader(
  props: SolutionFitMatrixHeaderProps
): ReactElement {
  const opportunitySelectedForPilot = props.opportunities.find(
    (opportunity) => opportunity.isSelectedForPilot
  );

  const selectedOpportunityLogo = opportunitySelectedForPilot?.startup?.files
    ? findLogo(opportunitySelectedForPilot.startup.files)
    : null;

  return (
    <StyledHeader data-testid="solution-fit-matrix-header">
      <ButtonGroup variant="outlined">
        <DetailsButton
          variant={props.selectedDetailsView ? "contained" : "outlined"}
          onClick={() => props.setSelectedDetailsView(true)}
        >
          Details
        </DetailsButton>
        <RequirementsButton
          variant={!props.selectedDetailsView ? "contained" : "outlined"}
          onClick={() => props.setSelectedDetailsView(false)}
        >
          Requirements
        </RequirementsButton>
      </ButtonGroup>
      <SelectedStartupsInfo>
        <Typography variant="body2" fontWeight="500">
          {props.projectFunnelStage === "discover"
            ? "Startups Selected for Assess Stage"
            : "Startup Selected for Pilot"}
        </Typography>
        {props.projectFunnelStage === "discover" && (
          <NumberOfStartups
            label={getNumberOfQualifiedStartups(props.opportunities)}
          />
        )}
        {props.projectFunnelStage === "assess" &&
        opportunitySelectedForPilot ? (
          <StyledLink
            target="_blank"
            href={"/startups/" + opportunitySelectedForPilot.startup.id}
          >
            {selectedOpportunityLogo && (
              <img
                src={selectedOpportunityLogo}
                alt="logo associated to the selected startup"
              />
            )}
            <Typography variant="body2" fontWeight="500" color="grey.400">
              {opportunitySelectedForPilot.startup.name}
            </Typography>
          </StyledLink>
        ) : (
          props.projectFunnelStage === "assess" && (
            <Typography variant="body2" fontWeight="500" color="grey.400">
              Not selected
            </Typography>
          )
        )}
      </SelectedStartupsInfo>
    </StyledHeader>
  );
}
