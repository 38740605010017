import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactElement, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

interface Props {
  entity:
    | "objective"
    | "kpi"
    | "cluster"
    | "meeting"
    | "variable"
    | "lead-project-leader";
  setEditModalOpen: (state: boolean) => void;
  setDeleteModalOpen: (state: boolean) => void;
}

const EditDeleteMenu = (props: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const editOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={{ ml: "auto", mr: "-1rem", color: "black" }}
        onClick={handleMenuOpen}
        data-testid={`${props.entity}-menu-button`}
      >
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={editOpen}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            ml: -12,
          },
        }}
      >
        <MenuItem
          sx={{ gap: 2 }}
          onClick={(event) => {
            event.stopPropagation();
            props.setEditModalOpen(true);
            setAnchorEl(null);
          }}
        >
          <EditOutlinedIcon />
          Edit
        </MenuItem>
        <MenuItem
          sx={{ gap: 2 }}
          onClick={(event) => {
            event.stopPropagation();
            props.setDeleteModalOpen(true);
            setAnchorEl(null);
          }}
        >
          <DeleteIcon />
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default EditDeleteMenu;
