import { Box, Grid, Typography, styled } from "@mui/material";
import { Dispatch, Fragment, ReactElement } from "react";
import { SelectOption } from "../../../../Types/Common";
import { Project } from "../../../../Types/Project";
import CustomTextField from "../../../UI/InputFields/CustomTextField";
import { SelectInput } from "../../../UI/InputFields/SelectInput";
import { thousandSeparator } from "../../../../utils";
import useFetchImpactValue from "./useFetchImpactValue";
import theme from "../../../../theme";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import CalculationContainer from "./CalculationContainer";
import { calculate, getDescAndTitle, hasMonetaryValue } from "./helper/helper";
import { CalculationType } from "../../../../Types/ImpactValue";

const impactTypeSelectValues: SelectOption[] = [
  { id: "Cost", name: "Cost" },
  { id: "Revenue", name: "Revenue" },
  { id: "Cost & Revenue", name: "Cost & Revenue" },
];

const Container = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  width: "100%",
  outline: `1px solid ${theme.palette.other.surfaceSoft}`,
  borderRadius: theme.customBorderRadius.md,
  padding: theme.spacing(2),
}));

const Description = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  flex: 1,
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1.5),
}));

const ImpactValueContent = styled(Box)(({ theme }) => ({
  whiteSpace: "pre-line",
  paddingInline: theme.spacing(4, 5),
  display: "flex",
  flexDirection: "column",
}));

const ResultWrapper = styled(Box)(() => ({
  flex: 0.75,
  outline: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.customBorderRadius.md,
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2, 2),
  height: "100%",
  gap: theme.spacing(2),
}));

const Result = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(1.5),
}));

const Value = styled(Typography)(() => ({
  fontWeight: "300",
  fontSize: "1.75rem",
  color: theme.palette.primary.main,
}));

interface Props {
  project: Project;
  onChange: Dispatch<Partial<Project>>;
  editMode: boolean;
}

export default function StrategicBenefitsSection(props: Props): ReactElement {
  const { impactValues, setImpactValues, fetchImpactValues } =
    useFetchImpactValue(props.project);
  const { description, title } = getDescAndTitle(props.project.impactType);
  const calculations = impactValues.length ? impactValues[0].calculations : [];

  const costCalculations = calculations.filter(
    (calculation) => calculation.type === "Cost"
  );
  const revenueCalculations = calculations.filter(
    (calculation) => calculation.type === "Revenue"
  );
  const combinedCalculations = [...costCalculations, ...revenueCalculations];

  const handleImpactValue = () => {
    fetchImpactValues();
  };

  const getCalculation = (impactType: string) => {
    if (impactType === "Cost") {
      return costCalculations;
    } else if (impactType === "Revenue") {
      return revenueCalculations;
    } else if (impactType === "Cost & Revenue") {
      return combinedCalculations;
    } else {
      return [];
    }
  };
  const displayedCalculation = getCalculation(props.project.impactType);

  let calculationResult = 0;
  if (impactValues[0]?.value) {
    calculationResult = impactValues[0].value;
  } else {
    displayedCalculation.forEach((calculation: CalculationType) => {
      const variables = calculation.variables;
      const res = hasMonetaryValue(variables) ? calculate(variables) : 0;
      calculationResult += +res;
    });
  }

  const shouldShowCostCalculation =
    props.project.impactType === "Cost" ||
    props.project.impactType === "Cost & Revenue";
  const shouldShowRevenueCalculation =
    props.project.impactType === "Revenue" ||
    props.project.impactType === "Cost & Revenue";

  return (
    <Fragment>
      {props.project && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Grid item>
            <SelectInput
              id="impactType"
              labelText="Impact Type"
              selectValues={impactTypeSelectValues}
              required
              value={props.project.impactType}
              onChange={(e) => props.onChange({ impactType: e.target.value })}
              editMode={props.editMode}
            />
          </Grid>

          {props.project.impactType && (
            <Container>
              <Box sx={{ display: "flex", gap: 5 }}>
                <Description>
                  <Header>
                    <EuroSymbolIcon fontSize="small" />
                    <Typography variant="caption" color="primary">
                      Impact Value Calculation
                    </Typography>
                  </Header>
                  <ImpactValueContent>{description}</ImpactValueContent>
                </Description>
                <ResultWrapper>
                  <Typography variant="body1" color="text.disabled">
                    {title}
                  </Typography>
                  <Result>
                    <Value data-testid="impact-value-result">
                      {thousandSeparator(Math.round(calculationResult))}
                    </Value>
                    <Typography color="primary">€</Typography>
                  </Result>
                </ResultWrapper>
              </Box>
              <Box>
                {shouldShowCostCalculation && (
                  <CalculationContainer
                    type="Cost"
                    calculations={costCalculations}
                    handleImpactvalue={handleImpactValue}
                    setImpactValues={setImpactValues}
                    fetchImpactValues={fetchImpactValues}
                    impactValues={impactValues}
                    projectId={props.project.id}
                  />
                )}
                {shouldShowRevenueCalculation && (
                  <CalculationContainer
                    type="Revenue"
                    calculations={revenueCalculations}
                    handleImpactvalue={handleImpactValue}
                    setImpactValues={setImpactValues}
                    fetchImpactValues={fetchImpactValues}
                    impactValues={impactValues}
                    projectId={props.project.id}
                  />
                )}
              </Box>
            </Container>
          )}

          <Grid item xs={12}>
            <CustomTextField
              id="assumptions"
              label="Assumptions"
              editMode={props.editMode}
              value={props.project.assumptionsDiscover}
              onChange={(e) => {
                props.onChange({ assumptionsDiscover: e.target.value });
              }}
              fullWidth
              multiline
              minRows={2}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              id="intangibleBenefits"
              label="Intangible Benefits"
              editMode={props.editMode}
              value={props.project.strategicBenefits}
              maxCharacter={255}
              onChange={(e) =>
                props.onChange({ strategicBenefits: e.target.value })
              }
              fullWidth
              multiline
              minRows={2}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              id="businessImpactPotentialComment"
              label="Comment"
              editMode={props.editMode}
              value={props.project.businessImpactPotentialComment}
              onChange={(e) =>
                props.onChange({
                  businessImpactPotentialComment: e.target.value,
                })
              }
              fullWidth
              multiline
              minRows={2}
            />
          </Grid>
        </Box>
      )}
    </Fragment>
  );
}
